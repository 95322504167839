import React, { useEffect, useState, useContext } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"


//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _, { iteratee } from "lodash"
import fn_helper from "helpers/fn_helper"
import { CreatePromotionMain, GetPromotionMainById, UpdatePromotionMain } from "services/api/module/PromotionMain"
import ModalContext from "../../contexts/ModalContext";
import UploadFileAPI from "components/UploadFileAPI"
import InputSwitch from "components/Input/InputSwitch"
import MyEditor from "components/MyEditor"
import InputRadio from "components/Input/InputRadio"
import Stepper from "components/Stepper"
import moment from "moment"
import { SearchProductCategory } from "services/api/module/ProductCategory"
import { GetProductByProductCateId } from "services/api/module/Products"
import ProductCategoryPopup from "./Popup/ProductCategoryPopup"
import PromotionMainContainer from "./Container/PromotionMainContainer"

const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const PERIOD_TYPE_OPTION = [
  { id: 1, name: "Always On" },
  { id: 2, name: "Limit Time" },
]

const tempUserType = ["individule", "corporate", "guest"];
const tempUserSegmentation = ["haveOrder", "firstTime"];

const PromotionMainTemplate = ({
  pageView = "create"
}) => {
  const [productCategoryId, setProductCategoryId] = useState("")
  const [productCategoryName, setProductCategoryName] = useState("")

  // STATE ERROR FLAGE
  const [errorFlage1, setErrorFlage1] = useState(false)
  const [errorFlage2, setErrorFlage2] = useState(false)

  // STATE CHECKBOX OBJ
  const [checkboxUserType, setCheckboxUserType] = useState({
    individule: { value: true, isDisabled: false },
    corporate: { value: false, isDisabled: false },
    guest: { value: false, isDisabled: false }
  });
  const [checkboxUserSM, setCheckboxUserSM] = useState({
    haveOrder: { value: false },
    firstTime: { value: false }
  });

  // POPUP
  const { mAlert } = useContext(ModalContext);
  const [isOpen, setIsOpen] = useState(false)

  // OTHER STATE
  const qParams = useParams()
  const qPromotionMainId = qParams.id
  const navigate = useNavigate();

  // STEPPER STATE
  const [activeTab, setActiveTab] = useState(1)

  // LOCAL STATE
  const [contentList, setContentList] = useState([])
  const [contentProductList, setContentProductList] = useState([])


  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    nameTh: Yup.string().max(100).required("Please Enter Name TH"),
    nameEn: Yup.string().max(100).required("Please Enter Name EN"),
    detailTh: Yup.string().required("Please Enter Short Description TH"),
    detailEn: Yup.string().required("Please Enter Short Description En"),
    seoUrlKey: Yup.string().max(100).required("Please Enter URL Key"),
    seoMetaTitle: Yup.string().max(100).required("Please Enter Meta Title"),
    seoMetaKeyword: Yup.string().max(100).required("Please Enter Meta keyword"),
    seoMetaDescription: Yup.string().max(100).required("Please Enter Meta Description"),
    status: Yup.string().max(100).required("Please Enter Status"),
    startDate: Yup.number().when("periodType", {
      is: value => value === 2,
      then: schema =>
        schema
          .required("Please Enter Start Date")
    }),
    endDate: Yup.number().when("periodType", {
      is: value => value === 2,
      then: schema =>
        schema
          .required("Please Enter Start Date")
    }),
  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      nameTh: "",
      nameEn: "",
      detailTh: "",
      detailEn: "",
      seoUrlKey: "",
      seoMetaTitle: "",
      seoMetaKeyword: "",
      seoMetaDescription: "",
      isDefault: false,
      userType: "",
      userSegmentation: "",
      periodType: 1,
      startDate: "",
      endDate: "",
      conditionTh: "",
      conditionEn: "",
      status: "",
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ PromotionMainCreate ~ values:", values)

      const payload = {
        id: values.id ? values.id : undefined,
        nameTh: values.nameTh,
        nameEn: values.nameEn,
        detailTh: values.detailTh,
        detailEn: values.detailEn,
        seoUrlKey: values.seoUrlKey,
        seoMetaTitle: values.seoMetaTitle,
        seoMetaKeyword: values.seoMetaKeyword,
        seoMetaDescription: values.seoMetaDescription,
        isDefault: values.isDefault ? '1' : '0',
        status: Number(values.status),
        periodType: values.periodType,
        startDate: moment(values.startDate, 'YYYY-MM-DDTHH:mm').toISOString(),
        endDate: moment(values.endDate, 'YYYY-MM-DDTHH:mm').toISOString(),
        conditionTh: values.conditionTh,
        conditionEn: values.conditionEn

      };
      console.log("🚀 ~ onSave ~ payload:", payload);

      if (pageView === "update") {
        API.fetchUpdatePromotionMain(payload)
      } else {
        API.fetchCreatePromotionMain(payload)
      }
    },
  })

  const prepareJSON = (value, code) => {
    const resultObj = JSON.parse(value);
    console.log("🚀 ~ prepareJSON ~ resultObj:", resultObj)
    console.log("🚀 ~ prepareJSON ~ tempUserType:", tempUserType)
    return resultObj
  }

  const checkErrorFlag = (values) => {
    const valuesArray = Object.values(values);
    const errorFlag = !valuesArray.some((item) => item.value === true);
    return errorFlag;
  };


  // ฟังก์ชัน onChange
  const handleCheckboxChange = (key, checked, code) => {

    switch (code) {
      case 'user-type':
        if (key === 'individule' || key === 'guest') {
          setCheckboxUserType((prev) => ({
            ...prev,
            ['corporate']: { ...prev['corporate'], value: false, isDisabled: !checked ? false : true }
          }
          ));
        }
        else if (key === 'corporate') {
          setCheckboxUserType((prev) => ({
            ...prev,
            ['individule']: { ...prev['individule'], value: false, isDisabled: !checked ? false : true },
            ['guest']: { ...prev['guest'], value: false, isDisabled: !checked ? false : true }
          }
          ));
        }
        // set state checkbox
        setCheckboxUserType((prev) => ({
          ...prev,
          [key]: { ...prev[key], value: checked }
        }));
        break;
      case 'user-sm':
        setCheckboxUserSM((prev) => ({
          ...prev,
          [key]: { ...prev[key], value: checked }
        }));
        break;

      default:
        return null
    }

  };


  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("parentName", resData.parentName)
    validation.setFieldValue("nameTh", resData.nameTh)
    validation.setFieldValue("nameEn", resData.nameEn)
    validation.setFieldValue("detailTh", resData.detailTh)
    validation.setFieldValue("detailEn", resData.detailEn)
    validation.setFieldValue("userType", prepareJSON(resData.userType, "user-type"))
    validation.setFieldValue("userSegmentation", prepareJSON(resData.userSegmentation, "user-sm"))
    validation.setFieldValue("seoUrlKey", resData.seoUrlKey)
    validation.setFieldValue("seoMetaTitle", resData.seoMetaTitle)
    validation.setFieldValue("seoMetaKeyword", resData.seoMetaKeyword)
    validation.setFieldValue("seoMetaDescription", resData.seoMetaDescription)
    validation.setFieldValue("isDefault", resData.isDefault ? true : false)
    validation.setFieldValue("periodType", resData.periodType)
    validation.setFieldValue("startDate", moment(resData.startDate).format('YYYY-MM-DDTHH:mm'))
    validation.setFieldValue("endDate", moment(resData.endDate).format('YYYY-MM-DDTHH:mm'))
    validation.setFieldValue("conditionTh", resData.conditionTh)
    validation.setFieldValue("conditionEn", resData.conditionEn)
    validation.setFieldValue("status", resData.status)

  }

  const onStep = (step) => {
    switch (step) {
      case 0:
        setErrorFlage1(false)
        setErrorFlage2(false)
        const errorFlage1 = checkErrorFlag(checkboxUserType)
        const errorFlage2 = checkErrorFlag(checkboxUserSM)

        const isEmpty =
          validation.values.nameTh === "" ||
            validation.values.nameEn === "" ||
            validation.values.detailTh === "" ||
            validation.values.detailEn === "" ||
            validation.values.seoUrlKey === "" ||
            validation.values.seoMetaTitle === "" ||
            validation.values.seoMetaKeyword === "" ||
            validation.values.seoMetaDescription === "" ||
            validation.values.status === ""
            ? true : false

        if (isEmpty && errorFlage1 || errorFlage2) {
          validation.setFieldTouched("nameTh", true)
          validation.setFieldTouched("nameEn", true)
          validation.setFieldTouched("detailTh", true)
          validation.setFieldTouched("detailEn", true)
          validation.setFieldTouched("seoUrlKey", true)
          validation.setFieldTouched("seoMetaTitle", true)
          validation.setFieldTouched("seoMetaKeyword", true)
          validation.setFieldTouched("seoMetaDescription", true)
          validation.setFieldTouched("status", true)
          setErrorFlage1(errorFlage1)
          setErrorFlage2(errorFlage2)
        } else {

          // ปิดไว้ก่อน เปิดตอนเสร็จแล้ว

          // get product by category
          // if (validation.values.isDefault) {
          //   API.fetchProductCategoryList()
          // } else {
          //   // get product list
          // }
          setActiveTab(activeTab + 1)
        }
        break;

      case 1:
        console.log("STEP 1")
        break;

      default:
        break;
    }

  }

  const API = {
    fetchGetPromotionMainById: async (payload) => {
      try {
        const response = await GetPromotionMainById({ id: payload.id })
        const resData = response.data
        if (resData.status == 202) {
          preFilInput(resData.data)
        }
      }
      catch (err) {
        console.log('err ', err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchCreatePromotionMain: async (payload) => {
      try {
        const response = await CreatePromotionMain({ data: payload })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Created Data Success",
            content: "",
            onClose: () => {
              navigate('/promotionMain/promotionMain')
            },
          })
        }
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchUpdatePromotionMain: async (payload) => {
      try {
        const response = await UpdatePromotionMain({ data: payload, id: payload.id })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              navigate('/promotionMain/promotionMain')
            },
          })
        }
      }
      catch (err) {
        console.log('err ', err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchProductCategoryList: async () => {
      try {
        const response = await SearchProductCategory({})
        const resData = response?.data ?? []
        setContentList(resData.data)
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
  }

  const fetchAPI = () => {
    const payload = {}
    API.fetchProductCategoryList(payload)
  }

  useEffect(() => {
    fetchAPI()
  }, [])

  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id: qPromotionMainId }
      API.fetchGetPromotionMainById(payload)
    }
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `PromotionMain | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE


  // RENDER
  const STEP_CONTENT = [
    {
      stepLabel: 'General Infomation',
      components: <>
        <Col className="col-12">
          <Row className="">
            <Col className="col-6">
              <Col className="col-12">
                <h5 className="mb-3">Discount Information</h5>
              </Col>
              <Row className="">
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="label_name"
                    className="col-md-12 col-form-label"
                  >
                    ชื่อภาษาไทย
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="nameTh"
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="nameTh"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.nameTh}
                      invalid={validation.touched.nameTh && validation.errors.nameTh ? true : false}
                      disabled={pageView === "view"}
                    />
                    {
                      validation.touched.nameTh && validation.errors.nameTh && (
                        <FormFeedback type="invalid">
                          {validation.errors.nameTh}
                        </FormFeedback>
                      )
                    }
                  </div>
                </Col>
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="label_name"
                    className="col-md-12 col-form-label"
                  >
                    ชื่อภาษาอังกฤษ
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="nameEn"
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="nameEn"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.nameEn}
                      invalid={validation.touched.nameEn && validation.errors.nameEn ? true : false}
                      disabled={pageView === "view"}
                    />
                    {
                      validation.touched.nameEn && validation.errors.nameEn && (
                        <FormFeedback type="invalid">
                          {validation.errors.nameEn}
                        </FormFeedback>
                      )
                    }
                  </div>
                </Col>
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="label_name"
                    className="col-md-12 col-form-label"
                  >
                    รายละเอียดภาษาไทย
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id=""
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="detailTh"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.detailTh}
                      invalid={validation.touched.detailTh && validation.errors.detailTh ? true : false}
                      disabled={pageView === "view"}
                    />
                    {
                      validation.touched.detailTh && validation.errors.detailTh && (
                        <FormFeedback type="invalid">
                          {validation.errors.detailTh}
                        </FormFeedback>
                      )
                    }
                  </div>
                </Col>
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="label_name"
                    className="col-md-12 col-form-label"
                  >
                    รายละเอียดภาษาอังกฤษ
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="detailEn"
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="detailEn"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.detailEn}
                      invalid={validation.touched.detailEn && validation.errors.detailEn ? true : false}
                      disabled={pageView === "view"}
                    />
                    {
                      validation.touched.detailEn && validation.errors.detailEn && (
                        <FormFeedback type="invalid">
                          {validation.errors.detailEn}
                        </FormFeedback>
                      )
                    }
                  </div>
                </Col>
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="label_name"
                    className="col-md-12 col-form-label"
                  >
                    เป็นโปรโมชัน Default
                  </label>
                  < InputSwitch
                    labelTrue="Yes"
                    labelFalse="No"
                    value={validation.values.isDefault}
                    onChange={value => {
                      validation.setFieldValue("isDefault", value)
                      validation.setFieldValue("periodType", 1)
                    }}
                    disabled={pageView === "view"}
                  />
                </Col>
              </Row>
            </Col>
            <Col className="col-6">
              <Col className="col-12">
                <h5 className="mb-3">SEO Setting</h5>
              </Col>
              <Row className="">
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="label_name"
                    className="col-md-12 col-form-label"
                  >
                    URL Key
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="seoUrlKey"
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="seoUrlKey"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.seoUrlKey}
                      invalid={validation.touched.seoUrlKey && validation.errors.seoUrlKey ? true : false}
                      disabled={pageView === "view"}
                    />
                    {
                      validation.touched.seoUrlKey && validation.errors.seoUrlKey && (
                        <FormFeedback type="invalid">
                          {validation.errors.seoUrlKey}
                        </FormFeedback>
                      )
                    }
                  </div>
                </Col>
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="label_name"
                    className="col-md-12 col-form-label"
                  >
                    Meta Title
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="seoMetaTitle"
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="seoMetaTitle"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.seoMetaTitle}
                      invalid={validation.touched.seoMetaTitle && validation.errors.seoMetaTitle ? true : false}
                      disabled={pageView === "view"}
                    />
                    {
                      validation.touched.seoMetaTitle && validation.errors.seoMetaTitle && (
                        <FormFeedback type="invalid">
                          {validation.errors.seoMetaTitle}
                        </FormFeedback>
                      )
                    }
                  </div>
                </Col>
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="label_name"
                    className="col-md-12 col-form-label"
                  >
                    Meta keyword
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="seoMetaKeyword"
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="seoMetaKeyword"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.seoMetaKeyword}
                      invalid={validation.touched.seoMetaKeyword && validation.errors.seoMetaKeyword ? true : false}
                      disabled={pageView === "view"}
                    />
                    {
                      validation.touched.seoMetaKeyword && validation.errors.seoMetaKeyword && (
                        <FormFeedback type="invalid">
                          {validation.errors.seoMetaKeyword}
                        </FormFeedback>
                      )
                    }
                  </div>
                </Col>
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="label_name"
                    className="col-md-12 col-form-label"
                  >
                    Meta Description
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="seoMetaDescription"
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="seoMetaDescription"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.seoMetaDescription}
                      invalid={validation.touched.seoMetaDescription && validation.errors.seoMetaDescription ? true : false}
                      disabled={pageView === "view"}
                    />
                    {
                      validation.touched.seoMetaDescription && validation.errors.seoMetaDescription && (
                        <FormFeedback type="invalid">
                          {validation.errors.seoMetaDescription}
                        </FormFeedback>
                      )
                    }
                  </div>
                </Col>

                <Col className="col-12 mb-3">
                  <label
                    htmlFor="label_name"
                    className="col-md-12 col-form-label"
                  >
                    Status (Active/Inactive)
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Select
                      placeholder="Select Status"
                      value={fn_helper.FNFORM.getObjectValue(STATUS_OPTION, `${validation.values.status}`)}
                      onChange={(value) => validation.setFieldValue("status", value.id)}
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      options={STATUS_OPTION}
                      className={`select2-selection ${validation.touched.status && validation.errors.status ? "input-err" : ""}`}
                      isDisabled={pageView === "view"}
                    />
                    {
                      (validation.touched.status && validation.errors.status) && (
                        <div type="invalid" className="invalid-feedback d-block">{validation.errors.status}</div>
                      )
                    }
                    {
                      validation.touched.status && validation.errors.status && (
                        <FormFeedback type="invalid">
                          {validation.errors.status}
                        </FormFeedback>
                      )
                    }
                  </div>
                </Col>

              </Row>
            </Col>
          </Row>

          <Row className="">
            <Col className="col-6">
              <Col className="col-12 mb-3">
                <label
                  htmlFor="label_name"
                  className="col-md-12 col-form-label"
                >
                  ลูกค้าที่สามารถรับโปรโมชั่นได้
                  <span className="t-require">*</span>
                </label>

                <Row className="">
                  {tempUserType.map((item, i) => {
                    return (
                      <Col key={i} className="col-4">
                        <InputGroup>
                          <div className="form-check form-check-primary mb-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              value={checkboxUserType[item]?.value || false}
                              onChange={(e) => handleCheckboxChange(item, e.target.checked, 'user-type')}
                              disabled={checkboxUserType[item]?.isDisabled || pageView === "view"} // เช็ค isDisabled
                            />
                            <label className="form-check-label" htmlFor={`customCheck`}>
                              {item === 'individule' ? `บุคคลธรรมดา(${item})` : item === 'corporate' ? `นิติบุคคล(${item})` : `บุคคลทั่วไป(${item})`}
                            </label>
                          </div>
                        </InputGroup>
                      </Col>
                    );
                  })}

                  {errorFlage1 && (
                    <Col className="col-4" style={{ color: 'red' }}>{"Please Enter User Type"}</Col>
                  )
                  }
                  {/* <pre>{JSON.stringify(checkboxUserType, null, 2)}</pre> */}
                </Row>

                <Row className="">
                  <Col className="col-6">
                    <label
                      htmlFor="label_name"
                      className="col-md-12 col-form-label"
                    >
                      ลูกค้าใหม่หรือลูกค้าเก่า
                      <span className="t-require">*</span>
                    </label>
                  </Col>
                </Row>

                <Row className="">
                  {tempUserSegmentation.map((item, i) => {
                    return (
                      <Col key={i} className="col-4">
                        <InputGroup>
                          <div className="form-check form-check-primary mb-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              value={checkboxUserSM[item]?.value || false}
                              onChange={(e) => handleCheckboxChange(item, e.target.checked, 'user-sm')}
                              disabled={pageView === "view"}
                            />
                            <label className="form-check-label" htmlFor={`customCheck`}>
                              {item === 'haveOrder' ? 'ลูกค้าเก่า' : ' ลูกค้าใหม่'}
                            </label>

                          </div>
                        </InputGroup>
                      </Col>
                    );
                  })}

                  {errorFlage2 && (
                    <Col className="col-6" style={{ color: 'red' }}>{"Please Enter User Segmentation"}</Col>
                  )
                  }

                  {/* <pre>{JSON.stringify(checkboxUserSM, null, 2)}</pre> */}

                  <Col className="col-12 mb-3">
                    <label
                      htmlFor="label_name"
                      className="col-md-12 col-form-label"
                    >
                      รูปแบบเวลาการลดราคา
                      <span className="t-require">*</span>
                    </label>
                    <InputRadio
                      label=""
                      name="periodType"
                      value={validation.values.periodType}
                      option={PERIOD_TYPE_OPTION}
                      onChange={value => {
                        validation.setFieldValue("periodType", value)
                      }}
                      disabled={pageView === "view" || validation.values.isDefault}
                    />
                  </Col>

                </Row>
              </Col>
            </Col>
            <Col className="col-12">
              {validation.values.periodType === 2 && (
                <Row className="">
                  <Col className="col-6 mb-3">
                    <label
                      htmlFor="startDate"
                      className="col-md-12 col-form-label"
                    >
                      Start Date
                      <span className="t-require">*</span>
                    </label>
                    <div className="col-md-12">
                      <Input
                        id="startDate"
                        className="form-control"
                        type="datetime-local"
                        placeholder=""
                        name="startDate"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.startDate}
                        invalid={
                          validation.touched.startDate &&
                            validation.errors.startDate
                            ? true
                            : false
                        }
                        disabled={pageView === "view"}
                      />
                      {validation.touched.startDate &&
                        validation.errors.startDate && (
                          <FormFeedback type="invalid">
                            {validation.errors.startDate}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                  <Col className="col-6 mb-3">
                    <label
                      htmlFor="endDate"
                      className="col-md-12 col-form-label"
                    >
                      End Date
                      <span className="t-require">*</span>
                    </label>
                    <div className="col-md-12">
                      <Input
                        id="endDate"
                        className="form-control"
                        type="datetime-local"
                        placeholder=""
                        name="endDate"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.endDate}
                        invalid={
                          validation.touched.endDate &&
                            validation.errors.endDate
                            ? true
                            : false
                        }
                        disabled={pageView === "view"}
                      />
                      {validation.touched.endDate &&
                        validation.errors.endDate && (
                          <FormFeedback type="invalid">
                            {validation.errors.endDate}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                </Row>
              )}

              <Col className="col-12 mb-3">
                <label
                  htmlFor="detail_th"
                  className="col-md-12 col-form-label"
                >
                  Detail TH
                </label>
                <div className="col-md-12">
                  <div
                    className={
                      validation.touched.conditionTh &&
                        validation.errors.conditionTh
                        ? "invalid-editor"
                        : ""
                    }
                  >
                    <MyEditor
                      value={validation.values.conditionTh}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        validation.setFieldValue(
                          "conditionTh",
                          data
                        )
                        console.log({ event, editor, data })
                      }}
                      disabled={pageView === "view"}
                    />
                  </div>

                  {validation.touched.conditionTh &&
                    validation.errors.conditionTh && (
                      <div
                        type="invalid"
                        className="invalid-feedback d-block"
                      >
                        {validation.errors.conditionTh}
                      </div>
                    )}
                </div>
              </Col>
              <Col className="col-12 mb-3">
                <label
                  htmlFor="detail_en"
                  className="col-md-12 col-form-label"
                >
                  Detail En
                </label>
                <div className="col-md-12">
                  <div
                    className={
                      validation.touched.conditionEn &&
                        validation.errors.conditionEn
                        ? "invalid-editor"
                        : ""
                    }
                  >
                    <MyEditor
                      value={validation.values.conditionEn}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        validation.setFieldValue(
                          "conditionEn",
                          data
                        )
                        console.log({ event, editor, data })
                      }}
                      disabled={pageView === "view"}
                    />
                  </div>

                  {validation.touched.conditionEn &&
                    validation.errors.conditionEn && (
                      <div
                        type="invalid"
                        className="invalid-feedback d-block"
                      >
                        {validation.errors.conditionEn}
                      </div>
                    )}
                </div>
              </Col>
            </Col>
          </Row>

          <Row className="">
            <Col className="col-6">
              <Col className="col-12">
                <h5 className="mb-3">Discount Theme</h5>
              </Col>
              <Row className="">
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="label_name"
                    className="col-md-12 col-form-label"
                  >
                    Text on Frame
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="textOnProductFrame"
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="textOnProductFrame"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.textOnProductFrame}
                      invalid={validation.touched.textOnProductFrame && validation.errors.textOnProductFrame ? true : false}
                      disabled={pageView === "view"}
                    />
                    {
                      validation.touched.textOnProductFrame && validation.errors.textOnProductFrame && (
                        <FormFeedback type="invalid">
                          {validation.errors.textOnProductFrame}
                        </FormFeedback>
                      )
                    }
                  </div>
                </Col>
                <Col className="col-12 mb-3">
                  <Row className="">
                    <Col className="col-6 mb-3">
                      <label
                        htmlFor="label_name"
                        className="col-md-12 col-form-label"
                      >
                        Product Frame 1st Color
                      </label>
                      <div className="col-md-12">
                        <Input
                          id="firstProductFrameColor"
                          type="color"
                          name="firstProductFrameColor"
                          value={validation.values.firstProductFrameColor}
                          style={{ width: "200px", height: "calc(1.5em + 0.94rem + calc(var(--bs-border-width) * 2))" }}
                          onChange={e =>
                            validation.setFieldValue(
                              "firstProductFrameColor",
                              e.target.value
                            )
                          }
                          className="form-control form-control-color"
                          invalid={
                            validation.touched.firstProductFrameColor &&
                              validation.errors.firstProductFrameColor
                              ? true
                              : false
                          }
                          disabled={pageView === "view"}
                        />
                        {
                          validation.touched.firstProductFrameColor && validation.errors.firstProductFrameColor && (
                            <FormFeedback type="invalid">
                              {validation.errors.firstProductFrameColor}
                            </FormFeedback>
                          )
                        }
                      </div>
                    </Col>
                    <Col className="col-6 mb-3">
                      <label
                        htmlFor="label_name"
                        className="col-md-12 col-form-label"
                      >
                        Product Frame 2nd Color
                      </label>
                      <div className="col-md-12">
                        <Input
                          id="secondProductFrameColor"
                          type="color"
                          name="secondProductFrameColor"
                          value={validation.values.secondProductFrameColor}
                          style={{ width: "200px", height: "calc(1.5em + 0.94rem + calc(var(--bs-border-width) * 2))" }}
                          onChange={e =>
                            validation.setFieldValue(
                              "secondProductFrameColor",
                              e.target.value
                            )
                          }
                          className="form-control form-control-color"
                          invalid={
                            validation.touched.secondProductFrameColor &&
                              validation.errors.secondProductFrameColor
                              ? true
                              : false
                          }
                          disabled={pageView === "view"}
                        />
                        {
                          validation.touched.secondProductFrameColor && validation.errors.secondProductFrameColor && (
                            <FormFeedback type="invalid">
                              {validation.errors.secondProductFrameColor}
                            </FormFeedback>
                          )
                        }
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col className="col-12">
              <Row className="">
                <Col className="col-12 mb-3">
                  <div className="col-md-12">
                    <UploadFileAPI
                      bucketName="shipping-method"
                      upload="Image"
                      typeUpload="ImageMap"
                      prefixName="promotion-category-image"
                      label="Icon"
                      required={true}
                      widthSize={97}
                      heightSize={24}
                      description="description"
                      value={validation.values.icon}
                      onChange={([
                        imageURL = "",
                        imageSize = "",
                      ]) => {
                        validation.setFieldValue(
                          "icon",
                          imageURL
                        )
                      }}
                    />
                    <Input
                      id="icon"
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="icon"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.icon}
                      invalid={
                        validation.touched.icon &&
                          validation.errors.icon
                          ? true
                          : false
                      }
                      readOnly={true}
                      disabled={pageView === "view" || pageView === "update"}
                    />
                    {validation.touched.icon &&
                      validation.errors.icon && (
                        <div
                          type="invalid"
                          className="invalid-feedback d-block"
                        >
                          {validation.errors.icon}
                        </div>
                      )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col >
      </>,
    },
    {
      stepLabel: 'Discount & Product',
      components: <>
        <Col className="col-12">
          <Row className="">
            <Col className="col-6">
              <Row className="">

                <Col className="col-12 mb-3">
                  <Button color="primary" className="btn btn-primary waves-effect waves-light" onClick={() => setIsOpen(true)}>Select Product Category</Button>
                </Col>
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="label_name"
                    className="col-md-12 col-form-label"
                  >
                    Product Category
                    <span className="t-require">*</span>
                  </label>
                  <div className="col-md-12">
                    <Input
                      id="productCategoryName"
                      className="form-control"
                      type="text"
                      placeholder="Please Select Product Category"
                      name="productCategoryName"
                      value={productCategoryName}
                      disabled={true}
                    />
                  </div>
                </Col>
                <Row>
                  <Col>
                    <Card>
                      <ProductCategoryPopup
                        isOpen={isOpen}
                        onClose={() => {
                          setIsOpen(false)
                        }}
                        onChange={(productCateId) => {
                          setProductCategoryId(productCateId)
                          setProductCategoryName(contentList.find(item => item.id == productCateId).cateNameTh)
                          setIsOpen(false)

                        }}
                        pageView={pageView}
                        contentList={contentList}
                      />
                    </Card>
                  </Col>
                </Row>
              </Row>
              <Row>
                <Col className="col-12">
                  <Col className="col-6">
                    <h5 className="mb-3">ข้อมูลการลดราคา</h5>
                  </Col>
                </Col>
                <Col className="col-12">
                  <Row className="">
                    {/* <Col className="col-2 mb-3">
                      <label
                        htmlFor="label_name"
                        className="col-md-12 col-form-label"
                      >
                        URL Key
                        <span className="t-require">*</span>
                      </label>
                      <div className="col-md-12">
                        <Input
                          id="seoUrlKey"
                          className="form-control"
                          type="text"
                          placeholder=""
                          name="seoUrlKey"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.seoUrlKey}
                          invalid={validation.touched.seoUrlKey && validation.errors.seoUrlKey ? true : false}
                          disabled={pageView === "view"}
                        />
                        {
                          validation.touched.seoUrlKey && validation.errors.seoUrlKey && (
                            <FormFeedback type="invalid">
                              {validation.errors.seoUrlKey}
                            </FormFeedback>
                          )
                        }
                      </div>
                    </Col>
                    <Col className="col-2 mb-3">
                      <label
                        htmlFor="label_name"
                        className="col-md-12 col-form-label"
                      >
                        URL Key
                        <span className="t-require">*</span>
                      </label>
                      <div className="col-md-12">
                        <Input
                          id="seoUrlKey"
                          className="form-control"
                          type="text"
                          placeholder=""
                          name="seoUrlKey"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.seoUrlKey}
                          invalid={validation.touched.seoUrlKey && validation.errors.seoUrlKey ? true : false}
                          disabled={pageView === "view"}
                        />
                        {
                          validation.touched.seoUrlKey && validation.errors.seoUrlKey && (
                            <FormFeedback type="invalid">
                              {validation.errors.seoUrlKey}
                            </FormFeedback>
                          )
                        }
                      </div>
                    </Col>
                    <Col className="col-2 mb-3">
                      <label
                        htmlFor="label_name"
                        className="col-md-12 col-form-label"
                      >
                        URL Key
                        <span className="t-require">*</span>
                      </label>
                      <div className="col-md-12">
                        <Input
                          id="seoUrlKey"
                          className="form-control"
                          type="text"
                          placeholder=""
                          name="seoUrlKey"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.seoUrlKey}
                          invalid={validation.touched.seoUrlKey && validation.errors.seoUrlKey ? true : false}
                          disabled={pageView === "view"}
                        />
                        {
                          validation.touched.seoUrlKey && validation.errors.seoUrlKey && (
                            <FormFeedback type="invalid">
                              {validation.errors.seoUrlKey}
                            </FormFeedback>
                          )
                        }
                      </div>
                    </Col>
                    <Col className="col-2 mb-3">
                      <label
                        htmlFor="label_name"
                        className="col-md-12 col-form-label"
                      >
                        URL Key
                        <span className="t-require">*</span>
                      </label>
                      <div className="col-md-12">
                        <Input
                          id="seoUrlKey"
                          className="form-control"
                          type="text"
                          placeholder=""
                          name="seoUrlKey"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.seoUrlKey}
                          invalid={validation.touched.seoUrlKey && validation.errors.seoUrlKey ? true : false}
                          disabled={pageView === "view"}
                        />
                        {
                          validation.touched.seoUrlKey && validation.errors.seoUrlKey && (
                            <FormFeedback type="invalid">
                              {validation.errors.seoUrlKey}
                            </FormFeedback>
                          )
                        }
                      </div>
                    </Col>
                    <Col className="col-2 mb-3">
                      <label
                        htmlFor="label_name"
                        className="col-md-12 col-form-label"
                      >
                        URL Key
                        <span className="t-require">*</span>
                      </label>
                      <div className="col-md-12">
                        <Input
                          id="seoUrlKey"
                          className="form-control"
                          type="text"
                          placeholder=""
                          name="seoUrlKey"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.seoUrlKey}
                          invalid={validation.touched.seoUrlKey && validation.errors.seoUrlKey ? true : false}
                          disabled={pageView === "view"}
                        />
                        {
                          validation.touched.seoUrlKey && validation.errors.seoUrlKey && (
                            <FormFeedback type="invalid">
                              {validation.errors.seoUrlKey}
                            </FormFeedback>
                          )
                        }
                      </div>
                    </Col>
                    <Col className="col-2 mb-3">
                      <label
                        htmlFor="label_name"
                        className="col-md-12 col-form-label"
                      >
                        URL Key
                        <span className="t-require">*</span>
                      </label>
                      <div className="col-md-12">
                        <Input
                          id="seoUrlKey"
                          className="form-control"
                          type="text"
                          placeholder=""
                          name="seoUrlKey"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.seoUrlKey}
                          invalid={validation.touched.seoUrlKey && validation.errors.seoUrlKey ? true : false}
                          disabled={pageView === "view"}
                        />
                        {
                          validation.touched.seoUrlKey && validation.errors.seoUrlKey && (
                            <FormFeedback type="invalid">
                              {validation.errors.seoUrlKey}
                            </FormFeedback>
                          )
                        }
                      </div>
                    </Col> */}

                  </Row>
                </Col>
              </Row>
            </Col>

            <Col className="col-12">

              <PromotionMainContainer
                // cateId={productCategoryId}
                cateId={"674d5a4179b82474f874a2ff"}
                pageView={pageView}
              />
            </Col>

          </Row>

        </Col>
      </>
    }
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} PromotionMain`}
            breadcrumbItems={[
              { title: "PromotionMain", link: "promotionMain/promotionMain" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <h5 className="mb-3">PromotionMain</h5>
                      <Stepper
                        value={activeTab}
                        componentSteps={STEP_CONTENT}
                      />
                    </Row>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
                      <button type="button" className="btn btn-primary w-md"
                        onClick={() => { setActiveTab(activeTab - 1) }}
                        disabled={activeTab === 0}
                      >
                        Previous
                      </button>
                      {
                        activeTab === 0 && (
                          <>
                            {
                              pageView == "view"
                                ? <><button className="btn btn-primary w-md" onClick={() => navigate('/promotionMain/promotionMain')}>BACK</button></>
                                : <>
                                  <button type="button" className="btn btn-primary w-md" onClick={() => onStep(activeTab)} >Next</button>
                                  <button className="btn btn-secondary w-md" onClick={() => navigate('/promotionMain/promotionMain')}>CANCEL</button>
                                </>
                            }
                          </>

                        )
                      }
                      {
                        activeTab === 1 && (
                          <>
                            {
                              pageView == "view"
                                ? <><button className="btn btn-primary w-md" onClick={() => navigate('/promotionMain/promotionMain')}>BACK</button></>
                                : <>
                                  <button type="button" className="btn btn-primary w-md" onClick={() => onStep(activeTab)} >Next</button>
                                  <button className="btn btn-secondary w-md" onClick={() => navigate('/promotionMain/promotionMain')}>CANCEL</button>
                                </>
                            }
                          </>

                        )
                      }
                      {
                        activeTab === 2 && (
                          <>
                            {
                              pageView == "view"
                                ? <><button className="btn btn-primary w-md" onClick={() => navigate('/promotionMain/promotionMain')}>BACK</button></>
                                : <>
                                  <button type="submit" className="btn btn-primary w-md">SAVE</button>
                                  <button className="btn btn-secondary w-md" onClick={() => navigate('/promotionMain/promotionMain')}>CANCEL</button>
                                </>
                            }
                          </>
                        )
                      }

                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(PromotionMainTemplate)
