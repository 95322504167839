import React, { useEffect, useState, useContext } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  Container,
  Input,
  Form,
  FormFeedback,
} from "reactstrap"

import Select from "react-select"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import fn_helper from "helpers/fn_helper"
import ModalContext from "../../contexts/ModalContext";
import moment from "moment"
import { GetById, Update, Create } from "services/api/module/InstallmentPlan"
import { DropdownEnumInstallmentPlans } from "services/api/module/DropdownEnum"


const InstallmentPlanTemplate = ({
  pageView = "create"
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  // OTHER STATE
  const qParams = useParams()
  const rowId = qParams.id
  const navigate = useNavigate();

  // OPTION STATE
  const [STATUS_OPTION, SET_STATUS_OPTION] = useState([])
  const [CREDIT_DEBIT_CODE_OPTION, SET_CREDIT_DEBIT_CODE_OPTION] = useState([])
  const [PERIOD_OPTION, SET_PERIOD_OPTION] = useState([])
  const [STORE_TYPE_OPTIONS, SET_STORE_TYPE_OPTIONS] = useState([])

  // LOCAL STATE DROPDOWN
  const DEF_LOADED = pageView === "create" ? true : false

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    sku: Yup.string().max(100).required("Please Enter SKU"),
    productName: Yup.string().required("Please Enter Product Name"),
    creditDebitCode: Yup.string().max(100).required("Please Enter Card"),
    storeType: Yup.string().max(100).required("Please Enter Store Type"),
    interestRate: Yup.string().max(100).required("Please Enter Interest Rate"),
    period: Yup.string().required("Please Enter Period"),
    startDate: Yup.string().required("Please Enter Start Date"),
    endDate: Yup.string().required("Please Enter End Date"),
    status: Yup.string().max(100).required("Please Enter Status"),

  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      sku: "",
      productName: "",
      creditDebitCode: "",
      storeType: "",
      interestRate: "",
      period: 1,
      promotionCode: "",
      productBankCode: "",
      startDate: "",
      endDate: "",
      remark: "",
      status: 1,
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ PaymentCreditDebitCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        sku: values.sku,
        productName: values.productName,
        creditDebitCode: values.creditDebitCode,
        storeType: values.storeType,
        interestRate: values.interestRate,
        period: Number(values.period),
        promotionCode: values.promotionCode,
        productBankCode: values.productBankCode,
        remark: values.remark,
        startDate: moment(values.startDate, "YYYY-MM-DDTHH:mm").toISOString(),
        endDate: moment(values.endDate, "YYYY-MM-DDTHH:mm").toISOString(),
        status: Number(values.status),
      };
      console.log("🚀 ~ onSave ~ payload:", payload);

      if (pageView === "update") {
        API.fetchUpdatePaymentCreditDebit(payload)
      } else {
        API.fetchCreatePaymentCreditDebit(payload)
      }
    },
  })

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("sku", resData.sku)
    validation.setFieldValue("productName", resData.productName)
    validation.setFieldValue("creditDebitCode", resData.creditDebitCode)
    validation.setFieldValue("storeType", resData.storeType)
    validation.setFieldValue("interestRate", resData.interestRate)
    validation.setFieldValue("period", resData.period)
    validation.setFieldValue("promotionCode", resData.promotionCode)
    validation.setFieldValue("productBankCode", resData.productBankCode)
    validation.setFieldValue("remark", resData.remark)
    validation.setFieldValue("startDate", moment(resData.startDate).format("YYYY-MM-DDTHH:mm"))
    validation.setFieldValue("endDate", moment(resData.endDate).format("YYYY-MM-DDTHH:mm"))
    validation.setFieldValue("status", resData.status)
  }

  const API = {
    fetchGetRowById: async (payload) => {
      try {
        const response = await GetById({ id: payload.id })
        const resData = response.data
        if (resData.status == 202) {
          preFilInput(resData.data)
        }
      }
      catch (err) {
        console.log('err ', err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchCreatePaymentCreditDebit: async (payload) => {
      try {
        const response = await Create({ data: payload })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Created Data Success",
            content: "",
            onClose: () => {
              navigate('/installment-plan/list')
            },
          })
        }
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchUpdatePaymentCreditDebit: async (payload) => {
      try {
        const response = await Update({ data: payload, id: payload.id })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              navigate('/installment-plan/list')
            },
          })
        } else {
          if (resData?.error && resData?.message) {
            mAlert.info({
              title: "Alert",
              subTitle: resData?.message,
              content: "",
              onClose: () => {
                //  alert("onClose")
              },
            })
          }
        }
      }
      catch (err) {
        console.log('err ', err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchGetDDEnumInstallmentPlans: async () => {
      try {
        const response = await DropdownEnumInstallmentPlans({})
        const resData = response?.data ?? []
        if (resData.status == 200) {
          SET_STATUS_OPTION(resData.data.option.item0)
          SET_CREDIT_DEBIT_CODE_OPTION(resData.data.option.item1)
          SET_PERIOD_OPTION(resData.data.option.item2)
          SET_STORE_TYPE_OPTIONS(resData.data.option.item3)
        }
      }
      catch (err) {
        console.log('err ', err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },

  }

  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id: rowId }
      API.fetchGetRowById(payload)
    }
  }, [])

  // LoadDropdown
  useEffect(() => {
    API.fetchGetDDEnumInstallmentPlans({})
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `Installment | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} Installment`}
            breadcrumbItems={[
              { title: "Installment ", link: "installment-plan/list" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">

                      <Col className="col-12">
                        <Row className="">
                          <Col className="col-6">
                            <Row className="">
                              <Col className="col-12">
                                <h5 className="mb-3">Installment Infomation</h5>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>


                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="sku"
                          className="col-md-12 col-form-label"
                        >
                          JIB SKU
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="sku"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="sku"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.sku}
                            invalid={validation.touched.sku && validation.errors.sku ? true : false}
                            disabled={pageView === "view"}
                          />
                          {
                            validation.touched.sku && validation.errors.sku && (
                              <FormFeedback type="invalid">
                                {validation.errors.sku}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>

                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="productName"
                          className="col-md-12 col-form-label"
                        >
                          Product Name
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="productName"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="productName"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.productName}
                            invalid={validation.touched.productName && validation.errors.productName ? true : false}
                            disabled={pageView === "view"}
                          />
                          {
                            validation.touched.productName && validation.errors.productName && (
                              <FormFeedback type="invalid">
                                {validation.errors.productName}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>


                      <Col className="col-6">
                        <Row className="">
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="creditDebitCode"
                              className="col-md-12 col-form-label"
                            >
                              บัตร
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Select
                                placeholder="Select Period"
                                value={fn_helper.FNFORM.getObjectValue(CREDIT_DEBIT_CODE_OPTION, `${validation.values.creditDebitCode}`)}
                                onChange={(value) => validation.setFieldValue("creditDebitCode", value.id)}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.name}
                                options={CREDIT_DEBIT_CODE_OPTION}
                                className={`select2-selection ${validation.touched.creditDebitCode && validation.errors.creditDebitCode ? "input-err" : ""}`}
                                isDisabled={pageView === "view"}
                              />
                              {
                                (validation.touched.creditDebitCode && validation.errors.creditDebitCode) && (
                                  <div type="invalid" className="invalid-feedback d-block">{validation.errors.creditDebitCode}</div>
                                )
                              }
                              {
                                validation.touched.creditDebitCode && validation.errors.creditDebitCode && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.creditDebitCode}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col className="col-6">
                        <Row className="">
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="storeType"
                              className="col-md-12 col-form-label"
                            >
                              Store Type
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Select
                                placeholder="Select Period"
                                value={fn_helper.FNFORM.getObjectValue(STORE_TYPE_OPTIONS, `${validation.values.storeType}`)}
                                onChange={(value) => validation.setFieldValue("storeType", value.id)}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.name}
                                options={STORE_TYPE_OPTIONS}
                                className={`select2-selection ${validation.touched.storeType && validation.errors.storeType ? "input-err" : ""}`}
                                isDisabled={pageView === "view"}
                              />
                              {
                                (validation.touched.storeType && validation.errors.storeType) && (
                                  <div type="invalid" className="invalid-feedback d-block">{validation.errors.storeType}</div>
                                )
                              }
                              {
                                validation.touched.storeType && validation.errors.storeType && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.storeType}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                        </Row>
                      </Col>


                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="interestRate"
                          className="col-md-12 col-form-label"
                        >
                          ดอกเบี้ย
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="interestRate"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="interestRate"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.interestRate}
                            invalid={validation.touched.interestRate && validation.errors.interestRate ? true : false}
                            disabled={pageView === "view"}
                          />
                          {
                            validation.touched.interestRate && validation.errors.interestRate && (
                              <FormFeedback type="invalid">
                                {validation.errors.interestRate}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>

                      <Col className="col-6">
                        <Row className="">
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="period"
                              className="col-md-12 col-form-label"
                            >
                              จำนวนเดือน
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Select
                                placeholder="Select Period"
                                value={fn_helper.FNFORM.getObjectValue(PERIOD_OPTION, `${validation.values.period}`)}
                                onChange={(value) => validation.setFieldValue("period", value.id)}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.name}
                                options={PERIOD_OPTION}
                                className={`select2-selection ${validation.touched.period && validation.errors.period ? "input-err" : ""}`}
                                isDisabled={pageView === "view"}
                              />
                              {
                                (validation.touched.period && validation.errors.period) && (
                                  <div type="invalid" className="invalid-feedback d-block">{validation.errors.period}</div>
                                )
                              }
                              {
                                validation.touched.period && validation.errors.period && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.period}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="promotionCode"
                          className="col-md-12 col-form-label"
                        >
                          รหัส Supplier
                          {/* <span className="t-require">*</span> */}
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="promotionCode"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="promotionCode"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.promotionCode}
                            invalid={validation.touched.promotionCode && validation.errors.promotionCode ? true : false}
                            disabled={pageView === "view"}
                          />
                          {
                            validation.touched.promotionCode && validation.errors.promotionCode && (
                              <FormFeedback type="invalid">
                                {validation.errors.promotionCode}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>

                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="productBankCode"
                          className="col-md-12 col-form-label"
                        >
                          Product code(จากธนาคาร)
                          {/* <span className="t-require">*</span> */}
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="productBankCode"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="productBankCode"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.productBankCode}
                            invalid={validation.touched.productBankCode && validation.errors.productBankCode ? true : false}
                            disabled={pageView === "view"}
                          />
                          {
                            validation.touched.productBankCode && validation.errors.productBankCode && (
                              <FormFeedback type="invalid">
                                {validation.errors.productBankCode}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="remark"
                          className="col-md-12 col-form-label"
                        >
                          Remark
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="remark"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="remark"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.remark}
                            invalid={validation.touched.remark && validation.errors.remark ? true : false}
                            disabled={pageView === "view"}
                          />
                          {/* {
                            validation.touched.remark && validation.errors.remark && (
                              <FormFeedback type="invalid">
                                {validation.errors.remark}
                              </FormFeedback>
                            )
                          } */}
                        </div>
                      </Col>

                      <Col className="col-6">
                        <Row className="">
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="status"
                              className="col-md-12 col-form-label"
                            >
                              Status (Active/Inactive)
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Select
                                placeholder="Select Status"
                                value={fn_helper.FNFORM.getObjectValue(STATUS_OPTION, `${validation.values.status}`)}
                                onChange={(value) => validation.setFieldValue("status", value.id)}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.name}
                                options={STATUS_OPTION}
                                className={`select2-selection ${validation.touched.status && validation.errors.status ? "input-err" : ""}`}
                                isDisabled={pageView === "view"}
                              />
                              {
                                (validation.touched.status && validation.errors.status) && (
                                  <div type="invalid" className="invalid-feedback d-block">{validation.errors.status}</div>
                                )
                              }
                              {
                                validation.touched.status && validation.errors.status && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.status}
                                  </FormFeedback>
                                )
                              }
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="col-6">
                        <Row className="">
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="startDate"
                              className="col-md-12 col-form-label"
                            >
                              Start Date
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="startDate"
                                className="form-control"
                                type="datetime-local"
                                placeholder=""
                                name="startDate"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.startDate}
                                invalid={
                                  validation.touched.startDate &&
                                    validation.errors.startDate
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.startDate &&
                                validation.errors.startDate && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.startDate}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col className="col-6">
                        <Row className="">
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="endDate"
                              className="col-md-12 col-form-label"
                            >
                              End Date
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="endDate"
                                className="form-control"
                                type="datetime-local"
                                placeholder=""
                                name="endDate"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.endDate}
                                invalid={
                                  validation.touched.endDate &&
                                    validation.errors.endDate
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.endDate &&
                                validation.errors.endDate && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.endDate}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                        </Row>
                      </Col>

                    </Row>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
                      {
                        pageView == "view"
                          ? <><button className="btn btn-primary w-md" onClick={() => navigate('/installment-plan/list')}>BACK</button></>
                          : <>
                            <button type="submit" className="btn btn-primary w-md" >SAVE</button>
                            <button className="btn btn-secondary w-md" onClick={() => navigate('/installment-plan/list')}>CANCEL</button>
                          </>
                      }
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(InstallmentPlanTemplate)
