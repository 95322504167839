import React, { useEffect, useState, useContext } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  Container,
  Input,
  Form,
  FormFeedback,
} from "reactstrap"

import Select from "react-select"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import fn_helper from "helpers/fn_helper"
import { CreatePromotionTheme, GetPromotionThemeById, UpdatePromotionTheme } from "services/api/module/PromotionTheme"
import ModalContext from "../../contexts/ModalContext";
import UploadFileAPI from "components/UploadFileAPI"
import { DropdownEnumPromotionTheme } from "services/api/module/DropdownEnum"

const PromotionThemeTemplate = ({
  pageView = "create"
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  // OTHER STATE
  const qParams = useParams()
  const qPromotionThemeId = qParams.id
  const navigate = useNavigate();

  // OPTION STATE
  const [TYPE_OPTION, SET_TYPE_OPTION] = useState([])
  const [STATUS_OPTION, SET_STATUS_OPTION] = useState([])

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    name: Yup.string().max(255).required("Please Enter Name"),
    type: Yup.string().max(255).required("Please Enter Type"),
    textOnProductFrame: Yup.string().max(15).required("Please Enter Text"),
    firstProductFrameColor: Yup.string().max(255).required("Please Enter Color"),
    secondProductFrameColor: Yup.string().max(255).required("Please Enter Color"),
    bgDesktop: Yup.number().when("type", {
      is: value => value === "FLASHSALE",
      then: schema =>
        schema
          .required("Please Enter Background Desktop")
    }),
    bgMobile: Yup.number().when("type", {
      is: value => value === "FLASHSALE",
      then: schema =>
        schema
          .required("Please Enter Background Mobile")
    }),
    firstTimerFrameColor: Yup.number().when("type", {
      is: value => value === "FLASHSALE",
      then: schema =>
        schema
          .required("Please Enter Color")
    }),
    secondTimerFrameVolor: Yup.number().when("type", {
      is: value => value === "FLASHSALE",
      then: schema =>
        schema
          .required("Please Enter Color")
    }),
    textOnTimerFrame: Yup.number().when("type", {
      is: value => value === "FLASHSALE",
      then: schema =>
        schema
          .required("Please Enter text")
    }),
    icon: Yup.string().max(100).required("Please Enter Icon"),
    status: Yup.string().max(100).required("Please Enter Status"),
  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      type: "",
      bgDesktop: "",
      bgMobile: "",
      firstTimerFrameColor: "",
      secondTimerFrameVolor: "",
      textOnTimerFrame: "",
      textOnProductFrame: "",
      firstProductFrameColor: "",
      secondProductFrameColor: "",
      icon: "",
      status: "",
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ PromotionThemeCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        name: values.name,
        type: values.type,
        bgDesktop: values.bgDesktop,
        bgMobile: values.bgMobile,
        firstTimerFrameColor: values.firstTimerFrameColor,
        secondTimerFrameVolor: values.secondTimerFrameVolor,
        textOnTimerFrame: values.textOnTimerFrame,
        textOnProductFrame: values.textOnProductFrame,
        firstProductFrameColor: values.firstProductFrameColor,
        secondProductFrameColor: values.secondProductFrameColor,
        icon: values.icon,
        status: Number(values.status),
      };
      console.log("🚀 ~ onSave ~ payload:", payload);

      if (pageView === "update") {
        API.fetchUpdatePromotionTheme(payload)
      } else {
        API.fetchCreatePromotionTheme(payload)
      }
    },
  })

  const preFilInput = (resData = {}) => {
    console.log("🚀 ~ preFilInput ~ resData:", resData)

    validation.setFieldValue("id", fn_helper.FN.replaceNullWithDefault(resData.id, 'NUMBER'))
    validation.setFieldValue("name", fn_helper.FN.replaceNullWithDefault(resData.name, 'STRING'))
    validation.setFieldValue("type", fn_helper.FN.replaceNullWithDefault(resData.type, 'STRING'))
    validation.setFieldValue("bgDesktop", fn_helper.FN.replaceNullWithDefault(resData.bgDesktop, 'STRING'))
    validation.setFieldValue("bgMobile", fn_helper.FN.replaceNullWithDefault(resData.bgMobile, 'STRING'))
    validation.setFieldValue("firstTimerFrameColor", fn_helper.FN.replaceNullWithDefault(resData.firstTimerFrameColor, 'STRING'))
    validation.setFieldValue("secondTimerFrameVolor", fn_helper.FN.replaceNullWithDefault(resData.secondTimerFrameVolor, 'STRING'))
    validation.setFieldValue("textOnTimerFrame", fn_helper.FN.replaceNullWithDefault(resData.textOnTimerFrame, 'STRING'))
    validation.setFieldValue("textOnProductFrame", fn_helper.FN.replaceNullWithDefault(resData.textOnProductFrame, 'STRING'))
    validation.setFieldValue("firstProductFrameColor", fn_helper.FN.replaceNullWithDefault(resData.firstProductFrameColor, 'STRING'))
    validation.setFieldValue("secondProductFrameColor", fn_helper.FN.replaceNullWithDefault(resData.secondProductFrameColor, 'STRING'))
    validation.setFieldValue("icon", fn_helper.FN.replaceNullWithDefault(resData.icon, 'STRING'))
    validation.setFieldValue("status", fn_helper.FN.replaceNullWithDefault(resData.status, 'STRING'))
  }

  const API = {
    fetchGetPromotionThemeById: async (payload) => {
      try {
        const response = await GetPromotionThemeById({ id: payload.id })
        const resData = response.data
        if (resData.status == 202) {
          preFilInput(resData.data)
        }
      }
      catch (err) {
        console.log('err ', err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchCreatePromotionTheme: async (payload) => {
      try {
        const response = await CreatePromotionTheme({ data: payload })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Created Data Success",
            content: "",
            onClose: () => {
              navigate('/promotion/promotion-theme')
            },
          })
        }
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchUpdatePromotionTheme: async (payload) => {
      try {
        const response = await UpdatePromotionTheme({ data: payload, id: payload.id })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              navigate('/promotion/promotion-theme')
            },
          })
        }
      }
      catch (err) {
        console.log('err ', err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchGetDDEnumPromotionTheme: async () => {
      try {
        const response = await DropdownEnumPromotionTheme({})
        const resData = response?.data ?? []
        if (resData.status == 200) {
          SET_STATUS_OPTION(resData.data.option.item0)
          SET_TYPE_OPTION(resData.data.option.item1)
        }
      }
      catch (err) {
        console.log('err ', err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
  }

  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id: qPromotionThemeId }
      API.fetchGetPromotionThemeById(payload)
    }
  }, [])

  //กรณีมี Dropdown
  useEffect(() => {
    API.fetchGetDDEnumPromotionTheme()
  }, [])




  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `Promotion Theme | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} Promotion Theme`}
            breadcrumbItems={[
              { title: "Promotion Theme", link: "promotion/promotion-theme" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  console.log("🚀 ~ PromotionThemeCreate ~ e", validation.errors)
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <Col className="col-12">
                        <Row className="">
                          <Col className="col-6">
                            <Row className="">
                              <Col className="col-12">
                                <h5 className="mb-3">Promotion Theme Infomation</h5>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="label_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Name
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="name"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="name"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.name}
                                    invalid={validation.touched.name && validation.errors.name ? true : false}
                                    disabled={pageView === "view"}
                                  />
                                  {
                                    validation.touched.name && validation.errors.name && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.name}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Type
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Select
                                    placeholder="Select Type"
                                    value={fn_helper.FNFORM.getObjectValue(TYPE_OPTION, `${validation.values.type}`)}
                                    onChange={(value) => validation.setFieldValue("type", value.id)}
                                    getOptionValue={(option) => option.id}
                                    getOptionLabel={(option) => option.name}
                                    options={TYPE_OPTION}
                                    className={`select2-selection ${validation.touched.type && validation.errors.type ? "input-err" : ""}`}
                                    isDisabled={pageView === "view"}
                                  />
                                  {
                                    (validation.touched.type && validation.errors.type) && (
                                      <div type="invalid" className="invalid-feedback d-block">{validation.errors.type}</div>
                                    )
                                  }
                                  {
                                    validation.touched.type && validation.errors.type && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.type}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="label_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Text on Frame
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="textOnProductFrame"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="textOnProductFrame"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.textOnProductFrame}
                                    invalid={validation.touched.textOnProductFrame && validation.errors.textOnProductFrame ? true : false}
                                    disabled={pageView === "view"}
                                  />
                                  {
                                    validation.touched.textOnProductFrame && validation.errors.textOnProductFrame && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.textOnProductFrame}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <Row className="">
                                  <Col className="col-6 mb-3">
                                    <label
                                      htmlFor="label_name"
                                      className="col-md-12 col-form-label"
                                    >
                                      Product Frame 1st Color
                                      <span className="t-require">*</span>
                                    </label>
                                    <div className="col-md-12">
                                      <Input
                                        id="firstProductFrameColor"
                                        type="color"
                                        name="firstProductFrameColor"
                                        value={validation.values.firstProductFrameColor}
                                        style={{ width: "100%", height: "calc(1.5em + 0.94rem + calc(var(--bs-border-width) * 2))" }}
                                        onChange={e =>
                                          validation.setFieldValue(
                                            "firstProductFrameColor",
                                            e.target.value
                                          )
                                        }
                                        className="form-control form-control-color"
                                        invalid={
                                          validation.touched.firstProductFrameColor &&
                                            validation.errors.firstProductFrameColor
                                            ? true
                                            : false
                                        }
                                        disabled={pageView === "view"}
                                      />
                                      {
                                        validation.touched.firstProductFrameColor && validation.errors.firstProductFrameColor && (
                                          <FormFeedback type="invalid">
                                            {validation.errors.firstProductFrameColor}
                                          </FormFeedback>
                                        )
                                      }
                                    </div>
                                  </Col>
                                  <Col className="col-6 mb-3">
                                    <label
                                      htmlFor="label_name"
                                      className="col-md-12 col-form-label"
                                    >
                                      Product Frame 2nd Color
                                      <span className="t-require">*</span>
                                    </label>
                                    <div className="col-md-12">
                                      <Input
                                        id="secondProductFrameColor"
                                        type="color"
                                        name="secondProductFrameColor"
                                        value={validation.values.secondProductFrameColor}
                                        style={{ width: "100%", height: "calc(1.5em + 0.94rem + calc(var(--bs-border-width) * 2))" }}
                                        onChange={e =>
                                          validation.setFieldValue(
                                            "secondProductFrameColor",
                                            e.target.value
                                          )
                                        }
                                        className="form-control form-control-color"
                                        invalid={
                                          validation.touched.secondProductFrameColor &&
                                            validation.errors.secondProductFrameColor
                                            ? true
                                            : false
                                        }
                                        disabled={pageView === "view"}
                                      />
                                      {
                                        validation.touched.secondProductFrameColor && validation.errors.secondProductFrameColor && (
                                          <FormFeedback type="invalid">
                                            {validation.errors.secondProductFrameColor}
                                          </FormFeedback>
                                        )
                                      }
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>

                          <Col className="col-12">

                            {validation.values.type === "FLASHSALE" && (
                              <div className="dropdown-options-form mt-3 mb-3">
                                <hr className="mt-0 mb-0" />
                                <Row className="">
                                  <Col className="col-12">
                                    <h5 className="mb-3">Timer Frame</h5>
                                  </Col>
                                  <Col className="col-6">
                                    <Row className="">
                                      <Col className="col-12 mb-3">
                                        <label
                                          htmlFor="label_name"
                                          className="col-md-12 col-form-label"
                                        >
                                          Text on Timer Frame
                                          <span className="t-require">*</span>
                                        </label>

                                        <div className="col-md-12">
                                          <Input
                                            id="textOnTimerFrame"
                                            className="form-control"
                                            type="text"
                                            placeholder=""
                                            name="textOnTimerFrame"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.textOnTimerFrame}
                                            invalid={validation.touched.textOnTimerFrame && validation.errors.textOnTimerFrame ? true : false}
                                            disabled={pageView === "view"}
                                          />
                                          {
                                            validation.touched.textOnTimerFrame && validation.errors.textOnTimerFrame && (
                                              <FormFeedback type="invalid">
                                                {validation.errors.textOnTimerFrame}
                                              </FormFeedback>
                                            )
                                          }
                                        </div>
                                      </Col>
                                      <Col className="col-12 mb-3">
                                        <Row className="">
                                          <Col className="col-6 mb-3">
                                            <label
                                              htmlFor="label_name"
                                              className="col-md-12 col-form-label"
                                            >
                                              1st Color Timer Frame
                                              <span className="t-require">*</span>
                                            </label>
                                            <div className="col-md-12">
                                              <Input
                                                id="firstTimerFrameColor"
                                                type="color"
                                                name="firstTimerFrameColor"
                                                value={validation.values.firstTimerFrameColor}
                                                style={{ width: "100%", height: "calc(1.5em + 0.94rem + calc(var(--bs-border-width) * 2))" }}
                                                onChange={e =>
                                                  validation.setFieldValue(
                                                    "firstTimerFrameColor",
                                                    e.target.value
                                                  )
                                                }
                                                className="form-control form-control-color"
                                                invalid={
                                                  validation.touched.firstTimerFrameColor &&
                                                    validation.errors.firstTimerFrameColor
                                                    ? true
                                                    : false
                                                }
                                                disabled={pageView === "view"}
                                              />
                                              {
                                                validation.touched.firstTimerFrameColor && validation.errors.firstTimerFrameColor && (
                                                  <FormFeedback type="invalid">
                                                    {validation.errors.firstTimerFrameColor}
                                                  </FormFeedback>
                                                )
                                              }
                                            </div>
                                          </Col>
                                          <Col className="col-6 mb-3">
                                            <label
                                              htmlFor="label_name"
                                              className="col-md-12 col-form-label"
                                            >
                                              2nd Color Timer Frame
                                              <span className="t-require">*</span>
                                            </label>
                                            <div className="col-md-12">
                                              <Input
                                                id="secondTimerFrameVolor"
                                                type="color"
                                                name="secondTimerFrameVolor"
                                                value={validation.values.secondTimerFrameVolor}
                                                style={{ width: "100%", height: "calc(1.5em + 0.94rem + calc(var(--bs-border-width) * 2))" }}
                                                onChange={e =>
                                                  validation.setFieldValue(
                                                    "secondTimerFrameVolor",
                                                    e.target.value
                                                  )
                                                }
                                                className="form-control form-control-color"
                                                invalid={
                                                  validation.touched.secondTimerFrameVolor &&
                                                    validation.errors.secondTimerFrameVolor
                                                    ? true
                                                    : false
                                                }
                                                disabled={pageView === "view"}
                                              />
                                              {
                                                validation.touched.secondTimerFrameVolor && validation.errors.secondTimerFrameVolor && (
                                                  <FormFeedback type="invalid">
                                                    {validation.errors.secondTimerFrameVolor}
                                                  </FormFeedback>
                                                )
                                              }
                                            </div>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <hr className="mt-0 mb-0" />

                              </div>
                            )}
                            <Row>
                              <Col className="col-6 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Status (Active/Inactive)
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Select
                                    placeholder="Select Status"
                                    value={fn_helper.FNFORM.getObjectValue(STATUS_OPTION, `${validation.values.status}`)}
                                    onChange={(value) => validation.setFieldValue("status", value.id)}
                                    getOptionValue={(option) => option.id}
                                    getOptionLabel={(option) => option.name}
                                    options={STATUS_OPTION}
                                    className={`select2-selection ${validation.touched.status && validation.errors.status ? "input-err" : ""}`}
                                    isDisabled={pageView === "view"}
                                  />
                                  {
                                    (validation.touched.status && validation.errors.status) && (
                                      <div type="invalid" className="invalid-feedback d-block">{validation.errors.status}</div>
                                    )
                                  }
                                  {
                                    validation.touched.status && validation.errors.status && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.status}
                                      </FormFeedback>
                                    )
                                  }
                                </div>

                              </Col>
                            </Row>
                          </Col>

                          <Col className="col-12">

                            <div className="col-md-12">
                              <UploadFileAPI
                                bucketName="promotion-theme"
                                upload="Image"
                                typeUpload="ImageMap"
                                prefixName="promotion-theme-image"
                                label="Icon"
                                required={true}
                                widthSize={60}
                                heightSize={60}
                                description="description"
                                value={validation.values.icon}
                                onChange={([imageURL = '', imageSize = '']) => {
                                  validation.setFieldValue("icon", imageURL)
                                }}
                              />
                              <Input
                                id="icon"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="icon"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.icon}
                                invalid={validation.touched.icon && validation.errors.icon ? true : false}
                                readOnly={true}
                                disabled={pageView === "view"}
                              />
                              {
                                validation.touched.icon && validation.errors.icon && (
                                  <div type="invalid" className="invalid-feedback d-block">{validation.errors.icon}</div>
                                )
                              }
                            </div>
                          </Col>

                          {validation.values.type === "FLASHSALE" && (
                            <Col className="col-12">
                              <div className="dropdown-options-form mt-3 mb-3">
                                <Row className="">
                                  <Col className="col-12">
                                    <h5 className="mb-3">Flash Sale Image</h5>
                                  </Col>
                                  <Col className="col-12 mb-3">
                                    <div className="col-md-12">
                                      <UploadFileAPI
                                        bucketName="promotionTheme"
                                        upload="Image"
                                        typeUpload="ImageMap"
                                        prefixName="promotionTheme-image"
                                        label="Background Desktop"
                                        required={true}
                                        widthSize={1440}
                                        heightSize={612}
                                        description="description"
                                        value={validation.values.bgDesktop}
                                        onChange={([imageURL = '', imageSize = '']) => {
                                          validation.setFieldValue("bgDesktop", imageURL)
                                        }}
                                      />
                                      <Input
                                        id="bgDesktop"
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        name="bgDesktop"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.bgDesktop}
                                        invalid={validation.touched.bgDesktop && validation.errors.bgDesktop ? true : false}
                                        readOnly={true}
                                        disabled={pageView === "view"}
                                      />
                                      {
                                        validation.touched.bgDesktop && validation.errors.bgDesktop && (
                                          <div type="invalid" className="invalid-feedback d-block">{validation.errors.bgDesktop}</div>
                                        )
                                      }
                                    </div>
                                  </Col>
                                  <Col className="col-12 mb-3">
                                    <div className="col-md-12">
                                      <UploadFileAPI
                                        bucketName="promotionTheme"
                                        upload="Image"
                                        typeUpload="ImageMap"
                                        prefixName="promotionTheme-image"
                                        label="Background Mobile"
                                        required={true}
                                        widthSize={100}
                                        heightSize={100}
                                        description="description"
                                        value={validation.values.bgMobile}
                                        onChange={([imageURL = '', imageSize = '']) => {
                                          validation.setFieldValue("bgMobile", imageURL)
                                        }}
                                      />
                                      <Input
                                        id="bgMobile"
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        name="bgMobile"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.bgMobile}
                                        invalid={validation.touched.bgMobile && validation.errors.bgMobile ? true : false}
                                        readOnly={true}
                                        disabled={pageView === "view"}
                                      />
                                      {
                                        validation.touched.bgMobile && validation.errors.bgMobile && (
                                          <div type="invalid" className="invalid-feedback d-block">{validation.errors.bgMobile}</div>
                                        )
                                      }
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          )}

                        </Row>
                      </Col>




                    </Row>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
                      {
                        pageView == "view"
                          ? <><button className="btn btn-primary w-md" onClick={() => navigate('/promotion/promotion-theme')}>BACK</button></>
                          : <>
                            <button type="submit" className="btn btn-primary w-md" >SAVE</button>
                            <button className="btn btn-secondary w-md" onClick={() => navigate('/promotion/promotion-theme')}>CANCEL</button>
                          </>
                      }
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(PromotionThemeTemplate)
