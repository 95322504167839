import React, { useState } from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx"
import { Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { get } from 'helpers/api_helper';
import moment from 'moment';

export const HeaderColumn = ({ column = [] }) => {
    const headerCol = column
    return (
        <thead>
            <tr>
                {headerCol.map((col, index) => (
                    <th key={index}>{col}</th>
                ))}
            </tr>
        </thead>
    );
};