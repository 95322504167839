import React, { useEffect, useState, useContext } from "react"
import { Col, Row, TabPane } from "reactstrap"
import Select from "react-select"
import { withTranslation } from "react-i18next"
import _ from "lodash"
import fn_helper from "helpers/fn_helper"
import InputText from "components/Input/InputText"
import UploadFileAPI from "components/UploadFileAPI"
import InputSelect from "components/Input/InputSelect"
import InputSwitch from "components/Input/InputSwitch"
import AttributeForm from "./AttributeForm"
import RequireStar from "components/RequireStar"
import ShowBox from "components/ShowBox"
import CategorySelector from "./CategorySelector"

const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const MainInfo = ({ 
  validation = null,
  pageView = "create",
  dropdownOption = {
    category: [],
    brand: [],
    attributeSet: [],
  },
  isComputerDIY = false,
  subComponent = <></>
}) => {
  const [ productCodeErr, setProductCodeErr ] = useState(false)
  const isVariantData = validation.values.type === 2
  let labelPage = "Products"
  if (isVariantData) {
    labelPage = "Product Variant"
  }
  return (
    <>
      <button id="btn-err-main" type="button" onClick={() => setProductCodeErr(true)} style={{ display: 'none' }}>แดง</button>
      <Row className="mb-3">
        <Col className="col-12">
          <h5 className="mb-3">{labelPage} Infomation</h5>
        </Col>
        <Col className="col-12">
          <Row className="">
            <Col className="col-6 mb-3">
              <label
                htmlFor="category_name"
                className="col-md-12 col-form-label"
              >
                Status (Active/Inactive)
                <RequireStar validation={validation} fieldName="status"/>
              </label>
              <div className="col-md-12">
                <InputSelect
                  placeholder="Select Status"
                  onBlur={validation.handleBlur}
                  value={validation.values.status}
                  onChange={(newValue) => {
                    validation.setFieldValue("status", newValue)
                  }}
                  invalid={validation.touched.status && validation.errors.status ? true : false}
                  options={STATUS_OPTION}
                  errorText={validation.errors.status}
                  disabled={pageView === "view"}
                />
              </div>
            </Col>
            {subComponent}
            <Col className="col-6 mb-3" >
              <label
                htmlFor="category_name"
                className="col-md-12 col-form-label"
              >
                SKU
                <RequireStar validation={validation} fieldName="sku"/>
              </label>
              <InputText
                type="text"
                placeholder=""
                onChange={(newValue) => {
                  validation.setFieldValue("sku", fn_helper.FN.toSKU(newValue))
                }}
                onBlur={(e) => {
                  validation.handleBlur(e)
                  setProductCodeErr(false)
                }}
                value={validation.values.sku}
                invalid={validation.touched.sku && validation.errors.sku || productCodeErr ? true : false}
                disabled={pageView === "view"}
                errorText={productCodeErr ? '': validation.errors.sku}
              />
            </Col>
            <Col className="col-6 mb-3">
              <label className="col-md-12 col-form-label">
                Product Thai Name
                <RequireStar validation={validation} fieldName="nameTh"/>
              </label>
              <div className="col-md-12">
                <InputText
                  type="text"
                  placeholder=""
                  onChange={(newValue) => {
                    validation.setFieldValue("nameTh", newValue)
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.nameTh}
                  invalid={validation.touched.nameTh && validation.errors.nameTh ? true : false}
                  disabled={pageView === "view"}
                  errorText={validation.errors.nameTh}
                />
              </div>
            </Col>
            <Col className="col-6 mb-3">
              <label className="col-md-12 col-form-label">
                Product Eng Name
                <RequireStar validation={validation} fieldName="nameEn"/>
              </label>
              <div className="col-md-12">
                <InputText
                  type="text"
                  placeholder=""
                  onChange={(newValue) => {
                    validation.setFieldValue("nameEn", newValue)
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.nameEn}
                  invalid={validation.touched.nameEn && validation.errors.nameEn ? true : false}
                  disabled={pageView === "view"}
                  errorText={validation.errors.nameEn}
                />
              </div>
            </Col>

            <ShowBox isShow={validation.isChild === false}>
              <Col className="col-6 mb-3">
                <label
                  htmlFor="category_name"
                  className="col-md-12 col-form-label"
                >
                  Select Category
                  <RequireStar validation={validation} fieldName="categoryIds"/>
                </label>
                <div className="col-md-12">
                  {/* <InputSelect
                    placeholder="Select Category"
                    isMulti={true}
                    onBlur={validation.handleBlur}
                    value={validation.values.categoryIds}
                    onChange={(newValue = []) => {
                      console.log("🚀 ~ newValue bb :", newValue)
                      let updateValue = newValue ? newValue : []
                      validation.setFieldValue("categoryIds", updateValue)
                    }}
                    isClearable={true}
                    invalid={validation.touched.categoryIds && validation.errors.categoryIds ? true : false}
                    // options={dropdownOption.categoryIds}
                    options={dropdownOption.category}
                    errorText={validation.errors.categoryIds}
                    disabled={pageView === "view"}
                  /> */}
                  <CategorySelector
                    placeholder="Select Category"
                    isMulti={true}
                    onBlur={validation.handleBlur}
                    value={validation.values.categoryIds}
                    onChange={(newValue = []) => {
                      console.log("🚀 ~ newValue bb :", newValue)
                      let updateValue = newValue ? newValue : []
                      validation.setFieldValue("categoryIds", updateValue)
                    }}
                    isClearable={true}
                    invalid={validation.touched.categoryIds && validation.errors.categoryIds ? true : false}
                    treeOption={dropdownOption.category}
                    errorText={validation.errors.categoryIds}
                    disabled={pageView === "view"}
                  />
                </div>
              </Col>
              <Col className="col-6 mb-3">
                <label
                  htmlFor="category_name"
                  className="col-md-12 col-form-label"
                >
                  Select Brand
                  <RequireStar validation={validation} fieldName="brandId"/>
                </label>
                <div className="col-md-12">
                  <InputSelect
                    placeholder="Select Brand"
                    onBlur={validation.handleBlur}
                    value={validation.values.brandId}
                    onChange={(newValue) => {
                      validation.setFieldValue("brandId", newValue)
                    }}
                    isClearable={true}
                    invalid={validation.touched.brandId && validation.errors.brandId ? true : false}
                    options={dropdownOption.brand}
                    errorText={validation.errors.brandId}
                    disabled={pageView === "view"}
                  />
                </div>
              </Col>
            
              {/* <Col className="col-6 mb-3">
                <label className="col-md-12 col-form-label">
                  Sort Order
                  <RequireStar validation={validation} fieldName="sortOrder"/>
                </label>
                <div className="col-md-12">
                  <InputText
                    type="number"
                    placeholder=""
                    onChange={(newValue) => {
                      validation.setFieldValue("sortOrder", newValue)
                    }}
                    maxLength={3}
                    onBlur={validation.handleBlur}
                    value={validation.values.sortOrder}
                    invalid={validation.touched.sortOrder && validation.errors.sortOrder ? true : false}
                    disabled={pageView === "view"}
                    errorText={validation.errors.sortOrder}
                  />
                </div>
              </Col> */}
              { (isVariantData === false && isComputerDIY == false) && (
                <Col className="col-12 mb-3">
                  <label
                    htmlFor="category_name"
                    className="col-md-12 col-form-label"
                  >
                    Is Variant
                  </label>
                  <InputSwitch
                    labelTrue="Yes"
                    labelFalse="No"
                    value={validation.values.isVariant == "1"}
                    onChange={(value)=> {
                      const updateValue = value ? '1' : '0'
                      validation.setFieldValue("isVariant", updateValue)
                    }}
                    disabled={["view", "update"].includes(pageView)}
                  />
                </Col>
              )}
            </ShowBox>
          </Row>
          {/* <hr className="mt-0 mb-0"/> */}
        </Col>
      </Row>
    </>
  )
}
export default withTranslation()(MainInfo)

