import React, { useEffect, useState, useContext, useRef } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _, { update } from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import {
  CreatePromotionArticleDetail,
  GetPromotionArticleDetailById,
  UpdatePromotionArticleDetail,
  CheckDuplicatePromotionArticleDetail,
} from "services/api/module/PromotionArticleDetail"
import ModalContext from "../../contexts/ModalContext"
import UploadFileAPI from "components/UploadFileAPI"
import images from "assets/images"
import {
  DropdownProductGroup,
  DropdownPromotionArticleCategoryParent,
  DropdownPromotionArticleCategoryChild,
} from "services/api/module/Dropdown"
import InputRadio from "components/Input/InputRadio"
import InputSwitch from "components/Input/InputSwitch"
import moment from "moment"
import MyEditor from "components/MyEditor"

const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const CONTENT_TYPE_OPTION = [
  { id: 1, name: "Text Editor" },
  { id: 2, name: "Product Group" },
]

const PromotionArticleDetailTemplate = ({ pageView = "create" }) => {
  // POPUP
  const { mAlert } = useContext(ModalContext)

  // OTHER STATE
  const qParams = useParams()
  const qPromotionArticleDetailId = qParams.id
  const navigate = useNavigate()

  // LOCAL STATE DROPDOWN
  const DEF_LOADED = pageView === "create" ? true : false
  const [ddPromotionCategory, setDDPromotionCategory] = useState([])
  const [ddPromotionSubCategory, setDDPromotionSubCategory] = useState([])
  const [isSubCate, setIsSubCate] = useState(false)
  const [ddProductGroup, setDDProductGroup] = useState([])
  const [ddLoaded0, setDDLoaded0] = useState(DEF_LOADED)

  // CheckDUP STATE
  const [errDupField, setErrDupField] = useState({
    nameTh: false,
    nameEn: false,
  })
  const timeoutInputRef = useRef(null)

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    nameTh: Yup.string().max(100).required("Please Enter PromotionArticleDetail Name TH"),
    nameEn: Yup.string().max(100).required("Please Enter PromotionArticleDetail Name EN"),
    promotionArticleCategoryId: Yup.string().max(100).required("Please Enter Promotion Category"),
    startDate: Yup.string().max(100).required("Please Enter Start Date"),
    endDate: Yup.string().max(100).required("Please Enter End Date"),
    seoUrlKey: Yup.string().max(100).required("Please Enter URL Key"),
    seoMetaTitle: Yup.string().max(100).required("Please Enter Meta Title"),
    seoMetaKeyword: Yup.string().max(100).required("Please Enter Meta keyword"),
    seoMetaDescription: Yup.string().max(100).required("Please Enter Meta Description"),
    status: Yup.string().max(100).required("Please Enter Status"),
    image: Yup.string().max(100).required("Please Enter logo Image")
  })


  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)

  useEffect(() => {

  }, [])

  const handleValidateFields = (val) => {

    if (val == 1) {
      // ADD
      const customValidationSchema = Yup.object({
        detailTh: Yup.string().required("Please Enter detail TH"),
        detailEn: Yup.string().required("Please Enter detail EN")
      });
      setUpdatedValidationSchema((prevSchema) =>
        prevSchema.shape({
          detailTh: customValidationSchema.fields.detailTh,
          detailEn: customValidationSchema.fields.detailEn,
        })
      );

      // DELETE 
      setUpdatedValidationSchema((prevSchema) => prevSchema.omit(['productGroupId']));
    } else if (val == 2) {

      // ADD
      const customValidationSchema = Yup.object({
        productGroupId: Yup.string().max(100).required("Please Enter Product Group"),
      });
      setUpdatedValidationSchema((prevSchema) =>
        prevSchema.shape({
          productGroupId: customValidationSchema.fields.productGroupId,
        })
      );

      // DELETE 
      setUpdatedValidationSchema((prevSchema) => prevSchema.omit(['detailTh', 'detailEn']));
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      productGroupId: "",
      promotionArticleCategoryId: "",
      promotionArticleSubCategoryId: "",
      nameTh: "",
      nameEn: "",
      image: "",
      detailTh: "",
      detailEn: "",
      contentType: 1,
      startDate: "",
      endDate: "",
      isHightlight: "",
      seoUrlKey: "",
      seoMetaTitle: "",
      seoMetaKeyword: "",
      seoMetaDescription: "",
      status: "",
    },

    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      //  const isPass = Object.values(errDupField).every(item => item === false) // เช็คว่าทุกตัว ไม่มีค่า true

      console.log("🚀 ~ PromotionArticleDetailCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        contentType: Number(values.contentType),
        detailTh: Number(values.contentType) == 1 ? values.detailTh : "",
        detailEn: Number(values.contentType) == 1 ? values.detailEn : "",
        productGroupId: Number(values.contentType) == 2 ? values.productGroupId : "",
        promotionArticleCategoryId: values.promotionArticleCategoryId,
        promotionArticleSubCategoryId: isSubCate ? values.promotionArticleSubCategoryId : "",
        nameTh: values.nameTh,
        nameEn: values.nameEn,
        image: values.image,
        startDate: moment(values.startDate, "YYYY-MM-DDTHH:mm").toISOString(),
        endDate: moment(values.endDate, "YYYY-MM-DDTHH:mm").toISOString(),
        isHightlight: Number(values.isHightlight),
        seoUrlKey: values.seoUrlKey,
        seoMetaTitle: values.seoMetaTitle,
        seoMetaKeyword: values.seoMetaKeyword,
        seoMetaDescription: values.seoMetaDescription,
        status: Number(values.status),
      }
      console.log("🚀 ~ onSave ~ payload:", payload)

      if (pageView === "update") {
        API.fetchUpdatePromotionArticleDetail(payload)
      } else {
        API.fetchCreatePromotionArticleDetail(payload)
      }

    },
  })

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("productGroupId", resData.productGroupId)
    validation.setFieldValue("promotionArticleCategoryId", resData.promotionArticleCategoryId)
    validation.setFieldValue("promotionArticleSubCategoryId", resData.promotionArticleSubCategoryId)
    validation.setFieldValue("nameTh", resData.nameTh)
    validation.setFieldValue("nameEn", resData.nameEn)
    validation.setFieldValue("image", resData.image)
    validation.setFieldValue("detailTh", resData.detailTh)
    validation.setFieldValue("detailEn", resData.detailEn)
    validation.setFieldValue("contentType", resData.contentType)
    validation.setFieldValue("startDate", moment(resData.startDate).format("YYYY-MM-DDTHH:mm"))
    validation.setFieldValue("endDate", moment(resData.endDate).format("YYYY-MM-DDTHH:mm"))
    validation.setFieldValue("isHightlight", resData.isHightlight)
    validation.setFieldValue("seoUrlKey", resData.seoUrlKey)
    validation.setFieldValue("seoMetaTitle", resData.seoMetaTitle)
    validation.setFieldValue("seoMetaKeyword", resData.seoMetaKeyword)
    validation.setFieldValue("seoMetaDescription", resData.seoMetaDescription)
    validation.setFieldValue("status", resData.status)


  }

  const API = {
    fetchGetPromotionArticleDetailById: async payload => {
      try {
        const response = await GetPromotionArticleDetailById({ id: payload.id })
        const resData = response.data
        if (resData.status == 202) {
          preFilInput(resData.data)
          if (resData.data.promotionArticleSubCategoryId) {
            await API.fetchDDPromotionSubCategory(resData.data.promotionArticleCategoryId)
            setIsSubCate(true)
          }
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchCreatePromotionArticleDetail: async payload => {
      try {
        const response = await CreatePromotionArticleDetail({ data: payload })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Created Data Success",
            content: "",
            onClose: () => {
              navigate("/promotion-article/detail")
            },
          })
        } else if (resData.status === 403) {
          const updateErrDupField = _.cloneDeep(errDupField)
          updateErrDupField[resData.data] = true
          setErrDupField(updateErrDupField)

          mAlert.info({
            title: "Alert",
            subTitle: "Data Existing",
            content: "",
            onClose: () => { },
          })
        } else {
          throw resData
        }
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchUpdatePromotionArticleDetail: async payload => {
      try {
        const response = await UpdatePromotionArticleDetail({
          data: payload,
          id: payload.id,
        })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              navigate("/promotion-article/detail")
            },
          })
        } else if (resData.status === 403) {
          const updateErrDupField = _.cloneDeep(errDupField)
          updateErrDupField[resData.data] = true
          setErrDupField(updateErrDupField)

          mAlert.info({
            title: "Alert",
            subTitle: "Data Existing",
            content: "",
            onClose: () => { },
          })
        } else {
          throw resData
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchDDPromotionCategory: async () => {
      try {
        const response = await DropdownPromotionArticleCategoryParent({})
        const resData = response?.data ?? []
        const tmpDD = fn_helper.OPTIONS.ADD_SELECT_DEF(resData.data)
        setDDPromotionCategory(tmpDD)
        setDDLoaded0(true)
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchDDPromotionSubCategory: async id => {
      try {
        const response = await DropdownPromotionArticleCategoryChild({ id })
        const resData = response?.data ?? []
        const tmpDD = fn_helper.OPTIONS.ADD_SELECT_DEF(resData.data)
        setDDPromotionSubCategory(tmpDD)
        if (resData.data.length) {
          setIsSubCate(true)
          const validationSchema2 = Yup.object({
            promotionArticleSubCategoryId: Yup.string().max(100).required("Please Enter Promotion Article Sub Category")
          });
          setUpdatedValidationSchema((prevSchema) =>
            prevSchema.shape({
              promotionArticleSubCategoryId: validationSchema2.fields.promotionArticleSubCategoryId,
            })
          );
        } else {
          setIsSubCate(false)
          setUpdatedValidationSchema((prevSchema) => prevSchema.omit(['promotionArticleSubCategoryId']));
        }

      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchDDProductGroup: async () => {
      try {
        const response = await DropdownProductGroup({})
        const resData = response?.data ?? []
        const tmpDD = fn_helper.OPTIONS.ADD_SELECT_DEF(resData.data)
        setDDProductGroup(tmpDD)
        setDDLoaded0(true)
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchCheckFieldDup: async payload => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await CheckDuplicatePromotionArticleDetail({
            data: payload,
          })
          const resData = response.data
          resolve(resData?.data ?? [])
        } catch (err) {
          console.log("err ", err)
          resolve([])
        }
      })
    },
  }

  const checkFieldDup = async (fieldName = "", value = "") => {
    if (timeoutInputRef.current) {
      clearTimeout(timeoutInputRef.current)
    }
    timeoutInputRef.current = setTimeout(async () => {
      const payload = { fieldName: fieldName, value: value }
      const rowsDup = await API.fetchCheckFieldDup(payload)

      let editId = qPromotionArticleDetailId ?? 0
      let isDupField = fn_helper.FNFORM.checkDupField(
        fieldName,
        value,
        rowsDup,
        editId
      )
      const updateErrDupField = _.cloneDeep(errDupField)
      updateErrDupField[fieldName] = isDupField
      setErrDupField(updateErrDupField)
    }, 500)
  }

  const handleChild = async id => {
    if (timeoutInputRef.current) {
      clearTimeout(timeoutInputRef.current)
    }
    timeoutInputRef.current = setTimeout(async () => {
      await API.fetchDDPromotionSubCategory(id)
    }, 200)
  }

  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id: qPromotionArticleDetailId }
      API.fetchGetPromotionArticleDetailById(payload)
    }
  }, [])

  // LoadDropdown
  useEffect(() => {
    // CALL DROPDOWN
    API.fetchDDPromotionCategory()
    API.fetchDDProductGroup()
  }, [])

  useEffect(() => {
    handleValidateFields(validation.values.contentType);
  }, [validation.values.contentType]);

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `Promotion Article | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} Promotion Article`}
            breadcrumbItems={[
              {
                title: "Promotion Article",
                link: "promotion-article/detail",
              },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <Col className="col-12">
                        <Row className="">
                          <Col className="col-6">
                            <Row className="">
                              <Col className="col-12">
                                <h5 className="mb-3">
                                  Promotion Article Information
                                </h5>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Name TH
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="nameTh"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="nameTh"
                                    onBlur={validation.handleBlur}
                                    onChange={e => {
                                      validation.setFieldValue(
                                        "nameTh",
                                        e.target.value
                                      )
                                      checkFieldDup("nameTh", e.target.value)
                                    }}
                                    value={validation.values.nameTh}
                                    invalid={
                                      (validation.touched.nameTh &&
                                        validation.errors.nameTh
                                        ? true
                                        : false) || errDupField.nameTh
                                    }
                                    disabled={pageView === "view"}
                                  />
                                  {validation.touched.nameTh &&
                                    validation.errors.nameTh && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.nameTh}
                                      </FormFeedback>
                                    )}
                                  {errDupField.nameTh && (
                                    <div
                                      type="invalid"
                                      className="invalid-feedback d-block"
                                    >
                                      Field Detail Name TH exist
                                    </div>
                                  )}
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Name EN
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="nameEn"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="nameEn"
                                    onBlur={validation.handleBlur}
                                    onChange={e => {
                                      validation.setFieldValue(
                                        "nameEn",
                                        e.target.value
                                      )
                                      checkFieldDup("nameEn", e.target.value)
                                    }}
                                    value={validation.values.nameEn}
                                    invalid={
                                      (validation.touched.nameEn &&
                                        validation.errors.nameEn
                                        ? true
                                        : false) || errDupField.nameEn
                                    }
                                    disabled={pageView === "view"}
                                  />
                                  {validation.touched.nameEn &&
                                    validation.errors.nameEn && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.nameEn}
                                      </FormFeedback>
                                    )}
                                  {errDupField.nameEn && (
                                    <div
                                      type="invalid"
                                      className="invalid-feedback d-block"
                                    >
                                      Field Detail Name EN exist
                                    </div>
                                  )}
                                </div>
                              </Col>

                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Category
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Select
                                    placeholder="Select Category"
                                    value={fn_helper.FNFORM.getObjectValue(
                                      ddPromotionCategory,
                                      `${validation.values.promotionArticleCategoryId}`
                                    )}
                                    onChange={value => {
                                      validation.setFieldValue("promotionArticleCategoryId", value.id)
                                      handleChild(value.id)
                                    }}
                                    getOptionValue={option => option.id}
                                    getOptionLabel={option => option.name}
                                    options={ddPromotionCategory.filter(
                                      item =>
                                        item.id != qPromotionArticleDetailId
                                    )}
                                    className={`select2-selection ${validation.touched
                                      .promotionArticleCategoryId &&
                                      validation.errors
                                        .promotionArticleCategoryId
                                      ? "input-err"
                                      : ""
                                      }`}
                                    isDisabled={["view"].includes(pageView)}
                                  />
                                  {validation.touched
                                    .promotionArticleCategoryId &&
                                    validation.errors
                                      .promotionArticleCategoryId && (
                                      <div
                                        type="invalid"
                                        className="invalid-feedback d-block"
                                      >
                                        {
                                          validation.errors
                                            .promotionArticleCategoryId
                                        }
                                      </div>
                                    )}
                                </div>
                              </Col>

                              {isSubCate && (
                                <Col className="col-12 mb-3">
                                  <label
                                    htmlFor="category_name"
                                    className="col-md-12 col-form-label"
                                  >
                                    Sub Category
                                    <span className="t-require">*</span>
                                  </label>

                                  <div className="col-md-12">
                                    <Select
                                      placeholder="Select Sub Category"
                                      value={fn_helper.FNFORM.getObjectValue(
                                        ddPromotionSubCategory,
                                        `${validation.values.promotionArticleSubCategoryId}`
                                      )}
                                      onChange={value => {
                                        validation.setFieldValue(
                                          "promotionArticleSubCategoryId",
                                          value.id
                                        )
                                      }}
                                      getOptionValue={option => option.id}
                                      getOptionLabel={option => option.name}
                                      options={ddPromotionSubCategory}
                                      className={`select2-selection ${validation.touched
                                        .promotionArticleSubCategoryId &&
                                        validation.errors
                                          .promotionArticleSubCategoryId
                                        ? "input-err"
                                        : ""
                                        }`}
                                      isDisabled={["view"].includes(pageView)}
                                    />
                                    {validation.touched
                                      .promotionArticleSubCategoryId &&
                                      validation.errors
                                        .promotionArticleSubCategoryId && (
                                        <div
                                          type="invalid"
                                          className="invalid-feedback d-block"
                                        >
                                          {
                                            validation.errors
                                              .promotionArticleSubCategoryId
                                          }
                                        </div>
                                      )}
                                  </div>
                                </Col>
                              )}
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Is HightLight on All Promotion Article Page
                                </label>
                                <InputSwitch
                                  labelTrue="Yes"
                                  labelFalse="No"
                                  value={validation.values.isHightlight == "1"}
                                  onChange={value => {
                                    const updateValue = value ? "1" : "0"
                                    validation.setFieldValue(
                                      "isHightlight",
                                      updateValue
                                    )
                                  }}
                                  disabled={pageView === "view"}
                                />
                              </Col>

                            </Row>
                          </Col>

                          <Col className="col-6">
                            <Col className="col-12">
                              <h5 className="mb-3">SEO Setting</h5>
                            </Col>
                            <Row className="">
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  URL Key
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="seoUrlKey"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="seoUrlKey"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.seoUrlKey}
                                    invalid={
                                      validation.touched.seoUrlKey &&
                                        validation.errors.seoUrlKey
                                        ? true
                                        : false
                                    }
                                    disabled={pageView === "view"}
                                  />
                                  {validation.touched.seoUrlKey &&
                                    validation.errors.seoUrlKey && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.seoUrlKey}
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Meta Title
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="seoMetaTitle"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="seoMetaTitle"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.seoMetaTitle}
                                    invalid={
                                      validation.touched.seoMetaTitle &&
                                        validation.errors.seoMetaTitle
                                        ? true
                                        : false
                                    }
                                    disabled={pageView === "view"}
                                  />
                                  {validation.touched.seoMetaTitle &&
                                    validation.errors.seoMetaTitle && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.seoMetaTitle}
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Meta keyword
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="seoMetaKeyword"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="seoMetaKeyword"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.seoMetaKeyword}
                                    invalid={
                                      validation.touched.seoMetaKeyword &&
                                        validation.errors.seoMetaKeyword
                                        ? true
                                        : false
                                    }
                                    disabled={pageView === "view"}
                                  />
                                  {validation.touched.seoMetaKeyword &&
                                    validation.errors.seoMetaKeyword && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.seoMetaKeyword}
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Meta Description
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="seoMetaDescription"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="seoMetaDescription"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.seoMetaDescription}
                                    invalid={
                                      validation.touched.seoMetaDescription &&
                                        validation.errors.seoMetaDescription
                                        ? true
                                        : false
                                    }
                                    disabled={pageView === "view"}
                                  />
                                  {validation.touched.seoMetaDescription &&
                                    validation.errors.seoMetaDescription && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.seoMetaDescription}
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Status (Active/Inactive)
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Select
                                    placeholder="Select Status"
                                    value={fn_helper.FNFORM.getObjectValue(
                                      STATUS_OPTION,
                                      `${validation.values.status}`
                                    )}
                                    onChange={value =>
                                      validation.setFieldValue(
                                        "status",
                                        value.id
                                      )
                                    }
                                    getOptionValue={option => option.id}
                                    getOptionLabel={option => option.name}
                                    options={STATUS_OPTION}
                                    className={`select2-selection ${validation.touched.status &&
                                      validation.errors.status
                                      ? "input-err"
                                      : ""
                                      }`}
                                    isDisabled={pageView === "view"}
                                  />
                                  {validation.touched.status &&
                                    validation.errors.status && (
                                      <div
                                        type="invalid"
                                        className="invalid-feedback d-block"
                                      >
                                        {validation.errors.status}
                                      </div>
                                    )}
                                  {validation.touched.status &&
                                    validation.errors.status && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.status}
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <Row className="">
                          <Col className="col-6">
                            <Row className="">
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="startDate"
                                  className="col-md-12 col-form-label"
                                >
                                  Start Date
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="startDate"
                                    className="form-control"
                                    type="datetime-local"
                                    placeholder=""
                                    name="startDate"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.startDate}
                                    invalid={
                                      validation.touched.startDate &&
                                        validation.errors.startDate
                                        ? true
                                        : false
                                    }
                                    disabled={pageView === "view"}
                                  />
                                  {validation.touched.startDate &&
                                    validation.errors.startDate && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.startDate}
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                            </Row>
                          </Col>

                          <Col className="col-6">
                            <Row className="">
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="endDate"
                                  className="col-md-12 col-form-label"
                                >
                                  End Date
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="endDate"
                                    className="form-control"
                                    type="datetime-local"
                                    placeholder=""
                                    name="endDate"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.endDate}
                                    invalid={
                                      validation.touched.endDate &&
                                        validation.errors.endDate
                                        ? true
                                        : false
                                    }
                                    disabled={pageView === "view"}
                                  />
                                  {validation.touched.endDate &&
                                    validation.errors.endDate && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.endDate}
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                            </Row>
                          </Col>

                          <Col className="col-12">
                            <Row className="">
                              <Col className="col-12 mb-3">
                                <div className="col-md-12">
                                  <UploadFileAPI
                                    bucketName="promotion-category"
                                    upload="Image"
                                    typeUpload="ImageMap"
                                    prefixName="promotion-category-image"
                                    label="Logo Image"
                                    required={true}
                                    widthSize={391}
                                    heightSize={287}
                                    description="description"
                                    value={validation.values.image}
                                    onChange={([
                                      imageURL = "",
                                      imageSize = "",
                                    ]) => {
                                      validation.setFieldValue(
                                        "image",
                                        imageURL
                                      )
                                    }}
                                  />
                                  <Input
                                    id="image"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="image"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.image}
                                    invalid={
                                      validation.touched.image &&
                                        validation.errors.image
                                        ? true
                                        : false
                                    }
                                    readOnly={true}
                                    disabled={
                                      pageView === "view" ||
                                      pageView === "update"
                                    }
                                  />
                                  {validation.touched.image &&
                                    validation.errors.image && (
                                      <div
                                        type="invalid"
                                        className="invalid-feedback d-block"
                                      >
                                        {validation.errors.image}
                                      </div>
                                    )}
                                </div>
                              </Col>

                              <Col className="col-12 mb-3">
                                <InputRadio
                                  label="Content Type"
                                  name="contentType"
                                  value={validation.values.contentType}
                                  option={CONTENT_TYPE_OPTION}
                                  onChange={value => {
                                    validation.setFieldValue(
                                      "contentType",
                                      value
                                    )
                                    handleValidateFields(value)
                                  }}
                                  disabled={pageView === "view"}
                                />
                              </Col>

                              {validation.values.contentType === 1 && (
                                <Row>
                                  <Col className="col-12 mb-3">
                                    <label
                                      htmlFor="detail_th"
                                      className="col-md-12 col-form-label"
                                    >
                                      Detail TH
                                      <span className="t-require">*</span>
                                    </label>
                                    <div className="col-md-12">
                                      <div
                                        className={
                                          validation.touched.detailTh &&
                                            validation.errors.detailTh
                                            ? "invalid-editor"
                                            : ""
                                        }
                                      >
                                        <MyEditor
                                          value={validation.values.detailTh}
                                          onChange={(event, editor) => {
                                            const data = editor.getData()
                                            validation.setFieldValue(
                                              "detailTh",
                                              data
                                            )
                                            console.log({ event, editor, data })
                                          }}
                                          disabled={pageView === "view"}
                                        />
                                      </div>

                                      {validation.touched.detailTh &&
                                        validation.errors.detailTh && (
                                          <div
                                            type="invalid"
                                            className="invalid-feedback d-block"
                                          >
                                            {validation.errors.detailTh}
                                          </div>
                                        )}
                                    </div>
                                  </Col>
                                  <Col className="col-12 mb-3">
                                    <label
                                      htmlFor="detail_en"
                                      className="col-md-12 col-form-label"
                                    >
                                      Detail En
                                      <span className="t-require">*</span>
                                    </label>
                                    <div className="col-md-12">
                                      <div
                                        className={
                                          validation.touched.detailEn &&
                                            validation.errors.detailEn
                                            ? "invalid-editor"
                                            : ""
                                        }
                                      >
                                        <MyEditor
                                          value={validation.values.detailEn}
                                          onChange={(event, editor) => {
                                            const data = editor.getData()
                                            validation.setFieldValue(
                                              "detailEn",
                                              data
                                            )
                                            console.log({ event, editor, data })
                                          }}
                                          disabled={pageView === "view"}
                                        />
                                      </div>

                                      {validation.touched.detailEn &&
                                        validation.errors.detailEn && (
                                          <div
                                            type="invalid"
                                            className="invalid-feedback d-block"
                                          >
                                            {validation.errors.detailEn}
                                          </div>
                                        )}
                                    </div>
                                  </Col>
                                </Row>
                              )}
                              {validation.values.contentType === 2 && (
                                <Col className="col-12 mb-3">
                                  <label
                                    htmlFor="category_name"
                                    className="col-md-12 col-form-label"
                                  >
                                    Product Group
                                    <span className="t-require">*</span>
                                  </label>
                                  <div className="col-md-12">
                                    <Select
                                      placeholder="Select Category"
                                      value={fn_helper.FNFORM.getObjectValue(
                                        ddProductGroup,
                                        `${validation.values.productGroupId}`
                                      )}
                                      onChange={value => {
                                        validation.setFieldValue(
                                          "productGroupId",
                                          value.id
                                        )
                                      }}
                                      getOptionValue={option => option.id}
                                      getOptionLabel={option => option.name}
                                      options={ddProductGroup}
                                      className={`select2-selection ${validation.touched.productGroupId &&
                                        validation.errors.productGroupId
                                        ? "input-err"
                                        : ""
                                        }`}
                                      isDisabled={["view"].includes(pageView)}
                                    />
                                    {validation.touched.productGroupId &&
                                      validation.errors.productGroupId && (
                                        <div
                                          type="invalid"
                                          className="invalid-feedback d-block"
                                        >
                                          {validation.errors.productGroupId}
                                        </div>
                                      )}
                                  </div>
                                </Col>
                              )}


                            </Row>
                          </Col>

                        </Row>
                      </Col>
                    </Row>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "8px",
                      }}
                    >
                      {pageView == "view" ? (
                        <>
                          <button
                            className="btn btn-primary w-md"
                            onClick={() =>
                              navigate("/promotion-article/detail")
                            }
                          >
                            BACK
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            SAVE
                          </button>
                          <button
                            className="btn btn-secondary w-md"
                            onClick={() =>
                              navigate("/promotion-article/detail")
                            }
                          >
                            CANCEL
                          </button>
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(PromotionArticleDetailTemplate)
