import { get, post, put, patch } from "../Config"

export function SearchFlag({ params = {} }) {
  return get({ url: `/v1/admin/flag`, params })
}
export function GetFlagById({ id }) {
  return get({ url: `/v1/admin/flag/${id}` })
}
export function CreateFlag({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/flag`, params, data })
}
export function UpdateFlag({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/flag/${id}`, params, data })
}
export function PatchFlag({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/flag/${id}`, params, data })
}
export function DeleteFlag({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/flag/mutiDelete`, params, data })
}
