import { get, post, put, patch } from "../Config"

export function SearchCoupon({ params = {} }) {
  return get({ url: `/v1/admin/coupon`, params })
}
export function GetCouponById({ id }) {
  return get({ url: `/v1/admin/coupon/${id}` })
}
export function CreateCoupon({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/coupon`, params, data })
}
export function UpdateCoupon({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/coupon/${id}`, params, data })
}
export function PatchCoupon({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/coupon/${id}`, params, data })
}
export function DeleteCoupon({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/coupon/mutiDelete`, params, data })
}
