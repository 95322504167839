import React, { useEffect, useState, useContext, useMemo  } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import {
  CreateComputerSet,
  GetComputerSetById,
  UpdateComputerSet,
} from "services/api/module/ComputerSet"
import ModalContext from "../../contexts/ModalContext"
import UploadFileAPI from "components/UploadFileAPI"
import DetailInfo from "pages/Products/Form/DetailInfo"
import SpecInfo from "pages/Products/Form/SpecInfo"
import ImageGalleryInfo from "pages/Products/Form/ImageGalleryInfo"
import ProductsForm from "pages/Products/ProductsForm"
import TableContainer from "components/Common/TableContainer"
import { DropdownAttributeConfig, DropdownAttributeSet, DropdownBrand } from "services/api/module/Dropdown"
import { DropdownProductCategoryRoot, DropdownProductSupplier, DropdownProductDiyPart } from "services/api/module/Dropdown"
import { DropdownComputerDiyCate, DropdownProductCompare } from "services/api/module/Dropdown"

const ComputerSetTemplate = ({ pageView = "create", isPageVariant = true }) => {
  const [childData, setChildData] = useState([])
  // const [activeTab, setActiveTab] = useState("image-gallery")
  // POPUP
  const { mAlert } = useContext(ModalContext)

  // OTHER STATE
  const qParams = useParams()
  const qProductsId = qParams.id
  const navigate = useNavigate()


  const [formValue, setFromValue] = useState({})

  const [ddBrand, setDDBrand] = useState([])
  const [ddCategory, setDDCategory] = useState([])
  const [ddAttributeSet, setDDAttributeSet] = useState([])
  const [ddAttributeConfig, setDDAttributeConfig] = useState([])
  const [ddProductSupplier, setDDProductSupplier] = useState([])
  const [ddProductDiy, setDDProductDiy] = useState({})
  const [ddComputerDiyCate, setDDComputerDiyCate] = useState([])
  const [ddProductCompare, setDDProductCompare] = useState([])

  // LOADING
  const [ddBrandLoading, setDDBrandLoading] = useState(true)
  const [ddCategoryLoading, setDDCategoryLoading] = useState(true)
  const [ddAttributeSetLoading, setDDAttributeSetLoading] = useState(true)
  const [ddAttributeConfigLoading, setDDAttributeConfigLoading] = useState(true)
  const [ddProductSupplierLoading, setDDProductSupplierLoading] = useState(true)
  const [ddProductDiyLoading, setDDProductDiyLoading] = useState(true)
  const [ddComputerDiyCateLoading, setDDComputerDiyCateLoading] = useState(true)
  const [ddProductCompareLoading, setDDProductCompareLoading] = useState(true)

  // ERROR
  const [ productCodeErr, setProductCodeErr ] = useState(true)

  let labelPage = "Computer Set"
  let routeLink = '/computer-set'

  const onSaveMain = (payload) => {
    console.log("🚀 ~ onSaveMain ~ payload:", payload)
    if (pageView === "update") {
      payload.id = qProductsId
      API.fetchUpdateComputerSet(payload)
    } else {
      API.fetchCreateComputerSet(payload)
    }
  }

  const preFilInput = (resData = {}) => {
    let updateValue = _.cloneDeep(resData)
    // updateValue.productGenerate = {
    //   attributeSelected: [],
    //   attributeValueSelected: {},
    //   generatedProducts: []
    // }
    updateValue.productGenerate.generatedProducts = []
    // let generatedProducts = fn_helper.FNPRODUCT.generateProducts(attributeSelected, attributeValueSelected, productName, data);
    setFromValue(updateValue)
  }

  const API = {
    fetchDDProductCategory: async () => {
      try {
          const response = await DropdownProductCategoryRoot({})
          const resData = response?.data ?? []
          // const tmpDD = fn_helper.OPTIONS.ADD_SELECT_DEF(resData.data)
          // console.log("🚀 ~ fetchDDBrand: ~ tmpDD:", tmpDD)
          setDDCategory(resData.data);
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      } finally {
        setDDCategoryLoading(false);
      }
    },
    fetchDDBrand: async () => {
      try {
          const response = await DropdownBrand({})
          const resData = response?.data ?? []
          // const tmpDD = fn_helper.OPTIONS.ADD_SELECT_DEF(resData.data)
          // console.log("🚀 ~ fetchDDBrand: ~ tmpDD:", tmpDD)
          setDDBrand(resData.data);
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      } finally {
        setDDBrandLoading(false);
      }
    },
    fetchDDAttributeSet: async () => {
      try {
          const response = await DropdownAttributeSet({})
          const resData = response?.data ?? []
          setDDAttributeSet(resData.data)
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      } finally {
        setDDAttributeSetLoading(false);
      }
    },
    fetchDDAttributeConfig: async () => {
      try {
          const response = await DropdownAttributeConfig({})
          const resData = response?.data ?? []
          setDDAttributeConfig(resData.data)
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      } finally {
        setDDAttributeConfigLoading(false);
      }
    },
    fetchDDProductSupplier: async () => {
      try {
          const response = await DropdownProductSupplier({})
          const resData = response?.data ?? []
          // const tmpDD = fn_helper.OPTIONS.ADD_SELECT_DEF(resData.data)
          // console.log("🚀 ~ fetchDDBrand: ~ tmpDD:", tmpDD)
          setDDProductSupplier(resData.data);
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      } finally {
        setDDProductSupplierLoading(false);
      }
    },
    fetchDDProductDiy: async (payload = {}) => {
      console.log("🚀 ~ fetchDDProductDiy: ~ payload:", payload)
      try {
          const response = await DropdownProductDiyPart({ data: payload })
          const resData = response?.data ?? {}
          console.log("🚀 ~ fetchDDProductDiy: ~ resData:", resData)
          // const tmpDD = fn_helper.OPTIONS.ADD_SELECT_DEF(resData.data)
          // console.log("🚀 ~ fetchDDBrand: ~ tmpDD:", tmpDD)
          setDDProductDiy(resData.resultData);
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      } finally {
        setDDProductDiyLoading(false);
      }
    },
    fetchDDComputerDiyCate: async () => {
      try {
          const response = await DropdownComputerDiyCate({})
          const resData = response?.data ?? []
          // const tmpDD = fn_helper.OPTIONS.ADD_SELECT_DEF(resData.data)
          // console.log("🚀 ~ fetchDDBrand: ~ tmpDD:", tmpDD)
          setDDComputerDiyCate(resData.data);
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      } finally {
        setDDComputerDiyCateLoading(false);
      }
    },
    fetchDDProductCompare: async () => {
      try {
          const response = await DropdownProductCompare({})
          const resData = response?.data ?? []
          setDDProductCompare(resData.data);
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      } finally {
        setDDProductCompareLoading(false);
      }
    },
    fetchGetComputerSetById: async payload => {
      try {
        const response = await GetComputerSetById({ id: payload.id })
        const resData = response.data
        if (resData.status == 202) {
          preFilInput(resData.data)
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchCreateComputerSet: async payload => {
      try {
        const response = await CreateComputerSet({ data: payload })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Created Data Success",
            content: "",
            onClose: () => {
              navigate("/product-management" + routeLink)
            },
          })
        } else if (resData.status === 403) {
          mAlert.info({
            title: "Alert",
            subTitle: "SKU Existing",
            content: "",
            onClose: () => {
              document.getElementById('btn-err-main').click()
              window.scrollTo(0, 0);

            },
          })
        }
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchUpdateComputerSet: async payload => {
      try {
        const response = await UpdateComputerSet({ data: payload, id: payload.id })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              navigate("/product-management" + routeLink)
            },
          })
        } else if (resData.status === 403) {
          mAlert.info({
            title: "Alert",
            subTitle: "SKU Existing",
            content: "",
            onClose: () => {
              document.getElementById('btn-err-main').click()
              window.scrollTo(0, 0);
            },
          })
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
  }

  // กรณี Edit
  useEffect(() => {
    if (ddComputerDiyCateLoading === false) {
      if (["update", "view"].includes(pageView)) {
        const payload = { id: qProductsId }
        API.fetchGetComputerSetById(payload)
      }
    }

  }, [ddComputerDiyCateLoading])
  // LoadDropdown
  useEffect(() => {
    API.fetchDDComputerDiyCate();
    API.fetchDDProductCompare();
  }, [])

  // LoadDropdown
  useEffect(() => {
    // CALL DROPDOWN
    if (ddComputerDiyCateLoading) {
      API.fetchDDProductCategory();
      API.fetchDDBrand();
      API.fetchDDAttributeSet();
      API.fetchDDAttributeConfig();
      API.fetchDDProductSupplier();
    }
  }, [ddComputerDiyCateLoading])

  // LoadDropdown
  useEffect(() => {
    // CALL DROPDOWN
    if(ddComputerDiyCate.length) {
      const attributeSetCodes = ddComputerDiyCate.map(item => item.attributeSetCode) 
      console.log('ddComputerDiyCate >>', ddComputerDiyCate)
      const payload = { attributeSetCodes }
      API.fetchDDProductDiy(payload);
    }
  }, [ddComputerDiyCate])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `Products | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE


  const loadingMain = ddBrandLoading ||  ddCategoryLoading ||  ddAttributeSetLoading ||  ddAttributeConfigLoading || ddProductSupplierLoading || ddProductDiyLoading


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} `+ labelPage}
            breadcrumbItems={[
              { title: labelPage, link: "banner/banner-platform" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Card>
                <ProductsForm
                  isComSet={true}
                  dropdownOption={{
                    brand: ddBrand,
                    category: ddCategory,
                    attributeSet: ddAttributeSet,
                    attributeConfig: ddAttributeConfig,
                    productSupplier: ddProductSupplier,
                    productDiy: ddProductDiy,
                    computerDiyCate: ddComputerDiyCate,
                    productCompare: ddProductCompare
                  }}
                  loading={loadingMain}
                  value={formValue}
                  onSaveMain={onSaveMain}
                  onBack={() => {
                    navigate("/product-management" + routeLink)
                  }}
                  pageView={pageView}
                  isComputerDIY={true}
                />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(ComputerSetTemplate)
