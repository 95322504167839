import PropTypes from "prop-types"
import React, { useEffect, useState, useMemo, useCallback, useContext } from "react"
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    Badge,
} from "reactstrap"

import { Link, useNavigate, useParams } from "react-router-dom"

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from '../../components/Common/TableContainer';
import { withTranslation } from "react-i18next";

import { get } from "helpers/api_helper";
import { checkScope } from "helpers/utility_helper";
import "../../components/Common/datatables.scss"
import Spinners from "components/Common/Spinner";
import { DeleteOrderHistory, PatchOrderHistory, SearchOrderHistory } from "services/api/module/OrderHistory";

import ModalContext from "../../contexts/ModalContext";
import moment from "moment";
import InputSwitch from "components/Input/InputSwitch";
import fn_helper from "helpers/fn_helper";

const RowStatus = ({ status }) => {
    switch (String(status)) {
        case "0":
            return <Badge className="bg-danger">InActive</Badge>;
        case "1":
            return <Badge className="bg-success">Active</Badge>;
        default:
            return <Badge className="bg-success">{status}</Badge>;
    }
};

const OrderHistoryList = props => {
    // POPUP
    const { mAlert } = useContext(ModalContext);

    // OTHER STATE
    const { categoryName } = useParams();
    const navigate = useNavigate();

    // LOCAL STATE
    const [contentList, setContentList] = useState([])
    const [contentListLoading, setContentListLoading] = useState(false)
    const [selectedContentId, setSelectedContentId] = useState([])

    const columns = useMemo(
        () => [
            {
                header: 'Tools',
                accessorKey: 'user_id',
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    let { uuid } = cellProps?.row?.original ?? {};
                    return (
                        <>
                            {checkScope('systemsettings:usersetting:view') && <Link className={`btn btn-info`} to={`/order-history/view/${uuid}`}><i className={`bx bx-show-alt`}></i></Link>}
                            &nbsp;
                        </>
                    )
                }
            },
            {
                header: 'วันที่สั่งซื้อ',
                accessorKey: 'createdAt',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const row = cellProps?.row?.original
                    return row.createdAt ? moment(row.createdAt).format('DD/MM/YYYY HH:mm:ss') : ''
                }
            },
            {
                header: 'รหัสคำสั่งซื้อ',
                accessorKey: 'orderNumber',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'ชนิดคำสั่งซื้อ',
                accessorKey: 'orderType',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'ยอดสุทธิ',
                accessorKey: 'grandTotal',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const row = cellProps?.row?.original
                    return fn_helper.FN.toNumberWithCommas(row.grandTotal)
                }
            },
            {
                header: 'สถานะการชำระเงิน',
                accessorKey: 'paymentStatus',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const row = cellProps?.row?.original
                    return row.paymentStatus === 0 ? 'Pending' : row.paymentStatus === 1 ? 'Partially' : row.paymentStatus === 2 ? 'Fully' : row.paymentStatus === 3 ? 'Cancel' : row.paymentStatus === 4 ? 'Failed' : row.paymentStatus === 5 ? 'Deposit' : row.paymentStatus === 6 ? 'Expired' : ""
                }
            },
            {
                header: 'รูปแบบการชำระเงิน',
                accessorKey: '',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const row = cellProps?.row?.original
                    return row.paymentMethodGroup.nameTh ? row.paymentMethodGroup.nameTh : ''
                }
            },
            {
                header: 'ชื่อผู้สั่ง',
                accessorKey: '',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const row = cellProps?.row?.original
                    return row.user?.firstname ? row.user?.firstname : '-'
                }
            },
            {
                header: 'นามสกุลผู้สั่ง',
                accessorKey: '',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const row = cellProps?.row?.original
                    return row.user?.lastname ? row.user?.lastname : '-'
                }
            },
            {
                header: 'เบอร์ผู้สั่ง',
                accessorKey: '',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const row = cellProps?.row?.original
                    return row.user?.mobileNo ? row.user?.mobileNo : '-'
                }
            },
            {
                header: 'ประเภทบุคคล',
                accessorKey: '',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const row = cellProps?.row?.original
                    return row.user?.userType === 0 ? 'individual' : row.user?.userType === 1 ? 'corporate' : 'guest'
                }
            },
            {
                header: 'ชื่อผู้รับ',
                accessorKey: 'firstName',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'นามสกุลผู้รับ',
                accessorKey: 'lastName',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'เบอร์โทรผู้รับ',
                accessorKey: 'mobileNo',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'สถานะคำสั่งซื้อ',
                accessorKey: 'orderStatus',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const row = cellProps?.row?.original
                    return row.orderStatus === 0 ? 'รอชำระเงิน' : row.orderStatus === 1 ? 'ที่ต้องจัดส่ง' : row.orderStatus === 2 ? 'ที่ต้องได้รับ' : row.orderStatus === 3 ? 'สำเร็จ' : row.orderStatus === 4 ? 'ยกเลิกคำสั่งซื้อ' : ""
                }
            },
            {
                header: 'Updated At',
                accessorKey: 'updatedBy',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const row = cellProps?.row?.original
                    return row.updatedAt ? moment(row.updatedAt).format('DD/MM/YYYY') : ''

                }
            },
            {
                header: 'Updated At',
                accessorKey: 'updatedAt',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const row = cellProps?.row?.original
                    return row.updatedAt ? moment(row.updatedAt).format('DD/MM/YYYY HH:mm:ss') : ''
                }
            }
        ],
        [selectedContentId, contentList]
    );

    const API = {
        fetchContentList: async () => {
            try {
                const response = await SearchOrderHistory({})
                const resData = response?.data ?? []
                setContentList(resData.data);
            } catch (e) {
                console.log(e)
                mAlert.info({
                    title: "Alert",
                    subTitle: "Something went wrong",
                    content: "",
                    onClose: () => {
                        //  alert("onClose")
                    },
                })
            }
        },
        fetchPatchContent: async (payload) => {
            try {
                const response = await PatchOrderHistory({ data: payload, uuid: payload.uuid })
                const resData = response.data
                if (resData.status === 201) {
                    mAlert.info({
                        title: "Alert",
                        subTitle: "Update Data Success",
                        content: "",
                        onClose: () => {
                            fetchAPI()
                        },
                    })
                }
            }
            catch (err) {
                console.log('err ', err)
                mAlert.info({
                    title: "Alert",
                    subTitle: "Something went wrong",
                    content: "",
                    onClose: () => {
                        //  alert("onClose")
                    },
                })
            }
        },
        fetchDeleteContentList: async (payload) => {
            try {
                const response = await DeleteOrderHistory({ data: payload })
                const resData = response.data
                if (resData.status === 201) {
                    mAlert.info({
                        title: "Alert",
                        subTitle: "Delete Data Success",
                        content: "",
                        onClose: () => {
                            fetchAPI()
                        },
                    })
                }
            }
            catch (err) {
                console.log('err ', err)
                mAlert.info({
                    title: "Alert",
                    subTitle: "Something went wrong",
                    content: "",
                    onClose: () => {
                        //  alert("onClose")
                    },
                })
            }
        }

    }

    const fetchAPI = () => {
        const payload = {}
        API.fetchContentList(payload)
    }

    useEffect(() => {
        setContentListLoading(true)
        fetchAPI()
    }, []);

    useEffect(() => {
        if (contentList.length) {
            setContentListLoading(false)
        }
    }, [contentList])

    //meta title
    document.title = "OrderHistory | " + process.env.REACT_APP_CMS_TITLE;

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="OrderHistory" breadcrumbItem="OrderHistory List" />
                <TableContainer
                    isAddButton={false}
                    addRowLink={`/create`}
                    columns={columns}
                    data={contentList || []}
                    isGlobalFilter={true}
                    isPagination={true}
                    SearchPlaceholder="Search"
                    pagination="pagination"
                    paginationWrapper='dataTables_paginate paging_simple_numbers'
                    tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                    rootMenu="systemsettings"
                    subMenu="usersetting"
                    isCustomPageSize={true}
                    isSelected={selectedContentId.length > 0}
                    onClearSelected={() => setSelectedContentId([])}
                    isExportButton={true}
                    exportOption={{
                        filename: "orderHistory-platform",
                        dateColumns: []
                    }}
                    loading={contentListLoading}
                    sortTable={{
                        tableName: 'jib_orderHistory',
                        fetchCallBack: () => {
                            setContentListLoading(true)
                            fetchAPI()
                        }
                    }}
                />
            </div>
        </div>
    );
}

OrderHistoryList.propTypes = {
    t: PropTypes.any,
}

export default withTranslation()(OrderHistoryList)
