import React, { useEffect, useState, useContext, useMemo  } from "react"
import { Col, Row, Card, CardBody, Container, Form, CardTitle } from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { GetProductsById } from "services/api/module/Products"
import { UpdateProductFlag } from "services/api/module/ProductFlag"
import { SearchFlag } from "services/api/module/Flag"
import ModalContext from "../../contexts/ModalContext";
import { TableLoading } from "components/TableComponents"

import InputText from "components/Input/InputText"
import InputSelect from "components/Input/InputSelect"
import InputSwitch from "components/Input/InputSwitch"
import InputDate from "components/Input/InputDate"
import TableContainer from "components/Common/TableContainer"
import { Input } from "reactstrap"
import moment from "moment"

const ProductFlagTemplate = ({
  pageView = "create"
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  // OTHER STATE
  const qParams = useParams()
  const qFlagId = qParams.id
  const navigate = useNavigate();

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
   
  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)

  // LOCAL STATE DROPDOWN
  const DEF_LOADING = pageView === "create" ? false : true
  const [loadingById, setLoadingById] = useState(DEF_LOADING)
  const [contentList , setContentList ] = useState([])
  const [contentListLoading, setContentListLoading ] = useState(false)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      isInStock: "0",
      isBestseller: "0",
      bestsellerNumber: "",
      isNew: "",
      newStart: null,
      newEnd: null,
      isFreeShipping: "0",
      isFastShipping: "0",
      flagList: []
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ FlagCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        isInStock: values.isInStock,
        isFreeShipping: values.isFreeShipping,
        isFastShipping: values.isFastShipping,
        isBestseller: values.isBestseller,
        bestsellerNumber: `${values.bestsellerNumber}`.replace(/,/g, ''),
        isNew: values.isNew,
        newStart: values.newStart,
        newEnd: values.newEnd,
        flagList: values.flagList
      };
      console.log("🚀 ~ onSave ~ payload:", payload);

      if (pageView === "update") {
        API.fetchUpdateProductFlag(payload)
      }
    },
  })


  // SELECT DATA
  const selectedContentId = validation?.values?.flagList ?? []
  const setSelectedContentId = (newState) => {
    validation.setFieldValue("flagList", newState)
  }

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("isInStock", resData.isInStock)
    validation.setFieldValue("isFreeShipping", resData.isFreeShipping)
    validation.setFieldValue("isFastShipping", resData.isFastShipping)
    validation.setFieldValue("isBestseller", resData.isBestseller)
    validation.setFieldValue("bestsellerNumber", resData.bestsellerNumber)
    validation.setFieldValue("isNew", resData.isNew)
    validation.setFieldValue("newStart", resData.newStart ? moment(resData.newStart).toDate() : null)
    validation.setFieldValue("newEnd", resData.newEnd ? moment(resData.newEnd).toDate() : null)
    validation.setFieldValue("flagList", resData?.flagList?.length ? resData.flagList : [])

    // ติด Effect Clear State
  }

  const onSelectionChange = (
    selectedValue = {},
    oldValues = [],
    option = [],
    mode = ""
  ) => {
    const selectedId = selectedValue?.id
    const tmpOldValues = _.cloneDeep(oldValues)
    const findOldValue = tmpOldValues.filter(id => id === selectedId)

    let updateState = []
    if (findOldValue && findOldValue.length) {
      updateState = tmpOldValues.filter(id => id != selectedId)
    } else {
      updateState = tmpOldValues
      updateState.push(selectedId)
    }

    if (mode === "all") {
      if (tmpOldValues.length >= option.length) {
        updateState = []
      } else {
        updateState = option.map(item => item.id)
      }
    }
    console.log("🚀 ~ onChangeEnum ~ updateState:", updateState)
    return updateState
  }

  const API = {
    fetchFlagList: async (payload) => {
      setContentListLoading(true)
      try {
        const response = await SearchFlag({ params: payload})
        const resData = response?.data ?? []
        setContentList(resData.data);
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
          //  alert("onClose")
          },
        })
      } finally {
        setContentListLoading(false)
      }
    },
    fetchGetProductsById: async (payload) => {
      setLoadingById(true)
      try {
        const response = await GetProductsById({ id: payload.id })
        const resData = response.data
        if (resData.status == 202) {
          preFilInput(resData.data)
        }
      }
      catch (err) {
        console.log('err ', err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
          //  alert("onClose")
          },
        })
      } finally {
        setLoadingById(false)
      }
    },
    fetchUpdateProductFlag: async (payload) => {
      try {
        const response = await UpdateProductFlag({ data: payload, id: payload.id })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              navigate('/product-management/product-flag')
            },
          })
        }
      }
      catch (err) {
        console.log('err ', err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
          //  alert("onClose")
          },
        })
      }
    }
  }

  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id : qFlagId }
      API.fetchGetProductsById(payload)
    }
  }, [])

  // กรณี Fetch Data
  useEffect(() => {
    const payload = { status: 1 }
    API.fetchFlagList(payload)
  }, [])

  const fnCreateColumns = (colType = 'main') => {
    const limitCountSelect = 2 
    return [
      {
          id: "colcheckbox",
          header: (cellProps) => {
              const row = cellProps?.row?.original
              return (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                      <Input 
                          type={`checkbox`} 
                          checked={contentList.length === selectedContentId.length && selectedContentId.length !== 0}
                          onClick={() => {
                              let updateData = onSelectionChange(row, selectedContentId, contentList, "all")
                              setSelectedContentId(updateData)
                          }}
                          readOnly
                      />
                  </div>
          )},
          enableColumnFilter: false,
          enableSorting: false,
          cell: (cellProps) => {
              const row = cellProps?.row?.original
              return (
                  <>
                      <div id={cellProps.row.id} style={{ display: "flex", justifyContent: "center" }}>
                          <Input 
                              type={`checkbox`} 
                              value={cellProps.row.original.id} 
                              checked={selectedContentId.includes(row.id)} 
                              onClick={() => {
                                  let updateData = onSelectionChange(row, selectedContentId, contentList)
                                  setSelectedContentId(updateData)
                              }}
                              readOnly
                          />
                      </div>
                  </>
              )
          }
      },
      {
          header: 'Desktop Img',
          accessorKey: 'iconDesktop',
          enableColumnFilter: false,
          enableSorting: true,
          cell: (cellProps) => {
              const { iconDesktop = '' } = cellProps?.row?.original
              return <img className="bImageBox" src={iconDesktop} style={{ maxHeight: 60 }}/>
          }
      },
      {
          header: 'Mobile Img',
          accessorKey: 'iconMobile',
          enableColumnFilter: false,
          enableSorting: true,
          cell: (cellProps) => {
              const { iconMobile = '' } = cellProps?.row?.original
              return <img className="bImageBox" src={iconMobile} style={{ maxHeight: 60 }}/>
          }
      },
      {
          header: 'Flag Name',
          accessorKey: 'nameTh',
          enableColumnFilter: false,
          enableSorting: true,
      }
    ]
  }

  let columns = useMemo(() => fnCreateColumns('main'), [selectedContentId, contentList]);
  let columnsSelect = useMemo(() => fnCreateColumns('select'), [selectedContentId, contentList]);
  const contentListSelected = contentList.filter(item => selectedContentId.includes(item.id))


  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `Product Flag | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  const loadingMain = loadingById

  return (
    <React.Fragment>

      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} Product Flag`}
            breadcrumbItems={[
              { title: "Product Flag", link: "banner/banner-list" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <h5 className="mb-3">Product Flag Infomation</h5>
                      {JSON.stringify(validation.errors)}
                      <hr/>
                      {
                        loadingMain
                        ? (
                          <table style={{ width: "100%", height: '60vh'}}>
                            <tbody>
                              <TableLoading colSpan={1} />
                            </tbody>
                          </table>
                        )
                        : (
                          <>
                            <Row className="mt-0">
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Is In Stock
                                </label>
                                <InputSwitch
                                  labelTrue="Yes"
                                  labelFalse="No"
                                  value={validation.values.isInStock == "1"}
                                  onChange={(value)=> {
                                    const updateValue = value ? '1' : '0'
                                    validation.setFieldValue("isInStock", updateValue)
                                  }}
                                  disabled={pageView === "view"}
                                />
                              </Col>

                              <Col className="col-2 mb-3" >
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Is Best Seller
                                </label>
                                <InputSwitch
                                  labelTrue="Yes"
                                  labelFalse="No"
                                  value={validation.values.isBestseller == "1"}
                                  onChange={(value)=> {
                                    const updateValue = value ? '1' : '0'
                                    validation.setFieldValue("isBestseller", updateValue)
                                  }}
                                  disabled={pageView === "view"}
                                />
                              </Col>

                              <Col className="col-10 mb-3" >
                              { 
                                validation.values.isBestseller == "1" && (
                                  <div className="d-flex-form gap-4">
                                    <div>
                                      <label
                                        htmlFor="category_name"
                                        className="col-md-12 col-form-label"
                                      >
                                        Best SellerNumber
                                      </label>
                                      <InputText
                                        type="number"
                                        format="money"
                                        placeholder=""
                                        onChange={(newValue) => {
                                          validation.setFieldValue("bestsellerNumber", newValue)
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.bestsellerNumber}
                                        invalid={validation.touched.bestsellerNumber && validation.errors.bestsellerNumber ? true : false}
                                        disabled={pageView === "view"}
                                        errorText={validation.errors.bestsellerNumber}
                                      />
                                    </div>
                                  </div>
                                )
                              }
                              </Col>

                              <Col className="col-6 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Is Free Shipping
                                </label>
                                <InputSwitch
                                  labelTrue="Yes"
                                  labelFalse="No"
                                  value={validation.values.isFreeShipping == "1"}
                                  onChange={(value)=> {
                                    const updateValue = value ? '1' : '0'
                                    validation.setFieldValue("isFreeShipping", updateValue)
                                  }}
                                  disabled={pageView === "view"}
                                />
                              </Col>

                              <Col className="col-6 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Is Fast Shipping
                                </label>
                                <InputSwitch
                                  labelTrue="Yes"
                                  labelFalse="No"
                                  value={validation.values.isFastShipping == "1"}
                                  onChange={(value)=> {
                                    const updateValue = value ? '1' : '0'
                                    validation.setFieldValue("isFastShipping", updateValue)
                                  }}
                                  disabled={pageView === "view"}
                                />
                              </Col>

                              <Col className="col-2 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Is New
                                </label>
                                <InputSwitch
                                  labelTrue="Yes"
                                  labelFalse="No"
                                  value={validation.values.isNew == "1"}
                                  onChange={(value)=> {
                                    const updateValue = value ? '1' : '0'
                                    validation.setFieldValue("isNew", updateValue)
                                  }}
                                  disabled={pageView === "view"}
                                />
                              </Col>

                              <Col className="col-10 mb-3" >
                              { 
                                (validation.values.isNew == "1" )&& (
                                  <div className="d-flex-form gap-4">
                                    <div>
                                      <label
                                        htmlFor="category_name"
                                        className="col-md-12 col-form-label"
                                      >
                                        New Start
                                        <span className="t-require">*</span>
                                      </label>
                                      <InputDate
                                        onChange={(newValue) => {
                                          console.log("🚀 ~ newValue:", newValue)
                                          validation.setFieldValue("newStart", newValue)
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.newStart}
                                        invalid={validation.touched.newStart && validation.errors.newStart ? true : false}
                                        disabled={pageView === "view"}
                                        errorText={validation.errors.newStart}
                                      />
                                    </div>
                                    <div>
                                      <label
                                        htmlFor="category_name"
                                        className="col-md-12 col-form-label"
                                      >
                                        New End
                                        <span className="t-require">*</span>
                                      </label>
                                      <InputDate
                                        onChange={(newValue) => {
                                          console.log("🚀 ~ newValue:", newValue)
                                          validation.setFieldValue("newEnd", newValue)
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.newEnd}
                                        invalid={validation.touched.newEnd && validation.errors.newEnd ? true : false}
                                        disabled={pageView === "view"}
                                        errorText={validation.errors.newEnd}
                                      />
                                    </div>
                                  </div>
                                )
                              }
                              </Col>

                              <br/>
                              <br/>
                              <hr/>
                              <br/>
                              <br/>

                              <CardTitle className="h4">Product Flag Detail</CardTitle>
                              <div className="d-flex justify-content-between align-items-center">
                                <p className="card-title-desc">
                                  (Selected product flag)
                                </p>
                                {pageView != "view" && (
                                <div className="flex-shrink-0 d-flex gap-2">
                                  <a className="btn btn-info" onClick={() => setSelectedContentId([])}> Clear Selected All</a>
                                </div>
                                )}
                              </div>
                              <Col className="col-12 mb-3">
                                <TableContainer
                                  noTop={true}
                                  tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                                  columns={columnsSelect}
                                  data={contentListSelected || []}
                                />
                              </Col>
                              <Col className="col-12">
                                <h5 className="mb-3">Product Flag</h5>
                              </Col>
                              <Col className="col-12">
                                <TableContainer
                                  isAddButton={false} // Set to true if you want an add button
                                  columns={columns} // Ensure `columns` is defined with your required structure
                                  data={contentList || []} // Adjust to match the data for your attribute list
                                  isGlobalFilter={true} // Set true if you need a global filter for the table
                                  isPagination={true}
                                  SearchPlaceholder="Search"
                                  pagination="pagination"
                                  paginationWrapper="dataTables_paginate paging_simple_numbers"
                                  tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                                  rootMenu="systemsettings"
                                  subMenu="usersetting"
                                  isCustomPageSize={true}
                                />
                              </Col>
                            </Row>
                          </>
                        )
                      }
                      
                    </Row>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '8px'}}>
                      {
                        pageView == "view"
                        ? <><button className="btn btn-primary w-md" onClick={() => navigate('/product-management/product-flag')}>BACK</button></> 
                        : <>
                          <button type="submit" className="btn btn-primary w-md" disabled={loadingMain} >SAVE</button>
                          <button className="btn btn-secondary w-md"  disabled={loadingMain} onClick={() => navigate('/product-management/product-flag')}>CANCEL</button>
                        </>
                      }
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// ProductFlagTemplate.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(ProductFlagTemplate)
