import fn_helper from "helpers/fn_helper";
import React from "react";
import Select, { components } from "react-select";
import styled from "styled-components";



// ฟังก์ชันแปลงข้อมูลเป็น Flat Options พร้อม Indents
const flattenTreeWithIndent = (tree, level = 0, parent = null) => {
  const options = [];
  const traverse = (node, level,parent) => {
    options.push({
      id: node.id,
      name: node.name, // ใช้สำหรับค่าที่เลือก
      label: `${"--".repeat(level)} ${node.name}`, // ใช้สำหรับแสดงใน Dropdown
      parent: parent ? parent.id : null, // Add parent reference
    });

    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => traverse(child, level + 1,node));
    }
  };

  tree.forEach((node) => traverse(node, level,parent));
  return options;
};

// Styled Components สำหรับ Custom Option
const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 13px;
  background-color: ${(props) => (props.isfocused ? "#556EE6" : "white")};
  color: ${(props) => (props.isfocused ? "white" : "#495057")};
  cursor: default;

  &:hover {
    background-color: #556ee6;
    color: white;
  }
`;

const Indent = styled.span`
  margin-right: 10px;
  color: #aaa;
`;

const CheckboxContainer = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  border: 1px solid #aaa; /* ขอบสีเทา */
  border-radius: 4px;
  margin-right: 10px;
  background-color: white; /* พื้นหลังสีขาว */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }

  svg {
    visibility: hidden;
    fill: none;
    stroke: #556ee6; /* สีเครื่องหมายถูก */
    stroke-width: 2.5px; /* ความหนาของเครื่องหมายถูก */
  }

  input:checked + svg {
    visibility: visible; /* แสดงเครื่องหมายถูกเมื่อเลือก */
  }
`;

// Custom Option Component
const CustomOption = (props) => {
  const { data, innerRef, innerProps, isSelected } = props;

  return (
    <OptionContainer
      ref={innerRef}
      {...innerProps}
      isFocused={props.isfocused} // ใช้ prop เพื่อจัดการสี hover
    >
      {/* Indents */}
      <Indent>{data.label.split(" ")[0]}</Indent>
      {/* Checkbox */}
      <CheckboxContainer>
        <input type="checkbox" checked={isSelected} readOnly />
        <svg viewBox="0 0 24 24" width="14" height="14">
          <polyline points="20 6 9 17 4 12" />
        </svg>
      </CheckboxContainer>
      {/* Label */}
      <span>{data.name}</span>
    </OptionContainer>
  );
};

const CategorySelector = ({
  id = "formId",
  placeholder = "Select Dropdown",
  name = "formName",
  onChange=(e) => { console.log('onChange!') },
  onBlur= () => { console.log('onBlur!') },

  value = [],
  invalid = false,
  disabled = false,
  errorText = '',
  
  treeOption = [],
  isClearable = false,

}) => {

  const options = flattenTreeWithIndent(treeOption);

  const handleChange = (selectedOptions) => {
    const selectedIds = new Set(selectedOptions.map((item) => item.id));
    
    // Automatically include parent nodes
    selectedOptions.forEach((option) => {
      let currentParentId = option.parent;
  
      while (currentParentId) {
        // Find the parent option
        const parentOption = options.find((opt) => opt.id === currentParentId);
  
        if (parentOption && !selectedIds.has(parentOption.id)) {
          selectedIds.add(parentOption.id);
        }
  
        // Move to the next parent
        currentParentId = parentOption ? parentOption.parent : null;
      }
    });
  
    const updatedSelection = Array.from(selectedIds).map((id) =>
      options.find((opt) => opt.id === id)
    );
  
    onChange(updatedSelection.map((item) => item.id));
  };
  

  const _value = fn_helper.FNFORM.getArrObjectValue(options , value)

  return (
    <>
      <Select
        className={`select2-selection ${invalid ? "input-err": ""}`}
        getOptionValue={(option) => option.id} // ระบุว่า id จะเป็น value
        getOptionLabel={(option) => option.name} // ใช้ชื่อที่ไม่มี Indents สำหรับค่าที่เลือก
        value={_value}
        components={{
          Option: CustomOption, // ใช้ Custom Option
        }}
        placeholder={placeholder}
        options={options} // ส่งข้อมูลเข้า Select
        isMulti // เปิดใช้งาน Multi Select
        hideSelectedOptions={false} // ทำให้ตัวเลือกที่เลือกไม่หายไป
        closeMenuOnSelect={false} // ป้องกันการปิดเมนูเมื่อเลือก
        onChange={handleChange} // ดึงค่าที่เลือก
        isClearable={isClearable}
        isDisabled={disabled}
      />
      {
        (errorText && invalid) && (
          <div type="invalid" className="invalid-feedback d-block">
            {errorText}
          </div>
        )
      }
    </>
  );
};

export default CategorySelector;
