import React, { useEffect, useState, useContext, useMemo } from "react"
import { Button, Card, CardBody, Col, Form, FormFeedback, Row } from "reactstrap"
import TableContainer from "../../components/Common/TableContainer"
import { Nav, NavItem, NavLink, TabContent, TabPane, CardTitle } from "reactstrap"
import { Input } from "reactstrap"
import Select from "react-select"
import classnames from "classnames"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { withTranslation } from "react-i18next"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import ModalContext from "../../contexts/ModalContext"
import moment from "moment"
import { GetOrderHistoryById, GetOrderProductByOrderHistoryId } from "services/api/module/OrderHistory"
import fn_helper from "helpers/fn_helper";

const OrderHistoryForm = ({
  loading = false,
  hasScroll = false,
  pageView = "create",
  onBack = () => console.log('onBack!')
}) => {
  const [activeTab, setActiveTab] = useState("info")

  // POPUP
  const { mAlert } = useContext(ModalContext);

  // OTHER STATE
  const qParams = useParams()
  const qOrderHistoryId = qParams.id
  const navigate = useNavigate();

  // LOCAL STATE
  const [contentList, setContentList] = useState([])
  const [contentListFreeGift, setContentListFreeGife] = useState([])
  const [contentListLoading, setContentListLoading] = useState(false)
  const [selectedContentId, setSelectedContentId] = useState([])
  const [selectedContentFreeGiftId, setSelectedContentFreeGiftId] = useState([])

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({})

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      createdAt: "",
      orderNumber: "",
      orderType: "",
      userFirstName: "",
      userLastName: "",
      userFullName: "",
      userType: "",
      userMobileNo: "",
      userEmail: "",
      taxNo: "",
      taxFirstName: "",
      taxLastName: "",
      taxFullName: "",
      taxAddress: "",
      orderFirstName: "",
      orderLastName: "",
      orderFullName: "",
      orderMobileNo: "",
      orderEmail: "",
      orderAddress: "",
      tombonName: "",
      amphureName: "",
      provinceName: "",
      note: "",
      grandTotal: "",
      paymentMethodGroupId: "",
      paymentMethodGroupName: "",
      paymentStatus: "",
      productSubtotal: "",
      shippingFee: "",
      receiptImg: "",
      receiptStatus: "",
      receiptRejectReason: "",
      receiptPaidDate: ""



    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ preFilInput ~ values:", values)
    },
  })

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("uuid", resData.uuid)
    validation.setFieldValue("createdAt", moment(resData.createdAt).format("YYYY-MM-DD HH:mm"))
    validation.setFieldValue("orderNumber", resData.orderNumber)
    validation.setFieldValue("orderType", resData.orderType)

    validation.setFieldValue("userFirstName", resData.user ? resData.user.firstname : null)
    validation.setFieldValue("userLastName", resData.user ? resData.user.lastname : null)
    validation.setFieldValue("userFullName", resData.user ? resData.user.firstname + " " + resData.user.lastname : "-")
    validation.setFieldValue("userType", resData.user ? resData.user.userType === 0 ? "individual" : resData.user.userType === 1 ? "corporate" : "guest" : null)
    validation.setFieldValue("userMobileNo", resData.user ? resData.user.mobileNo : null)
    validation.setFieldValue("userEmail", resData.user ? resData.user.email : null)

    validation.setFieldValue("taxNo", resData.orderTaxAddress.length ? resData.orderTaxAddress[0].taxNo : "-")
    validation.setFieldValue("taxFirstName", resData.orderTaxAddress.length ? resData.orderTaxAddress[0].taxFirstName : null)
    validation.setFieldValue("taxLastName", resData.orderTaxAddress.length ? resData.orderTaxAddress[0].taxLastName : null)
    validation.setFieldValue("taxFullName", resData.orderTaxAddress.length ? resData.orderTaxAddress[0].firstName + " " + resData.orderTaxAddress[0].lastName : "-")
    validation.setFieldValue("taxAddress", resData.orderTaxAddress.length ? resData.orderTaxAddress[0].address : null)

    validation.setFieldValue("orderFirstName", resData.firstName ? resData.firstName : null)
    validation.setFieldValue("orderLastName", resData.lastName ? resData.lastName : null)
    validation.setFieldValue("orderFullName", resData ? resData.firstName + " " + resData.lastName : null)
    validation.setFieldValue("orderMobileNo", resData.mobileNo ? resData.mobileNo : null)
    validation.setFieldValue("orderEmail", resData.email ? resData.email : null)
    validation.setFieldValue("orderAddress", resData.address ? resData.address : null)
    validation.setFieldValue("tombonName", resData.tombon ? resData.tombon.name : null)
    validation.setFieldValue("amphureName", resData.amphure ? resData.amphure.name : null)
    validation.setFieldValue("provinceName", resData.province ? resData.province.name : null)
    validation.setFieldValue("note", resData.note ? resData.note : "-")

    validation.setFieldValue("grandTotal", resData.grandTotal ? resData.grandTotal : null)
    validation.setFieldValue("paymentMethodGroupId", resData.paymentMethodGroup ? resData.paymentMethodGroup.id : null)
    validation.setFieldValue("paymentMethodGroupName", resData.paymentMethodGroup ? resData.paymentMethodGroup.name : null)
    validation.setFieldValue("paymentStatus", resData.paymentStatus === 0 ? 'Pending' : resData.paymentStatus === 1 ? 'Partially' : resData.paymentStatus === 2 ? 'Fully' : resData.paymentStatus === 3 ? 'Cancel' : resData.paymentStatus === 4 ? 'Failed' : resData.paymentStatus === 5 ? 'Deposit' : resData.paymentStatus === 0 ? 'Expired' : "")

    validation.setFieldValue("productSubtotal", resData.productSubtotal ? resData.productSubtotal : null)
    validation.setFieldValue("shippingFee", resData.shippingFee ? resData.shippingFee : null)

    validation.setFieldValue("receiptImg", resData.orderReceiptUpload.length ? resData.orderReceiptUpload[0].receiptImg : null)
    validation.setFieldValue("receiptStatus", resData.orderReceiptUpload.length ? resData.orderReceiptUpload[0].status === 0 ? "Awaiting Upload" : resData.orderReceiptUpload[0].receiptImg === 1 ? "Pending Review" : resData.orderReceiptUpload[0].receiptImg === 2 ? "Approved" : resData.orderReceiptUpload[0].receiptImg === 3 ? "Rejected" : "" : null)
    validation.setFieldValue("receiptRejectReason", resData.orderReceiptUpload.length ? resData.orderReceiptUpload[0].rejectReason : null)
    validation.setFieldValue("receiptPaidDate", resData.orderReceiptUpload.length ? moment(resData.orderReceiptUpload[0].paidDate).format("YYYY-MM-DD HH:mm") : null)

  }

  const API = {
    fetchGetOrderHistoryById: async (payload) => {
      try {
        const response = await GetOrderHistoryById({ uuid: payload.uuid })
        const resData = response.data

        if (resData.status == 202) {
          preFilInput(resData.data)
        }
      }
      catch (err) {
        console.log('err ', err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchContentList: async (payload) => {
      try {
        const response = await GetOrderProductByOrderHistoryId({ uuid: payload.uuid })
        const resData = response?.data ?? []
        setContentList(resData.data)
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
  }

  const columns = useMemo(
    () => [
      {
        header: "รหัสสินค้า",
        accessorKey: "sku",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "สินค้า",
        accessorKey: "",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "จำนวน",
        accessorKey: "quantity",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "สาขา",
        accessorKey: "branchName",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "รหัสสาขา",
        accessorKey: "branchCode",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "ราคา/หน่วย",
        accessorKey: "unitPrice",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const row = cellProps?.row?.original
          return fn_helper.FN.toNumberWithCommas(row.unitPrice)
        }
      },
    ],
    [selectedContentId, contentList]
  )

  const columnsFreeGift = useMemo(
    () => [
      {
        header: "รหัสสินค้า",
        accessorKey: "",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "แถมจากสินค้า",
        accessorKey: "",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "สินค้า",
        accessorKey: "",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "จำนวน",
        accessorKey: "",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "ราคา/หน่วย",
        accessorKey: "",
        enableColumnFilter: false,
        enableSorting: true,
      },
    ],
    [selectedContentFreeGiftId, contentListFreeGift]
  )


  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { uuid: qOrderHistoryId }
      API.fetchGetOrderHistoryById(payload)
    }
  }, [])

  const fetchAPI = () => {
    const payload = { uuid: qOrderHistoryId }
    API.fetchContentList(payload)
  }

  useEffect(() => {
    setContentListLoading(true)
    fetchAPI()
  }, []);

  useEffect(() => {
    if (contentList.length) {
      setContentListLoading(false)
    }
  }, [contentList])


  return (
    <React.Fragment>
      <Card style={{ marginBottom: '0px' }}>
        <CardBody>
          <Nav tabs className={"nav-tabs-custom mb-4 " + classnames({ error: false })}>
            <NavItem>
              <NavLink
                // className={classnames({ active: activeTab === "main-info", error: mainTabErr })}
                className={classnames({ active: activeTab === "info" })}
                onClick={() => setActiveTab("info")}
              >
                รายละเอียดการสั่งซื้อ
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "payment" })}
                onClick={() => setActiveTab("payment")}
              >
                การชำระเงิน
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "product" })}
                onClick={() => setActiveTab("product")}
              >
                รายการสินค้า
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink className={classnames({ active: activeTab === "shipping" })}
                onClick={() => setActiveTab("shipping")}
              >
                การจัดส่ง
              </NavLink>
            </NavItem>
          </Nav>
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <TabContent className={hasScroll ? 'bScorll' : ''} activeTab={activeTab}>
              <>
                <TabPane tabId="info">
                  {/* <>main-info</> */}
                  <Row className="mb-3">

                    <Col className="col-12">
                      <Row className="">
                        <Col className="col-6">
                          <Row className="">
                            <Col className="col-12">
                              <h5 className="mb-3">รายละเอียดการสั่งซื้อ</h5>
                            </Col>
                            <Col className="col-12 mb-3">
                              <label
                                htmlFor="label"
                                className="col-md-12 col-form-label"
                              >
                                วันที่สั่งซื้อ
                              </label>
                              <div className="col-md-12">
                                <Input
                                  id="createdAt"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  name="createdAt"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.createdAt}
                                  invalid={validation.touched.createdAt && validation.errors.createdAt ? true : false}
                                  disabled={pageView === "view"}
                                />
                                {
                                  validation.touched.createdAt && validation.errors.createdAt && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.createdAt}
                                    </FormFeedback>
                                  )
                                }
                              </div>
                            </Col>
                            <Col className="col-12 mb-3">
                              <label
                                htmlFor="label"
                                className="col-md-12 col-form-label"
                              >
                                รหัสคำสั่งซื้อ
                              </label>
                              <div className="col-md-12">
                                <Input
                                  id="orderNumber"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  name="orderNumber"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.orderNumber}
                                  invalid={validation.touched.orderNumber && validation.errors.orderNumber ? true : false}
                                  disabled={pageView === "view"}
                                />
                                {
                                  validation.touched.orderNumber && validation.errors.orderNumber && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.orderNumber}
                                    </FormFeedback>
                                  )
                                }
                              </div>
                            </Col>
                            <Col className="col-12 mb-3">
                              <label
                                htmlFor="label"
                                className="col-md-12 col-form-label"
                              >
                                ชนิดคำสั่งซื้อ
                              </label>
                              <div className="col-md-12">
                                <Input
                                  id="orderType"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  name="orderType"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.orderType}
                                  invalid={validation.touched.orderType && validation.errors.orderType ? true : false}
                                  disabled={pageView === "view"}
                                />
                                {
                                  validation.touched.orderType && validation.errors.orderType && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.orderType}
                                    </FormFeedback>
                                  )
                                }
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-12">
                      <Row className="">
                        <Col className="col-6">
                          <Row className="">
                            <Col className="col-12">
                              <h5 className="mb-3">ข้อมูลผู้สั่งซื้อ</h5>
                            </Col>
                            <Col className="col-12 mb-3">
                              <label
                                htmlFor="label"
                                className="col-md-12 col-form-label"
                              >
                                ชื่อ - นามสกุล
                              </label>
                              <div className="col-md-12">
                                <Input
                                  id="fullName"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  name="fullName"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}

                                  value={validation.values.userFullName}
                                  disabled={pageView === "view"}
                                />
                                {
                                  validation.touched.userFullName && validation.errors.userFullName && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.userFullName}
                                    </FormFeedback>
                                  )
                                }
                              </div>
                            </Col>
                            <Col className="col-12 mb-3">
                              <label
                                htmlFor="label"
                                className="col-md-12 col-form-label"
                              >
                                ประเภทบุคคล
                              </label>
                              <div className="col-md-12">
                                <Input
                                  id="userType"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  name="userType"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.userType}
                                  invalid={validation.touched.userType && validation.errors.userType ? true : false}
                                  disabled={pageView === "view"}
                                />
                                {
                                  validation.touched.userType && validation.errors.userType && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.userType}
                                    </FormFeedback>
                                  )
                                }
                              </div>
                            </Col>
                            <Col className="col-12 mb-3">
                              <label
                                htmlFor="label"
                                className="col-md-12 col-form-label"
                              >
                                เบอร์ติดต่อ
                              </label>
                              <div className="col-md-12">
                                <Input
                                  id="userMobileNo"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  name="userMobileNo"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.userMobileNo}
                                  invalid={validation.touched.userMobileNo && validation.errors.userMobileNo ? true : false}
                                  disabled={pageView === "view"}
                                />
                                {
                                  validation.touched.userMobileNo && validation.errors.userMobileNo && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.userMobileNo}
                                    </FormFeedback>
                                  )
                                }
                              </div>
                            </Col>
                            <Col className="col-12 mb-3">
                              <label
                                htmlFor="label"
                                className="col-md-12 col-form-label"
                              >
                                อีเมล
                              </label>
                              <div className="col-md-12">
                                <Input
                                  id="userEmail"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  name="userEmail"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.userEmail}
                                  invalid={validation.touched.userEmail && validation.errors.userEmail ? true : false}
                                  disabled={pageView === "view"}
                                />
                                {
                                  validation.touched.userEmail && validation.errors.userEmail && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.userEmail}
                                    </FormFeedback>
                                  )
                                }
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col className="col-6">
                          <Row className="">
                            <Col className="col-12">
                              <h5 className="mb-3">ที่อยู่ใบกำกับภาษี</h5>
                            </Col>
                            <Col className="col-12 mb-3">
                              <label
                                htmlFor="label"
                                className="col-md-12 col-form-label"
                              >
                                เลขประจำตัวผู้เสียภาษี
                              </label>
                              <div className="col-md-12">
                                <Input
                                  id="taxNo"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  name="taxNo"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.taxNo}
                                  invalid={validation.touched.taxNo && validation.errors.taxNo ? true : false}
                                  disabled={pageView === "view"}
                                />
                                {
                                  validation.touched.taxNo && validation.errors.taxNo && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.taxNo}
                                    </FormFeedback>
                                  )
                                }
                              </div>
                            </Col>
                            <Col className="col-12 mb-3">
                              <label
                                htmlFor="label"
                                className="col-md-12 col-form-label"
                              >
                                ชื่อ - นามสกุล
                              </label>
                              <div className="col-md-12">
                                <Input
                                  id="fullName"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  name="fullName"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}

                                  value={validation.values.taxFullName}
                                  disabled={pageView === "view"}
                                />
                                {
                                  validation.touched.taxFullName && validation.errors.taxFullName && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.taxFullName}
                                    </FormFeedback>
                                  )
                                }
                              </div>
                            </Col>

                            <Col className="col-12 mb-3">
                              <label
                                htmlFor="label"
                                className="col-md-12 col-form-label"
                              >
                                ที่อยู่
                              </label>
                              <div className="col-md-12">
                                <Input
                                  id="fullName"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  name="fullName"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}

                                  value={validation.values.taxAddress}
                                  disabled={pageView === "view"}
                                />
                                {
                                  validation.touched.taxAddress && validation.errors.taxAddress && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.taxAddress}
                                    </FormFeedback>
                                  )
                                }
                              </div>
                            </Col>

                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-12">
                      <Row className="">
                        <Col className="col-6">
                          <Row className="">
                            <Col className="col-12">
                              <h5 className="mb-3">ที่อยู่ในการจัดส่งสินค้า</h5>
                            </Col>
                            <Col className="col-12 mb-3">
                              <label
                                htmlFor="label"
                                className="col-md-12 col-form-label"
                              >
                                ชื่อ - นามสกุล
                              </label>
                              <div className="col-md-12">
                                <Input
                                  id="fullName"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  name="fullName"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}

                                  value={validation.values.orderFullName}
                                  disabled={pageView === "view"}
                                />
                                {
                                  validation.touched.orderFullName && validation.errors.orderFullName && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.orderFullName}
                                    </FormFeedback>
                                  )
                                }
                              </div>
                            </Col>
                            <Col className="col-12 mb-3">
                              <label
                                htmlFor="label"
                                className="col-md-12 col-form-label"
                              >
                                เบอร์ติดต่อ
                              </label>
                              <div className="col-md-12">
                                <Input
                                  id="orderMobileNo"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  name="orderMobileNo"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.orderMobileNo}
                                  invalid={validation.touched.orderMobileNo && validation.errors.orderMobileNo ? true : false}
                                  disabled={pageView === "view"}
                                />
                                {
                                  validation.touched.orderMobileNo && validation.errors.orderMobileNo && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.orderMobileNo}
                                    </FormFeedback>
                                  )
                                }
                              </div>
                            </Col>

                            <Col className="col-12 mb-3">
                              <label
                                htmlFor="label"
                                className="col-md-12 col-form-label"
                              >
                                อีเมล
                              </label>
                              <div className="col-md-12">
                                <Input
                                  id="orderEmail"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  name="orderEmail"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.orderEmail}
                                  invalid={validation.touched.orderEmail && validation.errors.orderEmail ? true : false}
                                  disabled={pageView === "view"}
                                />
                                {
                                  validation.touched.orderEmail && validation.errors.orderEmail && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.orderEmail}
                                    </FormFeedback>
                                  )
                                }
                              </div>
                            </Col>

                            <Col className="col-12 mb-3">
                              <label
                                htmlFor="label"
                                className="col-md-12 col-form-label"
                              >
                                ที่อยู่
                              </label>
                              <div className="col-md-12">
                                <Input
                                  id="orderAddress"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  name="orderAddress"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.orderAddress}
                                  invalid={validation.touched.orderAddress && validation.errors.orderAddress ? true : false}
                                  disabled={pageView === "view"}
                                />
                                {
                                  validation.touched.orderAddress && validation.errors.orderAddress && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.orderAddress}
                                    </FormFeedback>
                                  )
                                }
                              </div>
                            </Col>
                            <Col className="col-12 mb-3">
                              <label
                                htmlFor="label"
                                className="col-md-12 col-form-label"
                              >
                                ตำบล/แขวง
                              </label>
                              <div className="col-md-12">
                                <Input
                                  id="tombonName"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  name="tombonName"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.tombonName}
                                  invalid={validation.touched.tombonName && validation.errors.tombonName ? true : false}
                                  disabled={pageView === "view"}
                                />
                                {
                                  validation.touched.tombonName && validation.errors.tombonName && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.tombonName}
                                    </FormFeedback>
                                  )
                                }
                              </div>
                            </Col>
                            <Col className="col-12 mb-3">
                              <label
                                htmlFor="label"
                                className="col-md-12 col-form-label"
                              >
                                อำเภอ/เขต
                              </label>
                              <div className="col-md-12">
                                <Input
                                  id="amphureName"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  name="amphureName"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.amphureName}
                                  invalid={validation.touched.amphureName && validation.errors.amphureName ? true : false}
                                  disabled={pageView === "view"}
                                />
                                {
                                  validation.touched.amphureName && validation.errors.amphureName && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.amphureName}
                                    </FormFeedback>
                                  )
                                }
                              </div>
                            </Col>
                            <Col className="col-12 mb-3">
                              <label
                                htmlFor="label"
                                className="col-md-12 col-form-label"
                              >
                                จังหวัด
                              </label>
                              <div className="col-md-12">
                                <Input
                                  id="provinceName"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  name="provinceName"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.provinceName}
                                  invalid={validation.touched.provinceName && validation.errors.provinceName ? true : false}
                                  disabled={pageView === "view"}
                                />
                                {
                                  validation.touched.provinceName && validation.errors.provinceName && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.provinceName}
                                    </FormFeedback>
                                  )
                                }
                              </div>
                            </Col>
                            <Col className="col-12 mb-3">
                              <label
                                htmlFor="label"
                                className="col-md-12 col-form-label"
                              >
                                Note
                              </label>
                              <div className="col-md-12">
                                <Input
                                  id="note"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  name="note"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.note}
                                  invalid={validation.touched.note && validation.errors.note ? true : false}
                                  disabled={pageView === "view"}
                                />
                                {
                                  validation.touched.note && validation.errors.note && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.note}
                                    </FormFeedback>
                                  )
                                }
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="payment">
                  <Row className="mb-3">
                    <Col className="col-12">
                      <Row className="">
                        <Col className="col-6">
                          <Row className="">
                            <Col className="col-12">
                              <h5 className="mb-3">ข้อมูลการชำระเงิน</h5>
                            </Col>
                            <Col className="col-12 mb-3">
                              <label
                                htmlFor="label"
                                className="col-md-12 col-form-label"
                              >
                                ยอดสุทธิ
                              </label>
                              <div className="col-md-12">
                                <Input
                                  id="grandTotal"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  name="grandTotal"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={fn_helper.FN.toNumberWithCommas(validation.values.grandTotal)}
                                  invalid={validation.touched.grandTotal && validation.errors.grandTotal ? true : false}
                                  disabled={pageView === "view"}
                                />
                                {
                                  validation.touched.grandTotal && validation.errors.grandTotal && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.grandTotal}
                                    </FormFeedback>
                                  )
                                }
                              </div>
                            </Col>
                            <Col className="col-12 mb-3">
                              <label
                                htmlFor="label"
                                className="col-md-12 col-form-label"
                              >
                                ช่องทางการชำระเงิน
                              </label>
                              <div className="col-md-12">
                                <Input
                                  id="paymentMethodGroupName"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  name="paymentMethodGroupName"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.paymentMethodGroupName}
                                  invalid={validation.touched.paymentMethodGroupName && validation.errors.paymentMethodGroupName ? true : false}
                                  disabled={pageView === "view"}
                                />
                                {
                                  validation.touched.paymentMethodGroupName && validation.errors.paymentMethodGroupName && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.paymentMethodGroupName}
                                    </FormFeedback>
                                  )
                                }
                              </div>
                            </Col>
                            <Col className="col-12 mb-3">
                              <label
                                htmlFor="label"
                                className="col-md-12 col-form-label"
                              >
                                สถานะการชำระเงิน
                              </label>
                              <div className="col-md-12">
                                <Input
                                  id="paymentStatus"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  name="paymentStatus"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.paymentStatus}
                                  invalid={validation.touched.paymentStatus && validation.errors.paymentStatus ? true : false}
                                  disabled={pageView === "view"}
                                />
                                {
                                  validation.touched.paymentStatus && validation.errors.paymentStatus && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.paymentStatus}
                                    </FormFeedback>
                                  )
                                }
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {validation.values.paymentMethodGroupId === 2 && (
                    <Row className="mb-3">
                      <Col className="col-12">
                        <Row className="">
                          <Col className="col-6">
                            <Row className="">
                              <Col className="col-12">
                                <h5 className="mb-3">รูปใบเสร็จ</h5>
                              </Col>
                              <Col className="col-12 mb-3">
                                <img src={validation.values.receiptImg} />
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="label"
                                  className="col-md-12 col-form-label"
                                >
                                  สถานการอนุมัติใบเสร็จ
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="receiptStatus"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="receiptStatus"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.receiptStatus}
                                    invalid={validation.touched.receiptStatus && validation.errors.receiptStatus ? true : false}
                                    disabled={pageView === "view"}
                                  />
                                  {
                                    validation.touched.receiptStatus && validation.errors.receiptStatus && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.receiptStatus}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="label"
                                  className="col-md-12 col-form-label"
                                >
                                  เหตุผลการปฏิเสธใบเสร็จ
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="receiptRejectReason"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="receiptRejectReason"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.receiptRejectReason}
                                    invalid={validation.touched.receiptRejectReason && validation.errors.receiptRejectReason ? true : false}
                                    disabled={pageView === "view"}
                                  />
                                  {
                                    validation.touched.receiptRejectReason && validation.errors.receiptRejectReason && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.receiptRejectReason}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="label"
                                  className="col-md-12 col-form-label"
                                >
                                  วันที่ลูกค้าชำระเงิน (ดูจากใบเสร็จ)
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="receiptPaidDate"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="receiptPaidDate"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.receiptPaidDate}
                                    invalid={validation.touched.receiptPaidDate && validation.errors.receiptPaidDate ? true : false}
                                    disabled={pageView === "view"}
                                  />
                                  {
                                    validation.touched.receiptPaidDate && validation.errors.receiptPaidDate && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.receiptPaidDate}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}

                </TabPane>
                <TabPane tabId="product">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">รายการสินค้า</CardTitle>
                      <Col className="col-12 mb-3">
                        <TableContainer
                          noTop={true}
                          tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                          columns={columns}
                          data={contentList || []}
                        />
                      </Col>

                      <Row className="mb-3">
                        <Col className="col-12">
                          <Row className="">
                            <Col className="col-6">
                              <Row className="">
                                <Col className="col-12 mb-3">
                                  <label
                                    htmlFor="label"
                                    className="col-md-12 col-form-label"
                                  >
                                    รวมราคาสินค้า (บาท)
                                  </label>
                                  <div className="col-md-12">
                                    <Input
                                      id="productSubtotal"
                                      className="form-control"
                                      type="text"
                                      placeholder=""
                                      name="productSubtotal"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={fn_helper.FN.toNumberWithCommas(validation.values.productSubtotal)}
                                      invalid={validation.touched.productSubtotal && validation.errors.productSubtotal ? true : false}
                                      disabled={pageView === "view"}
                                    />
                                    {
                                      validation.touched.productSubtotal && validation.errors.productSubtotal && (
                                        <FormFeedback type="invalid">
                                          {validation.errors.productSubtotal}
                                        </FormFeedback>
                                      )
                                    }
                                  </div>
                                </Col>
                                <Col className="col-12 mb-3">
                                  <label
                                    htmlFor="label"
                                    className="col-md-12 col-form-label"
                                  >
                                    ค่าจัดส่ง (บาท)
                                  </label>
                                  <div className="col-md-12">
                                    <Input
                                      id="shippingFee"
                                      className="form-control"
                                      type="text"
                                      placeholder=""
                                      name="shippingFee"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={fn_helper.FN.toNumberWithCommas(validation.values.shippingFee)}
                                      invalid={validation.touched.shippingFee && validation.errors.shippingFee ? true : false}
                                      disabled={pageView === "view"}
                                    />
                                    {
                                      validation.touched.shippingFee && validation.errors.shippingFee && (
                                        <FormFeedback type="invalid">
                                          {validation.errors.shippingFee}
                                        </FormFeedback>
                                      )
                                    }
                                  </div>
                                </Col>
                                <Col className="col-12 mb-3">
                                  <label
                                    htmlFor="label"
                                    className="col-md-12 col-form-label"
                                  >
                                    ยอดสุทธิ (บาท)
                                  </label>
                                  <div className="col-md-12">
                                    <Input
                                      id="grandTotal"
                                      className="form-control"
                                      type="text"
                                      placeholder=""
                                      name="grandTotal"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={fn_helper.FN.toNumberWithCommas(validation.values.grandTotal)}
                                      invalid={validation.touched.grandTotal && validation.errors.grandTotal ? true : false}
                                      disabled={pageView === "view"}
                                    />
                                    {
                                      validation.touched.grandTotal && validation.errors.grandTotal && (
                                        <FormFeedback type="invalid">
                                          {validation.errors.grandTotal}
                                        </FormFeedback>
                                      )
                                    }
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <CardTitle className="h4">รายการของแถม</CardTitle>
                      <Col className="col-12 mb-3">
                        <TableContainer
                          noTop={true}
                          tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                          columns={columnsFreeGift}
                          data={contentListFreeGift || []}
                        />
                      </Col>
                    </CardBody>
                  </Card>
                </TabPane>
                <TabPane tabId="shipping">
                  <>shipping</>
                </TabPane>
              </>
            </TabContent>
            <hr />
            <div
              style={{
                padding: '16px 32px',
                display: "flex",
                justifyContent: "end",
                gap: "8px",
              }}
            >
              {pageView == "view" ? (
                <>
                  <button
                    className="btn btn-primary w-md"
                    onClick={() => onBack()}
                  >
                    BACK
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="submit"
                    className="btn btn-primary w-md"
                  >
                    SAVE
                  </button>
                  <button
                    className="btn btn-secondary w-md"
                    onClick={() => onBack()}
                  >
                    CANCEL
                  </button>
                </>
              )}
            </div>
          </Form>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default withTranslation()(OrderHistoryForm)
