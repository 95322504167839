import React, { useEffect, useState, useContext, useMemo } from "react"
import { Button, Card, CardBody, Col, Form, FormFeedback, Row } from "reactstrap"
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import classnames from "classnames"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { withTranslation } from "react-i18next"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import ModalContext from "../../contexts/ModalContext"
import MainInfo from "./Form/MainInfo"
import DetailInfo from "./Form/DetailInfo"
import SpecInfo from "./Form/SpecInfo"
import ImageGalleryInfo from "./Form/ImageGalleryInfo"
import VariableForm from "./Form/VariableForm"
import TableContainer from "components/Common/TableContainer"
import DialogCustom from "components/modal/DialogCustom"
import GenerateConfig from "./Popup/GenerateConfig"
import ShowBox from "components/ShowBox"
import fn_helper from "helpers/fn_helper"
import ConfigAttributes from "./Form/ConfigAttributes"
import { TableLoading } from "components/TableComponents"
import DropdownOptionsForm from "pages/Attributes/DropdownForm"
import HighlightForm from "./Form/HighlightForm"
import moment from "moment"
import ProductInSetInfo from "./Form/ProductInSetInfo"
import CompareInfo from "./Form/CompareInfo"
import Image360Info from "./Form/Image360Info"
import ProductTypeForm from "./Form/ProductTypeForm"

const convertDIYList = (diyList = []) => { 
  return diyList.map((item) => {
    return { 
      label: item.label, 
      attributeSetCode: item.attributeSetCode, 
      productDiyIds:[ ...Array(item.maxSlotQuantity)].map(item1 => ({ id: null, productId: null, productDiyId: null,sku: null, quantity: 0, slotQuantity: 0 })), 
      isCustomizable: "0", 
      maxSlotQuantity: item.maxSlotQuantity,
      partFilterMain: item.partFilterMain,
      partFilterOptional: item.partFilterOptional
    }
  })
}

const DEF_FORM = {
  // Main Info
  id: "",
  nameTh: "",
  nameEn: "",
  categoryIds: "",
  brandId: "",
  status: "1",
  sortOrder: "",
  isVariant: "0",
  sku: "",
  type: "",
  productConfig: [],
  
  // PART Highlight Form
  highlights: [],

  // PART Variable From
  attributeValue: [],
  sku: "",
  isBestseller: "0",
  bestsellerNumber: "",
  isNew: "",
  newStart: null,
  newEnd: null,
  isFreeShipping: "0",
  isInstallmentPlan: "0",
  isInStock: "1",

  productType: "",
  preOrderShippingDate: null,
  preOrderStart: null,
  preOrderEnd: null,

  isFastShipping: "0",
  supplierId: "",

  // Detail Info 
  weight: "",
  weightIncludeBox: "",
  width: "",
  length: "",
  height: "",
  shortDescription: "",
  description: "",

  videoType: "1", // 1 = 'youtube'
  videoValue: "",

  // Spec Info 
  attributeSetId: "",
  attributeValue: [],

  // Image Gallery
  gallery: [],

  // Image 360
  img360: [],

  // Product Compare
  compare: [],

  // Product Variant
  productGenerate: {
    attributeSelected: [],
    attributeValueSelected: {},
    generatedProducts: []
  },
  productVariant: [],

}

const checkproductDIYForm = (_pyProductDiy = []) => {
  // console.log("🚀 ~ checkproductDIYForm ~ _pyProductDiy:", _pyProductDiy)
  let isPass = true
  for(let pd of _pyProductDiy) {
    if (pd.isCustomizable === 1 && pd.productDiyIds.length === 0) {
      isPass = false
      break;
    }
  }
  // console.log("🚀 ~ checkproductDIYForm ~ isPass:", isPass)
  return isPass
}

const ProductsForm = ({
  isComSet = false,
  loading = false,
  dropdownOption = {
    category: [],
    brand: [],
    attributeSetId: [],
    attributeConfig: [],
    productSupplier: [],
    productDiy: [],
    computerDiyCate: [],
    productCompare: [],
  },
  value = {},
  isChild = false,
  hasScroll = false,
  pageView = "create",
  attributeSelectedMain = [],
  onBack = () => console.log('onBack!'),
  onSave = () => console.log('onSave!'),
  onSaveMain = () => console.log('onSaveMain!'),
  isComputerDIY = false,
  productCodeError = false
}) => {
  const [activeTab, setActiveTab] = useState("main-info")
  // const [activeTab, setActiveTab] = useState("image-gallery")
  // const [activeTab, setActiveTab] = useState("specification")

  // STATE POPUP
  const [ isOpenGenConf, setIsOpenGenConf ] = useState(false)
  const [ isOpenEdit, setIsOpenEdit ] = useState(false)
  const [ popupState, setPopupState ] = useState({})
  const [ popupStateIndex, setPopupStateIndex, ] = useState(-1)

  // POPUP
  const { mAlert } = useContext(ModalContext)

  // OTHER STATE
  const qParams = useParams()
  const qProductsId = qParams.id
  const navigate = useNavigate()

  // ========== Form Validation ========== //
  const schemaParent = Yup.object({
    // MainInfo
    sku: Yup.string().required("Please Enter SKU"),
    nameTh: Yup.string().required("Please Enter Thai Name"),
    nameEn: Yup.string().required("Please Enter Eng Name"),
    categoryIds: Yup.array().min(1, 'Please Select Category').required('Please Select Category'),
    brandId: Yup.string().required("Please Select Brand"),
    // attributeSetId: Yup.string().required("Please Select Attribute Set"),
    status: Yup.string().required("Please Select Status"),
    // sortOrder: Yup.number().required("Please Enter Sort Order"),

    // // Detail Info 
    weight: Yup.string().required("Please Enter Weight"),
    weightIncludeBox: Yup.string().required("Please Enter Weight Include Box"),
    width: Yup.string().required("Please Enter Width"),
    length: Yup.string().required("Please Enter Length"),
    height: Yup.string().required("Please Enter Height"),
    // shortDescription: Yup.string().required("Please Enter Short Description"),
    // description: Yup.string().required("Please Enter Description"),
    // videoValue: Yup.string().required("Please Enter Video Value"),
    productType: Yup.string().required("Please Select Product Type"),
    newStart: Yup.string().when('isNew', {
      is: (val) => val == 1 || val == '1', 
      then: (schema) => Yup.string().required("Please Enter New Start"), 
      otherwise: (schema) => schema.notRequired(), 
    }),
    newEnd: Yup.string().when('isNew', {
      is: (val) => val == 1 || val == '1', 
      then: (schema) => Yup.string().required("Please Enter New End"), 
      otherwise: (schema) => schema.notRequired(),
    }),

    preOrderShippingDate: Yup.string().when('productType', {
      is: (val) => val == 4 || val == '4', 
      then: (schema) => Yup.string().required("Please Select Shipping Date"), 
      otherwise: (schema) => schema.notRequired(), 
    }),
    preOrderStart: Yup.string().when('productType', {
      is: (val) => val == 4 || val == '4', 
      then: (schema) => Yup.string().required("Please Enter Pre Order Start"), 
      otherwise: (schema) => schema.notRequired(), 
    }),
    preOrderEnd: Yup.string().when('productType', {
      is: (val) => val == 4 || val == '4', 
      then: (schema) => Yup.string().required("Please Enter Pre Order End"), 
      otherwise: (schema) => schema.notRequired(),
    }),
    supplierId: Yup.string().when('productType', {
      is: (val) => val == 2 || val == '2', 
      then: (schema) => Yup.string().required("Please Select Supplier"), 
      otherwise: (schema) => schema.notRequired(),
    }),

    // //Specification
    // attributeSetId: Yup.string().required("Please Select Spec Attribute Set"),
    attributeValue: Yup.array().of(
      Yup.object().shape({
        attributeId: Yup.string(),
        isRequired: Yup.string(),
        value: Yup.array().when('isRequired', {
          is: (val) => val == 1 || val == '1', // เช็คว่า isRequired ต้องเป็น 1 (ทั้งแบบ string และ number)
          then: (schema) => schema.min(1, "Please $Action $LabelText"), // ถ้า isRequired เป็น 1 ให้ value ต้องมีอย่างน้อย 2 ค่า
          otherwise: (schema) => schema.notRequired(), // ถ้าไม่ใช่ 1 ไม่จำเป็นต้องกรอก
        }),
      })
    ),

    // //Image Gallery
    gallery: Yup.array()
      .min(1, 'Please Select Gallery')
      .required('Please Select Gallery'),
    
  })

  const schemaChild = Yup.object({
    // MainInfo
    sku: Yup.string().required("Please Enter SKU"),
    nameTh: Yup.string().required("Please Enter Thai Name"),
    nameEn: Yup.string().required("Please Enter Eng Name"),
    // categoryIds: Yup.string().required("Please Select Category"),
    // brandId: Yup.string().required("Please Select Brand"),
    // attributeSetId: Yup.string().required("Please Select Attribute Set"),
    status: Yup.string().required("Please Select Status"),
    // sortOrder: Yup.number().required("Please Enter Sort Order"),

    // // Detail Info 
    // weight: Yup.string().required("Please Enter Weight"),
    // weightIncludeBox: Yup.string().required("Please Enter Weight Include Box"),
    // width: Yup.string().required("Please Enter Width"),
    // length: Yup.string().required("Please Enter Length"),
    // height: Yup.string().required("Please Enter Height"),
    // shortDescription: Yup.string().required("Please Enter Short Description"),
    // description: Yup.string().required("Please Enter Description"),
    // videoValue: Yup.string().required("Please Enter Video Value"),

    newStart: Yup.string().when('isNew', {
      is: (val) => val == 1 || val == '1', 
      then: (schema) => Yup.string().required("Please Enter New Start"), 
      otherwise: (schema) => schema.notRequired(), 
    }),
    newEnd: Yup.string().when('isNew', {
      is: (val) => val == 1 || val == '1', 
      then: (schema) => Yup.string().required("Please Enter New End"), 
      otherwise: (schema) => schema.notRequired(),
    }),

    // //Specification
    // attributeSetId: Yup.string().required("Please Select Spec Attribute Set"),
    attributeValue: Yup.array().of(
      Yup.object().shape({
        attributeId: Yup.string(),
        isRequired: Yup.string(),
        value: Yup.array().when('isRequired', {
          is: (val) => val == 1 || val == '1', // เช็คว่า isRequired ต้องเป็น 1 (ทั้งแบบ string และ number)
          then: (schema) => schema.min(1, "Please $Action $LabelText"), // ถ้า isRequired เป็น 1 ให้ value ต้องมีอย่างน้อย 2 ค่า
          otherwise: (schema) => schema.notRequired(), // ถ้าไม่ใช่ 1 ไม่จำเป็นต้องกรอก
        }),
      })
    ),

    // //Image Gallery
    gallery: Yup.array()
      .min(1, 'At least one item is required')
      .required('Gallery field is required')

  })

  const productDiyMapSku = (productDiy = [], optionObject = {}) => {
    // console.log("🚀 ~ productDiyMapSku ~ productDiy:", productDiy)
    // console.log("🚀 ~ productDiyMapSku ~ optionObject:", optionObject)

    let option = []
    for (let key in optionObject) {
      option = [...option, ...optionObject[key]]
    }

    console.log("🚀 ~ productDiyMapSku ~ productDiy:", productDiy)
    console.log("🚀 ~ productDiyMapSku ~ option:", option)
    console.log("🚀 ~ productDiyMapSku ~ ---------------------------------------------------")

    return productDiy.map((item0, index) => { 
      item0.productDiyIds = item0.productDiyIds.map((item1, index1) => {
        const findOption = option.find(opt => opt.id == item1.productDiyId)
        item1.sku = findOption?.sku ?? null
        return item1
      })
      return item0
    })
  }
  const productMapSku = (productIds = [], productList = []) => {
    let productListObject = {}
    for (let pd of productList) {
      productListObject[pd.id] = pd.sku
    }

    console.log("🚀 ~ productMapSku ~ productIds:", productIds)
    console.log("🚀 ~ productMapSku ~ productList:", productList)
    console.log("🚀 ~ productMapSku ~ ---------------------------------------------------")

    return productIds.map((item0, index) => { 
      const sku = productListObject[item0] ? productListObject[item0] : null
      return {
        id: item0,
        sku: sku
      }
    })
  }

  const validationSchema = isChild === true ? schemaChild : schemaParent 

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: { ...DEF_FORM, productDiy: [] },
    validationSchema: validationSchema,
    onSubmit: values => {
      console.log("🚀 ~ ProductsForm ~ values:", values)
      const payload = {
        // Main Info
        id: values?.id ? values.id : undefined,
        sku: values.sku ? fn_helper.FN.toSKU(values.sku) : '',
        nameTh: values.nameTh,
        nameEn: values.nameEn,
        categoryIds: values.categoryIds ? values.categoryIds : [],
        brandId: values.brandId,
        status: values.status,
        sortOrder: values.sortOrder,
        attributeSetId: values.attributeSetId,
        productConfig: values.productConfig,
        isVariant: values.isVariant ?  values.isVariant : 0,

        // PART Highlight From
        highlights: fnCleanHighlightPy(values.highlights),

        // PART Variable From
        isBestseller: values.isBestseller,
        bestsellerNumber: values.bestsellerNumber,
        isNew: values.isNew,
        newStart: values.newStart,
        newEnd: values.newEnd,
        isFreeShipping: values.isFreeShipping,
        isInstallmentPlan: values.isInstallmentPlan,
        isInStock: values.isInStock,

        productType: values.productType,
        preOrderShippingDate: values.preOrderShippingDate,
        preOrderStart: values.preOrderStart,
        preOrderEnd: values.preOrderEnd,

        isFastShipping: values.isFastShipping,
        supplierId: values.supplierId,


        // Detail Info 
        weight: values.weight,
        weightIncludeBox: values.weightIncludeBox,
        width: values.width,
        length: values.length,
        height: values.height,
        shortDescription: values.shortDescription,
        description: values.description,

        videoType: values.videoType,
        videoValue: values.videoValue,

        // Spect Info 
        attributeSetId: values.attributeSetId,
        attributeValue: values.attributeValue,

        // Image Gallery
        gallery: values.gallery,

        // Image 360
        img360: values.img360,

        // Product Compare
        compare: values.compare,

        // Product Variant
        productGenerate: values.productGenerate,
        productVariant: values.productVariant,
        productDiy: productDiyMapSku(values.productDiy, dropdownOption.productDiy),
      }

      // console.log("🚀 ~ ProductsForm ~ payload:", payload)
      // console.log("🚀 ~ ProductsForm ~ payload.productDiy :", payload.productDiy)
      if (isComSet === true) {
        if (checkproductDIYForm(payload.productDiy) === false) {
          return;
        }
      }

      if (isChild === true) {
        onSave(payload)
      } else {
        onSaveMain(payload)
      }
    },
  })

  // set เพิ่มเข้าไปเอง
  validation.validationSchema = validationSchema
  validation.isChild = isChild

  const columns = useMemo(
    () => [
      // {
      //   header: "Image",
      //   accessorKey: "",
      //   enableColumnFilter: false,
      //   enableSorting: false,
      //   cell: cellProps => {
      //     const { gallery = "" } = cellProps?.row?.original
      //     return <img className="bImageBox" src={gallery} />
      //   },
      // },
      {
        header: "SKU",
        accessorKey: "sku",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Name TH",
        accessorKey: "nameTh",
        enableColumnFilter: false,
        enableSorting: false,
      },
      // {
      //   header: "NameEN",
      //   accessorKey: "nameEn",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      // },
      
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          const { status = "" } = cellProps?.row?.original
          return fn_helper.Component.RowStatus({ status: status })
        },
      },
      {
        header: "Tools",
        accessorKey: "user_id",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          let { id } = cellProps?.row?.original ?? {}
          let { index } = cellProps?.row ?? -1
          // console.log("🚀 ~ cellProps?.row.index :", index)
          const onEdit =() => {
            const productVariant = validation.values.productVariant
            const findProduct = productVariant.find((item, i) => i === index)
            console.log("🚀 ~ findProduct:", findProduct)
            setPopupState(findProduct)
            setPopupStateIndex(index)
            setTimeout(() => setIsOpenEdit(true), 100)
          }
          return (
            <>
              {
                pageView === 'view'
                ? (
                  <a className="btn btn-info" onClick={onEdit}>
                    <i className="bx bx-show-alt"></i>
                  </a>
                )
                : (
                  <a className="btn btn-warning" onClick={onEdit}>
                    <i className="bx bx-edit-alt"></i>
                  </a>
                )
              }
            </>
          )
        },
      },
    ],
    [validation.values.productVariant]
  )

  const genProductToProductVariant = (genProduct = [], currentForm = {}) => {
    let ans = []
    const DEF_CURRENT_FORM = _.cloneDeep(currentForm)
    for (let gp of genProduct) {
      const tmp = {
        ...DEF_FORM,
        // ...DEF_CURRENT_FORM,
        nameTh: gp.sku,
        nameEn: gp.sku,
        sku: gp.sku,
        productConfig: gp.attributeConfig
      }
      ans.push(tmp)
    }
    return ans
  }

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)

    // Main Info
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("nameTh", resData.nameTh)
    validation.setFieldValue("nameEn", resData.nameEn)
    validation.setFieldValue("categoryIds", resData.categoryIds)
    validation.setFieldValue("brandId", resData.brandId)
    validation.setFieldValue("attributeSetId", resData.attributeSetId)
    validation.setFieldValue("status", resData.status)
    validation.setFieldValue("sortOrder", resData.sortOrder)
    validation.setFieldValue("isVariant", resData.isVariant)
    validation.setFieldValue("sku", resData.sku)
    validation.setFieldValue("type", resData.type)
    validation.setFieldValue("productConfig", resData.productConfig)


    // PART Highlight From
    validation.setFieldValue("highlights", resData.highlights)

    // PART Variable From
    validation.setFieldValue("attributeSetId", resData.attributeSetId)
    validation.setFieldValue("attributeValue", resData.attributeValue)
    validation.setFieldValue("sku", resData.sku)
    validation.setFieldValue("isBestseller", resData.isBestseller)
    validation.setFieldValue("bestsellerNumber", resData.bestsellerNumber)
    validation.setFieldValue("isNew", resData.isNew)
    validation.setFieldValue("newStart", resData.newStart ? moment(resData.newStart).toDate() : null)
    validation.setFieldValue("newEnd", resData.newEnd ? moment(resData.newEnd).toDate() : null)
    validation.setFieldValue("isFreeShipping", resData.isFreeShipping)
    validation.setFieldValue("isInstallmentPlan", resData.isInstallmentPlan)
    validation.setFieldValue("isInStock", resData.isInStock)
    
    validation.setFieldValue("productType", resData.productType)
    validation.setFieldValue("preOrderShippingDate", resData.preOrderShippingDate ? moment(resData.preOrderShippingDate).toDate() : null)
    validation.setFieldValue("preOrderStart", resData.preOrderStart ? moment(resData.preOrderStart).toDate() : null)
    validation.setFieldValue("preOrderEnd", resData.preOrderEnd ? moment(resData.preOrderEnd).toDate() : null)
    
    validation.setFieldValue("isFastShipping", resData.isFastShipping)
    validation.setFieldValue("supplierId", resData.supplierId)



    // Detail Info 
    validation.setFieldValue("weight", resData.weight)
    validation.setFieldValue("weightIncludeBox", resData.weightIncludeBox)
    validation.setFieldValue("width", resData.width)
    validation.setFieldValue("length", resData.length)
    validation.setFieldValue("height", resData.height)
    validation.setFieldValue("shortDescription", resData.shortDescription)
    validation.setFieldValue("description", resData.description)
    validation.setFieldValue("videoType", resData.videoType)
    validation.setFieldValue("videoValue", resData.videoValue)


    // Spect Info 
    validation.setFieldValue("attributeSetId", resData.attributeSetId)
    validation.setFieldValue("attributeValue", resData.attributeValue)


    // Image Gallery
    validation.setFieldValue("gallery", resData.gallery)

    // Image 360
    validation.setFieldValue("img360", resData.img360)

    // Product Compare
    validation.setFieldValue("compare", resData.compare)


    // Product Variant
    validation.setFieldValue("productGenerate", resData.productGenerate)
    validation.setFieldValue("productVariant", resData.productVariant)

    // Product Set
    console.log('findProduct >> dropdownOption?.computerDiyCate >>', dropdownOption?.computerDiyCate)
    let defComputerDiyCate = _.cloneDeep(convertDIYList(dropdownOption?.computerDiyCate) ?? [])
    console.log('🚀 findProduct >> defComputerDiyCate ', defComputerDiyCate)

    let computerSetComponentsValue = []
    for (let cdc of defComputerDiyCate) {

      const findProduct = resData.computerSetComponents.find(item => item.attributeSetCode === cdc.attributeSetCode)
      console.log('🚀 findProduct >> ' + cdc.attributeSetCode+ ' :', findProduct)

      let maxSlot = 1
      // ถ้าเป็น แรมต้องหา slot จาก mainboard ก่อน
      if (cdc.attributeSetCode === 'ram') {
        const findMainboard = resData.computerSetComponents.find(item => item.attributeSetCode === 'mainboard')
        // const ramMaxSlot = findMainboard. // เด๋วมาเขียนต่อ
        maxSlot = 4
      }

      // เติม isCustomizable ถ้าไม่มีเชื่อตัวเริ่มต้น
      if (findProduct?.isCustomizable) {
        cdc.isCustomizable = findProduct?.isCustomizable
      }

      // เติม SLOT
      cdc.maxSlotQuantity = maxSlot

      console.log('findProduct?.productDiyIds >>', findProduct?.productDiyIds)

      // cdc.productDiyIds = findProduct?.productDiyIds
      cdc.productDiyIds = findProduct?.productDiyIds.length ? findProduct?.productDiyIds : []
      computerSetComponentsValue.push(cdc)
    }
    console.log("🚀 findProduct >> ~ preFilInput ~ computerSetComponentsValue:", computerSetComponentsValue)
    validation.setFieldValue("productDiy", computerSetComponentsValue)
    

    // ERROR FORM
    validation.setFieldValue("nameRequire", resData.nameRequire)
  }

  const fnCleanHighlightPy = (highlights = []) => {
    const clearNull = highlights.filter(item => item.highlightTextTh && item.highlightTextEn)
    const newOrder = clearNull.map((item, i) => {
      item.sortOrder = i + 1
      return item
    })
    return newOrder

  }

  useEffect(() => {

    if (Object.keys(value).length) {
      console.log("🚀 ~ useEffect ~ xvalue xxx:", value)
      preFilInput(value)
    }
  }, [value])

  const findTabError = (errList = [], formmik = {} ) => {
    const submitCount = formmik?.submitCount ?? 0
    if (submitCount > 0 ) {
      const jsonString  = JSON.stringify(formmik.errors)

      for (let text of errList) {
        const searchText = text;
        // ค้นหาตำแหน่งใน String
        const position = jsonString.indexOf(searchText);
        if (position !== -1) {
          return true
        }
      }
    }
    return false
  }

  const variableFrom = ['attributeValue', 'sku', 'isBestseller', 'bestsellerNumber', 'isNew', 'newStart', 'newEnd', 'isFreeShipping', 'isInstallmentPlan', 'isInStock']

  const mainTabConf = ['nameTh', 'nameEn', 'categoryIds', 'brandId', 'status', 'sortOrder', 'isVariant', 'sku', 'productConfig', ...variableFrom]
  const detailTabConf = ['weight', 'weightIncludeBox', 'width', 'length', 'height', 'shortDescription', 'description', 'videoType', 'videoValue']
  const specTabConf = ['attributeSetId', 'attributeValue']
  const imageGalleryTabConf = ['gallery']

  // ERROR
  const mainTabErr = findTabError(mainTabConf, validation)
  const detailTabErr = findTabError(detailTabConf, validation)
  const specTabErr = findTabError(specTabConf, validation)
  const imageGalleryTabErr = findTabError(imageGalleryTabConf, validation)

  return (
    <React.Fragment>
      <Card style={{ marginBottom: '0px' }}>
        {/* {JSON.stringify(validation.errors)} */}
        {/* {JSON.stringify(validation)} */}
        {/* {JSON.stringify(validation.values?.id)} */}
        <CardBody>
          <Nav tabs className={"nav-tabs-custom mb-4 " + classnames({ error: false })}>
            <NavItem>
              <NavLink
                // className={classnames({ active: activeTab === "main-info", error: mainTabErr })}
                className={classnames({ active: activeTab === "main-info", error: mainTabErr })}
                onClick={() => setActiveTab("main-info")}
              >
                Main Info
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "detail", error: detailTabErr })}
                onClick={() => setActiveTab("detail")}
              >
                Detail
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({active: activeTab === "specification", error: specTabErr })}
                onClick={() => setActiveTab("specification")}
              >
                Specification
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={classnames({ active: activeTab === "image-gallery", error: imageGalleryTabErr })}
                onClick={() => setActiveTab("image-gallery")}
              >
                Image Gallery
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={classnames({ active: activeTab === "image-360", error: false })}
                onClick={() => setActiveTab("image-360")}
              >
                Image 360
              </NavLink>
            </NavItem>
            {
              (isComSet || validation.values.isVariant == "0") && (
                <NavItem>
                  <NavLink
                    className={classnames({active: activeTab === "compare", error: false })}
                    onClick={() => setActiveTab("compare")}
                  >
                    Compare
                  </NavLink>
                </NavItem>
              )
            }
            
            {
              isComSet && (
                <NavItem>
                  <NavLink className={classnames({ active: activeTab === "product-in-set", error: validation.isSubmitting && checkproductDIYForm(validation.values.productDiy) === false })}
                    onClick={() => setActiveTab("product-in-set")}
                  >
                    Product in Set
                  </NavLink>
                </NavItem>
              )
            }
          </Nav>
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <TabContent className={ hasScroll ? 'bScorll' : ''} activeTab={activeTab}>
              {
                loading 
                ? (
                  <table style={{ width: "100%", height: '60vh'}}>
                    <tbody>
                      <TableLoading colSpan={1} />
                    </tbody>
                  </table>
                )
                : (
                  <>
                    <TabPane tabId="main-info">
                    
                      <MainInfo 
                        dropdownOption={dropdownOption} 
                        validation={validation} 
                        pageView={pageView} 
                        isComputerDIY={isComputerDIY}
                        subComponent={<>
                        { (isChild === false  && isComputerDIY === false ) && <ProductTypeForm dropdownOption={dropdownOption} validation={validation}/> }
                        </>}
                      />
                      {
                        !isChild && (
                          <>
                            {/* {JSON.stringify(validation.values.highlights)} */}
                            <HighlightForm
                              disabled={pageView === "view"}
                              values={validation.values.highlights}
                              onChange={(newValue) => {
                                console.log("🚀 ~ newValue:", newValue)
                                validation.setFieldValue('highlights', newValue)
                              }}
                            />
                          </>
                        )
                      }
                      
                      {/* {JSON.stringify(validation.values)} */}
                      {/* {JSON.stringify(validation.values.productVariant)} */}
                      {/* {JSON.stringify(validation.values.productGenerate)} */}
                      {/* { (isChild === false  && isComputerDIY === false )&& <VariableForm dropdownOption={dropdownOption} validation={validation}/> } */}
                      
                      {validation.values.isVariant == "0" && (
                        <>
                          {isChild && (<ConfigAttributes dropdownOption={dropdownOption} validation={validation} attributeSelectedMain={attributeSelectedMain}/>)}
                        </>
                      )}
                      
                      {
                        validation.values.isVariant == "1"
                        ? (
                          <ShowBox isShow={validation.values.isVariant == "1"}>
                            <Row className="mt-4">
                              <Col className="col-6" style={{ display:'flex', alignItems:'center' }}>
                                <h5 className="mb-0">Current Variations</h5>
                              </Col>
                              <Col className="col-6">
                                <div className="flex-shrink-0 d-flex gap-2" style={{ justifyContent: 'end' }}>
                                  <a type="button" className={`btn ${  validation?.values?.productVariant?.length ? 'btn-warning':'btn-primary'} `} 
                                    onClick={() => {
                                      if (validation.values.nameTh) {
                                        setIsOpenGenConf(true)
                                      } else {
                                        mAlert.info({
                                          title: "Alert",
                                          subTitle: `กรุณากรอก Product Thai Name`,
                                          content: "",
                                          onClose: () => {
                                          },
                                        })
                                        validation.setTouched({ nameTh: true })
                                      }
                                    }}
                                  >
                                    {
                                      validation?.values?.productVariant.length
                                      ? <> <i className="bx bx-edit me-1"></i>Edit Generate Configurations</>
                                      : <> <i className="bx bx-plus me-1"></i>Generate Configurations</>
                                    }
                                  </a>
                                </div>
                              </Col>
                              <Col className="col-12 mb-3" >
                              {/* {JSON.stringify(validation?.values?.productVariant)} */}
                                <TableContainer
                                  noTop={true}
                                  tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                                  columns={columns}
                                  data={validation.values.productVariant}
                                />
                              </Col>
                            </Row>
                          </ShowBox>
                        )
                        : <></>
                      }
                
                    </TabPane>
                    <TabPane tabId="detail">
                      <DetailInfo dropdownOption={dropdownOption} validation={validation} pageView={pageView}/>
                    </TabPane>
                    <TabPane tabId="specification">
                      <SpecInfo dropdownOption={dropdownOption} validation={validation} pageView={pageView}/>
                    </TabPane>
                    <TabPane tabId="image-gallery">
                      <ImageGalleryInfo dropdownOption={dropdownOption} validation={validation} pageView={pageView}/>
                    </TabPane>
                    <TabPane tabId="image-360">
                      <Image360Info dropdownOption={dropdownOption} validation={validation} pageView={pageView}/>
                    </TabPane>
                    <TabPane tabId="compare">
                      <CompareInfo dropdownOption={dropdownOption} validation={validation} pageView={pageView}/>
                    </TabPane>
                    {
                      isComSet && (
                        <TabPane tabId="product-in-set">
                          <ProductInSetInfo 
                            dropdownOption={dropdownOption} 
                            validation={validation} 
                            pageView={pageView}
                          />
                        </TabPane>
                      )
                    }
                  </>
                )
              }
            </TabContent>
            <hr/>
            <div
              style={{
                padding: '16px 32px',
                display: "flex",
                justifyContent: "end",
                gap: "8px",
              }}
            >
              {pageView == "view" ? (
                <>
                  <button
                    className="btn btn-primary w-md"
                    onClick={() => onBack()}
                  >
                    BACK
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="submit"
                    className="btn btn-primary w-md"
                  >
                    SAVE
                  </button>
                  <button
                    className="btn btn-secondary w-md"
                    onClick={() => onBack()}
                  >
                    CANCEL
                  </button>
                </>
              )}
            </div>
          </Form>
        </CardBody>
      </Card>
      <DialogCustom visible={isOpenGenConf} size="xl" onHide={() => onClose()} onClickOut = {() => onClose()}>
        <GenerateConfig 
          productName={validation.values.nameTh}
          data={dropdownOption.attributeConfig} 
          onClose={() => setIsOpenGenConf(false)} 
          value={validation.values.productGenerate}
          onSave={(newProduct) => {
            setIsOpenGenConf(false)
            console.log("🚀 ~ onSave newProduct: ", newProduct)
            validation.setFieldValue('productGenerate', newProduct)

            const genProduct = newProduct.generatedProducts
            const _productVariant = genProductToProductVariant(genProduct, validation.values)
            console.log("🚀 ~ onSave _productVariant:", _productVariant)
            validation.setFieldValue('productVariant', _productVariant)
            
          }} 
        />
      </DialogCustom>

      <DialogCustom visible={isOpenEdit} size="xl" onHide={() => onClose()} onClickOut = {() => onClose()}>
        <Row className="mb-0">
          <Col className="col-12">
            <h5 className="mb-0" style={{ padding: '20px'}}>Products Variant Infomation</h5>
            <hr className="mt-0 mb-0"/>
          </Col>
          <Col className="col-12">
          <ProductsForm
            hasScroll={true}
            isChild={true}
            dropdownOption={{
              brand: dropdownOption.brand,
              category: dropdownOption.category,
              attributeSet: dropdownOption.attributeSet,
              attributeConfig: dropdownOption.attributeConfig,
              productSupplier: dropdownOption.productSupplier,
              productDiy: dropdownOption.productDiy,
              productCompare: dropdownOption.productCompare,
            }}
            attributeSelectedMain={validation?.values?.productGenerate?.attributeSelected ?? []}
            value={popupState ?? {}}
            onSave={(rowPayload) => {
              setIsOpenEdit(false)
              console.log("🚀 ~ rowPayload:", rowPayload)
              let _productVariant = _.cloneDeep(validation.values.productVariant)
              console.log("🚀 ~ _productVariant Clone:", _productVariant)
              for (let i in _productVariant) {
                if (i == popupStateIndex) {
                  _productVariant[i] = _.cloneDeep(rowPayload)
                }
              }
              console.log("🚀 ~ _productVariant New:", _productVariant)
              validation.setFieldValue('productVariant', _productVariant)
            }}
            onBack={() => {
              setIsOpenEdit(false)
            }}
            pageView={pageView}
          />
          </Col>
        </Row>
        
      </DialogCustom>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(ProductsForm)
