import React, { useEffect, useState, useContext, useRef  } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"


//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import { CreateFlag, GetFlagById, UpdateFlag } from "services/api/module/Flag"
import ModalContext from "../../contexts/ModalContext";
import Flatpickr from "react-flatpickr";
import moment from "moment"
import UploadFileAPI from "components/UploadFileAPI"
import { TableLoading } from "components/TableComponents"


const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const FlagTemplate = ({
  pageView = "create"
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  // OTHER STATE
  const qParams = useParams()
  const qFlagId = qParams.id
  const navigate = useNavigate();

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    nameTh: Yup.string().max(100).required("Please Enter Flag Name TH"),
    nameEn: Yup.string().max(100).required("Please Enter Flag Name EN"),
    descriptionTh: Yup.string().max(100).required("Please Enter Description TH"),
    descriptionEn: Yup.string().max(100).required("Please Enter Description EN"),
    iconDesktop: Yup.string().max(200).required("Please Enter Image Desktop"),
    iconMobile: Yup.string().max(200).required("Please Enter Image Mobile"),
    // sort_order: Yup.string().max(100).required("Please Enter Order"),
    status: Yup.string().max(100).required("Please Select Status"),
  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)

  // LOCAL STATE DROPDOWN
  const DEF_LOADING = pageView === "create" ? false : true
  const [loadingById, setLoadingById] = useState(DEF_LOADING)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      nameTh: "",
      nameEn: "",
      descriptionTh: "",
      descriptionEn: "",
      iconDesktop: "",
      iconMobile: "",
      sort_order: "",
      status: ""
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ FlagCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        nameTh: values.nameTh,
        nameEn: values.nameEn,
        descriptionTh: values.descriptionTh,
        descriptionEn: values.descriptionEn,
        iconDesktop: values.iconDesktop,
        iconMobile: values.iconMobile,
        sort_order: values.sort_order,
        status: Number(values.status)
      };
      console.log("🚀 ~ onSave ~ payload:", payload);

      if (pageView === "update") {
        API.fetchUpdateFlag(payload)
      } else {
        API.fetchCreateFlag(payload)
      }
    },
  })

  // SELECT DATA

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("nameTh", resData.nameTh)
    validation.setFieldValue("nameEn", resData.nameEn)
    validation.setFieldValue("descriptionTh", resData.descriptionTh)
    validation.setFieldValue("descriptionEn", resData.descriptionEn)
    validation.setFieldValue("iconDesktop", resData.iconDesktop)
    validation.setFieldValue("iconMobile", resData.iconMobile)
    validation.setFieldValue("sort_order", resData.sort_order)
    validation.setFieldValue("status", resData.status)



    // ติด Effect Clear State
  }

  const API = {
    fetchGetFlagById: async (payload) => {
      setLoadingById(true)
      try {
          const response = await GetFlagById({ id: payload.id })
          const resData = response.data
          if (resData.status == 202) {
            preFilInput(resData.data)
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
            title: "Alert",
            subTitle: "Something went wrong",
            content: "",
            onClose: () => {
            //  alert("onClose")
            },
        })
      } finally {
        setLoadingById(false)
      }
    },
    fetchCreateFlag: async (payload) => {
      try {
          const response = await CreateFlag({ data: payload })
          const resData = response.data
          if (resData.status === 201) {
            mAlert.info({
                title: "Alert",
                subTitle: "Created Data Success",
                content: "",
                onClose: () => {
                  navigate('/product-management/flag')
                },
            })
          }
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchUpdateFlag: async (payload) => {
      try {
          const response = await UpdateFlag({ data: payload, id: payload.id })
          const resData = response.data
          if (resData.status === 201) {
              mAlert.info({
                  title: "Alert",
                  subTitle: "Update Data Success",
                  content: "",
                  onClose: () => {
                    navigate('/product-management/flag')
                  },
              })
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    }
  }


  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id : qFlagId }
      API.fetchGetFlagById(payload)
    }
  }, [])


  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `Flag | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  const loadingMain = loadingById

  return (
    <React.Fragment>

      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} Flag`}
            breadcrumbItems={[
              { title: "Flag", link: "banner/banner-list" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <h5 className="mb-3">Flag Infomation</h5>
                      <hr/>
                      {
                        loadingMain
                        ? (
                          <table style={{ width: "100%", height: '60vh'}}>
                            <tbody>
                              <TableLoading colSpan={1} />
                            </tbody>
                          </table>
                        )
                        : (
                          <>
                            <Col className="col-12 mb-3">
                              <Row>
                                <Col className="col-6 mb-3">
                                  <label
                                    htmlFor="category_name"
                                    className="col-md-12 col-form-label"
                                  >
                                    Status (Active/Inactive)
                                    <span className="t-require">*</span>
                                  </label>
                                  <div className="col-md-12">
                                    <Select
                                      placeholder="Select Status"
                                      value={fn_helper.FNFORM.getObjectValue(STATUS_OPTION, `${validation.values.status}`)}
                                      onChange={(value) => validation.setFieldValue("status", value.id)}
                                      getOptionValue={(option) => option.id}
                                      getOptionLabel={(option) => option.name}
                                      options={STATUS_OPTION}
                                      className={`select2-selection ${validation.touched.status && validation.errors.status ? "input-err" : ""}` }
                                      isDisabled={pageView === "view"}
                                    />
                                    {
                                      (validation.touched.status && validation.errors.status) && (
                                        <div type="invalid" className="invalid-feedback d-block">{validation.errors.status}</div>
                                      )
                                    }
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            <Col className="col-6 mb-3">
                              <label
                                htmlFor="category_name"
                                className="col-md-12 col-form-label"
                              >
                                Flag Name TH
                                <span className="t-require">*</span>
                              </label>
                              <div className="col-md-12">
                                <Input
                                  id="nameTh"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  name="nameTh"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.nameTh}
                                  invalid={validation.touched.nameTh && validation.errors.nameTh ? true : false}
                                  disabled={pageView === "view"}
                                />
                                { 
                                  validation.touched.nameTh && validation.errors.nameTh && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.nameTh}
                                    </FormFeedback>
                                  )
                                }
                              </div>
                            </Col>
                            <Col className="col-6 mb-3">
                              <label
                                htmlFor="category_name"
                                className="col-md-12 col-form-label"
                              >
                                Flag Name EN
                                <span className="t-require">*</span>
                              </label>
                              <div className="col-md-12">
                                <Input
                                  id="nameEn"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  name="nameEn"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.nameEn}
                                  invalid={validation.touched.nameEn && validation.errors.nameEn ? true : false}
                                  disabled={pageView === "view"}
                                />
                                { 
                                  validation.touched.nameEn && validation.errors.nameEn && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.nameEn}
                                    </FormFeedback>
                                  )
                                }
                              </div>
                            </Col>
                            <Col className="col-6 mb-3">
                              <label
                                htmlFor="category_name"
                                className="col-md-12 col-form-label"
                              >
                                Description TH
                                <span className="t-require">*</span>
                              </label>
                              <div className="col-md-12">
                                <Input
                                  id="descriptionTh"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  name="descriptionTh"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.descriptionTh}
                                  invalid={validation.touched.descriptionTh && validation.errors.descriptionTh ? true : false}
                                  disabled={pageView === "view"}
                                />
                                { 
                                  validation.touched.descriptionTh && validation.errors.descriptionTh && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.descriptionTh}
                                    </FormFeedback>
                                  )
                                }
                              </div>
                            </Col>
                            <Col className="col-6 mb-3">
                              <label
                                htmlFor="category_name"
                                className="col-md-12 col-form-label"
                              >
                                Description EN
                                <span className="t-require">*</span>
                              </label>
                              <div className="col-md-12">
                                <Input
                                  id="descriptionEn"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  name="descriptionEn"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.descriptionEn}
                                  invalid={validation.touched.descriptionEn && validation.errors.descriptionEn ? true : false}
                                  disabled={pageView === "view"}
                                />
                                { 
                                  validation.touched.descriptionEn && validation.errors.descriptionEn && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.descriptionEn}
                                    </FormFeedback>
                                  )
                                }
                              </div>
                            </Col>
                            <Col className="col-12 mb-3">
                              <div className="col-md-12">
                                <UploadFileAPI 
                                  bucketName="banner"
                                  upload="Image"
                                  typeUpload="ImageMap"
                                  prefixName="banner-desktop"
                                  label="Upload Desktop Image"
                                  required={true}
                                  widthSize={1024}
                                  heightSize={1024}
                                  description="description"
                                  value={validation.values.iconDesktop}
                                  onChange={([ imageURL = '', imageSize = '']) => {
                                      validation.setFieldValue("iconDesktop", imageURL)
                                  }}
                                />
                                <Input
                                  id="iconDesktop"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  name="iconDesktop"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.iconDesktop}
                                  invalid={validation.touched.iconDesktop && validation.errors.iconDesktop ? true : false}
                                  readOnly={true}
                                  disabled={pageView === "view"}
                                />
                                { 
                                  validation.touched.iconDesktop && validation.errors.iconDesktop && (
                                    <div type="invalid" className="invalid-feedback d-block">{validation.errors.iconDesktop}</div>
                                  )
                                }
                              </div>
                            </Col>
                            <Col className="col-12 mb-3">
                              <div className="col-md-12">
                                <UploadFileAPI
                                  bucketName="banner"
                                  upload="Image"
                                  typeUpload="ImageMap"
                                  prefixName="banner-mobile"
                                  label="Upload Mobile Image"
                                  required={true}
                                  widthSize={1024}
                                  heightSize={1024}
                                  description="description"
                                  value={validation.values.iconMobile}
                                  onChange={([ imageURL = '', imageSize = '']) => {
                                      validation.setFieldValue("iconMobile", imageURL)
                                  }}
                                />
                                <Input
                                  id="iconMobile"
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  name="iconMobile"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.iconMobile}
                                  invalid={validation.touched.iconMobile && validation.errors.iconMobile ? true : false}
                                  readOnly={true}
                                  disabled={pageView === "view"}
                                />
                                { 
                                  validation.touched.iconMobile && validation.errors.iconMobile && (
                                    <div type="invalid" className="invalid-feedback d-block">{validation.errors.iconMobile}</div>
                                  )
                                }
                              </div>
                            </Col>

                            {/* <Row>
                              <Col className="col-6 mb-3">
                                <label
                                  htmlFor="sort_order"
                                  className="col-md-12 col-form-label"
                                >
                                  Sort Order
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="sort_order"
                                    className="form-control"
                                    type="number"
                                    name="sort_order"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.sort_order}
                                    invalid={
                                      validation.touched.sort_order &&
                                      validation.errors.sort_order
                                        ? true
                                        : false
                                    }
                                    disabled={pageView === "view"}
                                    min="1"
                                  />
                                  {validation.touched.sort_order &&
                                    validation.errors.sort_order && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.sort_order}
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                            </Row> */}
                          </>
                        )
                      }
                      
                    </Row>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '8px'}}>
                      {
                        pageView == "view"
                        ? <><button className="btn btn-primary w-md" onClick={() => navigate('/product-management/flag')}>BACK</button></> 
                        : <>
                          <button type="submit" className="btn btn-primary w-md" disabled={loadingMain} >SAVE</button>
                          <button className="btn btn-secondary w-md"  disabled={loadingMain} onClick={() => navigate('/product-management/flag')}>CANCEL</button>
                        </>
                      }
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(FlagTemplate)
