import React, { useEffect, useState, useContext } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import {
  CreateCorporateBusinessContact,
  GetCorporateBusinessContactById,
  UpdateCorporateBusinessContact,
} from "services/api/module/CorporateBusinessContact"
import ModalContext from "../../contexts/ModalContext"
import UploadFileAPI from "components/UploadFileAPI"
import MyEditor from "components/MyEditor"

const STATUS_OPTION = [
  { id: "0", name: "Not Read" },
  { id: "1", name: "Read" },
]

const CorporateBusinessContactTemplate = ({ pageView = "create" }) => {
  // POPUP
  const { mAlert } = useContext(ModalContext)

  // OTHER STATE
  const qParams = useParams()
  const qCorporateBusinessContactId = qParams.id
  const navigate = useNavigate()

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    status: Yup.string().max(100).required("Please Enter Status"),
  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] =
    useState(validationSchema)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      customerType: "",
      contact: "",
      name: "",
      mobileNo: "",
      email: "",
      address: "",
      contact: "",
      detail: "",
      remark: "",
      status: "0",
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ CorporateBusinessContactCreate ~ values:", values)
      const payload = {
        status: Number(values.status),
      }
      console.log("🚀 ~ onSave ~ payload:", payload)

      if (pageView === "update") {
        API.fetchUpdateCorporateBusinessContact(payload)
      } else {
        API.fetchCreateCorporateBusinessContact(payload)
      }
    },
  })

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("customerType", resData.customerType)
    validation.setFieldValue("topic", resData.topic)
    validation.setFieldValue("name", resData.name)
    validation.setFieldValue("mobileNo", resData.mobileNo)
    validation.setFieldValue("email", resData.email)
    validation.setFieldValue("address", resData.address)
    validation.setFieldValue("detail", resData.detail)
    validation.setFieldValue("remark", resData.remark)
    validation.setFieldValue("status", resData.status)
  }

  const API = {
    fetchGetCorporateBusinessContactById: async payload => {
      try {
        const response = await GetCorporateBusinessContactById({ id: payload.id })
        const resData = response.data
        if (resData.status == 202) {
          preFilInput(resData.data)
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchCreateCorporateBusinessContact: async payload => {
      try {
        const response = await CreateCorporateBusinessContact({ data: payload })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Created Data Success",
            content: "",
            onClose: () => {
              navigate("/corporate-business-contact")
            },
          })
        }
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchUpdateCorporateBusinessContact: async payload => {
      try {
        const response = await UpdateCorporateBusinessContact({
          data: payload,
          id: payload.id,
        })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              navigate("/corporate-business-contact")
            },
          })
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
  }

  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id: qCorporateBusinessContactId }
      API.fetchGetCorporateBusinessContactById(payload)
    }
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title =
    `CorporateBusinessContact | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} CorporateBusinessContact`}
            breadcrumbItems={[
              { title: "CorporateBusinessContact", link: "banner/banner-platform" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <Col className="col-12">
                        <Row className="">
                          <Col className="col-12">
                            <h5 className="mb-3">Contact Detail</h5>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="customerType"
                              className="col-md-12 col-form-label"
                            >
                              Customer Type
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="customerType"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="customerType"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.customerType}
                                invalid={
                                  validation.touched.customerType &&
                                  validation.errors.customerType
                                    ? true
                                    : false
                                }
                                disabled={["view", "edit"].includes(pageView)}
                              />
                              {validation.touched.customerType &&
                                validation.errors.customerType && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.customerType}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="topic"
                              className="col-md-12 col-form-label"
                            >
                              Contact Topic
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="topic"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="topic"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.topic}
                                invalid={
                                  validation.touched.topic &&
                                  validation.errors.topic
                                    ? true
                                    : false
                                }
                                disabled={["view", "edit"].includes(pageView)}
                              />
                              {validation.touched.topic &&
                                validation.errors.topic && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.topic}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="name"
                              className="col-md-12 col-form-label"
                            >
                              Contact Name
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="name"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name}
                                invalid={
                                  validation.touched.name &&
                                  validation.errors.name
                                    ? true
                                    : false
                                }
                                disabled={["view", "edit"].includes(pageView)}
                              />
                              {validation.touched.name &&
                                validation.errors.name && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.name}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="mobileNo"
                              className="col-md-12 col-form-label"
                            >
                              Mobile No
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="mobileNo"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="mobileNo"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.mobileNo}
                                invalid={
                                  validation.touched.mobileNo &&
                                  validation.errors.mobileNo
                                    ? true
                                    : false
                                }
                                disabled={["view", "edit"].includes(pageView)}
                              />
                              {validation.touched.mobileNo &&
                                validation.errors.mobileNo && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.mobileNo}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="email"
                              className="col-md-12 col-form-label"
                            >
                              Email
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="email"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email}
                                invalid={
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? true
                                    : false
                                }
                                disabled={["view", "edit"].includes(pageView)}
                              />
                              {validation.touched.email &&
                                validation.errors.email && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.email}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3"></Col>
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="address"
                              className="col-md-12 col-form-label"
                            >
                              Address Detail
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="address"
                                className="form-control"
                                type="textarea"
                                rows="3"
                                placeholder=""
                                name="address"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.address}
                                invalid={
                                  validation.touched.address &&
                                  validation.errors.address
                                    ? true
                                    : false
                                }
                                disabled={["view", "edit"].includes(pageView)}
                              />
                              {validation.touched.address &&
                                validation.errors.address && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.address}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="detail"
                              className="col-md-12 col-form-label"
                            >
                              Contact Detail
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="detail"
                                className="form-control"
                                type="textarea"
                                rows="3"
                                placeholder=""
                                name="detail"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.detail}
                                invalid={
                                  validation.touched.detail &&
                                  validation.errors.detail
                                    ? true
                                    : false
                                }
                                disabled={["view", "edit"].includes(pageView)}
                              />
                              {validation.touched.detail &&
                                validation.errors.detail && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.detail}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="remark"
                              className="col-md-12 col-form-label"
                            >
                              Remark
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="remark"
                                className="form-control"
                                type="textarea"
                                rows="3"
                                placeholder=""
                                name="remark"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.remark}
                                invalid={
                                  validation.touched.remark &&
                                  validation.errors.remark
                                    ? true
                                    : false
                                }
                                disabled={["view", "edit"].includes(pageView)}
                              />
                              {validation.touched.remark &&
                                validation.errors.remark && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.remark}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Status (Active/Inactive)
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Select
                                placeholder="Select Status"
                                value={fn_helper.FNFORM.getObjectValue(
                                  STATUS_OPTION,
                                  `${validation.values.status}`
                                )}
                                onChange={value =>
                                  validation.setFieldValue("status", value.id)
                                }
                                getOptionValue={option => option.id}
                                getOptionLabel={option => option.name}
                                options={STATUS_OPTION}
                                className={`select2-selection ${
                                  validation.touched.status &&
                                  validation.errors.status
                                    ? "input-err"
                                    : ""
                                }`}
                                isDisabled={pageView === "view"}
                              />
                              {validation.touched.status &&
                                validation.errors.status && (
                                  <div
                                    type="invalid"
                                    className="invalid-feedback d-block"
                                  >
                                    {validation.errors.status}
                                  </div>
                                )}
                              {validation.touched.status &&
                                validation.errors.status && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.status}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "8px",
                      }}
                    >
                      {pageView == "view" ? (
                        <>
                          <button
                            className="btn btn-primary w-md"
                            onClick={() =>
                              navigate("/corporate-business-contact")
                            }
                          >
                            BACK
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            SAVE
                          </button>
                          <button
                            className="btn btn-secondary w-md"
                            onClick={() =>
                              navigate("/corporate-business-contact")
                            }
                          >
                            CANCEL
                          </button>
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(CorporateBusinessContactTemplate)
