import React, { useEffect, useState, useContext } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import { CreatePaymentCreditDebit, GetPaymentCreditDebitById, UpdatePaymentCreditDebit } from "services/api/module/PaymentCreditDebit"
import ModalContext from "../../contexts/ModalContext";
import UploadFileAPI from "components/UploadFileAPI"
import MyEditor from "components/MyEditor"
import moment from "moment"
import { DropdownBank, DropdownPaymentMethodGroup } from "services/api/module/Dropdown"
import { DropdownEnumPaymentCreditDebit } from "services/api/module/DropdownEnum"

const PaymentCreditDebitTemplate = ({
  pageView = "create"
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  // OTHER STATE
  const qParams = useParams()
  const qPaymentCreditDebitId = qParams.id
  const navigate = useNavigate();

  // OPTION STATE
  const [STATUS_OPTION, SET_STATUS_OPTION] = useState([])
  const [CREDIT_DEBIT_CODE_OPTION, SET_CREDIT_DEBIT_CODE_OPTION] = useState([])

  // LOCAL STATE DROPDOWN
  const DEF_LOADED = pageView === "create" ? true : false
  const [ddBank, setDDBank] = useState([])
  const [ddPaymentMethodGroup, setDDPaymentMethodGroup] = useState([])
  const [ddLoaded0, setDDLoaded0] = useState(DEF_LOADED)

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    name: Yup.string().max(100).required("Please Enter Name"),
    code: Yup.string().max(100).required("Please Enter Code"),
    bankId: Yup.string().required("Please Selected Bank"),
    paymentMethodGroupId: Yup.string().required("Please Selected Payment Method Group"),
    image: Yup.string().max(100).required("Please Enter Image"),
    seoUrlKey: Yup.string().max(100).required("Please Enter URL Key"),
    seoMetaTitle: Yup.string().max(100).required("Please Enter Meta Title"),
    seoMetaKeyword: Yup.string().max(100).required("Please Enter Meta keyword"),
    seoMetaDescription: Yup.string().max(100).required("Please Enter Meta Description"),
    status: Yup.string().max(100).required("Please Enter Status"),

  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      code: "",
      description: "",
      bankId: "",
      paymentMethodGroupId: "",
      minOrderPrice: "",
      maxOrderPrice: "",
      image: "",
      tempCloseStartDate: "",
      tempCloseEndDate: "",
      seoUrlKey: "",
      seoMetaTitle: "",
      seoMetaKeyword: "",
      seoMetaDescription: "",
      status: "",
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ PaymentCreditDebitCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        name: values.name,
        code: values.code,
        description: values.description,
        bankId: values.bankId,
        paymentMethodGroupId: values.paymentMethodGroupId,
        minOrderPrice: Number(values.minOrderPrice) ?? 0,
        maxOrderPrice: Number(values.maxOrderPrice) ?? 0,
        tempCloseStartDate: moment(values.tempCloseStartDate, "YYYY-MM-DDTHH:mm").toISOString(),
        tempCloseEndDate: moment(values.tempCloseEndDate, "YYYY-MM-DDTHH:mm").toISOString(),
        image: values.image,
        seoUrlKey: values.seoUrlKey,
        seoMetaTitle: values.seoMetaTitle,
        seoMetaKeyword: values.seoMetaKeyword,
        seoMetaDescription: values.seoMetaDescription,
        status: Number(values.status),
      };
      console.log("🚀 ~ onSave ~ payload:", payload);

      if (pageView === "update") {
        API.fetchUpdatePaymentCreditDebit(payload)
      } else {
        API.fetchCreatePaymentCreditDebit(payload)
      }
    },
  })

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("name", resData.name)
    validation.setFieldValue("code", resData.code)
    validation.setFieldValue("description", resData.description)
    validation.setFieldValue("bankId", resData.bankId)
    validation.setFieldValue("paymentMethodGroupId", resData.paymentMethodGroupId)
    validation.setFieldValue("minOrderPrice", resData.minOrderPrice)
    validation.setFieldValue("maxOrderPrice", resData.maxOrderPrice)
    validation.setFieldValue("tempCloseStartDate", moment(resData.tempCloseStartDate).format("YYYY-MM-DDTHH:mm"))
    validation.setFieldValue("tempCloseEndDate", moment(resData.tempCloseEndtDate).format("YYYY-MM-DDTHH:mm"))
    validation.setFieldValue("image", resData.image)
    validation.setFieldValue("seoUrlKey", resData.seoUrlKey)
    validation.setFieldValue("seoMetaTitle", resData.seoMetaTitle)
    validation.setFieldValue("seoMetaKeyword", resData.seoMetaKeyword)
    validation.setFieldValue("seoMetaDescription", resData.seoMetaDescription)
    validation.setFieldValue("status", resData.status)
  }

  const API = {
    fetchGetPaymentCreditDebitById: async (payload) => {
      try {
        const response = await GetPaymentCreditDebitById({ id: payload.id })
        const resData = response.data
        if (resData.status == 202) {
          preFilInput(resData.data)
        }
      }
      catch (err) {
        console.log('err ', err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchCreatePaymentCreditDebit: async (payload) => {
      try {
        const response = await CreatePaymentCreditDebit({ data: payload })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Created Data Success",
            content: "",
            onClose: () => {
              navigate('/payment/payment-credit-debit')
            },
          })
        }
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchUpdatePaymentCreditDebit: async (payload) => {
      try {
        const response = await UpdatePaymentCreditDebit({ data: payload, id: payload.id })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              navigate('/payment/payment-credit-debit')
            },
          })
        }
      }
      catch (err) {
        console.log('err ', err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchDDBank: async () => {
      try {
        const response = await DropdownBank({})
        const resData = response?.data ?? []
        const tmpDD = fn_helper.OPTIONS.ADD_SELECT_DEF(resData.data)
        setDDBank(tmpDD)
        setDDLoaded0(true)
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchDDPaymentMetHodGroup: async () => {
      try {
        const response = await DropdownPaymentMethodGroup({})
        const resData = response?.data ?? []
        const tmpDD = fn_helper.OPTIONS.ADD_SELECT_DEF(resData.data)
        setDDPaymentMethodGroup(tmpDD)
        setDDLoaded0(true)
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchGetDDEnumPaymentCreditDebit: async () => {
      try {
        const response = await DropdownEnumPaymentCreditDebit({})
        const resData = response?.data ?? []
        if (resData.status == 200) {
          SET_STATUS_OPTION(resData.data.option.item0)
          SET_CREDIT_DEBIT_CODE_OPTION(resData.data.option.item1)
        }
      }
      catch (err) {
        console.log('err ', err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
  }

  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id: qPaymentCreditDebitId }
      API.fetchGetPaymentCreditDebitById(payload)
    }
  }, [])

  // LoadDropdown
  useEffect(() => {
    // CALL DROPDOWN
    API.fetchDDBank()
    API.fetchDDPaymentMetHodGroup()
    API.fetchGetDDEnumPaymentCreditDebit()
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `Payment Credit Debit | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} Payment Credit Debit`}
            breadcrumbItems={[
              { title: "Payment Credit Debit ", link: "payment/payment-credit-debit" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">

                      <Col className="col-12">
                        <Row className="">
                          <Col className="col-6">
                            <Row className="">
                              <Col className="col-12">
                                <h5 className="mb-3">Payment Credit Debit Infomation</h5>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="name"
                                  className="col-md-12 col-form-label"
                                >
                                  Name
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="name"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="name"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.name}
                                    invalid={validation.touched.name && validation.errors.name ? true : false}
                                    disabled={pageView === "view"}
                                  />
                                  {
                                    validation.touched.name && validation.errors.name && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.name}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>

                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Code
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Select
                                    placeholder="Select Code"
                                    value={fn_helper.FNFORM.getObjectValue(CREDIT_DEBIT_CODE_OPTION, `${validation.values.code}`)}
                                    onChange={(value) => validation.setFieldValue("code", value.id)}
                                    getOptionValue={(option) => option.id}
                                    getOptionLabel={(option) => option.name}
                                    options={CREDIT_DEBIT_CODE_OPTION}
                                    className={`select2-selection ${validation.touched.code && validation.errors.code ? "input-err" : ""}`}
                                    isDisabled={pageView === "view"}
                                  />
                                  {
                                    (validation.touched.code && validation.errors.code) && (
                                      <div type="invalid" className="invalid-feedback d-block">{validation.errors.code}</div>
                                    )
                                  }
                                  {
                                    validation.touched.code && validation.errors.code && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.code}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>

                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Bank
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Select
                                    placeholder="Select Category"
                                    value={fn_helper.FNFORM.getObjectValue(
                                      ddBank,
                                      `${validation.values.bankId}`
                                    )}
                                    onChange={value => {
                                      validation.setFieldValue("bankId", value.id)
                                    }}
                                    getOptionValue={option => option.id}
                                    getOptionLabel={option => option.name}
                                    options={ddBank}
                                    className={`select2-selection ${validation.touched
                                      .bankId &&
                                      validation.errors
                                        .bankId
                                      ? "input-err"
                                      : ""
                                      }`}
                                    isDisabled={["view"].includes(pageView)}
                                  />
                                  {validation.touched
                                    .bankId &&
                                    validation.errors
                                      .bankId && (
                                      <div
                                        type="invalid"
                                        className="invalid-feedback d-block"
                                      >
                                        {
                                          validation.errors
                                            .bankId
                                        }
                                      </div>
                                    )}
                                </div>
                              </Col>

                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Payment Method Group
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Select
                                    placeholder="Select Category"
                                    value={fn_helper.FNFORM.getObjectValue(
                                      ddPaymentMethodGroup,
                                      `${validation.values.paymentMethodGroupId}`
                                    )}
                                    onChange={value => {
                                      validation.setFieldValue("paymentMethodGroupId", value.id)
                                    }}
                                    getOptionValue={option => option.id}
                                    getOptionLabel={option => option.name}
                                    options={ddPaymentMethodGroup}
                                    className={`select2-selection ${validation.touched
                                      .paymentMethodGroupId &&
                                      validation.errors
                                        .paymentMethodGroupId
                                      ? "input-err"
                                      : ""
                                      }`}
                                    isDisabled={["view"].includes(pageView)}
                                  />
                                  {validation.touched
                                    .paymentMethodGroupId &&
                                    validation.errors
                                      .paymentMethodGroupId && (
                                      <div
                                        type="invalid"
                                        className="invalid-feedback d-block"
                                      >
                                        {
                                          validation.errors
                                            .paymentMethodGroupId
                                        }
                                      </div>
                                    )}
                                </div>
                              </Col>





                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="min-order-price"
                                  className="col-md-12 col-form-label"
                                >
                                  Min Order Price
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="minOrderPrice"
                                    className="form-control"
                                    type="number"
                                    name="minOrderPrice"
                                    width="100%"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.minOrderPrice}
                                    invalid={
                                      validation.touched.minOrderPrice &&
                                        validation.errors.minOrderPrice
                                        ? true
                                        : false
                                    }
                                    disabled={pageView === "view"}
                                    min="1"
                                  />
                                  {validation.touched.minOrderPrice &&
                                    validation.errors.minOrderPrice && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.minOrderPrice}
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>

                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="max-order-price"
                                  className="col-md-12 col-form-label"
                                >
                                  Max Order Price
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="maxOrderPrice"
                                    className="form-control"
                                    type="number"
                                    name="maxOrderPrice"
                                    width="100%"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.maxOrderPrice}
                                    invalid={
                                      validation.touched.maxOrderPrice &&
                                        validation.errors.maxOrderPrice
                                        ? true
                                        : false
                                    }
                                    disabled={pageView === "view"}
                                    min="1"
                                  />
                                  {validation.touched.maxOrderPrice &&
                                    validation.errors.maxOrderPrice && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.maxOrderPrice}
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>

                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="status"
                                  className="col-md-12 col-form-label"
                                >
                                  Status (Active/Inactive)
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Select
                                    placeholder="Select Status"
                                    value={fn_helper.FNFORM.getObjectValue(STATUS_OPTION, `${validation.values.status}`)}
                                    onChange={(value) => validation.setFieldValue("status", value.id)}
                                    getOptionValue={(option) => option.id}
                                    getOptionLabel={(option) => option.name}
                                    options={STATUS_OPTION}
                                    className={`select2-selection ${validation.touched.status && validation.errors.status ? "input-err" : ""}`}
                                    isDisabled={pageView === "view"}
                                  />
                                  {
                                    (validation.touched.status && validation.errors.status) && (
                                      <div type="invalid" className="invalid-feedback d-block">{validation.errors.status}</div>
                                    )
                                  }
                                  {
                                    validation.touched.status && validation.errors.status && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.status}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>
                            </Row>
                          </Col>

                          <Col className="col-6">
                            <Col className="col-12">
                              <h5 className="mb-3">SEO Setting</h5>
                            </Col>
                            <Row className="">
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="url-key"
                                  className="col-md-12 col-form-label"
                                >
                                  URL Key
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="seoUrlKey"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="seoUrlKey"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.seoUrlKey}
                                    invalid={validation.touched.seoUrlKey && validation.errors.seoUrlKey ? true : false}
                                    disabled={pageView === "view"}
                                  />
                                  {
                                    validation.touched.seoUrlKey && validation.errors.seoUrlKey && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.seoUrlKey}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="meta-title"
                                  className="col-md-12 col-form-label"
                                >
                                  Meta Title
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="seoMetaTitle"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="seoMetaTitle"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.seoMetaTitle}
                                    invalid={validation.touched.seoMetaTitle && validation.errors.seoMetaTitle ? true : false}
                                    disabled={pageView === "view"}
                                  />
                                  {
                                    validation.touched.seoMetaTitle && validation.errors.seoMetaTitle && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.seoMetaTitle}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="meta-keyword"
                                  className="col-md-12 col-form-label"
                                >
                                  Meta keyword
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="seoMetaKeyword"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="seoMetaKeyword"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.seoMetaKeyword}
                                    invalid={validation.touched.seoMetaKeyword && validation.errors.seoMetaKeyword ? true : false}
                                    disabled={pageView === "view"}
                                  />
                                  {
                                    validation.touched.seoMetaKeyword && validation.errors.seoMetaKeyword && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.seoMetaKeyword}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="meta-description"
                                  className="col-md-12 col-form-label"
                                >
                                  Meta Description
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Input
                                    id="seoMetaDescription"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    name="seoMetaDescription"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.seoMetaDescription}
                                    invalid={validation.touched.seoMetaDescription && validation.errors.seoMetaDescription ? true : false}
                                    disabled={pageView === "view"}
                                  />
                                  {
                                    validation.touched.seoMetaDescription && validation.errors.seoMetaDescription && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.seoMetaDescription}
                                      </FormFeedback>
                                    )
                                  }
                                </div>
                              </Col>
                            </Row>
                          </Col>

                        </Row>
                      </Col>

                      <Col className="col-6">
                        <Row className="">
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="tempCloseStartDate"
                              className="col-md-12 col-form-label"
                            >
                              Temporary Start Date
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="tempCloseStartDate"
                                className="form-control"
                                type="datetime-local"
                                placeholder=""
                                name="tempCloseStartDate"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.tempCloseStartDate}
                                invalid={
                                  validation.touched.tempCloseStartDate &&
                                    validation.errors.tempCloseStartDate
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.tempCloseStartDate &&
                                validation.errors.tempCloseStartDate && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.tempCloseStartDate}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col className="col-6">
                        <Row className="">
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="tempCloseEndDate"
                              className="col-md-12 col-form-label"
                            >
                              Temporary End Date
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="tempCloseEndDate"
                                className="form-control"
                                type="datetime-local"
                                placeholder=""
                                name="tempCloseEndDate"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.tempCloseEndDate}
                                invalid={
                                  validation.touched.tempCloseEndDate &&
                                    validation.errors.tempCloseEndDate
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.tempCloseEndDate &&
                                validation.errors.tempCloseEndDate && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.tempCloseEndDate}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col className="col-12">

                        <Col className="col-12 mb-3">
                          <div className="col-md-12">
                            <UploadFileAPI
                              bucketName="promotion-category"
                              upload="Image"
                              typeUpload="ImageMap"
                              prefixName="promotion-category-image"
                              label="Logo Image"
                              required={true}
                              widthSize={42}
                              heightSize={24}
                              description="description"
                              value={validation.values.image}
                              onChange={([
                                imageURL = "",
                                imageSize = "",
                              ]) => {
                                validation.setFieldValue(
                                  "image",
                                  imageURL
                                )
                              }}
                            />
                            <Input
                              id="image"
                              className="form-control"
                              type="text"
                              placeholder=""
                              name="image"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.image}
                              invalid={
                                validation.touched.image &&
                                  validation.errors.image
                                  ? true
                                  : false
                              }
                              readOnly={true}
                              disabled={
                                pageView === "view" ||
                                pageView === "update"
                              }
                            />
                            {validation.touched.image &&
                              validation.errors.image && (
                                <div
                                  type="invalid"
                                  className="invalid-feedback d-block"
                                >
                                  {validation.errors.image}
                                </div>
                              )}
                          </div>
                        </Col>




                      </Col>

                    </Row>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
                      {
                        pageView == "view"
                          ? <><button className="btn btn-primary w-md" onClick={() => navigate('/payment/payment-credit-debit')}>BACK</button></>
                          : <>
                            <button type="submit" className="btn btn-primary w-md" >SAVE</button>
                            <button className="btn btn-secondary w-md" onClick={() => navigate('/payment/payment-credit-debit')}>CANCEL</button>
                          </>
                      }
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(PaymentCreditDebitTemplate)
