import React, { useEffect, useState, useMemo, useContext } from "react"
import { Input } from "reactstrap"
import { Link, useNavigate, useParams } from "react-router-dom"

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from '../../components/Common/TableContainer';
import { checkScope } from "helpers/utility_helper";
import { DeleteProductStock, PatchProductStock, SearchProductStock } from "services/api/module/ProductStock";
import ModalContext from "../../contexts/ModalContext";
import InputSwitch from "components/Input/InputSwitch";
import fn_helper from "helpers/fn_helper";

const ProductStockList = (props) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  // OTHER STATE
  const { categoryName } = useParams();
  const navigate = useNavigate();

  // LOCAL STATE
  const [contentList, setContentList] = useState([])
  const [contentListLoading, setContentListLoading] = useState(false)
  const [selectedContentId, setSelectedContentId] = useState([])

  // DROPDOWN
  const [dropdownFilter, setDropdownFilter] = useState([])

  const onDeleteRow = (ids = [], name = '') => {
      // alert('onDeleteRow'+ JSON.stringify(ids))
      mAlert.info({
          type: "confrim",
          title: "Alert Confrim",
          subTitle: `Are you sure Delete ${name} ?`,
          mode: "red",
          content: "",
          onClose: () => {},
          onYes: () => { 
              const payload = { ProductStockIds: ids };
              API.fetchDeleteContentList(payload)
          },
          onNo: () => { console.log("Cancel") },
      })
  }
  const onPatchRow = (id = 0, fieldName = '', value = null) => {
      const payload = { id: id, fieldName: fieldName, value: value };
      API.fetchPatchContent(payload)
  }

  const onSelectionChange = (selectedValue = {}, oldValues = [], option = [], mode = "") => {
      const selectedId = selectedValue?.id
      const tmpOldValues = _.cloneDeep(oldValues)
      const findOldValue = tmpOldValues.filter(id => id === selectedId)

      let updateState = []
      if ( findOldValue && findOldValue.length) {
          updateState = tmpOldValues.filter(id => id != selectedId)
      } else {
          updateState = tmpOldValues
          updateState.push(selectedId)
      }

      if (mode === "all") {
          if (tmpOldValues.length >= option.length) {
          updateState = []
          } else {
          updateState = option.map(item => item.id)
          }
      }
      console.log("🚀 ~ onChangeEnum ~ updateState:", updateState)
      return updateState
  };

  const columns = useMemo(
    () => [
      
      {
        header: "#",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {parseInt(cellProps.row.id) + 1}
              </div>
            </>
          )
        },
      },
      {
        header: "SKU",
        accessorKey: "sku",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const row = cellProps?.row?.original
          return row.sku ? fn_helper.FN.splitText(row.sku, 50) : ''
        }
      },
      {
        header: "Product Name ( ITEC )",
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const row = cellProps?.row?.original
          return row.name ? fn_helper.FN.splitText(row.name, 50) : ''
        }
      },
      {
        header: "Branch",
        accessorKey: "branchCode",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const row = cellProps?.row?.original
          return row.branchCode ? row.branchCode : '-'
        }
      },
      {
        header: "Price",
        accessorKey: "price",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const row = cellProps?.row?.original
          return row.price ? fn_helper.FN.toNumberWithCommas(row.price) + ' บาท': '-'
        }
      },
    ],
    [selectedContentId, contentList]
  )

  const API = {
      fetchContentList: async () => {
          setContentListLoading(true)
          try {
              const response = await SearchProductStock({})
              const resData = response?.data ?? []
              setContentList(resData.data);
          } catch (e) {
              console.log(e)
              mAlert.info({
                  title: "Alert",
                  subTitle: "Something went wrong",
                  content: "",
                  onClose: () => {
                  //  alert("onClose")
                  },
              })
          } finally {
              setContentListLoading(false)
          }
      },
      fetchPatchContent: async (payload) => {
          setContentListLoading(true)
          try {
              const response = await PatchProductStock({ data: payload, id: payload.id })
              const resData = response.data
              if (resData.status === 201) {
                  mAlert.info({
                      title: "Alert",
                      subTitle: "Update Data Success",
                      content: "",
                      onClose: () => {
                          fetchAPI()
                      },
                  })
              }
          }
          catch (err) {
              console.log('err ', err)
              mAlert.info({
                  title: "Alert",
                  subTitle: "Something went wrong",
                  content: "",
                  onClose: () => {
                  //  alert("onClose")
                  },
              })
          } finally {
              // setContentListLoading(false)
          }
      },
      fetchDeleteContentList: async (payload) => {
          setContentListLoading(true)
          try {
              const response = await DeleteProductStock({ data: payload })
              const resData = response.data
              if (resData.status === 201) {
                  mAlert.info({
                      title: "Alert",
                      subTitle: "Delete Data Success",
                      content: "",
                      onClose: () => {
                          fetchAPI()
                      },
                  })
              }
          }
          catch (err) {
              console.log('err ', err)
              mAlert.info({
                  title: "Alert",
                  subTitle: "Something went wrong",
                  content: "",
                  onClose: () => {
                  //  alert("onClose")
                  },
              })
          } finally {
              setContentListLoading(false)
          }
      }
      
  }

  const fetchAPI = () => {
      const payload = {}
      API.fetchContentList(payload)
  }

  useEffect(() => {
      fetchAPI()
  }, []);

  //meta title
  document.title = "ITEC Stock List | " + process.env.REACT_APP_CMS_TITLE;

  return (
      <div className="page-content">
          <div className="container-fluid">
              <Breadcrumbs title="ITEC Stock" breadcrumbItem="ITEC Stock List ( ITEC NOT IN PRODUCT )" />
              <TableContainer
                  isAddButton={false}
                  addRowLink={`/create`}
                  
                  columns={columns}
                  data={contentList || []}
                  isGlobalFilter={true}
                  isPagination={true}
                  SearchPlaceholder="Search"
                  pagination="pagination"
                  paginationWrapper='dataTables_paginate paging_simple_numbers'
                  tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                  rootMenu="systemsettings"
                  subMenu="usersetting"
                  isCustomPageSize={true}
                  isSelected={selectedContentId.length > 0}
                  onClearSelected={() => setSelectedContentId([])}
                  onDeleleSelected={() => onDeleteRow(_.cloneDeep(selectedContentId))}
                  isExportButton={true}
                  exportOption={{
                      filename: "ProductStock",
                      dateColumns: []
                  }}
                  dropdownFilter={dropdownFilter}
                  loading={contentListLoading}
                  // sortTable={{
                  //     tableName: 'jib_ProductStock',
                  //     db: 'mongo',
                  //     fetchCallBack: () => {
                  //         setContentListLoading(true)
                  //         fetchAPI()
                  //     }
                  // }}
              />
          </div>
      </div>
  );
}

export default ProductStockList