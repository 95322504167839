import { get, post, put, patch } from "../Config"

export function SearchProductStock({ params = {} }) {
  return get({ url: `/v1/admin/product-stock`, params })
}
export function GetProductStockById({ id }) {
  return get({ url: `/v1/admin/product-stock/${id}` })
}
export function CreateProductStock({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/product-stock`, params, data })
}
export function UpdateProductStock({ params = {}, data = {}, id }) {
  return put({ url: `/v1/admin/product-stock/${id}`, params, data })
}
export function PatchProductStock({ params = {}, data = {}, id }) {
  return patch({ url: `/v1/admin/product-stock/${id}`, params, data })
}
export function DeleteProductStock({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/product-stock/mutiDelete`, params, data })
}
export function GetProductByProductCateId({ id }) {
  return get({ url: `/v1/admin/product-stock/productByCategory/${id}` })
}
