import React, { useEffect, useState, useMemo, useContext } from "react"
import { Input } from "reactstrap"
import { Link, useNavigate, useParams } from "react-router-dom"

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from '../../components/Common/TableContainer';
import { checkScope } from "helpers/utility_helper";
import { DeleteProducts, PatchProducts, SearchProducts } from "services/api/module/Products";
import ModalContext from "../../contexts/ModalContext";
import InputSwitch from "components/Input/InputSwitch";
import fn_helper from "helpers/fn_helper";
import moment from "moment";

const ProductProductsList = props => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  // OTHER STATE
  const { categoryName } = useParams();
  const navigate = useNavigate();

  // LOCAL STATE
  const [contentList, setContentList] = useState([])
  const [contentListLoading, setContentListLoading] = useState(false)
  const [selectedContentId, setSelectedContentId] = useState([])

  // DROPDOWN
  const [dropdownFilter, setDropdownFilter] = useState([])

  const onDeleteRow = (ids = [], name = '') => {
      // alert('onDeleteRow'+ JSON.stringify(ids))
      mAlert.info({
          type: "confrim",
          title: "Alert Confrim",
          subTitle: `Are you sure Delete ${name} ?`,
          mode: "red",
          content: "",
          onClose: () => {},
          onYes: () => { 
              const payload = { ProductsIds: ids };
              API.fetchDeleteContentList(payload)
          },
          onNo: () => { console.log("Cancel") },
      })
  }
  const onPatchRow = (id = 0, fieldName = '', value = null) => {
      const payload = { id: id, fieldName: fieldName, value: value };
      API.fetchPatchContent(payload)
  }

  const onSelectionChange = (selectedValue = {}, oldValues = [], option = [], mode = "") => {
      const selectedId = selectedValue?.id
      const tmpOldValues = _.cloneDeep(oldValues)
      const findOldValue = tmpOldValues.filter(id => id === selectedId)

      let updateState = []
      if ( findOldValue && findOldValue.length) {
          updateState = tmpOldValues.filter(id => id != selectedId)
      } else {
          updateState = tmpOldValues
          updateState.push(selectedId)
      }

      if (mode === "all") {
          if (tmpOldValues.length >= option.length) {
          updateState = []
          } else {
          updateState = option.map(item => item.id)
          }
      }
      console.log("🚀 ~ onChangeEnum ~ updateState:", updateState)
      return updateState
  };

  const columns = useMemo(
    () => [
      {
        id: "colcheckbox",
        header: cellProps => {
          const row = cellProps?.row?.original
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Input
                type={`checkbox`}
                checked={contentList.length === selectedContentId.length}
                onClick={() => {
                  let updateData = onSelectionChange(
                    row,
                    selectedContentId,
                    contentList,
                    "all"
                  )
                  setSelectedContentId(updateData)
                }}
                readOnly
              />
            </div>
          )
        },
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          const row = cellProps?.row?.original
          return (
            <>
              <div
                id={cellProps.row.id}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Input
                  type={`checkbox`}
                  value={cellProps.row.original.id}
                  checked={selectedContentId.includes(row.id)}
                  onClick={() => {
                    let updateData = onSelectionChange(
                      row,
                      selectedContentId,
                      contentList
                    )
                    setSelectedContentId(updateData)
                  }}
                  readOnly
                />
              </div>
            </>
          )
        },
      },
      {
        header: "#",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {parseInt(cellProps.row.id) + 1}
              </div>
            </>
          )
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        // cell: cellProps => <RowStatus status={cellProps.row.original.status} />,
        cell: cellProps => {
          const { id, status } = cellProps.row.original
          return (
            <InputSwitch
              labelON="Active"
              labelOFF="Inactive"
              value={status == 1}
              onChange={value => {
                const updateValue = value ? 1 : 0
                onPatchRow(id, "status", updateValue)
              }}
              onColor="#34c38f"
              offColor="#f1b44c"
              width={72}
            />
          )
        },
      },
      {
        header: "SKU",
        accessorKey: "sku",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const row = cellProps?.row?.original
          return row.sku ? fn_helper.FN.splitText(row.sku, 30) : ''
        }
      },
      {
        header: "Name TH",
        accessorKey: "nameTh",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const row = cellProps?.row?.original
          return row.nameTh ? fn_helper.FN.splitText(row.nameTh, 30) : ''
        }
      },
      {
        header: "NameEN",
        accessorKey: "nameEn",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const row = cellProps?.row?.original
          return row.nameEn ? fn_helper.FN.splitText(row.nameEn, 30) : ''
        }
      },
      {
        header: "Thumbnail",
        accessorKey: "thumbnail",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          const [ thumbnail = {} ] = cellProps?.row?.original?.gallery
          return <img className="bImageBox" src={thumbnail?.url} />
        },
      },
      {
        header: "Tools",
        accessorKey: "user_id",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          let { id, platformName } = cellProps?.row?.original ?? {}
          return (
            <>
              {checkScope("systemsettings:usersetting:update") && (
                <Link
                  className={`btn btn-warning`}
                  to={`/product-management/product-flag/edit/${id}`}
                >
                  <i className={`bx bx-edit-alt`}></i>
                </Link>
              )}
            </>
          )
        },
      },
    ],
    [selectedContentId, contentList]
  )

  const API = {
      fetchContentList: async () => {
          setContentListLoading(true)
          try {
              const response = await SearchProducts({})
              const resData = response?.data ?? []
              setContentList(resData.data);
          } catch (e) {
              console.log(e)
              mAlert.info({
                  title: "Alert",
                  subTitle: "Something went wrong",
                  content: "",
                  onClose: () => {
                  //  alert("onClose")
                  },
              })
          } finally {
              setContentListLoading(false)
          }
      },
      fetchPatchContent: async (payload) => {
          setContentListLoading(true)
          try {
              const response = await PatchProducts({ data: payload, id: payload.id })
              const resData = response.data
              if (resData.status === 201) {
                  mAlert.info({
                      title: "Alert",
                      subTitle: "Update Data Success",
                      content: "",
                      onClose: () => {
                          fetchAPI()
                      },
                  })
              }
          }
          catch (err) {
              console.log('err ', err)
              mAlert.info({
                  title: "Alert",
                  subTitle: "Something went wrong",
                  content: "",
                  onClose: () => {
                  //  alert("onClose")
                  },
              })
          } finally {
              // setContentListLoading(false)
          }
      },
      fetchDeleteContentList: async (payload) => {
          setContentListLoading(true)
          try {
              const response = await DeleteProducts({ data: payload })
              const resData = response.data
              if (resData.status === 201) {
                  mAlert.info({
                      title: "Alert",
                      subTitle: "Delete Data Success",
                      content: "",
                      onClose: () => {
                          fetchAPI()
                      },
                  })
              }
          }
          catch (err) {
              console.log('err ', err)
              mAlert.info({
                  title: "Alert",
                  subTitle: "Something went wrong",
                  content: "",
                  onClose: () => {
                  //  alert("onClose")
                  },
              })
          } finally {
              setContentListLoading(false)
          }
      }
      
  }

  const fetchAPI = () => {
      const payload = {}
      API.fetchContentList(payload)
  }

  useEffect(() => {
      fetchAPI()
  }, []);

  //meta title
  document.title = "Product Flag List | " + process.env.REACT_APP_CMS_TITLE;

  return (
      <div className="page-content">
          <div className="container-fluid">
              <Breadcrumbs title="Product Flag" breadcrumbItem="Product Flag List" />
              <TableContainer
                  isAddButton={false}
                  addRowLink={`/create`}
                  
                  columns={columns}
                  data={contentList || []}
                  isGlobalFilter={true}
                  isPagination={true}
                  SearchPlaceholder="Search"
                  pagination="pagination"
                  paginationWrapper='dataTables_paginate paging_simple_numbers'
                  tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                  rootMenu="systemsettings"
                  subMenu="usersetting"
                  isCustomPageSize={true}
                  isSelected={selectedContentId.length > 0}
                  onClearSelected={() => setSelectedContentId([])}
                  onDeleleSelected={() => onDeleteRow(_.cloneDeep(selectedContentId))}
                  isExportButton={true}
                  exportOption={{
                      filename: "Products",
                      dateColumns: []
                  }}
                  dropdownFilter={dropdownFilter}
                  loading={contentListLoading}
                  sortTable={{
                      tableName: 'jib_Products',
                      db: 'mongo',
                      fetchCallBack: () => {
                          setContentListLoading(true)
                          fetchAPI()
                      }
                  }}
              />
          </div>
      </div>
  );
}

export default ProductProductsList