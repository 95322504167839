import React, { useEffect, useState, useMemo, useContext } from "react"
import { Input } from "reactstrap"
import { Link, useNavigate, useParams } from "react-router-dom"

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from '../../components/Common/TableContainer';
import { checkScope } from "helpers/utility_helper";
import { DeleteFlag, PatchFlag, SearchFlag } from "services/api/module/Flag";
import ModalContext from "../../contexts/ModalContext";
import InputSwitch from "components/Input/InputSwitch";

const FlagList = props => {
    // POPUP
    const { mAlert } = useContext(ModalContext);

    // OTHER STATE
    const { categoryName } = useParams();
    const navigate = useNavigate();

    // LOCAL STATE
    const [contentList, setContentList] = useState([])
    const [contentListLoading, setContentListLoading] = useState(false)
    const [selectedContentId, setSelectedContentId] = useState([])

    // DROPDOWN
    const [dropdownFilter, setDropdownFilter] = useState([])

    const onDeleteRow = (ids = [], name = '') => {
        // alert('onDeleteRow'+ JSON.stringify(ids))
        mAlert.info({
            type: "confrim",
            title: "Alert Confrim",
            subTitle: `Are you sure Delete ${name} ?`,
            mode: "red",
            content: "",
            onClose: () => {},
            onYes: () => { 
                const payload = { flagIds: ids };
                API.fetchDeleteContentList(payload)
            },
            onNo: () => { console.log("Cancel") },
        })
    }
    const onPatchRow = (id = 0, fieldName = '', value = null) => {
        const payload = { id: id, fieldName: fieldName, value: value };
        API.fetchPatchContent(payload)
    }

    const onSelectionChange = (selectedValue = {}, oldValues = [], option = [], mode = "") => {
        const selectedId = selectedValue?.id
        const tmpOldValues = _.cloneDeep(oldValues)
        const findOldValue = tmpOldValues.filter(id => id === selectedId)

        let updateState = []
        if ( findOldValue && findOldValue.length) {
            updateState = tmpOldValues.filter(id => id != selectedId)
        } else {
            updateState = tmpOldValues
            updateState.push(selectedId)
        }

        if (mode === "all") {
            if (tmpOldValues.length >= option.length) {
            updateState = []
            } else {
            updateState = option.map(item => item.id)
            }
        }
        console.log("🚀 ~ onChangeEnum ~ updateState:", updateState)
        return updateState
    };

    const columns = useMemo(
        () => [
            {
                id: "colcheckbox",
                header: (cellProps) => {
                    const row = cellProps?.row?.original
                    return (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Input 
                                type={`checkbox`} 
                                checked={contentList.length === selectedContentId.length && selectedContentId.length !== 0}
                                onClick={() => {
                                    let updateData = onSelectionChange(row, selectedContentId, contentList, "all")
                                    setSelectedContentId(updateData)
                                }}
                                readOnly
                            />
                        </div>
                )},
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    const row = cellProps?.row?.original
                    return (
                        <>
                            <div id={cellProps.row.id} style={{ display: "flex", justifyContent: "center" }}>
                                <Input 
                                    type={`checkbox`} 
                                    value={cellProps.row.original.id} 
                                    checked={selectedContentId.includes(row.id)} 
                                    onClick={() => {
                                        let updateData = onSelectionChange(row, selectedContentId, contentList)
                                        setSelectedContentId(updateData)
                                    }}
                                    readOnly
                                />
                            </div>
                        </>
                    )
                }
            },
            {
                header: '#',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                { cellProps.row?.original?.sortOrder }
                            </div>
                        </>
                    )
                }
            },
            {
                header: 'Status',
                accessorKey: 'status',
                enableColumnFilter: false,
                enableSorting: true,
                width: '10%',
                cell: (cellProps) => {
                    const { id, status } = cellProps.row.original
                    return (
                        <InputSwitch
                            labelON=""
                            labelOFF=""
                            value={status == 1}
                            onChange={(value)=> {
                                const updateValue = value ? 1 : 0
                                onPatchRow(id, 'status', updateValue)
                            }}
                        />
                    )
                }
            },
            {
                header: 'Desktop Img',
                accessorKey: 'iconDesktop',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const { iconDesktop = '' } = cellProps?.row?.original
                    return <img className="bImageBox" src={iconDesktop}/>
                }
            },
            {
                header: 'Mobile Img',
                accessorKey: 'iconMobile',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const { iconMobile = '' } = cellProps?.row?.original
                    return <img className="bImageBox" src={iconMobile}/>
                }
            },
            {
                header: 'Flag Name',
                accessorKey: 'nameTh',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Tools',
                accessorKey: 'user_id',
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    let { id, bannerName } = cellProps?.row?.original ?? {};
                    return (
                        <>
                            {checkScope('systemsettings:usersetting:view') && <Link className={`btn btn-info`} to={`/product-management/flag/view/${id}`}><i className={`bx bx-show-alt`}></i></Link>}
                            &nbsp;
                            {checkScope('systemsettings:usersetting:update') && <Link className={`btn btn-warning`} to={`/product-management/flag/edit/${id}`}><i className={`bx bx-edit-alt`}></i></Link>}
                            &nbsp;
                            {checkScope('systemsettings:usersetting:delete') && <a className={`btn btn-danger`} onClick={() => onDeleteRow([id], bannerName)}><i className={`bx bx-trash`}></i></a>}
                        </>
                    )
                }
            },
        ],
        [selectedContentId, contentList]
    );

    const API = {
        fetchContentList: async () => {
            setContentListLoading(true)
            try {
                const response = await SearchFlag({})
                const resData = response?.data ?? []
                setContentList(resData.data);
            } catch (e) {
                console.log(e)
                mAlert.info({
                    title: "Alert",
                    subTitle: "Something went wrong",
                    content: "",
                    onClose: () => {
                    //  alert("onClose")
                    },
                })
            } finally {
                setContentListLoading(false)
            }
        },
        fetchPatchContent: async (payload) => {
            setContentListLoading(true)
            try {
                const response = await PatchFlag({ data: payload, id: payload.id })
                const resData = response.data
                if (resData.status === 201) {
                    mAlert.info({
                        title: "Alert",
                        subTitle: "Update Data Success",
                        content: "",
                        onClose: () => {
                            fetchAPI()
                        },
                    })
                }
            }
            catch (err) {
                console.log('err ', err)
                mAlert.info({
                    title: "Alert",
                    subTitle: "Something went wrong",
                    content: "",
                    onClose: () => {
                    //  alert("onClose")
                    },
                })
            } finally {
                // setContentListLoading(false)
            }
        },
        fetchDeleteContentList: async (payload) => {
            setContentListLoading(true)
            try {
                const response = await DeleteFlag({ data: payload })
                const resData = response.data
                if (resData.status === 201) {
                    mAlert.info({
                        title: "Alert",
                        subTitle: "Delete Data Success",
                        content: "",
                        onClose: () => {
                            fetchAPI()
                        },
                    })
                }
            }
            catch (err) {
                console.log('err ', err)
                mAlert.info({
                    title: "Alert",
                    subTitle: "Something went wrong",
                    content: "",
                    onClose: () => {
                    //  alert("onClose")
                    },
                })
            } finally {
                setContentListLoading(false)
            }
        }
        
    }

    const fetchAPI = () => {
        const payload = {}
        API.fetchContentList(payload)
    }

    useEffect(() => {
        fetchAPI()
    }, []);

    //meta title
    document.title = "Flag List | " + process.env.REACT_APP_CMS_TITLE;

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="Flag" breadcrumbItem="Flag List" />
                <TableContainer
                    isAddButton={true}
                    addRowLink={`/create`}
                    
                    columns={columns}
                    data={contentList || []}
                    isGlobalFilter={true}
                    isPagination={true}
                    SearchPlaceholder="Search"
                    pagination="pagination"
                    paginationWrapper='dataTables_paginate paging_simple_numbers'
                    tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                    rootMenu="systemsettings"
                    subMenu="usersetting"
                    isCustomPageSize={true}
                    isSelected={selectedContentId.length > 0}
                    onClearSelected={() => setSelectedContentId([])}
                    onDeleleSelected={() => onDeleteRow(_.cloneDeep(selectedContentId))}
                    isExportButton={true}
                    exportOption={{
                        filename: "flag",
                        dateColumns: []
                    }}
                    dropdownFilter={dropdownFilter}
                    loading={contentListLoading}
                    sortTable={{
                        tableName: 'jib_flag',
                        db: 'mongo',
                        fetchCallBack: () => {
                            setContentListLoading(true)
                            fetchAPI()
                        }
                    }}
                />
            </div>
        </div>
    );
}

export default FlagList