import React, { useEffect, useState, useContext, useMemo } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"
import TableContainer from "../../components/Common/TableContainer"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { json, Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import fn_helper from "helpers/fn_helper"
import {
  CreateCoupon,
  GetCouponById,
  UpdateCoupon,
} from "services/api/module/Coupon"
import ModalContext from "../../contexts/ModalContext"
import UploadFileAPI from "components/UploadFileAPI"
import MyEditor from "components/MyEditor"
import InputRadio from "components/Input/InputRadio"
import InputSwitch from "components/Input/InputSwitch"
import Stepper from "components/Stepper"
import {
  DropdownCouponCategory,
  DropdownProductCategoryRoot,
} from "services/api/module/Dropdown"
import { SearchProducts } from "services/api/module/Products"
import CategorySelector from "pages/Products/Form/CategorySelector"
import moment from "moment"

const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const TYPE_OPTION = [
  { id: "1", name: "Single Code" },
  { id: "2", name: "Multiple Code" },
]

const LIMIT_OPTION = [
  { id: "0", name: "Unlimited" },
  { id: "1", name: "Limited" },
]
const DISCOUNT_OPTION = [
  { id: "1", name: "Fixed" },
  { id: "2", name: "Percent" },
]
const DISCOUNT_APPLY_OPTION = [
  { id: "1", name: "Sub total" },
  { id: "2", name: "Shipping Fee" },
]
const USER_TYPE_OPTION = [
  { id: "0", name: "บุคคลธรรมดา" },
  { id: "1", name: "นิติบุคคล" },
]
const USER_GROUP_OPTION = [
  { id: "1", name: "New" },
  { id: "2", name: "Existing" },
]
const PRODUCT_BY_OPTION = [
  { id: "1", name: "Specific Product" },
  { id: "2", name: "By Category" },
]

const CouponTemplate = ({ pageView = "create" }) => {
  // POPUP
  const { mAlert } = useContext(ModalContext)

  // OTHER STATE
  const qParams = useParams()
  const qCouponId = qParams.id
  const navigate = useNavigate()
  const [ddCouponCate, setDDCouponCate] = useState([])
  const [ddCategory, setDDCategory] = useState([])
  const [ddCategoryLoading, setDDCategoryLoading] = useState(true)
  // product select table
  const [contentList, setContentList] = useState([])
  const [contentListLoading, setContentListLoading] = useState(false)

  // STEPPER STATE
  const [activeTab, setActiveTab] = useState(0)

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    //step 1
    couponCategoryId: Yup.string().required("Please Select Coupon Category"),
    nameTh: Yup.string().required("Please Enter Name TH"),
    nameEn: Yup.string().required("Please Enter Name EN"),
    collectFromDate: Yup.string().required("Please Enter Collect From Date"),
    collectToDate: Yup.string().required("Please Enter Collect To Date"),
    validFrom: Yup.string().required("Please Enter Valid From Date"),
    validTo: Yup.string().required("Please Enter Valid To Date"),
    conditionTh: Yup.string().required("Please Enter Condition TH"),
    conditionEn: Yup.string().required("Please Enter Condition EN"),
    // image: Yup.string().required("Please Upload Image"),
    seoUrlKey: Yup.string().required("Please Enter SEO URL Key"),
    seoMetaTitle: Yup.string().required("Please Enter SEO Meta Title"),
    seoMetaKeyword: Yup.string().required("Please Enter SEO Meta Keyword"),
    seoMetaDescription: Yup.string().required(
      "Please Enter SEO Meta Description"
    ),
    //step 2
    couponCodeType: Yup.string().required("Please Select Coupon Code Type"),
    isSystemGenerated: Yup.string().required(
      "Please Select Is System Generate"
    ),
    isPrefix: Yup.string().required("Please Select Is Prefix"),
    codeRange: Yup.string().when("isSystemGenerated", {
      is: val => val == "1",
      then: schema => Yup.string().required("Please Enter Coupon Code Range"),
      otherwise: schema => schema.notRequired(),
    }),
    prefix: Yup.string().when("isPrefix", {
      is: val => val == "1",
      then: schema => Yup.string().required("Please Enter Prefix"),
      otherwise: schema => schema.notRequired(),
    }),
    couponCode: Yup.string().when("isSystemGenerated", {
      is: val => val == "0",
      then: schema => Yup.string().required("Please Enter Coupon Code"),
      otherwise: schema => schema.notRequired(),
    }),
    usageLimit: Yup.string().required("Please Select Usage Limit"),
    maxUsage: Yup.string().required("Please Enter Max Usage"),
    discountType: Yup.string().required("Please Select Discount Type"),
    discountValue: Yup.string().required("Please Enter Discount Value"),
    minSpending: Yup.string().required("Please Enter Min Spending"),
    maxDiscount: Yup.string().required("Please Enter Max Discount"),
    discountApplyAt: Yup.string().required("Please Select Discount Apply At"),
    userType: Yup.string().required("Please Select User Type"),
    userGroup: Yup.string().required("Please Select User Group"),
    applicableToOtherCoupon: Yup.string().required(
      "Please Select Applicable To Other Coupon"
    ),
    applicableToOtherPromotion: Yup.string().required(
      "Please Select Applicable To Other Promotion"
    ),
  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] =
    useState(validationSchema)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      //step 1
      couponCategoryId: "",
      nameTh: "",
      nameEn: "",
      collectFromDate: "",
      collectToDate: "",
      validFrom: "",
      validTo: "",
      conditionTh: "",
      conditionEn: "",
      image: "",
      seoUrlKey: "",
      seoMetaTitle: "",
      seoMetaKeyword: "",
      seoMetaDescription: "",
      status: 1,
      // step 2
      couponCodeType: "1",
      isSystemGenerated: "0",
      isPrefix: "0",
      codeRange: "",
      prefix: "",
      couponCode: "",
      usageLimit: "1",
      maxUsage: "",
      discountType: "",
      discountValue: "",
      minSpending: "",
      maxDiscount: "",
      discountApplyAt: "",
      userType: "",
      userGroup: "",
      applicableToOtherCoupon: "0",
      applicableToOtherPromotion: "0",
      //step 3
      applicableToAllProduct: "0",
      selectProductBy: "1",
      selectedContentId: [],
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ CouponCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        //step 1
        couponCategoryId: values.couponCategoryId,
        nameTh: values.nameTh,
        nameEn: values.nameEn,
        collectFromDate: values.collectFromDate,
        collectToDate: values.collectToDate,
        validFrom: values.validFrom,
        validTo: values.validTo,
        conditionTh: values.conditionTh,
        conditionEn: values.conditionEn,
        image: values.image || null,
        seoUrlKey: values.seoUrlKey,
        seoMetaTitle: values.seoMetaTitle,
        seoMetaKeyword: values.seoMetaKeyword,
        seoMetaDescription: values.seoMetaDescription,
        status: values.status,
        //step 2
        couponCodeType: values.couponCodeType,
        isSystemGenerated: values.isSystemGenerated,
        isPrefix: values.isPrefix,
        codeRange: values.codeRange,
        prefix: values.prefix,
        couponCode: values.couponCode,
        usageLimit: values.usageLimit,
        maxUsage: values.maxUsage,
        discountType: values.discountType,
        discountValue: values.discountValue,
        minSpending: values.minSpending,
        maxDiscount: values.maxDiscount,
        discountApplyAt: values.discountApplyAt,
        userType: values.userType,
        userGroup: values.userGroup,
        applicableToOtherCoupon: values.applicableToOtherCoupon,
        applicableToOtherPromotion: values.applicableToOtherPromotion,
        //step 3
        applicableToAllProduct: values.applicableToAllProduct,
        selectProductBy: values.selectProductBy,
        selectedContentId: values.selectedContentId,
      }
      console.log("🚀 ~ onSave ~ payload:", payload)

      if (pageView === "update") {
        API.fetchUpdateCoupon(payload)
      } else {
        API.fetchCreateCoupon(payload)
      }
    },
  })

  const preFilInput = (resData = {}) => {
    //step 1
    validation.setFieldValue("couponCategoryId", resData.couponCategoryId)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("nameTh", resData.nameTh)
    validation.setFieldValue("nameEn", resData.nameEn)
    validation.setFieldValue(
      "collectFromDate",
      moment(resData.collectFromDate).format("YYYY-MM-DDTHH:mm")
    )
    validation.setFieldValue(
      "collectToDate",
      moment(resData.collectToDate).format("YYYY-MM-DDTHH:mm")
    )
    validation.setFieldValue(
      "validFrom",
      moment(resData.validFrom).format("YYYY-MM-DDTHH:mm")
    )
    validation.setFieldValue(
      "validTo",
      moment(resData.validTo).format("YYYY-MM-DDTHH:mm")
    )
    validation.setFieldValue("conditionTh", resData.conditionTh)
    validation.setFieldValue("conditionEn", resData.conditionEn)
    validation.setFieldValue("image", resData.image)
    validation.setFieldValue("seoUrlKey", resData.seoUrlKey)
    validation.setFieldValue("seoMetaTitle", resData.seoMetaTitle)
    validation.setFieldValue("seoMetaKeyword", resData.seoMetaKeyword)
    validation.setFieldValue("seoMetaDescription", resData.seoMetaDescription)
    validation.setFieldValue("status", resData.status)
    //step 2
    validation.setFieldValue("couponCodeType", resData.couponCodeType)
    validation.setFieldValue("isSystemGenerated", resData.isSystemGenerated)
    validation.setFieldValue("isPrefix", resData.isPrefix)
    validation.setFieldValue("codeRange", resData.codeRange)
    validation.setFieldValue("prefix", resData.prefix)
    validation.setFieldValue("couponCode", resData.code)
    validation.setFieldValue("usageLimit", resData.usageLimit)
    validation.setFieldValue("maxUsage", resData.maxUsage)
    validation.setFieldValue("discountType", resData.discountType)
    validation.setFieldValue("discountValue", resData.discountValue)
    validation.setFieldValue("minSpending", resData.minSpending)
    validation.setFieldValue("maxDiscount", resData.maxDiscount)
    validation.setFieldValue("discountApplyAt", resData.discountApplyAt)
    validation.setFieldValue("userType", resData.userType)
    validation.setFieldValue("userGroup", resData.userGroup)
    validation.setFieldValue(
      "applicableToOtherCoupon",
      resData.applicableToOtherCoupon
    )
    validation.setFieldValue(
      "applicableToOtherPromotion",
      resData.applicableToOtherPromotion
    )
    //step3
    validation.setFieldValue(
      "applicableToAllProduct",
      resData.applicableToAllProduct
    )
    validation.setFieldValue("selectProductBy", resData.selectProductBy)
    if (resData.selectProductBy == "1") {
      const skuArray = resData.couponProducts.map(
        product => product.products.id
      )
      validation.setFieldValue("selectedContentId", skuArray)
    } else {
      const productIdArray = resData.couponProducts.map(
        product => product.products.id
      )
      validation.setFieldValue("selectedContentId", productIdArray)
    }
  }

  const API = {
    fetchGetCouponById: async payload => {
      try {
        const response = await GetCouponById({ id: payload.id })
        const resData = response.data
        if (resData.status == 202) {
          preFilInput(resData.data)
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchCreateCoupon: async payload => {
      try {
        const response = await CreateCoupon({ data: payload })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Created Data Success",
            content: "",
            onClose: () => {
              navigate("/coupon-management/coupon")
            },
          })
        }
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchUpdateCoupon: async payload => {
      try {
        const response = await UpdateCoupon({
          data: payload,
          id: payload.id,
        })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              navigate("/coupon-management/coupon")
            },
          })
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchDDCouponCategory: async () => {
      try {
        const response = await DropdownCouponCategory({})
        const resData = response?.data ?? []
        setDDCouponCate(resData.data)
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchDDProductCategory: async () => {
      try {
        const response = await DropdownProductCategoryRoot({})
        const resData = response?.data ?? []
        setDDCategory(resData.data)
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      } finally {
        setDDCategoryLoading(false)
      }
    },
    fetchContentList: async () => {
      try {
        const response = await SearchProducts({})
        const resData = response?.data ?? []
        setContentList(resData.data)
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      } finally {
        setContentListLoading(false)
      }
    },
  }

  const onStep1 = async () => {
    const errors = await validation.validateForm()
    const step1Fields = [
      "nameTh",
      "nameEn",
      "collectFromDate",
      "collectToDate",
      "validFrom",
      "validTo",
      "conditionTh",
      "conditionEn",
      // "image",
      "seoUrlKey",
      "seoMetaTitle",
      "seoMetaKeyword",
      "seoMetaDescription",
      "status",
    ]

    const step1Errors = step1Fields.some(field => errors[field])

    if (step1Errors) {
      validation.setTouched(
        step1Fields.reduce((acc, field) => {
          acc[field] = true
          return acc
        }, {})
      )
    } else {
      setActiveTab(activeTab + 1)
    }
  }
  const onStep2 = async () => {
    const errors = await validation.validateForm()
    const step2Fields = [
      "couponCodeType",
      "isSystemGenerated",
      "isPrefix",
      "codeRange",
      "prefix",
      "couponCode",
      "usageLimit",
      "maxUsage",
      "discountType",
      "discountValue",
      "minSpending",
      "maxDiscount",
      "discountApplyAt",
      "userType",
      "userGroup",
      "applicableToOtherCoupon",
      "applicableToOtherPromotion",
    ]

    const step2Errors = step2Fields.some(field => errors[field])

    if (step2Errors) {
      validation.setTouched(
        step2Fields.reduce((acc, field) => {
          acc[field] = true
          return acc
        }, {})
      )
    } else {
      setActiveTab(activeTab + 1)
    }
  }

  const onSelectionChange = (
    selectedValue = {},
    oldValues = [],
    option = [],
    mode = "",
    couponCodeType = ""
  ) => {
    const selectedId = selectedValue?.id
    let tmpOldValues = _.cloneDeep(oldValues)
    let updateState = []

    if (couponCodeType == "2") {
      // Allow selecting only one product
      updateState = selectedId ? [selectedId] : []
    } else {
      const findOldValue = tmpOldValues.filter(id => id === selectedId)

      if (findOldValue && findOldValue.length) {
        updateState = tmpOldValues.filter(id => id !== selectedId)
      } else {
        updateState = tmpOldValues
        updateState.push(selectedId)
      }

      if (mode === "all") {
        if (tmpOldValues.length >= option.length) {
          updateState = []
        } else {
          updateState = option.map(item => item.id)
        }
      }
    }

    console.log("🚀 ~ onChangeEnum ~ updateState:", updateState)
    return updateState
  }

  const columns = useMemo(
    () => [
      {
        id: "colcheckbox",
        header: cellProps => {
          const row = cellProps?.row?.original
          const disableSelectAll = validation.values.couponCodeType == "2"
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Input
                type={`checkbox`}
                checked={
                  contentList.length ==
                  validation.values.selectedContentId.length
                }
                onClick={() => {
                  let updateData = onSelectionChange(
                    row,
                    validation.values.selectedContentId,
                    contentList,
                    "all"
                  )
                  validation.setFieldValue("selectedContentId", updateData)
                }}
                readOnly
                disabled={disableSelectAll || ["view"].includes(pageView)}
              />
            </div>
          )
        },
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          const row = cellProps?.row?.original
          const isSelected = validation.values.selectedContentId.includes(
            row.id
          )
          const disableOtherCheckboxes =
            validation.values.couponCodeType == "2" &&
            validation.values.selectedContentId.length > 0 &&
            !isSelected
          return (
            <>
              <div
                id={cellProps.row.id}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Input
                  type={`checkbox`}
                  value={cellProps.row.original.id}
                  checked={validation.values.selectedContentId.includes(row.id)}
                  onClick={() => {
                    let updateData = onSelectionChange(
                      row,
                      validation.values.selectedContentId,
                      contentList
                    )
                    validation.setFieldValue("selectedContentId", updateData)
                  }}
                  readOnly
                  disabled={
                    ["view"].includes(pageView) || disableOtherCheckboxes
                  }
                />
              </div>
            </>
          )
        },
      },
      {
        header: "#",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {parseInt(cellProps.row.id) + 1}
              </div>
            </>
          )
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        // cell: cellProps => <RowStatus status={cellProps.row.original.status} />,
        cell: cellProps => {
          const { id, status } = cellProps.row.original
          return (
            <InputSwitch
              labelON="Active"
              labelOFF="Inactive"
              value={status == 1}
              onChange={value => {
                const updateValue = value ? 1 : 0
                onPatchRow(id, "status", updateValue, 0)
              }}
              onColor="#34c38f"
              offColor="#f1b44c"
              width={72}
              disabled
            />
          )
        },
      },
      {
        header: "sku",
        accessorKey: "sku",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Name TH",
        accessorKey: "nameTh",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Name EN",
        accessorKey: "nameEn",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Thumbnail",
        accessorKey: "thumbnail",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          const [thumbnail = {}] = cellProps?.row?.original?.gallery
          return <img className="bImageBox" src={thumbnail?.url} />
        },
      },
    ],
    [validation.values.selectedContentId, contentList]
  )

  const fetchAPI = () => {
    const payload = {}
    API.fetchContentList(payload)
  }

  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id: qCouponId }
      API.fetchGetCouponById(payload)
    }
    API.fetchDDCouponCategory()
    API.fetchDDProductCategory()
    // product table
    setContentListLoading(true)
    fetchAPI()
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `Coupon | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  // RENDER
  const STEP_CONTENT = [
    {
      stepLabel: "Main Infomation",
      components: (
        <>
          <Col className="col-12">
            <Row className="">
              <Col className="col-12">
                <Row className="">
                  <Col className="col-12">
                    <h5 className="mb-3">Coupon Detail</h5>
                  </Col>
                  <Col className="col-12 mb-3">
                    <label
                      htmlFor="category_name"
                      className="col-md-12 col-form-label"
                    >
                      Select Category
                    </label>
                    <div className="col-md-12">
                      <Select
                        placeholder="Select Category"
                        value={fn_helper.FNFORM.getObjectValue(
                          ddCouponCate,
                          `${validation.values.couponCategoryId}`
                        )}
                        onChange={value =>
                          validation.setFieldValue("couponCategoryId", value.id)
                        }
                        getOptionValue={option => option.id}
                        getOptionLabel={option => option.name}
                        options={ddCouponCate}
                        className={`select2-selection ${
                          validation.touched.couponCategoryId &&
                          validation.errors.couponCategoryId
                            ? "input-err"
                            : ""
                        }`}
                        isDisabled={pageView === "view"}
                      />
                      {validation.touched.couponCategoryId &&
                        validation.errors.couponCategoryId && (
                          <div
                            type="invalid"
                            className="invalid-feedback d-block"
                          >
                            {validation.errors.couponCategoryId}
                          </div>
                        )}
                      {validation.touched.couponCategoryId &&
                        validation.errors.couponCategoryId && (
                          <FormFeedback type="invalid">
                            {validation.errors.couponCategoryId}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                  <Col className="col-6 mb-3">
                    <label
                      htmlFor="nameTh"
                      className="col-md-12 col-form-label"
                    >
                      Name Thai
                    </label>
                    <div className="col-md-12">
                      <Input
                        id="nameTh"
                        className="form-control"
                        type="text"
                        placeholder=""
                        name="nameTh"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.nameTh}
                        invalid={
                          validation.touched.nameTh && validation.errors.nameTh
                            ? true
                            : false
                        }
                        disabled={pageView === "view"}
                      />
                      {validation.touched.nameTh &&
                        validation.errors.nameTh && (
                          <FormFeedback type="invalid">
                            {validation.errors.nameTh}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                  <Col className="col-6 mb-3">
                    <label
                      htmlFor="nameEn"
                      className="col-md-12 col-form-label"
                    >
                      Name English
                    </label>
                    <div className="col-md-12">
                      <Input
                        id="nameEn"
                        className="form-control"
                        type="text"
                        placeholder=""
                        name="nameEn"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.nameEn}
                        invalid={
                          validation.touched.nameEn && validation.errors.nameEn
                            ? true
                            : false
                        }
                        disabled={pageView === "view"}
                      />
                      {validation.touched.nameEn &&
                        validation.errors.nameEn && (
                          <FormFeedback type="invalid">
                            {validation.errors.nameEn}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                  <Col className="col-6 mb-3">
                    <label
                      htmlFor="collectFromDate"
                      className="col-md-12 col-form-label"
                    >
                      collect From Date
                    </label>
                    <div className="col-md-12">
                      <Input
                        id="collectFromDate"
                        className="form-control"
                        type="datetime-local"
                        placeholder=""
                        name="collectFromDate"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.collectFromDate}
                        invalid={
                          validation.touched.collectFromDate &&
                          validation.errors.collectFromDate
                            ? true
                            : false
                        }
                        disabled={pageView === "view"}
                      />
                      {validation.touched.collectFromDate &&
                        validation.errors.collectFromDate && (
                          <FormFeedback type="invalid">
                            {validation.errors.collectFromDate}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                  <Col className="col-6 mb-3">
                    <label
                      htmlFor="collectToDate"
                      className="col-md-12 col-form-label"
                    >
                      collect To Date
                    </label>
                    <div className="col-md-12">
                      <Input
                        id="collectToDate"
                        className="form-control"
                        type="datetime-local"
                        placeholder=""
                        name="collectToDate"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.collectToDate}
                        invalid={
                          validation.touched.collectToDate &&
                          validation.errors.collectToDate
                            ? true
                            : false
                        }
                        disabled={pageView === "view"}
                      />
                      {validation.touched.collectToDate &&
                        validation.errors.collectToDate && (
                          <FormFeedback type="invalid">
                            {validation.errors.collectToDate}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                  <Col className="col-6 mb-3">
                    <label
                      htmlFor="collectToDate"
                      className="col-md-12 col-form-label"
                    >
                      Valid From Date
                    </label>
                    <div className="col-md-12">
                      <Input
                        id="validFrom"
                        className="form-control"
                        type="datetime-local"
                        placeholder=""
                        name="validFrom"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.validFrom}
                        invalid={
                          validation.touched.validFrom &&
                          validation.errors.validFrom
                            ? true
                            : false
                        }
                        disabled={pageView === "view"}
                      />
                      {validation.touched.validFrom &&
                        validation.errors.validFrom && (
                          <FormFeedback type="invalid">
                            {validation.errors.validFrom}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                  <Col className="col-6 mb-3">
                    <label
                      htmlFor="validTo"
                      className="col-md-12 col-form-label"
                    >
                      Valid To Date
                    </label>
                    <div className="col-md-12">
                      <Input
                        id="validTo"
                        className="form-control"
                        type="datetime-local"
                        placeholder=""
                        name="validTo"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.validTo}
                        invalid={
                          validation.touched.validTo &&
                          validation.errors.validTo
                            ? true
                            : false
                        }
                        disabled={pageView === "view"}
                      />
                      {validation.touched.validTo &&
                        validation.errors.validTo && (
                          <FormFeedback type="invalid">
                            {validation.errors.validTo}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                  <Col className="col-6 mb-3">
                    <label
                      htmlFor="status"
                      className="col-md-12 col-form-label"
                    >
                      Status
                    </label>
                    <div className="col-md-6">
                      <Select
                        placeholder="Select Status"
                        value={fn_helper.FNFORM.getObjectValue(
                          STATUS_OPTION,
                          `${validation.values.status}`
                        )}
                        onChange={value =>
                          validation.setFieldValue("status", value.id)
                        }
                        getOptionValue={option => option.id}
                        getOptionLabel={option => option.name}
                        options={STATUS_OPTION}
                        className={`select2-selection ${
                          validation.touched.status && validation.errors.status
                            ? "input-err"
                            : ""
                        }`}
                        isDisabled={pageView === "view"}
                      />
                      {validation.touched.status &&
                        validation.errors.status && (
                          <div
                            type="invalid"
                            className="invalid-feedback d-block"
                          >
                            {validation.errors.status}
                          </div>
                        )}
                      {validation.touched.status &&
                        validation.errors.status && (
                          <FormFeedback type="invalid">
                            {validation.errors.status}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                  <Col className="col-6 mb-3"></Col>
                  <Col className="col-12 mb-3">
                    <label
                      htmlFor="conditionTh"
                      className="col-md-12 col-form-label"
                    >
                      Condition TH
                      <span className="t-require">*</span>
                    </label>
                    <div className="col-md-12">
                      <div
                        className={
                          validation.touched.conditionTh &&
                          validation.errors.conditionTh
                            ? "invalid-editor"
                            : ""
                        }
                      >
                        <MyEditor
                          value={validation.values.conditionTh}
                          onChange={(event, editor) => {
                            const data = editor.getData()
                            validation.setFieldValue("conditionTh", data)
                            console.log({ event, editor, data })
                          }}
                          disabled={pageView === "view"}
                        />
                      </div>

                      {validation.touched.conditionTh &&
                        validation.errors.conditionTh && (
                          <div
                            type="invalid"
                            className="invalid-feedback d-block"
                          >
                            {validation.errors.conditionTh}
                          </div>
                        )}
                    </div>
                  </Col>
                  <Col className="col-12 mb-3">
                    <label
                      htmlFor="conditionEn"
                      className="col-md-12 col-form-label"
                    >
                      Condition EN
                      <span className="t-require">*</span>
                    </label>
                    <div className="col-md-12">
                      <div
                        className={
                          validation.touched.conditionEn &&
                          validation.errors.conditionEn
                            ? "invalid-editor"
                            : ""
                        }
                      >
                        <MyEditor
                          value={validation.values.conditionEn}
                          onChange={(event, editor) => {
                            const data = editor.getData()
                            validation.setFieldValue("conditionEn", data)
                            console.log({ event, editor, data })
                          }}
                          disabled={pageView === "view"}
                        />
                      </div>

                      {validation.touched.conditionEn &&
                        validation.errors.conditionEn && (
                          <div
                            type="invalid"
                            className="invalid-feedback d-block"
                          >
                            {validation.errors.conditionEn}
                          </div>
                        )}
                    </div>
                  </Col>
                  <Col className="col-12 mb-3">
                    <div className="col-md-12">
                      <UploadFileAPI
                        bucketName="coupon"
                        upload="Image"
                        typeUpload="ImageMap"
                        prefixName="coupon-image"
                        label="Upload coupon Image"
                        required={false}
                        widthSize={180}
                        heightSize={180}
                        description="description"
                        value={validation.values.image}
                        onChange={([imageURL = "", imageSize = ""]) => {
                          validation.setFieldValue("image", imageURL)
                        }}
                      />
                      <Input
                        id="image"
                        className="form-control"
                        type="text"
                        placeholder=""
                        name="image"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.image}
                        invalid={
                          validation.touched.image && validation.errors.image
                            ? true
                            : false
                        }
                        readOnly={true}
                        disabled={pageView === "view"}
                      />
                      {validation.touched.image && validation.errors.image && (
                        <div
                          type="invalid"
                          className="invalid-feedback d-block"
                        >
                          {validation.errors.image}
                        </div>
                      )}
                    </div>
                  </Col>
                  {/* SEO setting */}
                  <Row>
                    <Col className="col-12">
                      <h5 className="mb-3">SEO Setting</h5>
                    </Col>
                    <Col className="col-6 mb-3">
                      <label
                        htmlFor="seoUrlKey"
                        className="col-md-12 col-form-label"
                      >
                        URL Key
                      </label>
                      <div className="col-md-12">
                        <Input
                          id="seoUrlKey"
                          className="form-control"
                          type="text"
                          placeholder=""
                          name="seoUrlKey"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.seoUrlKey}
                          invalid={
                            validation.touched.seoUrlKey &&
                            validation.errors.seoUrlKey
                              ? true
                              : false
                          }
                          disabled={pageView === "view"}
                        />
                        {validation.touched.seoUrlKey &&
                          validation.errors.seoUrlKey && (
                            <FormFeedback type="invalid">
                              {validation.errors.seoUrlKey}
                            </FormFeedback>
                          )}
                      </div>
                    </Col>
                    <Col className="col-6 mb-3">
                      <label
                        htmlFor="seoMetaTitle"
                        className="col-md-12 col-form-label"
                      >
                        Meta title
                      </label>
                      <div className="col-md-12">
                        <Input
                          id="seoMetaTitle"
                          className="form-control"
                          type="text"
                          placeholder=""
                          name="seoMetaTitle"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.seoMetaTitle}
                          invalid={
                            validation.touched.seoMetaTitle &&
                            validation.errors.seoMetaTitle
                              ? true
                              : false
                          }
                          disabled={pageView === "view"}
                        />
                        {validation.touched.seoMetaTitle &&
                          validation.errors.seoMetaTitle && (
                            <FormFeedback type="invalid">
                              {validation.errors.seoMetaTitle}
                            </FormFeedback>
                          )}
                      </div>
                    </Col>
                    <Col className="col-6 mb-3">
                      <label
                        htmlFor="seo_meta_keyword"
                        className="col-md-12 col-form-label"
                      >
                        Meta Keyword
                      </label>
                      <div className="col-md-12">
                        <Input
                          id="seoMetaKeyword"
                          className="form-control"
                          type="text"
                          placeholder=""
                          name="seoMetaKeyword"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.seoMetaKeyword}
                          invalid={
                            validation.touched.seoMetaKeyword &&
                            validation.errors.seoMetaKeyword
                              ? true
                              : false
                          }
                          disabled={pageView === "view"}
                        />
                        {validation.touched.seoMetaKeyword &&
                          validation.errors.seoMetaKeyword && (
                            <FormFeedback type="invalid">
                              {validation.errors.seoMetaKeyword}
                            </FormFeedback>
                          )}
                      </div>
                    </Col>
                    <Col className="col-6 mb-3">
                      <label
                        htmlFor="seo_meta_description"
                        className="col-md-12 col-form-label"
                      >
                        Meta Description
                      </label>
                      <div className="col-md-12">
                        <Input
                          id="seoMetaDescription"
                          className="form-control"
                          type="text"
                          placeholder=""
                          name="seoMetaDescription"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.seoMetaDescription}
                          invalid={
                            validation.touched.seoMetaDescription &&
                            validation.errors.seoMetaDescription
                              ? true
                              : false
                          }
                          disabled={pageView === "view"}
                        />
                        {validation.touched.seoMetaDescription &&
                          validation.errors.seoMetaDescription && (
                            <FormFeedback type="invalid">
                              {validation.errors.seoMetaDescription}
                            </FormFeedback>
                          )}
                      </div>
                    </Col>
                  </Row>
                </Row>
              </Col>
            </Row>
          </Col>
        </>
      ),
    },
    {
      stepLabel: "Coupon Condition",
      components: (
        <>
          <Col className="col-12">
            <Row className="">
              <Col className="col-12">
                <Row className="">
                  <Col className="col-12">
                    <h5 className="mb-3">Coupon Detail</h5>
                  </Col>
                  <Col className="col-6 mb-3">
                    <label
                      htmlFor="couponCodeType"
                      className="col-md-12 col-form-label"
                    >
                      Coupon Code Type
                    </label>
                    <div className="col-md-12">
                      <Select
                        placeholder="Select Coupon Code Type"
                        value={fn_helper.FNFORM.getObjectValue(
                          TYPE_OPTION,
                          `${validation.values.couponCodeType}`
                        )}
                        onChange={value => {
                          validation.setFieldValue("couponCodeType", value.id)
                          if (value.id == "2") {
                            validation.setFieldValue("isSystemGenerated", "1")
                            validation.setFieldValue(
                              "applicableToAllProduct",
                              "0"
                            )
                            validation.setFieldValue("selectProductBy", "1")
                            validation.setFieldValue("usageLimit", "1")
                          }
                          validation.setFieldValue("selectedContentId", [])
                        }}
                        getOptionValue={option => option.id}
                        getOptionLabel={option => option.name}
                        options={TYPE_OPTION}
                        className={`select2-selection ${
                          validation.touched.couponCodeType &&
                          validation.errors.couponCodeType
                            ? "input-err"
                            : ""
                        }`}
                        isDisabled={pageView === "view"}
                      />
                      {validation.touched.couponCodeType &&
                        validation.errors.couponCodeType && (
                          <div
                            type="invalid"
                            className="invalid-feedback d-block"
                          >
                            {validation.errors.couponCodeType}
                          </div>
                        )}
                      {validation.touched.couponCodeType &&
                        validation.errors.couponCodeType && (
                          <FormFeedback type="invalid">
                            {validation.errors.couponCodeType}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                  <Col className="col-6 mb-3"></Col>
                  <Col className="col-6 mb-3">
                    <label
                      htmlFor="isHighlight"
                      className="col-md-12 col-form-label"
                    >
                      Is System Generate Code
                    </label>
                    <InputSwitch
                      labelTrue="Yes"
                      labelFalse="No"
                      value={validation.values.isSystemGenerated == "1"}
                      onChange={value => {
                        const updateValue = value ? "1" : "0"
                        validation.setFieldValue(
                          "isSystemGenerated",
                          updateValue
                        )
                        validation.setFieldValue("codeRange", "")
                        validation.setFieldValue("couponCode", "")
                        validation.setFieldTouched("codeRange", false)
                        validation.setFieldTouched("couponCode", false)
                      }}
                      disabled={
                        pageView === "view" ||
                        validation.values.couponCodeType == "2"
                      }
                    />
                  </Col>
                  <Col className="col-6 mb-3">
                    <label
                      htmlFor="isHighlight"
                      className="col-md-12 col-form-label"
                    >
                      Is Prefix
                    </label>
                    <InputSwitch
                      labelTrue="Yes"
                      labelFalse="No"
                      value={validation.values.isPrefix == "1"}
                      onChange={value => {
                        const updateValue = value ? "1" : "0"
                        validation.setFieldValue("isPrefix", updateValue)
                        validation.setFieldValue("prefix", "")
                        validation.setFieldTouched("prefix", false)
                      }}
                      disabled={pageView === "view"}
                    />
                  </Col>
                  <Col className="col-6 mb-3">
                    <label
                      htmlFor="codeRange"
                      className="col-md-12 col-form-label"
                    >
                      Coupon Code Range
                    </label>
                    <div className="col-md-12">
                      <Input
                        id="codeRange"
                        className="form-control"
                        type="text"
                        placeholder=""
                        name="codeRange"
                        onChange={e => {
                          const regex = /^[0-9]*$/ // Allow only numbers
                          if (regex.test(e.target.value)) {
                            validation.handleChange(e) // Update state if the value is valid
                          }
                        }}
                        onBlur={validation.handleBlur}
                        value={validation.values.codeRange}
                        invalid={
                          validation.touched.codeRange &&
                          validation.errors.codeRange
                            ? true
                            : false
                        }
                        disabled={
                          pageView === "view" ||
                          validation.values.isSystemGenerated == "0"
                        }
                      />
                      {validation.touched.codeRange &&
                        validation.errors.codeRange && (
                          <FormFeedback type="invalid">
                            {validation.errors.codeRange}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                  <Col className="col-6 mb-3">
                    <label
                      htmlFor="prefix"
                      className="col-md-12 col-form-label"
                    >
                      Prefix Code
                    </label>
                    <div className="col-md-12">
                      <Input
                        id="prefix"
                        className="form-control"
                        type="text"
                        placeholder=""
                        name="prefix"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.prefix}
                        invalid={
                          validation.touched.prefix && validation.errors.prefix
                            ? true
                            : false
                        }
                        disabled={
                          pageView === "view" ||
                          validation.values.isPrefix == "0"
                        }
                      />
                      {validation.touched.prefix &&
                        validation.errors.prefix && (
                          <FormFeedback type="invalid">
                            {validation.errors.prefix}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                  <Col className="col-6 mb-3">
                    <label
                      htmlFor="couponCode"
                      className="col-md-12 col-form-label"
                    >
                      Coupon Code
                    </label>
                    <div className="col-md-12">
                      <Input
                        id="couponCode"
                        className="form-control"
                        type="text"
                        placeholder=""
                        name="couponCode"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.couponCode}
                        invalid={
                          validation.touched.couponCode &&
                          validation.errors.couponCode
                            ? true
                            : false
                        }
                        disabled={
                          pageView === "view" ||
                          validation.values.isSystemGenerated == "1"
                        }
                      />
                      {validation.touched.couponCode &&
                        validation.errors.couponCode && (
                          <FormFeedback type="invalid">
                            {validation.errors.couponCode}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                  <Col className="col-6 mb-3"></Col>
                  <Col className="col-6 mb-3">
                    <label
                      htmlFor="usageLimit"
                      className="col-md-12 col-form-label"
                    >
                      Usage Limit
                    </label>
                    <div className="col-md-12">
                      <Select
                        placeholder="Select Coupon Code Type"
                        value={fn_helper.FNFORM.getObjectValue(
                          LIMIT_OPTION,
                          `${validation.values.usageLimit}`
                        )}
                        onChange={value =>
                          validation.setFieldValue("usageLimit", value.id)
                        }
                        getOptionValue={option => option.id}
                        getOptionLabel={option => option.name}
                        options={LIMIT_OPTION}
                        className={`select2-selection ${
                          validation.touched.usageLimit &&
                          validation.errors.usageLimit
                            ? "input-err"
                            : ""
                        }`}
                        isDisabled={
                          pageView === "view" ||
                          validation.values.couponCodeType == "2"
                        }
                      />
                      {validation.touched.usageLimit &&
                        validation.errors.usageLimit && (
                          <div
                            type="invalid"
                            className="invalid-feedback d-block"
                          >
                            {validation.errors.usageLimit}
                          </div>
                        )}
                      {validation.touched.usageLimit &&
                        validation.errors.usageLimit && (
                          <FormFeedback type="invalid">
                            {validation.errors.usageLimit}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                  <Col className="col-6 mb-3">
                    <label
                      htmlFor="maxUsage"
                      className="col-md-12 col-form-label"
                    >
                      Max Usage
                    </label>
                    <div className="col-md-12">
                      <Input
                        id="maxUsage"
                        className="form-control"
                        type="text"
                        placeholder=""
                        name="maxUsage"
                        onChange={e => {
                          const { value } = e.target
                          if (/^\d*$/.test(value)) {
                            validation.handleChange(e)
                          }
                        }}
                        onBlur={validation.handleBlur}
                        value={validation.values.maxUsage}
                        invalid={
                          validation.touched.maxUsage &&
                          validation.errors.maxUsage
                            ? true
                            : false
                        }
                        disabled={pageView === "view"}
                      />
                      {validation.touched.maxUsage &&
                        validation.errors.maxUsage && (
                          <FormFeedback type="invalid">
                            {validation.errors.maxUsage}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                  <Col className="col-6 mb-3">
                    <label
                      htmlFor="discountType"
                      className="col-md-12 col-form-label"
                    >
                      Discount Type
                    </label>
                    <div className="col-md-12">
                      <Select
                        placeholder="Select Coupon Code Type"
                        value={fn_helper.FNFORM.getObjectValue(
                          DISCOUNT_OPTION,
                          `${validation.values.discountType}`
                        )}
                        onChange={value =>
                          validation.setFieldValue("discountType", value.id)
                        }
                        getOptionValue={option => option.id}
                        getOptionLabel={option => option.name}
                        options={DISCOUNT_OPTION}
                        className={`select2-selection ${
                          validation.touched.discountType &&
                          validation.errors.discountType
                            ? "input-err"
                            : ""
                        }`}
                        isDisabled={pageView === "view"}
                      />
                      {validation.touched.discountType &&
                        validation.errors.discountType && (
                          <div
                            type="invalid"
                            className="invalid-feedback d-block"
                          >
                            {validation.errors.discountType}
                          </div>
                        )}
                      {validation.touched.discountType &&
                        validation.errors.discountType && (
                          <FormFeedback type="invalid">
                            {validation.errors.discountType}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                  <Col className="col-6 mb-3">
                    <label
                      htmlFor="discountValue"
                      className="col-md-12 col-form-label"
                    >
                      Discount Value
                    </label>
                    <div className="col-md-12">
                      <Input
                        id="discountValue"
                        className="form-control"
                        type="text"
                        placeholder=""
                        name="discountValue"
                        onChange={e => {
                          const { value } = e.target
                          if (/^\d*$/.test(value)) {
                            validation.handleChange(e)
                          }
                        }}
                        onBlur={validation.handleBlur}
                        value={validation.values.discountValue}
                        invalid={
                          validation.touched.discountValue &&
                          validation.errors.discountValue
                            ? true
                            : false
                        }
                        disabled={pageView === "view"}
                      />
                      {validation.touched.discountValue &&
                        validation.errors.discountValue && (
                          <FormFeedback type="invalid">
                            {validation.errors.discountValue}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                  <Col className="col-6 mb-3">
                    <label
                      htmlFor="minSpending"
                      className="col-md-12 col-form-label"
                    >
                      Min Spending
                    </label>
                    <div className="col-md-12">
                      <Input
                        id="minSpending"
                        className="form-control"
                        type="text"
                        placeholder=""
                        name="minSpending"
                        onChange={e => {
                          const { value } = e.target
                          if (/^\d*$/.test(value)) {
                            validation.handleChange(e)
                          }
                        }}
                        onBlur={validation.handleBlur}
                        value={validation.values.minSpending}
                        invalid={
                          validation.touched.minSpending &&
                          validation.errors.minSpending
                            ? true
                            : false
                        }
                        disabled={pageView === "view"}
                      />
                      {validation.touched.minSpending &&
                        validation.errors.minSpending && (
                          <FormFeedback type="invalid">
                            {validation.errors.minSpending}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                  <Col className="col-6 mb-3">
                    <label
                      htmlFor="maxDiscount"
                      className="col-md-12 col-form-label"
                    >
                      Max Discount
                    </label>
                    <div className="col-md-12">
                      <Input
                        id="maxDiscount"
                        className="form-control"
                        type="text"
                        placeholder=""
                        name="maxDiscount"
                        onChange={e => {
                          const { value } = e.target
                          if (/^\d*$/.test(value)) {
                            validation.handleChange(e)
                          }
                        }}
                        onBlur={validation.handleBlur}
                        value={validation.values.maxDiscount}
                        invalid={
                          validation.touched.maxDiscount &&
                          validation.errors.maxDiscount
                            ? true
                            : false
                        }
                        disabled={pageView === "view"}
                      />
                      {validation.touched.maxDiscount &&
                        validation.errors.maxDiscount && (
                          <FormFeedback type="invalid">
                            {validation.errors.maxDiscount}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                  <Col className="col-6 mb-3">
                    <label
                      htmlFor="discountApplyAt"
                      className="col-md-12 col-form-label"
                    >
                      Discount Apply At
                    </label>
                    <div className="col-md-12">
                      <Select
                        placeholder="Select Coupon Code Type"
                        value={fn_helper.FNFORM.getObjectValue(
                          DISCOUNT_APPLY_OPTION,
                          `${validation.values.discountApplyAt}`
                        )}
                        onChange={value =>
                          validation.setFieldValue("discountApplyAt", value.id)
                        }
                        getOptionValue={option => option.id}
                        getOptionLabel={option => option.name}
                        options={DISCOUNT_APPLY_OPTION}
                        className={`select2-selection ${
                          validation.touched.discountApplyAt &&
                          validation.errors.discountApplyAt
                            ? "input-err"
                            : ""
                        }`}
                        isDisabled={pageView === "view"}
                      />
                      {validation.touched.discountApplyAt &&
                        validation.errors.discountApplyAt && (
                          <div
                            type="invalid"
                            className="invalid-feedback d-block"
                          >
                            {validation.errors.discountApplyAt}
                          </div>
                        )}
                      {validation.touched.discountApplyAt &&
                        validation.errors.discountApplyAt && (
                          <FormFeedback type="invalid">
                            {validation.errors.discountApplyAt}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                  <Col className="col-6 mb-3"></Col>
                  <Col className="col-6 mb-3">
                    <label
                      htmlFor="userType"
                      className="col-md-12 col-form-label"
                    >
                      Member Type
                    </label>
                    <div className="col-md-12">
                      <Select
                        placeholder="Select Member Type"
                        value={fn_helper.FNFORM.getObjectValue(
                          USER_TYPE_OPTION,
                          `${validation.values.userType}`
                        )}
                        onChange={value =>
                          validation.setFieldValue("userType", value.id)
                        }
                        getOptionValue={option => option.id}
                        getOptionLabel={option => option.name}
                        options={USER_TYPE_OPTION}
                        className={`select2-selection ${
                          validation.touched.userType &&
                          validation.errors.userType
                            ? "input-err"
                            : ""
                        }`}
                        isDisabled={pageView === "view"}
                      />
                      {validation.touched.userType &&
                        validation.errors.userType && (
                          <div
                            type="invalid"
                            className="invalid-feedback d-block"
                          >
                            {validation.errors.userType}
                          </div>
                        )}
                      {validation.touched.userType &&
                        validation.errors.userType && (
                          <FormFeedback type="invalid">
                            {validation.errors.userType}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                  <Col className="col-6 mb-3">
                    <label
                      htmlFor="userGroup"
                      className="col-md-12 col-form-label"
                    >
                      Member Group
                    </label>
                    <div className="col-md-12">
                      <Select
                        placeholder="Select Coupon Code Type"
                        value={fn_helper.FNFORM.getObjectValue(
                          USER_GROUP_OPTION,
                          `${validation.values.userGroup}`
                        )}
                        onChange={value =>
                          validation.setFieldValue("userGroup", value.id)
                        }
                        getOptionValue={option => option.id}
                        getOptionLabel={option => option.name}
                        options={USER_GROUP_OPTION}
                        className={`select2-selection ${
                          validation.touched.userGroup &&
                          validation.errors.userGroup
                            ? "input-err"
                            : ""
                        }`}
                        isDisabled={pageView === "view"}
                      />
                      {validation.touched.userGroup &&
                        validation.errors.userGroup && (
                          <div
                            type="invalid"
                            className="invalid-feedback d-block"
                          >
                            {validation.errors.userGroup}
                          </div>
                        )}
                      {validation.touched.userGroup &&
                        validation.errors.userGroup && (
                          <FormFeedback type="invalid">
                            {validation.errors.userGroup}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                  <Col className="col-6 mb-3">
                    <label
                      htmlFor="applicableToOtherCoupon"
                      className="col-md-12 col-form-label"
                    >
                      Applicable To Other Coupon
                    </label>
                    <InputSwitch
                      labelTrue="Yes"
                      labelFalse="No"
                      value={validation.values.applicableToOtherCoupon == "1"}
                      onChange={value => {
                        const updateValue = value ? "1" : "0"
                        validation.setFieldValue(
                          "applicableToOtherCoupon",
                          updateValue
                        )
                      }}
                      disabled={pageView === "view"}
                    />
                  </Col>
                  <Col className="col-6 mb-3">
                    <label
                      htmlFor="applicableToOtherPromotion"
                      className="col-md-12 col-form-label"
                    >
                      Applicable To Other Promotion
                    </label>
                    <InputSwitch
                      labelTrue="Yes"
                      labelFalse="No"
                      value={
                        validation.values.applicableToOtherPromotion == "1"
                      }
                      onChange={value => {
                        const updateValue = value ? "1" : "0"
                        validation.setFieldValue(
                          "applicableToOtherPromotion",
                          updateValue
                        )
                      }}
                      disabled={pageView === "view"}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </>
      ),
    },
    {
      stepLabel: "Coupon Product",
      components: (
        <>
          <Col className="col-12">
            <Row className="">
              <Col className="col-12">
                <Row className="">
                  <Col className="col-12">
                    <h5 className="mb-3">Coupon Detail</h5>
                  </Col>
                  <Col className="col-6 mb-3">
                    <label
                      htmlFor="applicableToAllProduct"
                      className="col-md-12 col-form-label"
                    >
                      Applicable To All Product
                    </label>
                    <InputSwitch
                      labelTrue="Yes"
                      labelFalse="No"
                      value={validation.values.applicableToAllProduct == "1"}
                      onChange={value => {
                        const updateValue = value ? "1" : "0"
                        validation.setFieldValue(
                          "applicableToAllProduct",
                          updateValue
                        )
                        validation.setFieldValue("sku", "")
                        validation.setFieldValue("categoryId", "")
                        validation.setFieldTouched("sku", false)
                        validation.setFieldTouched("categoryId", false)
                        validation.setFieldValue("selectedContentId", [])
                      }}
                      disabled={
                        pageView === "view" ||
                        validation.values.couponCodeType == "2"
                      }
                    />
                  </Col>
                  <Col className="col-6 mb-3"></Col>
                  {validation.values.applicableToAllProduct == "0" && (
                    <>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="selectProductBy"
                          className="col-md-12 col-form-label"
                        >
                          Select Product By
                        </label>
                        <div className="col-md-12">
                          <Select
                            placeholder="Select Coupon Code Type"
                            value={fn_helper.FNFORM.getObjectValue(
                              PRODUCT_BY_OPTION,
                              `${validation.values.selectProductBy}`
                            )}
                            onChange={value => {
                              validation.setFieldValue(
                                "selectProductBy",
                                value.id
                              )
                              validation.setFieldValue("selectedContentId", [])
                            }}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.name}
                            options={PRODUCT_BY_OPTION}
                            className={`select2-selection ${
                              validation.touched.selectProductBy &&
                              validation.errors.selectProductBy
                                ? "input-err"
                                : ""
                            }`}
                            isDisabled={
                              pageView === "view" ||
                              validation.values.couponCodeType == "2"
                            }
                          />
                          {validation.touched.selectProductBy &&
                            validation.errors.selectProductBy && (
                              <div
                                type="invalid"
                                className="invalid-feedback d-block"
                              >
                                {validation.errors.selectProductBy}
                              </div>
                            )}
                          {validation.touched.selectProductBy &&
                            validation.errors.selectProductBy && (
                              <FormFeedback type="invalid">
                                {validation.errors.selectProductBy}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>
                      <Col className="col-6 mb-3"></Col>
                      {validation.values.selectProductBy == "1" ? (
                        <>
                          <Card>
                            <CardBody>
                              <CardTitle className="h4">List</CardTitle>
                              <div className="d-flex justify-content-between align-items-center">
                                <p className="card-title-desc">
                                  (Selected Products)
                                </p>
                                {pageView != "view" && (
                                  <div className="flex-shrink-0 d-flex gap-2">
                                    <a
                                      className="btn btn-info"
                                      onClick={() =>
                                        validation.setFieldValue(
                                          "selectedContentId",
                                          []
                                        )
                                      }
                                    >
                                      {" "}
                                      Clear Selected All
                                    </a>
                                  </div>
                                )}
                              </div>
                              <Col className="col-12 mb-3">
                                <TableContainer
                                  noTop={true}
                                  tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                                  columns={columns}
                                  data={contentList.filter(item =>
                                    validation.values.selectedContentId.includes(
                                      item.id
                                    )
                                  )}
                                />
                              </Col>
                            </CardBody>
                          </Card>
                          <Row>
                            <Col className="col-12">
                              <h5 className="mb-3">Product List</h5>
                            </Col>
                            <TableContainer
                              isAddButton={false} // Set to true if you want an add button
                              columns={columns} // Ensure `columns` is defined with your required structure
                              data={contentList || []} // Adjust to match the data for your attribute list
                              isGlobalFilter={true} // Set true if you need a global filter for the table
                              isPagination={true}
                              SearchPlaceholder="Search"
                              pagination="pagination"
                              paginationWrapper="dataTables_paginate paging_simple_numbers"
                              tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                              rootMenu="systemsettings"
                              subMenu="usersetting"
                              isCustomPageSize={true}
                            />
                          </Row>
                        </>
                      ) : (
                        <>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Select Category
                            </label>
                            <div className="col-md-12">
                              <CategorySelector
                                placeholder="Select Category"
                                isMulti={true}
                                onBlur={validation.handleBlur}
                                value={validation.values.selectedContentId}
                                onChange={(newValue = []) => {
                                  let updateValue = newValue ? newValue : []
                                  validation.setFieldValue(
                                    "selectedContentId",
                                    updateValue
                                  )
                                }}
                                isClearable={true}
                                invalid={
                                  validation.touched.categoryIds &&
                                  validation.errors.categoryIds
                                    ? true
                                    : false
                                }
                                treeOption={ddCategory}
                                errorText={validation.errors.categoryIds}
                                disabled={pageView === "view"}
                              />
                            </div>
                          </Col>
                        </>
                      )}
                    </>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
        </>
      ),
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} Coupon`}
            breadcrumbItems={[
              { title: "Coupon", link: "banner/banner-platform" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  console.log("Errors:", validation)
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <Col className="col-12">
                        <Row className="mb-3">
                          <Col className="col-12">
                            <h5 className="mb-3">Coupon Category</h5>
                            <Stepper
                              value={activeTab}
                              componentSteps={STEP_CONTENT}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "8px",
                      }}
                    >
                      <button
                        type="button"
                        className="btn btn-primary w-md"
                        onClick={() => {
                          setActiveTab(activeTab - 1)
                        }}
                        disabled={activeTab === 0}
                      >
                        Previous
                      </button>
                      {activeTab === 0 && (
                        <>
                          {pageView == "view" ? (
                            <>
                              <button
                                type="button"
                                className="btn btn-primary w-md"
                                onClick={() => onStep1()}
                              >
                                Next
                              </button>
                              <button
                                className="btn btn-primary w-md"
                                onClick={() =>
                                  navigate("/coupon-management/coupon")
                                }
                              >
                                BACK
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                type="button"
                                className="btn btn-primary w-md"
                                onClick={() => onStep1()}
                              >
                                Next
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary w-md"
                                onClick={() =>
                                  navigate("/coupon-management/coupon")
                                }
                              >
                                CANCEL
                              </button>
                            </>
                          )}
                        </>
                      )}
                      {activeTab === 1 && (
                        <>
                          {pageView == "view" ? (
                            <>
                              <button
                                type="button"
                                className="btn btn-primary w-md"
                                onClick={() => onStep2()}
                              >
                                Next
                              </button>
                              <button
                                className="btn btn-primary w-md"
                                onClick={() =>
                                  navigate("/coupon-management/coupon")
                                }
                              >
                                BACK
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                type="button"
                                className="btn btn-primary w-md"
                                onClick={() => onStep2()}
                              >
                                Next
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary w-md"
                                onClick={() =>
                                  navigate("/coupon-management/coupon")
                                }
                              >
                                CANCEL
                              </button>
                            </>
                          )}
                        </>
                      )}
                      {activeTab === 2 && (
                        <>
                          {pageView == "view" ? (
                            <>
                              <button
                                className="btn btn-primary w-md"
                                onClick={() =>
                                  navigate("/coupon-management/coupon")
                                }
                              >
                                BACK
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                type="submit"
                                className="btn btn-primary w-md"
                              >
                                SAVE
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary w-md"
                                onClick={() =>
                                  navigate("/coupon-management/coupon")
                                }
                              >
                                CANCEL
                              </button>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(CouponTemplate)
