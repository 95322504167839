import React, { useEffect, useState, useContext, useRef } from "react"

import {
    Col,
    Row,
    Card,
    CardBody,
    // CardTitle,
    // CardSubtitle,
    // CardImg,
    // CardText,
    // CardHeader,
    // CardImgOverlay,
    // CardFooter,
    // CardDeck,
    Container,
    Label,
    Input,
    InputGroup,
    Form,
    FormFeedback,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Button,
    Spinner,
    CardFooter,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../../store/actions"
import {
    createQuestionaireCategory,
    createQuestionaireCategoryReset,
} from "../../../store/actions"


//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import { CreateJobApplication, GetJobApplicationById, UpdateJobApplication } from "services/api/module/JobApplication"
import ModalContext from "../../../contexts/ModalContext";
import { DropdownJobType, DropdownJobPosition } from "services/api/module/Dropdown"
import Flatpickr from "react-flatpickr";
import moment from "moment"
import UploadFileAPI from "components/UploadFileAPI"
import InputRadio from "components/Input/InputRadio"
import InputSwitch from "components/Input/InputSwitch"
import MyEditor from "../../../components/MyEditor"
import Spinners from "components/Common/Spinner";
import Dropzone from "react-dropzone"

import xlsIcon from 'assets/icon/xls.png'
import xlsxIcon from 'assets/icon/xlsx.png'

const installmentImportTemplate = ({
    pageView = "create"
}) => {
    // POPUP
    const { mAlert } = useContext(ModalContext);
    const [messages, setMessages] = useState([]);

    // OTHER STATE
    const qParams = useParams()
    const navigate = useNavigate();

    // ========== Form Validation ========== //
    const MAX_FILE_SIZE = 10 * 1024 * 1024 // 10 MB file size limit
    const SUPPORTED_FORMATS = ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];

    const [selectedFiles, setselectedFiles] = useState([]);
    function handleAcceptedFiles(files) {
        files.map((file) => {
            // Extract extension by splitting on the '.' and taking the last segment
            const extension = file.name.split('.').pop();
            let fileIcon = ''
            switch (extension) {
                case 'xls':
                    fileIcon = xlsIcon
                    break;
                case 'xlsx':
                    fileIcon = xlsxIcon
                    break;

                default:
                    fileIcon = xlsxIcon
                    break;
            }
            return Object.assign(file, {
                preview: fileIcon,
                formattedSize: formatBytes(file.size),
            })
        }
        )
        console.log(`files >>>>> `, files)
        setselectedFiles(files)
        validation.setFieldValue(`files`, files)
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    const validationSchema = Yup.object().shape({
        files: Yup.array()
            .of(
                Yup.mixed()
                    .test("fileSize", "File size is too large", (file) => {
                        // Check if file exists (user might not have dropped anything yet)
                        if (!file) return false;
                        return file.size <= MAX_FILE_SIZE;
                    })
                    .test("fileFormat", "Unsupported file format", (file) => {
                        if (!file) return false;
                        return SUPPORTED_FORMATS.includes(file.type);
                    })
            )
            .min(1, "At least one file is required.")
            .max(10, "You cannot upload more than 10 files.")
            .required("File(s) are required."),
    })

    // LOCAL STATE FORM
    const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)

    // LOCAL STATE DROPDOWN
    const DEF_LOADED = pageView === "create" ? true : false

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            // file: null,
        },
        validationSchema: updatedValidationSchema,
        onSubmit: values => {
            console.log("🚀 ~ installmentImportTemplate ~ values:", selectedFiles)

            if (selectedFiles.length > 0) {
                API.fetchUploadFiles(selectedFiles)
            }
        },
    })

    const { errors } = validation;

    const API = {
        fetchUploadFiles: async (files) => {
            try {
                let isCancelled = false;
                const formData = new FormData();

                // Append files to the FormData object
                for (const file of selectedFiles) {
                    formData.append("files", file);
                }

                try {
                    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/admin/installmentImport`, {
                        method: "POST",
                        credentials: 'include',
                        body: formData,
                    });

                    console.log(`response >>>>> `, response)

                    if (!response.ok) {
                        mAlert.info({
                            title: "Alert",
                            subTitle: "Something went wrong",
                            content: ``,
                            onClose: () => {
                                //  alert("onClose")
                            },
                        })
                    }

                    const json = await response.json();

                    setMessages(json.data)
                    setselectedFiles([])
                } catch (err) {
                    console.error('Error reading stream:', err);
                    mAlert.info({
                        title: "Alert",
                        subTitle: "Something went wrong",
                        content: `Error reading stream: ${err}`,
                        onClose: () => {
                            //  alert("onClose")
                        },
                    })
                }
            }
            catch (err) {
                console.log('err ', err)
                mAlert.info({
                    title: "Alert",
                    subTitle: "Something went wrong",
                    content: `Error: ${err}`,
                    onClose: () => {
                        //  alert("onClose")
                    },
                })
            }
        },
    }

    document.title = `Installment Plan | Import | ` + process.env.REACT_APP_CMS_TITLE

    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid={true}>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title={`Installment Plan Import`}
                        breadcrumbItems={[
                            { title: "Installment Plan", link: "installment-plan/import" },
                            { title: `Import` },
                        ]}
                    />
                    <Row>
                        <Col>
                            <Row>
                                {console.log(errors)}
                            </Row>
                            <Form
                                className="form-horizontal"
                                onSubmit={e => {
                                    e.preventDefault()
                                    validation.handleSubmit()
                                    return false
                                }}
                            >
                                <Card>
                                    <CardBody>
                                        <Row className="">
                                            <h5 className="mb-3">Installment Plans Import</h5>
                                        </Row>
                                        <Row className="mb-3">

                                            <Col className="col-12 text-end">
                                                <Row>
                                                    <Col col-sm="4">
                                                        <React.Fragment >
                                                            <a className="btn btn-success float-end"
                                                                href="/TemplateFile.xlsx"
                                                                target="_blank" rel="noopener noreferrer" download >Download Template</a>
                                                        </React.Fragment>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={12}>

                                                <Dropzone
                                                    onDrop={acceptedFiles => {
                                                        handleAcceptedFiles(acceptedFiles)
                                                    }}
                                                    maxFiles={10}
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <div className="dropzone">
                                                            <div
                                                                className="dz-message needsclick mt-2"
                                                                {...getRootProps()}
                                                            >
                                                                <input {...getInputProps()} />
                                                                <div className="mb-3">
                                                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                </div>
                                                                <h4>Drop files here or click to upload.</h4>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Dropzone>
                                                {errors.files && <div style={{ color: "red" }}>{errors.files}</div>}
                                                <div className="dropzone-previews mt-3" id="file-previews">
                                                    {selectedFiles.map((f, i) => {
                                                        return (
                                                            <Card
                                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                key={i + "-file"}
                                                            >
                                                                <div className="p-2">
                                                                    <Row className="align-items-center">
                                                                        <Col className="col-auto">
                                                                            <img
                                                                                data-dz-thumbnail=""
                                                                                height="80"
                                                                                className="avatar-sm rounded bg-light"
                                                                                alt={f.name}
                                                                                src={f.preview}
                                                                            />
                                                                        </Col>
                                                                        <Col>
                                                                            <Link
                                                                                to="#"
                                                                                className="text-muted font-weight-bold"
                                                                            >
                                                                                {f.name}
                                                                            </Link>
                                                                            <p className="mb-0">
                                                                                <strong>{f.formattedSize}</strong>
                                                                            </p>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Card>
                                                        )
                                                    })}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="">
                                            <h5 className="mb-3">Import Output</h5>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <div id={`import-logs`} style={{
                                                    border: '2px dashed #eff2f7'
                                                }} dangerouslySetInnerHTML={{
                                                    __html: messages
                                                }}></div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <div style={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
                                            <button type="submit" className="btn btn-primary w-md" >SAVE</button>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };
export default withTranslation()(installmentImportTemplate)
