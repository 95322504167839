import { get, post, put, patch } from "../Config"

export function SearchPromotionMain({ params = {} }) {
  return get({ url: `/v1/admin/promotionMain`, params })
}
export function GetPromotionMainById({ id }) {
  return get({ url: `/v1/admin/promotionMain/${id}` })
}
export function CreatePromotionMain({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/promotionMain`, params, data })
}
export function UpdatePromotionMain({ params = {}, data = {}, id }) {
  return put({ url: `/v1/admin/promotionMain/${id}`, params, data })
}
export function PatchPromotionMain({ params = {}, data = {}, id }) {
  return patch({ url: `/v1/admin/promotionMain/${id}`, params, data })
}
export function DeletePromotionMain({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/promotionMain/mutiDelete`, params, data })
}
