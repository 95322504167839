import { get } from "../Config"

export function DropdownEnumBannnerPlatform({ params = {} }) {
  return get({ url: `/v1/admin/dropdownEnum/bannnerPlatform`, params })
}

export function DropdownEnumBannnerPage({ params = {} }) {
  return get({ url: `/v1/admin/dropdownEnum/bannnerPage`, params })
}

export function DropdownEnumPromotionTheme({ params = {} }) {
  return get({ url: `/v1/admin/dropdownEnum/promotionTheme`, params })
}

export function DropdownEnumPaymentCreditDebit({ params = {} }) {
  return get({ url: `/v1/admin/dropdownEnum/paymentCreditDebit`, params })
}

export function DropdownEnumInstallmentPlans({ params = {} }) {
  return get({ url: `/v1/admin/dropdownEnum/installmentPlans`, params })
}
