import React, { useEffect, useState, useContext } from "react";
import { Card, Form, CardBody, CardSubtitle, Container, Spinner } from "reactstrap"
import { Col, Row } from "reactstrap"
import Dropzone from "react-dropzone";
import moment from "moment";
import ModalContext from "contexts/ModalContext";
import helper from "helpers/fn_helper";
import { UploadFile } from "services/api/module/Upload";
import axios from "axios";
import Spinners from "./Common/Spinner";
import fn_helper from "helpers/fn_helper";
import styled from "styled-components"
import { TableLoading } from "./TableComponents";

const ImageShow = styled.img`
  height: 60px;
  width: auto;
  max-width: 200px;
`

const UploadFileAPIMuti = ({
  label = "Dropzone",
  description = "description",
  required = false,
  onChange = () => console.log('onChange!'),
  prefixName = "broadcast",
  value = null,
  upload = "Image",
  typeUpload = "",
  widthSize = 0,
  heightSize = 0,
  bucketName = 'other',
  addOnly = false,
  pageView = '',
  isError = false
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);
  const [firstLoad, setFirstLoad] = useState(false);
  const [fileDataList, setFileDataList] = useState([]);
  const [fileURL, setFileURL] = useState(null);
  const [loadingImage, setLoadingImage] = useState(false);
  const [loadingImageFull, setLoadingImageFull] = useState(false);
  

async function handleAcceptedFiles(files) {
  console.log("🚀 ~ handleAcceptedFiles ~ files:", files);

  // ตั้งค่าค่าเริ่มต้นของประเภทและขนาดไฟล์
  let CONF_FILE_TYPE = ['png', 'jpg', 'jpeg'];
  let CONF_FILE_SIZE = 1;

  // เปลี่ยนค่าตามประเภทการอัพโหลด (Video, PDF)
  switch (upload) {
    case "Video":
      CONF_FILE_TYPE = ['mp4'];
      CONF_FILE_SIZE = 50;
      break;
    case "PDF":
      CONF_FILE_TYPE = ['pdf'];
      CONF_FILE_SIZE = 50;
      break;
  }

  // ฟังก์ชันตรวจสอบไฟล์เดี่ยว
  async function validateFile(file) {
    const fileName = file.name ?? "Unknown File";
    const fileType = fileName.split('.').pop().toLowerCase();
    const fileSizeMB = (file.size / 1024 / 1024).toFixed(2); // แปลงเป็น MB (2 ตำแหน่ง)
    const filePath = URL.createObjectURL(file); // สร้าง URL ของไฟล์

    // ตรวจสอบประเภทไฟล์
    if (!CONF_FILE_TYPE.includes(fileType)) {
      return { status: "error", reason: `❌ Invalid file type`, file: { name: fileName, size: fileSizeMB, url: filePath, fileData: file } };
    }

    // ตรวจสอบขนาดไฟล์
    if (fileSizeMB > CONF_FILE_SIZE) {
      return { status: "error", reason: `❌ File size exceeds ${CONF_FILE_SIZE} MB`, file: { name: fileName, size: fileSizeMB, url: filePath, fileData: file } };
    }

    // ตรวจสอบความละเอียด (เฉพาะ ImageMap)
    if (typeUpload === "ImageMap") {
      const { width, height } = await helper.FNFILE.getImageDimensions(file);
      if (width !== widthSize || height !== heightSize) {
        return { status: "error", reason: `❌ Resolution must be ${widthSize}x${heightSize}`, file: { name: fileName, size: fileSizeMB, url: filePath, fileData: file } };
      }
    }

    return { status: "valid", file: { name: fileName, size: fileSizeMB, url: filePath, fileData: file },  };
  }

  // 📌 กรณีไฟล์เดียว
  if (files.length === 1) {
    const validation = await validateFile(files[0]);
    if (validation.status === "valid") {
      let tmpUpdate = _.cloneDeep(fileDataList); // คัดลอกข้อมูลไฟล์ก่อนหน้า
      tmpUpdate.push(files[0]); // เพิ่มไฟล์ใหม่ลงไป
      setFileDataList(tmpUpdate); // อัปเดตรายการไฟล์
    } else {
      mAlert.info({
        title: "Alert",
        subTitle: `${validation.reason}\n📁 File: ${validation.file.name} (${validation.file.size} MB)\n`,
        content: "",
        onClose: () => {},
      });
    }
    return;
  }

  // 📌 กรณีหลายไฟล์
  const validations = await Promise.all(files.map(validateFile));

  // แยกไฟล์เป็น 2 กลุ่ม: ผ่าน และ ไม่ผ่าน
  const filePass = validations.filter(v => v.status === "valid").map(v => v.file);
  const fileError = validations.filter(v => v.status === "error").map(v => v.file);

  console.log('✅ ไฟล์ที่ผ่าน:', filePass);
  console.log('❌ ไฟล์ที่ผิดพลาด:', fileError);

  // รวมไฟล์ที่ผ่าน + ไฟล์ที่เลือกไว้ก่อนหน้า
  let updatedFileList = filePass.map(item => item.fileData);

  // แจ้งเตือนไฟล์ที่ผิดพลาด
  if (fileError.length > 0) {
    
    mAlert.info({
      type:"confrim",
      title: "Alert",
      content: (
        <div>
          <p>✅ File Pass</p>
          <div className="dropzone-previews mt-3" style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: 8 }}>
            {filePass.map((item, i) => (
              <FilePreviewCard
                key={i}
                file={item}
                index={i}
                onDelete={(index) => {}}
                disabled={pageView === "view"}
              />
            ))}
          </div>
          <br />
          <br />
          <p>❌ File Error</p>
          <div className="dropzone-previews mt-3" style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: 8 }}>
            {fileError.map((item, i) => (
              <FilePreviewCard
                key={i}
                file={item}
                index={i}
                onDelete={(index) => {}}
                disabled={pageView === "view"}
              />
            ))}
          </div>
        </div>
      ),
      onYes: () => {
        console.log('updatedFileList >>', updatedFileList)
        alert('onYes');
        setFileDataList(updatedFileList); // ✅ อัปเดตรายการไฟล์ที่ผ่านทั้งหมด
      },
      onNo: () => {
        alert('onNo');
      },
    });
  } else {
    // ถ้าไม่มีไฟล์ผิดพลาด ให้อัปเดตไฟล์ที่ผ่านทันที
    setFileDataList(updatedFileList);
  }
}

  

  const uploadFile = async (_fileData = {}) => {
    return new Promise(async (resolve, reject) => {
      const tmpfilename = _fileData?.name ?? '.png'
      const tmpTypeFile = `${tmpfilename}`.split('.')
      const typeFile = tmpTypeFile[tmpTypeFile.length - 1]

      function getRandomLetters(count) {
        const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        return Array.from({ length: count }, () => alphabet[Math.floor(Math.random() * alphabet.length)]).join('');
      }
      const timestamp = moment().unix();
      const fileName = `${prefixName}-${getRandomLetters(4)}-${timestamp}.${typeFile}`
    
      // const FILE_LINK = HOST_FILE + `/` + fileName
      setLoadingImage(true)
      const FILE_LINK = await fetchUploadFile(_fileData, fileName, bucketName)
      setTimeout(() => setLoadingImage(false), 100)

      const tmpData = {
        url: FILE_LINK,
        name: _fileData.name,
        size: _fileData.size,
        sequence: null
      }

      resolve(tmpData)
    });
  };

  const fetchUploadFile = async (selectedFile, fileName = '', _bucketName = '') => {
    const formData = new FormData();
    formData.append('file', selectedFile);

    return new Promise(async (resolve, reject) => {
      try {
        const response = await UploadFile({ formData: formData, bucketName: _bucketName, fileName: fileName })
        const resData = response.data
        if (resData.status === 201) {
          console.log('resData >>', resData)
          let fetching = true
          while (fetching) {
            try {
              const resImg = await fetch(resData?.data?.url) // Fetch Data Free
              console.log("🚀 ~ returnnewPromise ~ resImg:", resImg)
              fetching = false
              break;
            } catch(e) {
              console.log('e fetch img', e)
            }
            await fn_helper.FN.delayTime(500)
          }
          resolve(resData?.data?.url ?? null)
        }
      } catch (e) {
          console.log(e)
          resolve(null)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    })
    
  }

  const onClearState = () => {
    setFileDataList([])
    setFirstLoad(false)
    onChange("")
  }

  useEffect(() => {
    const uploadFiles = async () => {

      if (fileDataList.length > 0) {
        setLoadingImageFull(true)
        let fileList = []
        for (let fd of fileDataList) {
          console.log("🚀 ~ uploadFiles ~ fd: ", fd)
          const file = await uploadFile(fd)
          fileList.push(file)
        }
        console.log('uploadFiles fileList >>', fileList)
        onChange(fileList)
        setTimeout(() => onClearState(), 200)
        setLoadingImageFull(false)
      }
    }
    uploadFiles()
    
  }, [fileDataList])



  let acceptFile = {
    'image/jpeg': ['.jpeg', '.jpg'], // รองรับไฟล์ JPEG
    'image/png': ['.png'],           // รองรับไฟล์ PNG
    'image/gif': ['.gif'],           // รองรับไฟล์ GIF
  }

  description = 'File type png, jpg, jpeg only, Max filesize 10 MB '
  if (upload == "Video") {
    acceptFile = { 'video/*': ['.mp4'] }
    description = 'File type mp4 only, Max file size 50 MB'
  }

  if (upload == "PDF") {
    acceptFile = { 'application/pdf': ['.pdf'] }
    description = 'File type pdf only, Max file size 50 MB'
  }

  if (typeUpload == "ImageMap") {  
    description = `File type png, jpg, jpeg only, Max filesize 10 MB`
    if (widthSize) {
      description += `, File resolution `
      description += `${widthSize} x `
    }
    if (heightSize) {
      description += `${heightSize}`
    }
  }
  addOnly = true

  return (
    <React.Fragment>
      <Card >
        <div>
          <h6 className="card-title">
            {label}
            {required ? <span className="t-require">*</span> : <></> }
          </h6>
          <CardSubtitle className="mb-3">
            {description}
          </CardSubtitle>
          
          <div>
              {
                (fileDataList || firstLoad  ) && addOnly === false? (
                  <>
                      {
                        upload === "Image" ? (
                          <div className="dropzone bImageBox">
                            {
                              loadingImage
                              ? <Spinner color="primary" className='position-absolute top-50 start-50' />
                              : <img data-dz-thumbnail="" src={fileURL}/>
                            }
                            {/* {fileURL} <br/> */}
                            {/* {JSON.stringify(fileData)} */}
                          </div>
                        )
                        : 
                        upload === "Video" ? (
                          <div className="dropzone bImageBox">
                            <div className="bVideoUpload">
                              <div className="bx bx-video-recording bx-lg"></div>
                              <h4>Video File</h4>
                            </div>
                          </div>
                        )
                        : 
                        upload === "PDF" ? (
                          <div className="dropzone bImageBox">
                            <div className="bPDFUpload">
                              <div className="bx bxs-file-pdf bx-lg"></div>
                              <h4>PDF File</h4>
                            </div>
                          </div>
                        )
                        : <>NULL</>

                    }
                    {
                      !['view'].includes(pageView) && (
                        <div className="mt-4">
                          <button type="button" className="btn btn-danger " onClick={onClearState}>
                            Delete
                          </button>
                        </div>
                      )
                    }
                  </>
                ) :
                <>
                  <Dropzone
                    onDrop={acceptedFiles => {
                      handleAcceptedFiles(acceptedFiles)
                    }}
                    accept= {acceptFile}
                    multiple={true}
                    disabled={pageView === "view" || loadingImageFull}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className={`dropzone ${isError ? 'err' : ''}`}>
                        <div
                          className="dz-message needsclick mt-2"
                          {...getRootProps()}
                        >
                            {
                              loadingImageFull
                              ? <table style={{ width: "100%", height: '230px'}}>
                                <tbody>
                                  <TableLoading colSpan={1} style={{ height: '230px' }} />
                                </tbody>
                              </table>
                              : <>
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Drop {upload} file here or click to upload.</h4>
                              </>
                            }
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </>
              }
          </div>
        </div>
      </Card>
    </React.Fragment>
  )
}

export default UploadFileAPIMuti


const FilePreviewCard = ({ file, index, onDelete, disabled = false }) => {

  return (
    <Card
      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete upload-item"
      
      key={index + "-file"}
      data-main-id={index}
      data-sort-order={file?.sequence ? file.sequence :null}
    >
      <div className="p-2">
        <Row className="align-items-center">
          <Col className="col-auto">
            <ImageShow
              alt={file.name}
              src={file.url}
            />
          </Col>
          <Col>
            <div className="text-muted font-weight-bold">
              {file.name}
            </div>
            <p className="mb-0">
              <strong>{file.size}</strong>
            </p>
          </Col>
          <Col sm={1}>
          </Col>
        </Row>
      </div>
    </Card>
  )
}