import { get, post, put, patch } from "../Config"

export function SearchComputerSet({ params = {} }) {
  return get({ url: `/v1/admin/computer-set`, params })
}
export function GetComputerSetById({ id }) {
  return get({ url: `/v1/admin/computer-set/${id}` })
}
export function CreateComputerSet({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/computer-set`, params, data })
}
export function UpdateComputerSet({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/computer-set/${id}`, params, data })
}
export function PatchComputerSet({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/computer-set/${id}`, params, data })
}
export function DeleteComputerSet({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/computer-set/mutiDelete`, params, data })
}
