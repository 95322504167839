import React, { useEffect, useState, useContext } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import {
  CreateCommercialProduct,
  GetCommercialProductById,
  UpdateCommercialProduct,
} from "services/api/module/CommercialProduct"
import ModalContext from "../../contexts/ModalContext"
import UploadFileAPI from "components/UploadFileAPI"
import MyEditor from "components/MyEditor"
import { DropdownProductCategory } from "services/api/module/Dropdown"

const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const CommercialProductTemplate = ({ pageView = "create" }) => {
  // POPUP
  const { mAlert } = useContext(ModalContext)

  // OTHER STATE
  const qParams = useParams()
  const qCommercialProductId = qParams.id
  const navigate = useNavigate()

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    nameTh: Yup.string().required("Please Enter Name TH"),
    nameEn: Yup.string().required("Please Enter Name EN"),
    status: Yup.string().max(100).required("Please Enter Status"),
    // image: Yup.string().required("Please Enter Image"),
    bannerImageDesktop: Yup.string().required("Please Enter Image"),
    bannerImageMobile: Yup.string().required("Please Enter Image"),
    fileUpload: Yup.string().required("Please Enter File Upload"),
    categories: Yup.array().min(1, "Please select at least one category"),
  })

  // LOCAL STATE FORM
  const [ddProductCate, setDDProductCate] = useState([])
  const [updatedValidationSchema, setUpdatedValidationSchema] =
    useState(validationSchema)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      nameTh: "",
      nameEn: "",
      bannerImageDesktop: "",
      bannerImageMobile: "",
      fileUpload: "",
      status: "",
      categories: [],
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ CommercialProductCreate ~ values:", values)
      const cateId = values.categories.map(item => item.value)
      const payload = {
        id: values.id ? values.id : undefined,
        nameTh: values.nameTh,
        nameEn: values.nameEn,
        status: Number(values.status),
        bannerImageDesktop: values.bannerImageDesktop,
        bannerImageMobile: values.bannerImageMobile,
        fileUpload: values.fileUpload,
        categories: cateId,
      }
      console.log("🚀 ~ onSave ~ payload:", payload)

      if (pageView === "update") {
        API.fetchUpdateCommercialProduct(payload)
      } else {
        API.fetchCreateCommercialProduct(payload)
      }
    },
  })

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    console.log("resData.categoryId:", resData.categoryId)
    console.log("ddProductCate:", ddProductCate)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("nameTh", resData.nameTh)
    validation.setFieldValue("nameEn", resData.nameEn)
    validation.setFieldValue("bannerImageDesktop", resData.bannerImageDesktop)
    validation.setFieldValue("bannerImageMobile", resData.bannerImageMobile)
    validation.setFieldValue("fileUpload", resData.fileUpload)
    validation.setFieldValue("status", resData.status)
    const mappedCategories = (resData.categoryId || [])
      .map(id => {
        const match = ddProductCate.find(item => item.value == id)
        console.log(`Mapping id ${id}:`, match) // Debug each mapping
        return match
      })
      .filter(Boolean)
    console.log("xxx", mappedCategories)
    validation.setFieldValue("categories", mappedCategories)
  }

  const API = {
    fetchGetCommercialProductById: async payload => {
      try {
        const response = await GetCommercialProductById({ id: payload.id })
        const resData = response.data
        if (resData.status == 202) {
          preFilInput(resData.data)
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchCreateCommercialProduct: async payload => {
      try {
        const response = await CreateCommercialProduct({ data: payload })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Created Data Success",
            content: "",
            onClose: () => {
              navigate("/product-management/commercial-product")
            },
          })
        }
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchUpdateCommercialProduct: async payload => {
      try {
        const response = await UpdateCommercialProduct({
          data: payload,
          id: payload.id,
        })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              navigate("/product-management/commercial-product")
            },
          })
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchDDProductCategory: async () => {
      try {
        const response = await DropdownProductCategory({})
        const resData = response?.data ?? []
        setDDProductCate(
          resData?.data?.map(item => ({
            label: item.name,
            value: item.id,
          }))
        )
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
  }

  // กรณี Edit
  useEffect(() => {
    API.fetchDDProductCategory();
  }, [])

  useEffect(() =>{
    if (["update", "view"].includes(pageView)) {
      const payload = { id: qCommercialProductId }
      API.fetchGetCommercialProductById(payload) // Prefill data
    }
  }, [ddProductCate])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title =
    `CommercialProduct | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} CommercialProduct`}
            breadcrumbItems={[
              { title: "CommercialProduct", link: "banner/banner-platform" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody style={{ minHeight: "100vh", height: "100%" }}>
                    <Row className="mb-3">
                      <Col className="col-12">
                        <Row className="">
                          <Col className="col-12">
                            <h5 className="mb-3">Commercial Information</h5>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Status (Active/Inactive)
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Select
                                placeholder="Select Status"
                                value={fn_helper.FNFORM.getObjectValue(
                                  STATUS_OPTION,
                                  `${validation.values.status}`
                                )}
                                onChange={value =>
                                  validation.setFieldValue("status", value.id)
                                }
                                getOptionValue={option => option.id}
                                getOptionLabel={option => option.name}
                                options={STATUS_OPTION}
                                className={`select2-selection ${
                                  validation.touched.status &&
                                  validation.errors.status
                                    ? "input-err"
                                    : ""
                                }`}
                                isDisabled={pageView === "view"}
                              />
                              {validation.touched.status &&
                                validation.errors.status && (
                                  <div
                                    type="invalid"
                                    className="invalid-feedback d-block"
                                  >
                                    {validation.errors.status}
                                  </div>
                                )}
                              {validation.touched.status &&
                                validation.errors.status && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.status}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label className="col-md-12 col-form-label">
                              Category Multiple Select
                            </label>
                            <div className="col-md-12">
                              <Select
                                value={validation.values.categories}
                                isMulti={true}
                                onChange={selectedOptions => {
                                  validation.setFieldValue(
                                    "categories",
                                    selectedOptions
                                  )
                                }}
                                getOptionLabel={e => e.label} // Use 'label' for display
                                getOptionValue={e => e.value} // Use 'value' for internal tracking
                                options={ddProductCate}
                                className="select2-selection"
                                isDisabled={pageView === "view"}
                              />
                              {validation.touched.categories &&
                                validation.errors.categories && (
                                  <div className="invalid-feedback d-block">
                                    {validation.errors.categories}
                                  </div>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="nameTh"
                              className="col-md-12 col-form-label"
                            >
                              Name Th
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="nameTh"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="nameTh"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.nameTh}
                                invalid={
                                  validation.touched.nameTh &&
                                  validation.errors.nameTh
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.nameTh &&
                                validation.errors.nameTh && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.nameTh}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="nameEn"
                              className="col-md-12 col-form-label"
                            >
                              Name En
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="nameEn"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="nameEn"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.nameEn}
                                invalid={
                                  validation.touched.nameEn &&
                                  validation.errors.nameEn
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.nameEn &&
                                validation.errors.nameEn && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.nameEn}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-12 mb-3">
                            <div className="col-md-12">
                              <UploadFileAPI
                                bucketName="bannerImageDesktop"
                                pageView={pageView}
                                upload="Image"
                                typeUpload="ImageMap"
                                prefixName="banner-image-desktop"
                                label="Upload Banner Desktop"
                                required={false}
                                widthSize={808}
                                heightSize={280}
                                description="description"
                                value={validation.values.bannerImageDesktop}
                                onChange={([imageURL = "", imageSize = ""]) => {
                                  validation.setFieldValue(
                                    "bannerImageDesktop",
                                    imageURL
                                  )
                                }}
                              />
                              <Input
                                id="bannerImageDesktop"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="bannerImageDesktop"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.bannerImageDesktop}
                                invalid={
                                  validation.touched.bannerImageDesktop &&
                                  validation.errors.bannerImageDesktop
                                    ? true
                                    : false
                                }
                                readOnly={true}
                                disabled={pageView === "view"}
                              />
                              {validation.touched.bannerImageDesktop &&
                                validation.errors.bannerImageDesktop && (
                                  <div
                                    type="invalid"
                                    className="invalid-feedback d-block"
                                  >
                                    {validation.errors.bannerImageDesktop}
                                  </div>
                                )}
                            </div>
                          </Col>
                          <Col className="col-12 mb-3">
                            <div className="col-md-12">
                              <UploadFileAPI
                                bucketName="bannerImageMobile"
                                pageView={pageView}
                                upload="Image"
                                typeUpload="ImageMap"
                                prefixName="banner-image-mobile"
                                label="Upload Banner Mobile"
                                required={false}
                                widthSize={808}
                                heightSize={280}
                                description="description"
                                value={validation.values.bannerImageMobile}
                                onChange={([imageURL = "", imageSize = ""]) => {
                                  validation.setFieldValue(
                                    "bannerImageMobile",
                                    imageURL
                                  )
                                }}
                              />
                              <Input
                                id="bannerImageMobile"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="bannerImageMobile"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.bannerImageMobile}
                                invalid={
                                  validation.touched.bannerImageMobile &&
                                  validation.errors.bannerImageMobile
                                    ? true
                                    : false
                                }
                                readOnly={true}
                                disabled={pageView === "view"}
                              />
                              {validation.touched.bannerImageMobile &&
                                validation.errors.bannerImageMobile && (
                                  <div
                                    type="invalid"
                                    className="invalid-feedback d-block"
                                  >
                                    {validation.errors.bannerImageMobile}
                                  </div>
                                )}
                            </div>
                          </Col>
                          <Col className="col-12 mb-3">
                            <div className="col-md-12">
                              <UploadFileAPI
                                bucketName="file-upload"
                                pageView={pageView}
                                upload="PDF"
                                typeUpload="PDF"
                                prefixName="file-upload"
                                label="Upload .PDF file"
                                required={true}
                                widthSize={1024}
                                heightSize={1024}
                                description="description"
                                value={validation.values.fileUpload}
                                onChange={([imageURL = "", imageSize = ""]) => {
                                  validation.setFieldValue(
                                    "fileUpload",
                                    imageURL
                                  )
                                }}
                              />
                              <Input
                                id="fileUpload"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="fileUpload"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.fileUpload}
                                invalid={
                                  validation.touched.fileUpload &&
                                  validation.errors.fileUpload
                                    ? true
                                    : false
                                }
                                readOnly={true}
                                disabled={pageView === "view"}
                              />
                              {validation.touched.fileUpload &&
                                validation.errors.fileUpload && (
                                  <div
                                    type="invalid"
                                    className="invalid-feedback d-block"
                                  >
                                    {validation.errors.fileUpload}
                                  </div>
                                )}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "8px",
                      }}
                    >
                      {pageView == "view" ? (
                        <>
                          <button
                            className="btn btn-primary w-md"
                            onClick={() =>
                              navigate("/product-management/commercial-product")
                            }
                          >
                            BACK
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            SAVE
                          </button>
                          <button
                            className="btn btn-secondary w-md"
                            onClick={() =>
                              navigate("/product-management/commercial-product")
                            }
                          >
                            CANCEL
                          </button>
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(CommercialProductTemplate)
