import React, { useEffect, useState, useContext, useRef, useMemo } from "react"
import { Col, Row, Input, Form, FormFeedback } from "reactstrap"
import Select from "react-select"
import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
import { json, Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import fn_helper from "helpers/fn_helper"
import moment from "moment"
import UploadFileAPI from "components/UploadFileAPI"
import InputRadio from "components/Input/InputRadio"
import InputSwitch from "components/Input/InputSwitch"
import DialogCustom from "components/modal/DialogCustom"
import Spinners from "components/Common/Spinner"
import TableContainer from "../../../components/Common/TableContainer"

const ProductCategoryPopup = ({
  isOpen = false,
  onClose = () => console.log('onClose!'),
  onChange = () => console.log('onChange!'),
  pageView = 'create',
  contentList = [],
}) => {

  const [contentListLoading, setContentListLoading] = useState(false)
  const [selectedContentId, setSelectedContentId] = useState([])


  const columns = useMemo(
    () => [
      {
        id: "colcheckbox",
        header: cellProps => {
          const row = cellProps?.row?.original
        },
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          const row = cellProps?.row?.original
          return (
            <>
              <div
                id={cellProps.row.id}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {/* <>{JSON.stringify(selectedContentId)}</> */}
                <Input
                  type={`checkbox`}
                  value={cellProps.row.original.id}
                  checked={selectedContentId === row.id}
                  onClick={() => {
                    setSelectedContentId(row?.id)
                  }}
                  readOnly
                />
              </div>
            </>
          )
        },
      },
      {
        header: "Category Name TH",
        accessorKey: "cateNameTh",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Category Name EN",
        accessorKey: "cateNameEn",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Parent Category",
        accessorKey: "parentCateNameTh",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Logo Image",
        // accessorKey: "cateIcon",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          const { cateIcon = "" } = cellProps?.row?.original
          return <img className="bImageBox" src={cateIcon} />
        },
      },
      {
        header: "isHighlight",
        // accessorKey: "isHighlight",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          const { isHighlight } = cellProps?.row?.original; // Access parentCategory object
          return isHighlight ? "yes" : "no"; // Display cateNameTh or "N/A" if null
        },
      },
      {
        header: "Created At",
        // accessorKey: "createdAt",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          const row = cellProps?.row?.original
          return row.createdAt ? moment(row.createdAt).format("DD/MM/YYYY") : ""
        },
      },
      {
        header: "Updated At",
        // accessorKey: "updatedAt",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          const row = cellProps?.row?.original
          return row.updatedAt ? moment(row.updatedAt).format("DD/MM/YYYY") : ""
        },
      },
    ],
    [selectedContentId, contentList]
  )


  const onCancel = () => {
    console.log('onCancel')
    onClose()
  }

  useEffect(() => {
    if (isOpen) {
    }
  }, [isOpen])

  return (
    <>

      <div>
        <DialogCustom visible={isOpen} onHide={() => onClose()} onClickOut={() => onClose()} style={{ width: '800px' }}>
          <div className="modal-group">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Select Product Category</h5>
            </div>
            {/* {JSON.stringify(validation.values)} */}
            <div className="modal-body" style={{ height: '65vh' }}>
              {/* <>{JSON.stringify(contentList)}</> */}

              <div className="container-fluid">

                {contentListLoading ? (
                  <Spinners setLoading={setContentListLoading} />
                ) : (
                  <TableContainer
                    isAddButton={false}
                    addRowLink={``}
                    columns={columns}
                    data={contentList || []}
                    isGlobalFilter={true}
                    isPagination={true}
                    SearchPlaceholder="Search"
                    pagination="pagination"
                    paginationWrapper="dataTables_paginate paging_simple_numbers"
                    tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                    rootMenu="systemsettings"
                    subMenu="usersetting"
                    isCustomPageSize={true}
                    // isSelected={selectedContentId.length > 0}
                    // onClearSelected={() => setSelectedContentId([])}
                    isExportButton={true}
                    exportOption={{
                      filename: "product-category-detail-platform",
                      dateColumns: [],
                    }}
                    loading={contentListLoading}
                  />
                )}
              </div>

            </div>
            <div className="modal-footer">
              <div style={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
                {
                  pageView == "view"
                    ? <> <button type="button" className="btn btn-primary w-md" onClick={() => onCancel()}>BACK</button></>
                    : <>
                      <button type="button" className="btn btn-primary w-md" onClick={() => onChange(selectedContentId)}>CONFRIM</button>
                      <button type="button" className="btn btn-secondary w-md" onClick={() => onCancel()}>CANCEL</button>
                    </>
                }
              </div>
            </div>

          </div>

        </DialogCustom>
      </div>
    </>
  )
}
export default ProductCategoryPopup
