import React, { useEffect, useMemo, useState } from "react"
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Input,
} from "reactstrap"
import TableContainer from "../../../components/Common/TableContainer"
import Select from "react-select"
import { withTranslation } from "react-i18next"
import _ from "lodash"
import fn_helper from "helpers/fn_helper"
import InputText from "components/Input/InputText"
import UploadFileAPI from "components/UploadFileAPI"
import InputSelect from "components/Input/InputSelect"
import MyEditor from "components/MyEditor"
import InputRadio from "components/Input/InputRadio"
import AttributeForm from "./AttributeForm"
import RequireStar from "components/RequireStar"
import InputSwitch from "components/Input/InputSwitch"
import { PatchProductVariant, SearchProductVariant } from "services/api/module/ProductVariant"

const CompareInfo = ({ 
  validation = null,
  pageView = "create",
  dropdownOption = {
    category: [],
    brand: [],
    attributeSet: [],
    productCompare: [],
  }
}) => {
  const contentList = dropdownOption?.productCompare ?? []
  const selectedContentId = validation?.values?.compare ?? []
  const setSelectedContentId = (newState) => {
    validation.setFieldValue("compare", newState)
  }
  
  const onSelectionChange = (
    selectedValue = {},
    oldValues = [],
    option = [],
    mode = ""
  ) => {
    const selectedId = selectedValue?.id
    const tmpOldValues = _.cloneDeep(oldValues)
    const findOldValue = tmpOldValues.filter(id => id === selectedId)

    let updateState = []
    if (findOldValue && findOldValue.length) {
      updateState = tmpOldValues.filter(id => id != selectedId)
    } else {
      updateState = tmpOldValues
      updateState.push(selectedId)
    }

    if (mode === "all") {
      if (tmpOldValues.length >= option.length) {
        updateState = []
      } else {
        updateState = option.map(item => item.id)
      }
    }
    console.log("🚀 ~ onChangeEnum ~ updateState:", updateState)
    return updateState
  }

  const fnCreateColumns = (colType = 'main') => {
    const limitCountSelect = 2 
    return [
      {
        id: "colcheckbox",
        header: cellProps => {
          const row = cellProps?.row?.original
          if (colType !== 'main' || true) {
            return <></>
          }
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Input
                type={`checkbox`}
                checked={contentList.length === selectedContentId.length && contentList.length !== 0}
                onClick={() => {
                  let updateData = onSelectionChange(
                    row,
                    selectedContentId,
                    contentList,
                    "all"
                  )
                  setSelectedContentId(updateData)
                }}
                readOnly
                disabled={["view"].includes(pageView)}
              />
            </div>
          )
        },
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          const row = cellProps?.row?.original
          const isMainDisable = (colType === 'main' && selectedContentId.length >= limitCountSelect)
          return (
            <>
              <div
                id={cellProps.row.id}
                style={{ display: "flex", justifyContent: "center" }}
                onClick={() => {
                  if (!isMainDisable) {
                    let updateData = onSelectionChange(
                      row,
                      selectedContentId,
                      contentList
                    )
                    setSelectedContentId(updateData)
                  }
                }}
              >
                <Input
                  type={`checkbox`}
                  value={cellProps.row.original.id}
                  checked={selectedContentId.includes(row.id)}
                  readOnly
                  disabled={["view"].includes(pageView) || isMainDisable}
                />
              </div>
            </>
          )
        },
      },
      {
        header: "#",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {parseInt(cellProps.row.id) + 1}
              </div>
            </>
          )
        },
      },
      // {
      //   header: "Status",
      //   accessorKey: "status",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      //   // cell: cellProps => <RowStatus status={cellProps.row.original.status} />,
      //   cell: cellProps => {
      //     const { id, status } = cellProps.row.original
      //     return (
      //       <InputSwitch
      //         labelON="Active"
      //         labelOFF="Inactive"
      //         value={status == 1}
      //         onChange={value => {
      //           const updateValue = value ? 1 : 0
      //           onPatchRow(id, "status", updateValue, 0)
      //         }}
      //         onColor="#34c38f"
      //         offColor="#f1b44c"
      //         width={72}
      //         disabled
      //       />
      //     )
      //   },
      // },
      {
        header: "SKU",
        accessorKey: "sku",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Product Type",
        accessorKey: "type",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const row = cellProps?.row?.original
          return row.type === 1 ? 'Parent' : 'Variant'
        }
      },
      {
        header: "Category",
        accessorKey: "categoryNames",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Product Name",
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: true,
      },
    ]
  }

  let columns = useMemo(() => fnCreateColumns('main'), [selectedContentId, contentList]);
  let columnsSelect = useMemo(() => fnCreateColumns('select'), [selectedContentId, contentList]);


  useEffect(() => {
    // fetchAPI()
  }, [])

  const contentListSelected = contentList.filter(item => selectedContentId.includes(item.id))

  return (
    <>
      <Row className="mb-3">
        <Col className="col-12">
          <h5 className="mb-3">Compare Infomation</h5>
        </Col>
        <Col className="col-12">
          <Card>
            <CardBody>
              {/* {JSON.stringify(selectedContentId)} */}
              {/* <br/> */}
              {/* {selectedContentId.length} */}
              <CardTitle className="h4">Detail</CardTitle>
              <div className="d-flex justify-content-between align-items-center">
                <p className="card-title-desc">
                  (Selected product)
                </p>
                {pageView != "view" && (
                <div className="flex-shrink-0 d-flex gap-2">
                  <a className="btn btn-info" onClick={() => setSelectedContentId([])}> Clear Selected All</a>
                </div>
                )}
              </div>
              <Col className="col-12 mb-3">
                <TableContainer
                  noTop={true}
                  tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                  columns={columnsSelect}
                  data={contentListSelected || []}
                />
              </Col>
              <Col className="col-12">
                <h5 className="mb-3">Product List</h5>
              </Col>
              <Col className="col-12">
                <TableContainer
                  isAddButton={false} // Set to true if you want an add button
                  columns={columns} // Ensure `columns` is defined with your required structure
                  data={contentList || []} // Adjust to match the data for your attribute list
                  isGlobalFilter={true} // Set true if you need a global filter for the table
                  isPagination={true}
                  SearchPlaceholder="Search"
                  pagination="pagination"
                  paginationWrapper="dataTables_paginate paging_simple_numbers"
                  tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                  rootMenu="systemsettings"
                  subMenu="usersetting"
                  isCustomPageSize={true}
                />
              </Col>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}
export default withTranslation()(CompareInfo)
