import PropTypes from "prop-types"
import React, { useEffect, useState, useMemo, useCallback, useContext } from "react"
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    Badge,
    FormGroup,
    Label,
} from "reactstrap"

import { Link, useNavigate, useParams } from "react-router-dom"

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from '../../components/Common/TableContainer';
import { withTranslation } from "react-i18next";

import { get } from "helpers/api_helper";
import { checkScope } from "helpers/utility_helper";
import "../../components/Common/datatables.scss"
import Spinners from "components/Common/Spinner";

import ModalContext from "../../contexts/ModalContext";
import moment from "moment";
import InputSwitch from "components/Input/InputSwitch";
import { Delete, Patch, Search } from "services/api/module/InstallmentPlan";
import { tr } from "date-fns/locale";
import { debounce, filter } from "lodash";

const RowStatus = ({ status }) => {
    switch (String(status)) {
        case "0":
            return <Badge className="bg-danger">InActive</Badge>;
        case "1":
            return <Badge className="bg-success">Active</Badge>;
        default:
            return <Badge className="bg-success">{status}</Badge>;
    }
};

const InstallmentPlanList = props => {
    // POPUP
    const { mAlert } = useContext(ModalContext);

    // OTHER STATE
    const { categoryName } = useParams();
    const navigate = useNavigate();

    // LOCAL STATE
    const [contentList, setContentList] = useState([])
    let [filterData, setFilter] = useState([])
    const [contentListLoading, setContentListLoading] = useState(false)
    const [selectedContentId, setSelectedContentId] = useState([])


    const onDeleteRow = (ids = [], name = '') => {
        // alert('onDeleteRow'+ JSON.stringify(ids))
        mAlert.info({
            type: "confrim",
            title: "Alert Confrim",
            subTitle: `Are you sure Delete ${name} ?`,
            mode: "red",
            content: "",
            onClose: () => { },
            onYes: () => {
                const payload = { installmentPlansId: ids };
                API.fetchDeleteContentList(payload)
            },
            onNo: () => { console.log("Cancel") },
        })
    }
    const onPatchRow = (id = 0, fieldName = '', value = null) => {
        const payload = { id: id, fieldName: fieldName, value: value };
        API.fetchPatchContent(payload)
    }

    const onSelectionChange = (selectedValue = {}, oldValues = [], option = [], mode = "") => {
        const selectedId = selectedValue?.id
        const tmpOldValues = _.cloneDeep(oldValues)
        const findOldValue = tmpOldValues.filter(id => id === selectedId)

        let updateState = []
        if (findOldValue && findOldValue.length) {
            updateState = tmpOldValues.filter(id => id != selectedId)
        } else {
            updateState = tmpOldValues
            updateState.push(selectedId)
        }

        if (mode === "all") {
            if (tmpOldValues.length >= option.length) {
                updateState = []
            } else {
                updateState = option.map(item => item.id)
            }
        }
        console.log("🚀 ~ onChangeEnum ~ updateState:", updateState)
        return updateState
    };

    const columns = useMemo(
        () => [
            {
                id: "colcheckbox",
                header: (cellProps) => {
                    const row = cellProps?.row?.original
                    return (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Input
                                type={`checkbox`}
                                checked={contentList.length === selectedContentId.length}
                                onClick={() => {
                                    let updateData = onSelectionChange(row, selectedContentId, contentList, "all")
                                    setSelectedContentId(updateData)
                                }}
                                readOnly
                            />
                        </div>
                    )
                },
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    const row = cellProps?.row?.original
                    return (
                        <>
                            <div id={cellProps.row.id} style={{ display: "flex", justifyContent: "center" }}>
                                <Input
                                    type={`checkbox`}
                                    value={cellProps.row.original.id}
                                    checked={selectedContentId.includes(row.id)}
                                    onClick={() => {
                                        let updateData = onSelectionChange(row, selectedContentId, contentList)
                                        setSelectedContentId(updateData)
                                    }}
                                    readOnly
                                />
                            </div>
                        </>
                    )
                }
            },
            {
                header: '#',
                accessorKey: 'sortOrder',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                {cellProps.row?.original?.sortOrder}
                            </div>
                        </>
                    )
                }
            },
            {
                header: 'Status',
                accessorKey: 'status',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const { id, status } = cellProps.row.original
                    return (
                        <InputSwitch
                            labelON="Active"
                            labelOFF="Inactive"
                            value={status == 1}
                            onChange={value => {
                                const updateValue = value ? 1 : 0
                                onPatchRow(id, "status", updateValue, 0)
                            }}
                            onColor="#34c38f"
                            offColor="#f1b44c"
                            width={72}
                        />
                    )
                }
            },
            {
                header: 'JIB SKU',
                accessorKey: 'sku',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Product Name',
                accessorKey: 'productName',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Card',
                accessorKey: 'creditDebitCode',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Store Type',
                accessorKey: 'storeType',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Interest Rate',
                accessorKey: 'interestRate',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Period',
                accessorKey: 'period',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Suppiler Code',
                accessorKey: 'suppilerCode',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Product Code Bank',
                accessorKey: 'productBankCode',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Start Date',
                accessorKey: 'startDate',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const row = cellProps?.row?.original
                    return row.startDate ? moment(row.startDate).format('DD/MM/YYYY') : ''
                }
            },
            {
                header: 'End Date',
                accessorKey: 'endDate',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const row = cellProps?.row?.original
                    return row.endDate ? moment(row.endDate).format('DD/MM/YYYY') : ''
                }
            },

            // {
            //     header: 'Base Shipping Fee',
            //     accessorKey: 'baseShippingFee',
            //     enableColumnFilter: false,
            //     enableSorting: true,
            // },
            // {
            //     header: 'Icon',
            //     accessorKey: 'icon',
            //     enableColumnFilter: false,
            //     enableSorting: true,
            //     cell: (cellProps) => {
            //         const { icon = '' } = cellProps?.row?.original
            //         return <img className="bImageBox" src={icon} />
            //     }
            // },
            // {
            //     header: 'Parent Name',
            //     accessorKey: 'parentId',
            //     enableColumnFilter: false,
            //     enableSorting: true,
            //     filterFn: 'optionFN',
            //     cell: (cellProps) => {
            //         const { parentName = '' } = cellProps?.row?.original
            //         return <div>{parentName}</div>
            //     }
            // },
            {
                header: 'Created By',
                accessorKey: 'createByName',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Created At',
                accessorKey: 'createdAt',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const row = cellProps?.row?.original
                    return row.createdAt ? moment(row.createdAt).format('DD/MM/YYYY') : ''
                }
            },
            {
                header: 'Updated By',
                accessorKey: 'updateByName',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Updated At',
                accessorKey: 'updatedAt',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const row = cellProps?.row?.original
                    return row.updatedAt ? moment(row.updatedAt).format('DD/MM/YYYY') : ''
                }
            },
            {
                header: 'Tools',
                accessorKey: 'id',
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    let { id, platformName } = cellProps?.row?.original ?? {};
                    return (
                        <>
                            {checkScope('systemsettings:usersetting:view') && <Link className={`btn btn-info`} to={`/installment-plan/list/view/${id}`}><i className={`bx bx-show-alt`}></i></Link>}
                            &nbsp;
                            {checkScope('systemsettings:usersetting:update') && <Link className={`btn btn-warning`} to={`/installment-plan/list/edit/${id}`}><i className={`bx bx-edit-alt`}></i></Link>}
                            
                                          &nbsp;
                                          {checkScope("systemsettings:usersetting:delete") && (
                                            <a
                                              className={`btn btn-danger`}
                                              onClick={() => onDeleteRow([id], platformName)}
                                            >
                                              <i className={`bx bx-trash`}></i>
                                            </a>
                                          )}
                        </>
                    )
                }
            },
        ],
        [selectedContentId, contentList]
    );

    const API = {
        fetchContentList: async (filter = {}) => {
            try {
                console.log(filter)
                const response = await Search({ params: filter })
                const resData = response?.data ?? []
                setContentList(resData.data);
            } catch (e) {
                console.log(e)
                mAlert.info({
                    title: "Alert",
                    subTitle: "Something went wrong",
                    content: "",
                    onClose: () => {
                        //  alert("onClose")
                    },
                })
            }
        },
        fetchPatchContent: async (payload) => {
            try {
                const response = await Patch({ data: payload, id: payload.id })
                const resData = response.data
                if (resData.status === 201) {
                    mAlert.info({
                        title: "Alert",
                        subTitle: "Update Data Success",
                        content: "",
                        onClose: () => {
                            fetchAPI()
                        },
                    })
                }
            }
            catch (err) {
                console.log('err ', err)
                mAlert.info({
                    title: "Alert",
                    subTitle: "Something went wrong",
                    content: "",
                    onClose: () => {
                        //  alert("onClose")
                    },
                })
            }
        },
        fetchDeleteContentList: async (payload) => {
            try {
                const response = await Delete({ data: payload })
                const resData = response.data
                if (resData.status === 201) {
                    mAlert.info({
                        title: "Alert",
                        subTitle: "Delete Data Success",
                        content: "",
                        onClose: () => {
                            fetchAPI()
                        },
                    })
                }
            }
            catch (err) {
                console.log('err ', err)
                mAlert.info({
                    title: "Alert",
                    subTitle: "Something went wrong",
                    content: "",
                    onClose: () => {
                        //  alert("onClose")
                    },
                })
            }
        }

    }

    const fetchAPI = () => {
        const payload = {}
        API.fetchContentList(payload)
    }

    useEffect(() => {
        setContentListLoading(true)
        fetchAPI()
    }, []);

    useEffect(() => {
        if (contentList.length) {
            setContentListLoading(false)
        }
    }, [contentList])

    //meta title
    document.title = "Installment Plans | " + process.env.REACT_APP_CMS_TITLE;

    const handleFilter = debounce((key, value) => {
        if (value) {
            setFilter({ ...filterData, [key]: value });
            API.fetchContentList({ ...filterData, [key]: value });
        } else {
            const newFilterData = { ...filterData };
            delete newFilterData[key];
            setFilter(newFilterData);
            API.fetchContentList(newFilterData);
        }
    }, 500);

    const handleFilterSKU = (e) => handleFilter('sku', e.target.value.trim());
    const handleFilterProductName = (e) => handleFilter('product_name', e.target.value.trim());
    const handleFilterCard = (e) => handleFilter('credit_debit_code', e.target.value.trim());

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="Installment Plans" breadcrumbItem="Installment Plans List" />

                {
                    contentListLoading ? <Spinners setLoading={setContentListLoading} />
                        :
                        <>

                            <TableContainer
                                isAddButton={true}
                                addRowLink={`/create`}
                                columns={columns}
                                data={contentList || []}
                                isGlobalFilter={true}
                                isPagination={true}
                                SearchPlaceholder="Search All"
                                pagination="pagination"
                                paginationWrapper='dataTables_paginate paging_simple_numbers'
                                tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                                rootMenu="systemsettings"
                                subMenu="usersetting"
                                isCustomPageSize={true}
                                isSelected={false}
                                isCustomFilter={true}
                                customFilter={<>
                                    <Col col-sm="4">
                                        <Input
                                            type="text"
                                            onChange={handleFilterSKU}
                                            placeholder="Search Product JIB SKU"
                                        />
                                    </Col>
                                    <Col col-sm="4">
                                        <Input
                                            type="text"
                                            onChange={handleFilterProductName}
                                            placeholder="Search Product Name"
                                        />
                                    </Col>
                                    <Col col-sm="4">
                                        <Input
                                            type="text"
                                            onChange={handleFilterCard}
                                            placeholder="Search Card"
                                        />
                                    </Col>
                                </>}
                                onClearSelected={() => setSelectedContentId([])}
                                onDeleleSelected={() => onDeleteRow(_.cloneDeep(selectedContentId))}
                                isExportButton={true}
                                exportOption={{
                                    filename: "installment_plans",
                                    dateColumns: []
                                }}
                                loading={contentListLoading}
                                sortTable={{
                                    tableName: 'jib_installment_plans',
                                    fetchCallBack: () => {
                                        setContentListLoading(true)
                                        fetchAPI()
                                    }
                                }}
                            />
                        </>

                }
            </div>
        </div>
    );
}

InstallmentPlanList.propTypes = {
    t: PropTypes.any,
}

export default withTranslation()(InstallmentPlanList)
