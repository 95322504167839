import _ from "lodash";
import helper from "./fn_helper";

// 0 =
// 1 >=
// 2 <=
// 3 alert
// 4 [][] intersec เทียบ array

const attrComsetOperator = {
  cpu: {
    "sum": [],
    "cpu-socket": 4,
    "cpu-wattage": 0,
    "cpu-pcie": 0,
    "cpu-cooler-require": 3,
    "cpu-graphic-require": 3,
  },
  mainboard: {
    "sum": [],
    "cpu-socket": 4,
    "cpu-wattage": 0,
    "mainboard-size": 4,
    "mainboard-main-pwd-con": 0,
    "mainboard-cpu-pwd-con": 0,
    "ram-type": 0,
    "ram-speed": 4,
    "ram-max-capacity": 1,
    "ram-slot-no": 1,

    "storage-sata-data-con-slot-no": 1,
    "m2-size": 4,
    // "m2-type": 0,
    // "m2-pcie": 0,
    "vga-pcie-slots": 0,
    // "vga-video-out": 0,
  },
  m2: {
    "sum": [],
    "m2-size": 4,
    // "m2-type": 0,
    // "m2-pcie": 0,
    // "m2-height": 3,
  },
  ram: {
    "sum": [],
    "ram-type": 0,
    "ram-speed": 4,
    "ram-max-capacity": 2,
    "ram-slot-no": 2,
  },
  vga: {
    "sum": [],
    "case-expansion-slot": 2,
    "vga-length": 1,
    // "vga-slot-thick": 0,

    "vga-pcie-slots": 0,
    // "vga-video-out": 0,
    "vga-watt": 0,
    "vga-pcie-pwr-con": 0,


  },
  storage: {
    "sum": [],
    "storage-sata-pwr-con-slot-no": 2,
    "storage-sata-data-con-slot-no": 2,
  },
  psu: {
    "sum": ["psu-watt"],
    "psu-watt": 1,
    "psu-size-type": 0,
    "psu-size-length": 0,
    "psu-size-2": 0,
    "mainboard-main-pwd-con": 0,
    "mainboard-cpu-pwd-con": 0,
    "vga-pcie-pwr-con": 0,
    "storage-sata-pwr-con-slot-no": 1,
  },
  case: {
    "sum": [],
    "case-expansion-slot": 1,
    "vga-length": 1,
    // "vga-slot-thick": 0,

    "mainboard-size": 4,
    "hhd-3-5-pwr-con": 0,
    "ssd-2-5-pwr-con": 0,
    "psu-size-type": 0,
    "psu-size-length": 0,
    "psu-size-2": 0,

    // "cooler-air-max-height": 0,
    // "cooler-liquid-max-length": 0,
    // "cooler-liquid-location": 0,
    "cooler-size": 1
  },
  cooler: {
    "sum": [],
    "cooler-type": 0,
    "cooler-size": 2,
    "cpu-socket": 4,
    "cpu-wattage": 0,
  },
}

export default {
  convertToPartFilter: (_selectList = [], _optionPart = {}) => {
    const selectList = _.cloneDeep(_selectList)
    const optionPart = _.cloneDeep(_optionPart)
  
    let filterAll = {}
    let filter = {}
    let filterMax = {}
    for (let sl of selectList) {
      console.log("🚀 ~ convertToPartFilter ~ sl:", sl)
      const attributeSetCode = sl?.attributeSetCode ?? 'xx'
      const optionPartByAttrCode = _fnOptionPartByAttrCode(optionPart, attributeSetCode)

      for (let slv of sl.productDiyIds) {
        const optionSelect = optionPartByAttrCode.find(item => item.id === slv.productDiyId)
        const attributeKey = optionSelect?.attributeKey ?? {}
        filter = { ...filter, ...attributeKey }
        filterAll[attributeSetCode] = _.cloneDeep(attributeKey)

      }
    }

    filter = { ...filter, ...filterMax }
    console.log("🚀 ~ xxx filter:", filter)
    console.log("🚀 ~ xxx filterAll:", filterAll)
    return { filterAll, filter }
  },
  sumFilterByConfig: (_attributeSetCode = 'xx', _filterAll = {}) => {
    const config = attrComsetOperator
    const configSelect = config[_attributeSetCode]
    const configSelectSum = configSelect?.sum ?? []
    console.log("🚀 ~ sumFilterByConfig ~ configSelectSum:", configSelectSum)
    let sumFilter = {}
    if (configSelectSum.length) {

      console.log("🚀 ~ sumFilterByConfig ~ _attributeSetCode:", _attributeSetCode)
      console.log("🚀 ~ sumFilterByConfig ~ config:", config)
      console.log("🚀 ~ sumFilterByConfig ~ configSelect:", configSelect)
      console.log("🚀 ~ sumFilterByConfig ~ configSelect.sum:", configSelect.sum)
      console.log("🚀 ~ sumFilterByConfig ~ _filterAll:", _filterAll)

      for (const csKey of configSelectSum) {
        sumFilter[csKey] = 0
        for (const fkey in _filterAll) {
          const tmpFilter = _filterAll[fkey]
          if (tmpFilter[csKey]) {
            sumFilter[csKey] = sumFilter[csKey] + Number(`${tmpFilter[csKey]}`)
          }
        }

        // เคสพิเศษ
        if (csKey ==='psu-watt') {
          sumFilter[csKey] = sumFilter[csKey] + 200
        }
      }
      console.log("🚀 ~ sumFilterByConfig ~ sumFilter:", sumFilter)
    }
    return sumFilter
  },
  searchPartWithFilter: (attributeSetCode = 'xx', _productList = [], filterUse = {}) => {
    let productList = []
    console.log("🚀 searchPartWithFilter ~ attributeSetCode:", attributeSetCode)
    console.log("🚀 searchPartWithFilter ~ productList:", productList)
    console.log("🚀 searchPartWithFilter ~ filterUse:", filterUse)
    console.log("🚀 searchPartWithFilter ~ --------------------------------------------------------")
    console.log(`filterUse ${attributeSetCode} >>`, filterUse)
    productList = _productList.filter(product => {
      const productAttrKey = product?.attributeKey ?? {}
      let passList = []
      let passListMap = {}
      
      for (let kUse in filterUse) {

        let value = false

        const findOperator = (_attributeSetCode = 'xx', fieldName = 'yy', config = {}) => {
          console.log("🚀 ~ findOperator ~ config[_attributeSetCode]:", config[_attributeSetCode])
          console.log("🚀 ~ findOperator ~ config[_attributeSetCode] fieldName:", fieldName)
          console.log("🚀 ~ findOperator ~ config[_attributeSetCode] config[_attributeSetCode][fieldName]:", config[_attributeSetCode][fieldName])
          if (config[_attributeSetCode]) { 
            if (config[_attributeSetCode][fieldName] || config[_attributeSetCode][fieldName] === 0) {
              return config[_attributeSetCode][fieldName]
            }
          }
          return -1
        }

        const operatorValue = findOperator(attributeSetCode, kUse, attrComsetOperator)
        switch(`${operatorValue}`) {
          case '0':
            value = productAttrKey[kUse] === filterUse[kUse]
            break;
          case '1':
            value =  Number(`${productAttrKey[kUse]}`) >= Number(`${filterUse[kUse]}`)
            // value = `${productAttrKey[kUse]} >= `+` ${filterUse[kUse]}`
            break;
          case '2':
            // value = Number(`${filterUse[kUse]}`) <= Number(`${productAttrKey[kUse]}`)
            value =  Number(`${productAttrKey[kUse]}`) <= Number(`${filterUse[kUse]}`)
            // value = `${productAttrKey[kUse]} <= `+` ${filterUse[kUse]}`
            break;
          case '4':
            const array1 = `${productAttrKey[kUse]}`.split(',')
            const array2 = `${filterUse[kUse]}`.split(',')
            value =  _.intersection(array1, array2).length > 0
            // value =  JSON.stringify({array1, array2})
            break;
          default:
            value = 1
        }
        
        passList.push(value)
        passListMap[kUse] = value
      }
      const isPass = passList.every(item => item === true || item === 1)
      console.log(`test0 ~ >> ${attributeSetCode}`, productAttrKey)
      console.log(`test0 ~ filterUse`, filterUse)
      console.log("test0 ~ product.name:", product.name)
      console.log("test0 ~ product.attributeKey:", product.attributeKey)
      console.log("test0 ~ passListMap:", passListMap)
      console.log("test0 ~ passList:", passList)
      console.log("test0 ~ isPass:", isPass)
      console.log("test0 ~ ---------------------------------------------------")

      return isPass
    })


    return productList
  },
  fnOptionPartByAttrCode: _fnOptionPartByAttrCode,
  fnFindFilterUse: _fnFindFilterUse
}

function _fnOptionPartByAttrCode(optionPart = {}, attributeSetCode = 'xx') {
  if (optionPart[attributeSetCode]) {
    const optionPartByAttrCode = optionPart[attributeSetCode]
    return Array.isArray(optionPartByAttrCode) ? optionPartByAttrCode : []
  }
  return []
}
function _fnFindFilterUse(filterConfig = { main: {}, optional: {} }, partFilterAll = {}) {
  console.log("🚀 ~ _fnFindFilterUse ~ filterConfig:", filterConfig)
  let filterUse = {}
  // หาจาก Filter Main ก่อน
  for (let kMain in filterConfig.main) {
    console.log('kMain >>', kMain)
    if (partFilterAll[kMain]) {
      filterUse[kMain] = partFilterAll[kMain]
    } 
    // else {
    //   filterUse[kMain] = null
    // }
  }

  // หาจาก Filter Optional
  for (let kOptional in filterConfig.optional) {
    if (partFilterAll[kOptional]) {
      filterUse[kOptional] = partFilterAll[kOptional]
    }
  }

  return filterUse
}