import { get, post, put, patch } from "../Config"

export function SearchCorporateBusinessContact({ params = {} }) {
  return get({ url: `/v1/admin/corporateBusinessContact`, params })
}
export function GetCorporateBusinessContactById({ id }) {
  return get({ url: `/v1/admin/corporateBusinessContact/${id}` })
}
export function CreateCorporateBusinessContact({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/corporateBusinessContact`, params, data })
}
export function UpdateCorporateBusinessContact({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/corporateBusinessContact/${id}`, params, data })
}
export function PatchCorporateBusinessContact({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/corporateBusinessContact/${id}`, params, data })
}
export function DeleteCorporateBusinessContact({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/corporateBusinessContact/mutiDelete`, params, data })
}
