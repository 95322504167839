import React from "react"
import { Col, Row, TabPane } from "reactstrap"
import _ from "lodash"
import InputSelect from "components/Input/InputSelect"
import InputDate from "components/Input/InputDate"
import RequireStar from "components/RequireStar"


const PRODUCT_TYPE_OPTION = [
  { id: "1", name: "สินค้าทั่วไป" },
  { id: "2", name: "สินค้าฝากขาย (By Order)" },
  { id: "3", name: "สินค้าผ่อน 0%" },
  { id: "4", name: "สินค้า Pre-order" },
  { id: "5", name: "สินค้าองค์กร" },
]

const ProductTypeForm = ({ 
  validation = null,
  pageView = "create",
  dropdownOption = {
    category: [],
    brand: [],
    attributeSet: [],
  }
}) => {

  return (
    <>
      <Col className="col-6 mb-3">
        <label
          htmlFor="category_name"
          className="col-md-12 col-form-label"
        >
          Select Product Type
          <RequireStar validation={validation} fieldName="productType"/>
        </label>
        <div className="col-md-12">
          <InputSelect
            placeholder="Select Product Type"
            onBlur={validation.handleBlur}
            value={validation.values.productType}
            onChange={(newValue) => {
              validation.setFieldValue("productType", newValue)
            }}
            isClearable={true}
            invalid={validation.touched.productType  && validation.errors.productType  ? true : false}
            options={PRODUCT_TYPE_OPTION}
            errorText={validation.errors.productType }
            disabled={pageView === "view"}
          />
        </div>
      </Col>

      {
        (validation.values.productType == "4" ) && (
          <Col className="col-6 mb-3">
            <label
              htmlFor="category_name"
              className="col-md-12 col-form-label"
            >
              Shipping Date
              <span className="t-require">*</span>
              {/* <RequireStar validation={validation} fieldName="preOrderShippingDate"/> */}
            </label>
            <div className="col-md-12">
              <InputDate
                onChange={(newValue) => {
                  console.log("🚀 ~ newValue:", newValue)
                  validation.setFieldValue("preOrderShippingDate", newValue)
                }}
                onBlur={validation.handleBlur}
                value={validation.values.preOrderShippingDate}
                invalid={validation.touched.preOrderShippingDate && validation.errors.preOrderShippingDate ? true : false}
                disabled={pageView === "view"}
                errorText={validation.errors.preOrderShippingDate}
              />
            </div>
          </Col>
        )
      }

      { 
        (['2'].includes(validation.values.productType)) && (
          <Col className="col-6 mb-3">
            <label
              htmlFor="category_name"
              className="col-md-12 col-form-label"
            >
              Select Supplier
              <RequireStar validation={validation} fieldName="supplierId"/>
            </label>
            <div className="col-md-12">
              <InputSelect
                placeholder="Select Supplier"
                onBlur={validation.handleBlur}
                value={validation.values.supplierId}
                onChange={(newValue) => {
                  validation.setFieldValue("supplierId", newValue)
                }}
                isClearable={true}
                invalid={validation.touched.supplierId && validation.errors.supplierId ? true : false}
                options={dropdownOption.productSupplier}
                errorText={validation.errors.supplierId}
                disabled={pageView === "view"}
              />
            </div>
          </Col>
        )
      }

      { 
        (validation.values.productType == "4" )&& (
          <Col className="col-12 mb-3" >
            <div className="d-flex-form gap-4">
              <div>
                <label
                  htmlFor="category_name"
                  className="col-md-12 col-form-label"
                >
                  Pre Order Start
                  <span className="t-require">*</span>
                </label>
                <InputDate
                  onChange={(newValue) => {
                    console.log("🚀 ~ newValue:", newValue)
                    validation.setFieldValue("preOrderStart", newValue)
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.preOrderStart}
                  invalid={validation.touched.preOrderStart && validation.errors.preOrderStart ? true : false}
                  disabled={pageView === "view"}
                  errorText={validation.errors.preOrderStart}
                />
              </div>
              <div>
                <label
                  htmlFor="category_name"
                  className="col-md-12 col-form-label"
                >
                  Pre Order End
                  <span className="t-require">*</span>
                </label>
                <InputDate
                  onChange={(newValue) => {
                    console.log("🚀 ~ newValue:", newValue)
                    validation.setFieldValue("preOrderEnd", newValue)
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.preOrderEnd}
                  invalid={validation.touched.preOrderEnd && validation.errors.preOrderEnd ? true : false}
                  disabled={pageView === "view"}
                  errorText={validation.errors.preOrderEnd}
                />
              </div>
            </div>
          </Col>
        )
      }
    </>
  )
}
export default ProductTypeForm
