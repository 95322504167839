import { get, post, put, patch } from "../Config"

export function SearchShipping({ params = {} }) {
  return get({ url: `/v1/admin/shipping`, params })
}
export function GetShippingById({ id }) {
  return get({ url: `/v1/admin/shipping/${id}` })
}
export function CreateShipping({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/shipping`, params, data })
}
export function UpdateShipping({ params = {}, data = {}, id }) {
  return put({ url: `/v1/admin/shipping/${id}`, params, data })
}
export function PatchShipping({ params = {}, data = {}, id }) {
  return patch({ url: `/v1/admin/shipping/${id}`, params, data })
}
export function DeleteShipping({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/shipping/mutiDelete`, params, data })
}
