import React, { useEffect, useContext, useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Container,
  CardTitle,
  Label,
  Card,
  Input,
  InputGroup,
} from "reactstrap"
import _ from "lodash"
import Select from "react-select"
import moment from "moment"
import Flatpickr from "react-flatpickr"
import fn_helper from "helpers/fn_helper"
import InputText from "components/Input/InputText"
import InputSelect from "components/Input/InputSelect"
import InputRadio from "components/Input/InputRadio"
import styled from 'styled-components';

import { components } from 'react-select';
import MyTooltip from "components/MyTooltip"
const helper = fn_helper

const ColorBox = styled.div`
  width: 14px;
  height: 14px;
  background-color: ${props => props.bgColor || '#3498db'}; /* รับค่า props หรือใช้ค่าเริ่มต้น */
  border: 1px solid #DDDDDD;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); ลดขนาดเงาให้เหมาะกับขนาดเล็ก */
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  max-width: ${props => props.size || '80px'};
  max-height: ${props => props.size || '80px'};;
  object-fit: cover;
`;


// เขียน Render DROPDOWN ต้องเอาไว้ด้านนอก
const reRenderDD = {
  CustomOption: (props) => {
    const swatchType = props?.data?.swatchType ?? 'color'

    return (
      <components.Option {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {
            swatchType === 'color'
            ? <ColorBox bgColor={props.data.swatchValue}></ColorBox>
            : <Image src={props.data.swatchValue} alt="Small Example" />
          }
          <span style={{ marginLeft: 8 }}>{props.data.name}</span>
        </div>
      </components.Option>
    );
  },
  CustomSingleValue: (props) => {
    // console.log('CustomSingleValue props.data >>', props.data)
    const swatchType = props?.data?.swatchType ?? 'color'

    return (
      <components.SingleValue {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {
            swatchType === 'color'
            ? <ColorBox bgColor={props.data.swatchValue}></ColorBox>
            : <Image size="60px" src={props.data.swatchValue} alt="Small Example" />
          }
          <span style={{ marginLeft: 8 }}>{props.data.name}</span>
        </div>
      </components.SingleValue>
    );
  }
}

const OperatorInput = ({
  labelInput = "FORM",
  type = "TEXT",
  operator = "",
  value = [],
  attrOption = [],
  disabled = false,
  onChange = () => console.log("onChange"),
  invalid = false,
  errorText = "",
}) => {
  // console.log("🚀 ~ OperatorInput type:", type)
  // console.log("🚀 ~ OperatorInput operator:", operator)
  // console.log("🚀 ~ OperatorInput value:", value)

  const arrOptionToOption = (arrOption = []) => attrOption.map(item => ({ id: item.id, name: item.labelAdmin }))
  const swatchArrOptionToOption = (arrOption = []) => attrOption.map(item => ({ id: item.id, name: item.labelAdmin, swatchType: item.swatchType, swatchValue: item.swatchValue }))

  switch(type) {
    case "TEXT_FIELD":
      if (true) {
        const [ valueText ] = value
        return (
          <div className="col-md-6">
            <InputText
              type="text"
              placeholder={`Enter ${labelInput}`}
              maxLength="255"
              value={valueText}
              onChange={(newValue) => {
                let updateValue = newValue ? [newValue] : []
                onChange(updateValue)
              }}
              disabled={disabled}
              invalid={invalid}
              errorText={errorText}
            />
          </div>
        )
      }
      break;
    case "TEXT_AREA":
      if (true) {
        const [ valueText ] = value
        return (
          <div className="col-md-6">
            <InputText
              type="textarea"
              placeholder={`Enter ${labelInput}`}
              maxLength="255"
              value={valueText}
              onChange={(newValue) => {
                let updateValue = newValue ? [newValue] : []
                onChange(updateValue)
              }}
              disabled={disabled}
              invalid={invalid}
              errorText={errorText}
            />
          </div>
        )
      }
      break;
    case "DROPDOWN":
      if (true) {
        const options = arrOptionToOption(attrOption)
        const [ valueId ] = value
        return (
          <div className="col-md-6">
            <InputSelect
              placeholder={`Select ${labelInput}`}
              value={valueId}
              onChange={(newValue) => {
                let updateValue = newValue ? [newValue] : []
                onChange(updateValue)
              }}
              isClearable={true}
              options={options}
              disabled={disabled}
              invalid={invalid}
              errorText={errorText}
            />
          </div>
        )
      }
      break;
    case "MULTIPLE_SELECT":
      if (true) {
        const options = arrOptionToOption(attrOption)
        const values = value
        return (
          <div className="col-md-6">
            <InputSelect
              isMulti={true}
              placeholder={`Select ${labelInput}`}
              value={values}
              onChange={(newValue = []) => {
                console.log("🚀 ~ newValue bb :", newValue)
                let updateValue = newValue ? newValue : []
                onChange(updateValue)
              }}
              options={options}
              disabled={disabled}
              invalid={invalid}
              errorText={errorText}
            />
          </div>
        )
      }
      break;
    case "YES_NO":
        if (true) {
          const options = [ { id: 'YES', name: 'YES' }, { id: 'NO', name: 'NO' } ]
          const [ valueId ] = value
          return (
            <div className="col-md-6">
              <InputRadio
                style={{ marginTop: '-8px'}}
                name={labelInput}
                value={valueId}
                onChange={(newValue) => {
                  let updateValue = newValue ? [newValue] : []
                  onChange(updateValue)
                }}
                option={options}
                disabled={disabled}
                invalid={invalid}
                errorText={errorText}
              />
            </div>
          )
        }
        break;
    case "VISUAL_SWATCH":
      if (true) {
        const options = swatchArrOptionToOption(attrOption)
        const [ valueId ] = value
        return (
          <div className="col-md-6">
            <InputSelect
              placeholder={`Select ${labelInput}`}
              value={valueId}
              onChange={(newValue) => {
                let updateValue = newValue ? [newValue] : []
                onChange(updateValue)
              }}
              options={options}
              disabled={disabled}
              invalid={invalid}
              errorText={errorText}
              isClearable={true}
              isReRender={true}
              reRender={{
                Option: reRenderDD.CustomOption,
                SingleValue: reRenderDD.CustomSingleValue
              }}
            />
          </div>
        )
      }
      break;
    case "TEXT_SWATCH":
      if (true) {
        const options = arrOptionToOption(attrOption)
        const [ valueId ] = value
        return (
          <div className="col-md-6">
            <InputSelect
              placeholder={`Select ${labelInput}`}
              value={valueId}
              onChange={(newValue) => {
                let updateValue = newValue ? [newValue] : []
                onChange(updateValue)
              }}
              isClearable={true}
              options={options}
              disabled={disabled}
              invalid={invalid}
              errorText={errorText}
            />
          </div>
        )
      }
      break;
    default:
      return <>NOT WIDGET</>
  }
  return <></>
}
export default OperatorInput
