import React, { useEffect, useState, useContext } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik, FieldArray } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import {
  CreateAttributes,
  GetAttributesById,
  UpdateAttributes,
} from "services/api/module/Attributes"
import ModalContext from "../../contexts/ModalContext"
import UploadFileAPI from "components/UploadFileAPI"
import MyEditor from "components/MyEditor"
import InputSwitch from "components/Input/InputSwitch"
import VisualSwatchForm from "./VisualSwatch"
import DropdownOptionsForm from "./DropdownForm"
import TextSwatchForm from "./TextSwatchForm"
import { is } from "date-fns/locale"

const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const INPUT_OPTION = [
  { id: "TEXT_FIELD", name: "Text Field" },
  { id: "TEXT_AREA", name: "Text Area" },
  { id: "YES_NO", name: "Yes/No" },
  { id: "DROPDOWN", name: "Dropdown" },
  { id: "MULTIPLE_SELECT", name: "Multiple Select" },
  { id: "VISUAL_SWATCH", name: "Visual Swatch" },
  { id: "TEXT_SWATCH", name: "Text Swatch" },
];


const AttributesTemplate = ({ pageView = "create" }) => {
  // POPUP
  const { mAlert } = useContext(ModalContext)

  // Example
  const [swatchOptions, setSwatchOptions] = useState([])
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [textSwatchOptions, setTextSwatchOptions] = useState([]);
  // OTHER STATE
  const qParams = useParams()
  const qAttributesId = qParams.id
  const navigate = useNavigate()

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    code: Yup.string().required("Please Enter code"),
    labelAdmin: Yup.string().required("Please Enter Admin Title"),
    labelTh: Yup.string().max(100).required("Please Enter Label Thai"),
    labelEn: Yup.string().max(100).required("Please Enter Label Eng"),
    inputType: Yup.string().max(100).required("Please Enter input type"),
    isRequired: Yup.string().max(100).required("Please Enter isRequired"),
    status: Yup.string().max(100).required("Please Enter Status"),
  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] =
    useState(validationSchema)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: "",
      labelAdmin: "",
      labelTh: "",
      labelEn: "",
      inputType: "",
      isRequired: "0",
      isPartComputerSet: "0",
      status: "",
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ AttributesCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        code: values.code ? values.code : undefined,
        labelAdmin: values.labelAdmin,
        labelTh: values.labelTh,
        labelEn: values.labelEn,
        inputType: values.inputType,
        isRequired: values.isRequired,
        isPartComputerSet: values.isPartComputerSet,
        status: Number(values.status),
      }
      console.log("🚀 ~ onSave ~ payload:", payload)
      if (values.inputType === "VISUAL_SWATCH") {
        payload.swatchOptions = swatchOptions

        // Log all swatch-related data
        console.group("Swatch Submission Data")
        console.log("Form Values:", values)
        console.log("Swatch Options:", swatchOptions)
        console.log("Full Payload:", payload)
        console.groupEnd()
      }
      if (isDropdownOrMultiselectSelected()) {
        payload.swatchOptions = dropdownOptions;
      }
      if (isTextSwatchSelected()) {
        payload.swatchOptions = textSwatchOptions;
      }

      if (pageView === "update") {
        API.fetchUpdateAttributes(payload)
      } else {
        API.fetchCreateAttributes(payload)
      }
    },
  })

  const handleSwatchChange = newSwatchOptions => {
    setSwatchOptions(newSwatchOptions)
    console.group("Swatch Options Updated")
    console.log("New Swatch Options:", newSwatchOptions)
    console.groupEnd()
  }

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("code", resData.code)
    validation.setFieldValue("labelAdmin", resData.labelAdmin)
    validation.setFieldValue("labelTh", resData.labelTh)
    validation.setFieldValue("labelEn", resData.labelEn)
    validation.setFieldValue("inputType", resData.inputType)
    validation.setFieldValue("isRequired", resData.isRequired)
    validation.setFieldValue("isPartComputerSet", resData.isPartComputerSet)
    validation.setFieldValue("status", resData.status)

    if (resData.inputType === "VISUAL_SWATCH" && resData.swatchOptions) {
      setSwatchOptions(resData.swatchOptions)
    } else if (["DROPDOWN", "MULTIPLE_SELECT"].includes(resData.inputType) && resData.swatchOptions) {
      setDropdownOptions(resData.swatchOptions)
    } else if (resData.inputType === "TEXT_SWATCH" && resData.swatchOptions) {
      setTextSwatchOptions(resData.swatchOptions)
    }
  }

  const isSwatchSelected = () => validation.values.inputType === "VISUAL_SWATCH"
  const isDropdownOrMultiselectSelected = () =>
    ["DROPDOWN", "MULTIPLE_SELECT"].includes(validation.values.inputType)
  const isTextSwatchSelected = () => validation.values.inputType === "TEXT_SWATCH"

  const API = {
    fetchGetAttributesById: async payload => {
      try {
        const response = await GetAttributesById({ id: payload.id })
        const resData = response.data
        if (resData.status == 202) {
          preFilInput(resData.data)
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchCreateAttributes: async payload => {
      try {
        const response = await CreateAttributes({ data: payload })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Created Data Success",
            content: "",
            onClose: () => {
              navigate("/product-management/attributes")
            },
          })
        }
        if(resData.status === 403) {
          mAlert.info({
            title: "Alert",
            subTitle: "Cant create unique code",
            content: "",
            onClose: () => {
              // navigate("/product-management/attributes")
            },
          })
        }
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchUpdateAttributes: async payload => {
      try {
        const response = await UpdateAttributes({
          data: payload,
          id: payload.id,
        })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              navigate("/product-management/attributes")
            },
          })
        }
        if(resData.status === 403) {
          mAlert.info({
            title: "Alert",
            subTitle: "Cant update unique code",
            content: "",
            onClose: () => {
              // navigate("/product-management/attributes")
            },
          })
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
  }

  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id: qAttributesId }
      API.fetchGetAttributesById(payload)
    }
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title =
    `Attributes | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} Attributes`}
            breadcrumbItems={[
              { title: "Attributes", link: "banner/banner-platform" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody style={{ minHeight: '100vh' , height: '100%'}}>
                    <Row className="mb-3">
                      <Col className="col-12">
                        <Row className="">
                          <Col className="col-12">
                            <h5 className="mb-3">Attributes Infomation</h5>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Attribute Code
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="code"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="code"
                                onChange={e => {
                                  // Ensure the value is lowercase and contains only English letters (no spaces)
                                  const value = e.target.value.toLowerCase().replace(/[^a-z0-9-]/g, '');
                                  validation.setFieldValue("code", value);
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.code}
                                invalid={
                                  validation.touched.code &&
                                  validation.errors.code
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.code &&
                                validation.errors.code && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.code}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Status (Active/Inactive)
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Select
                                placeholder="Select Status"
                                value={fn_helper.FNFORM.getObjectValue(
                                  STATUS_OPTION,
                                  `${validation.values.status}`
                                )}
                                onChange={value =>
                                  validation.setFieldValue("status", value.id)
                                }
                                getOptionValue={option => option.id}
                                getOptionLabel={option => option.name}
                                options={STATUS_OPTION}
                                className={`select2-selection ${
                                  validation.touched.status &&
                                  validation.errors.status
                                    ? "input-err"
                                    : ""
                                }`}
                                isDisabled={pageView === "view"}
                              />
                              {validation.touched.status &&
                                validation.errors.status && (
                                  <div
                                    type="invalid"
                                    className="invalid-feedback d-block"
                                  >
                                    {validation.errors.status}
                                  </div>
                                )}
                              {validation.touched.status &&
                                validation.errors.status && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.status}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Admin Title
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="labelAdmin"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="labelAdmin"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.labelAdmin}
                                invalid={
                                  validation.touched.labelAdmin &&
                                  validation.errors.labelAdmin
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.labelAdmin &&
                                validation.errors.labelAdmin && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.labelAdmin}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="labelTh"
                              className="col-md-12 col-form-label"
                            >
                              Label Thai
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="labelTh"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="labelTh"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.labelTh}
                                invalid={
                                  validation.touched.labelTh &&
                                  validation.errors.labelTh
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.labelTh &&
                                validation.errors.labelTh && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.labelTh}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-12 mb-3">
                            <label
                              htmlFor="labelEn"
                              className="col-md-12 col-form-label"
                            >
                              Label Eng
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="labelEn"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="labelEn"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.labelEn}
                                invalid={
                                  validation.touched.labelEn &&
                                  validation.errors.labelEn
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.labelEn &&
                                validation.errors.labelEn && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.labelEn}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3" >
                            <label
                              htmlFor="input_type"
                              className="col-md-12 col-form-label"
                            >
                              Select Input Type
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Select
                                placeholder="Select Input Type"
                                value={fn_helper.FNFORM.getObjectValue(
                                  INPUT_OPTION,
                                  `${validation.values.inputType}`
                                )}
                                onChange={value =>
                                  validation.setFieldValue(
                                    "inputType",
                                    value.id
                                  )
                                }
                                getOptionValue={option => option.id}
                                getOptionLabel={option => option.name}
                                options={INPUT_OPTION}
                                className={`select2-selection ${
                                  validation.touched.inputType &&
                                  validation.errors.inputType
                                    ? "input-err"
                                    : ""
                                }`}
                                isDisabled={pageView === "view"}
                              />
                              {validation.touched.inputType &&
                                validation.errors.inputType && (
                                  <div
                                    type="invalid"
                                    className="invalid-feedback d-block"
                                  >
                                    {validation.errors.inputType}
                                  </div>
                                )}
                              {validation.touched.inputType &&
                                validation.errors.inputType && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.inputType}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="isRequired"
                              className="col-md-12 col-form-label"
                            >
                              Is Required
                            </label>
                            <InputSwitch
                              labelTrue="Yes"
                              labelFalse="No"
                              value={validation.values.isRequired == "1"}
                              onChange={value => {
                                const updateValue = value ? "1" : "0"
                                validation.setFieldValue(
                                  "isRequired",
                                  updateValue
                                )
                              }}
                              disabled={pageView === "view"}
                            />
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="isPartComputerSet"
                              className="col-md-12 col-form-label"
                            >
                              Is Part Computer Set
                            </label>
                            <InputSwitch
                              labelTrue="Yes"
                              labelFalse="No"
                              value={validation.values.isPartComputerSet == "1"}
                              onChange={value => {
                                const updateValue = value ? "1" : "0"
                                validation.setFieldValue(
                                  "isPartComputerSet",
                                  updateValue
                                )
                              }}
                              disabled={pageView === "view"}
                            />
                          </Col>
                        </Row>
                      </Col>
                      {isSwatchSelected() && (
                        <Col className="col-12">
                          <VisualSwatchForm
                            disabled={pageView === "view"}
                            values={swatchOptions}
                            onChange={handleSwatchChange}
                          />
                        </Col>
                      )}
                      {isDropdownOrMultiselectSelected() && (
                        <DropdownOptionsForm
                          disabled={pageView === "view"}
                          values={dropdownOptions}
                          onChange={setDropdownOptions}
                          isMultiselect={validation.values.inputType === "MULTIPLE_SELECT"}
                        />
                      )}
                      {isTextSwatchSelected() && (
                        <TextSwatchForm
                          disabled={pageView === "view"}
                          values={textSwatchOptions}
                          onChange={setTextSwatchOptions}
                        />
                      )}
                      {/* <Col className="col-12">
                        <DropdownOptionsForm />
                      </Col> */}
                    </Row>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "8px",
                      }}
                    >
                      {pageView == "view" ? (
                        <>
                          <button
                            className="btn btn-primary w-md"
                            onClick={() =>
                              navigate("/product-management/attributes")
                            }
                          >
                            BACK
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            SAVE
                          </button>
                          <button
                            className="btn btn-secondary w-md"
                            onClick={() =>
                              navigate("/product-management/attributes")
                            }
                          >
                            CANCEL
                          </button>
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(AttributesTemplate)
