import { get, post, put, patch } from "../Config"

export function SearchCouponCategory({ params = {} }) {
  return get({ url: `/v1/admin/couponCategory`, params })
}
export function GetCouponCategoryById({ id }) {
  return get({ url: `/v1/admin/couponCategory/${id}` })
}
export function CreateCouponCategory({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/couponCategory`, params, data })
}
export function UpdateCouponCategory({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/couponCategory/${id}`, params, data })
}
export function PatchCouponCategory({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/couponCategory/${id}`, params, data })
}
export function DeleteCouponCategory({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/couponCategory/mutiDelete`, params, data })
}
