import React, { useEffect, useState, useContext } from "react"
import { Card, Col, Row, TabPane, Input, Button } from "reactstrap"
import Select from "react-select"
import { withTranslation } from "react-i18next"
import _ from "lodash"
import fn_helper from "helpers/fn_helper"
import InputText from "components/Input/InputText"
import UploadFileAPI from "components/UploadFileAPI"
import InputSelect from "components/Input/InputSelect"
import { ReactSortable } from "react-sortablejs"
import { FieldArray, getIn } from "formik"
import FileUploadGallery from "components/FileUploadGallery"
import InputSwitch from "components/Input/InputSwitch"
import { t } from "i18next"
import InputSelectApi from "components/Input/InputSelectApi"
import fn_comset from "helpers/fn_comset"
import { DropdownComputerDiyCate, DropdownProductDiyPart  } from "services/api/module/Dropdown"
import classnames from "classnames";

// DropdownComputerDiyCate

import styled, { keyframes } from 'styled-components';
import ModalContext from "contexts/ModalContext"

const DiyBoxMain = styled.div`

  display: flex;
  gap: 24px;
  flex-direction: row;

`;
const DiySelectMain = styled.div`
  flex: 1;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  gap: 16px;

`;
const DiySelect = styled.div`
  /* background-color: pink; */
  display: flex;
  flex-direction: column;
  gap: 16px;
  cursor: pointer;
  padding: 16px;

  border-radius: 12px;
  border: 2px solid #D0D5DD;
  background: #FCFCFD;

  &.pass {
    border: 2px solid #34c38f;
  }
  &.active {
    border: 2px solid #2C3D92;
  }
  &.error {
    border: 2px solid #f46a6a;
  }

  
  label {
    color: #1D2939;
    font-size: 14px;
  }

  .diy-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .no-margin > div > div {
    margin: 0 !important;

  }

  .product-select-main {
    /* background-color: pink; */
    display: flex;
    flex-direction: column;
    gap: 16px;

    border-bottom: 2px solid #D0D5DD;
    padding-bottom: 20px;

    .product-select {
      display: flex;
      align-items: center;
      gap: 16px;

      .bImg {
        display: flex;
        justify-content: center;
        /* width: 100%; */
        .pImg {
          justify-content: center;
          width: auto;
          height: 70px;
        }
      }
      p {
        margin: 0;
      }
    }

    .product-control {
      display: flex;
      gap: 16px;
      justify-content: space-between;
      .btn-up-down {
        display: flex;
        gap: 4px;
        border: 2px solid #D0D5DD;
        background-color: #efefef;
        border-radius: 4px;
        height: 34px;
        div {
          width: 30px;
          line-height: 32px;
          text-align: center;
          color:rgb(73, 73, 73);
          font-size: 20px;
          user-select: none; /* ปิดการเลือกข้อความ */
          -webkit-user-select: none; /* สำหรับเบราว์เซอร์ที่ใช้ WebKit */
          -moz-user-select: none; /* สำหรับ Firefox */
          -ms-user-select: none; 
        }
      }
      input {
        border: 0px;
        height: 30px;
        width: 50px;
        ::-webkit-inner-spin-button,
        ::-webkit-outer-spin-button {
          -webkit-appearance: none; /* ปิด appearance ของปุ่ม */
          margin: 0; /* ลบ margin */
        }

        /* ซ่อน stepper บน Firefox */
          -moz-appearance: textfield; /* เปลี่ยน appearance ให้เหมือน input type text */
      }
    }
    
  }

  .cur-select {
    display: flex;
    justify-content: center;
    gap: 16px;
    padding: 8px 0px;
    background-color:rgb(245, 245, 245);
    border: 2px solid #D0D5DD;
    border-radius: 4px;
    p {
      margin: 0px !important;
      text-align: center;
      font-weight: 600;
      font-size: 14px;
      color: #2C3D92;
    }
  }

`;
const DiyProductMain = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* display: grid; */
  /* grid-template-columns: repeat(4, 1fr); */
  gap: 24px;
  height: fit-content;
  /* background-color: #FFEDD0; */
  flex: 4;
  /* padding: 8px; */
  /* border: 1px solid #EFF2F7; */
`;
const DiyProduct = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  cursor: pointer;
  padding: 24px 12px 16px;
  outline: 4px solid #EFF2F7;
  background-color: #FCFCFD;
  border-radius: 12px;
  width: 100%;
  max-width: 240px;
  height: 355px;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
  0px 2px 4px -2px rgba(16, 24, 40, 0.06);


  &.active {
    outline: 4px solid #2C3D92;
    background: #F3F5FB;
  }

  .bImage {
    display: flex;
    justify-content: center;
    /* background-color: blue; */
    background-color: #FFF;
    /* max-width: 300px; */
    /* max-height: 200px; */
    img {
      width: 100%;
      height: 200px;
      object-fit: contain;
    }
  }
  .bDesc {
    display: flex;
    flex-direction: column;
    gap: 12px;

    p {
      margin: 0;
      font-size: 14px;
      font-weight: 600;
      display: -webkit-box; /* ใช้สำหรับการสร้าง container แบบ flexbox */
      -webkit-line-clamp: 2; /* จำนวนบรรทัดที่ต้องการแสดง */
      -webkit-box-orient: vertical; /* กำหนดการจัดเรียงของ text เป็นแนวตั้ง */
      overflow: hidden; /* ซ่อนข้อความที่เกิน */
      text-overflow: ellipsis; /* เพิ่ม ... เมื่อข้อความถูกตัด */
      word-break: break-word; /* ตัดคำให้อัตโนมัติ */
    span {
      color: #98A2B3;
    }
  }
  }
  .bPrice {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    p {
      font-size: 20px;
      line-height: 44px;
      color: #F04438;
      font-weight: 500;
      margin: 0;

    }
  }
  
`;



const ProductInSetInfo = ({
  validation = null,
  pageView = "create",
  dropdownOption = {
    category: [],
    brand: [],
    attributeSet: [],
    productDiy: []
  },
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);
  const [ partFilterAll, setPartFilterAll ] = useState({})
  const [ selectPart, setSelectPart ] = useState("cpu")
  const ddComputerDiyCate = dropdownOption?.computerDiyCate ?? []
  const productObjects = dropdownOption?.productDiy ?? []

  // const [ productForms, setProductForms ] = useState([])
  const productForms = validation.values.productDiy ?? []

  const setProductForms = (newState) => {
    validation.setFieldValue("productDiy", newState)
  }

  const FN = {
    getProductByCode: (_productObjects = {}, attributeSetCode= 'xx') => {
      if(_productObjects[attributeSetCode]) {
        return _productObjects[attributeSetCode]
      }
      return []
    },
    getComputerDiyCateByCode: (_computerDiyCate = {}, attributeSetCode= 'xx') => {
      console.log("🚀 ~ _computerDiyCate:", _computerDiyCate)
      return _computerDiyCate.find(item => item.attributeSetCode === attributeSetCode)
    }
  }

  const createProductForm = (resData = []) => {
    let _productForms = []
    for (let rd of resData) {
      const tmpData = {
        label: rd.label,
        attributeSetCode: rd.attributeSetCode,
        productDiyIds: [
          // {
          //   id: null,
          //   productId: null,
          //   productDiyId: null,
          //   sku: null,
          //   quantity: 0,
          //   slotQuantity: 0,
          // },
        ],
        isCustomizable: "0",
        maxSlotQuantity: rd.maxSlotQuantity,
      }
      console.log("🚀 ~ createProductForm ~ tmpData:", tmpData)
      _productForms.push(tmpData)
    }
    setProductForms(_productForms)
  }

  const onSelectProduct = (productSelect = {}, attributeSetCode = 'xx') => {
    console.log("🚀 ~ onSelectProduct ~ productSelect:", productSelect)
    console.log("🚀 ~ onSelectProduct ~ attributeSetCode:", attributeSetCode)
    const _productForms = _.cloneDeep(productForms)
    const attributeKey = productSelect?.attributeKey ?? {}
    const tmpUpdate = _productForms.map(item => {
      console.log("🚀 ~ onSelectProduct ~ attributeSetCode: edit", attributeSetCode)
      let tmpProductDiyId = {
        id: null,
        productId: null,
        productDiyId: productSelect.id,
        sku: productSelect.sku,
        quantity: 1,
        slotQuantity: 1,
      }
      console.log("🚀 ~ onSelectProduct ~ item.productDiyIds.length:", item.productDiyIds.length)
      console.log("🚀 ~ onSelectProduct ~ item.slotQuantity:", item)

      const keySlotNo = attributeSetCode + '-slot-no'
      if (attributeKey[keySlotNo]) {
        tmpProductDiyId.slotQuantity = Number(`${attributeKey[keySlotNo]}`)
      }

      if (item.attributeSetCode === attributeSetCode) {
        
        console.log('ck item0: ', item)
        console.log('ck item1: ', item.productDiyIds.length)
        console.log('ck item2: ', item.maxSlotQuantity)

        let productCount = item.productDiyIds.reduce((sum, itema) => sum + (itema.quantity * itema.slotQuantity), 0)
        productCount = productCount + tmpProductDiyId.slotQuantity
        // alert(productCount)

        if (item.maxSlotQuantity === 1) {
          item.productDiyIds.pop(); // เอาตัวสุดท้ายออก
          item.productDiyIds.splice(0, 0, tmpProductDiyId);  // แทรกสมาชิกใหม่ที่ตำแหน่งแรก (index 0)
        }
        else if (productCount > item.maxSlotQuantity) {
          alertNoAdd(attributeSetCode)
        }
        else {
          const productId = productSelect.id
          const findDataIndex = item.productDiyIds.findIndex(row => row.productDiyId === productId)
          if (findDataIndex !== -1) {
            // alert("ตัวเดิม")
            item.productDiyIds[findDataIndex].quantity = item.productDiyIds[findDataIndex].quantity + 1
          } else {
            item.productDiyIds.push(tmpProductDiyId)
          }
        }
      }


      if (attributeSetCode === 'mainboard') {
        // alert("กำลังเลือก mainboard")
        if (item.attributeSetCode === 'ram') {
          // alert('แก้ไข ram')
          console.log('item >> productSelect', productSelect)
          console.log('item >> loop', item)
          const maxRamSlot = attributeKey['ram-slot-no']
          item.maxSlotQuantity = maxRamSlot
          let productCount = item.productDiyIds.reduce((sum, itema) => sum + (itema.quantity * itema.slotQuantity), 0)
          productCount = productCount + tmpProductDiyId.slotQuantity
          if (productCount > maxRamSlot) {
            item.productDiyIds = []
          }
        }

        if (item.attributeSetCode === 'm2') {
          // alert('แก้ไข ram')
          console.log('item >> productSelect', productSelect)
          console.log('item >> loop', item)
          const maxRamM2 = attributeKey['m2-slot-no']
          item.maxSlotQuantity = maxRamM2
          let productCount = item.productDiyIds.reduce((sum, itema) => sum + (itema.quantity * itema.slotQuantity), 0)
          productCount = productCount + tmpProductDiyId.slotQuantity
          if (productCount > maxRamM2) {
            item.productDiyIds = []
          }
        }

        if (item.attributeSetCode === 'storage') {
          // alert('แก้ไข ram')
          console.log('item >> productSelect', productSelect)
          console.log('item >> loop', item)
          const maxStorage = attributeKey['storage-sata-data-con-slot-no']
          item.maxSlotQuantity = maxStorage
          let productCount = item.productDiyIds.reduce((sum, itema) => sum + (itema.quantity * itema.slotQuantity), 0)
          // productCount = productCount + tmpProductDiyId.slotQuantity
          if (productCount > maxStorage) {
            item.productDiyIds = []
          }
        }
      }

      if (attributeSetCode === 'ram') {
        // alert("กำลังเลือก ram")
      }

      if (attributeSetCode === 'psu') {
        // alert("กำลังเลือก psu")
        if (item.attributeSetCode === 'storage') {
          // alert('แก้ไข ram')
          console.log('item >> productSelect', productSelect)
          console.log('item >> loop', item)
          const maxStorage = attributeKey['storage-sata-pwr-con-slot-no']
          item.maxSlotQuantity = maxStorage
          let productCount = item.productDiyIds.reduce((sum, itema) => sum + (itema.quantity * itema.slotQuantity), 0)
          // productCount = productCount + tmpProductDiyId.slotQuantity
          if (productCount > maxStorage) {
            item.productDiyIds = []
          }
        }
      }
      return item
    })

    setProductForms(tmpUpdate)
  }

  const alertNoAdd = (attributeSetCode = 'xx') => {
    mAlert.info({
      title: "Alert",
      subTitle: attributeSetCode + ` สินค้าเกินจำนวนที่กำหนดไว้`,
      content: "",
      onClose: () => {
      },
    })
  }

  const fnDefaultForm = (_productForms = []) => {
    let __productForms = _.cloneDeep(_productForms)
    for (let pf of __productForms) {
      // console.log('pf.attributeSetCode >>', pf.attributeSetCode)
      if (pf.attributeSetCode === 'ram') {
        console.log("🚀 ~ useEffect ~ pf.attributeSetCode:", pf.attributeSetCode)
        const mainboardForm = __productForms.find(item => item.attributeSetCode === 'mainboard')
        // เขียนเพิ่มได้
        if (mainboardForm.productDiyIds.length === 0) {
          pf.maxSlotQuantity = 4
        }
        console.log("🚀 ~ useEffect ~ mainboardForm:", mainboardForm)
      }
      
      if (pf.attributeSetCode === 'm2') {
        console.log("🚀 ~ useEffect ~ pf.attributeSetCode:", pf.attributeSetCode)
        const mainboardForm = __productForms.find(item => item.attributeSetCode === 'mainboard')
        // เขียนเพิ่มได้
        if (mainboardForm.productDiyIds.length === 0) {
          pf.maxSlotQuantity = 1
          pf.productDiyIds = []
        }
        console.log("🚀 ~ useEffect ~ mainboardForm:", mainboardForm)
      }

      if (pf.attributeSetCode === 'storage') {
        console.log("🚀 ~ useEffect ~ pf.attributeSetCode:", pf.attributeSetCode)
        const mainboardForm = __productForms.find(item => item.attributeSetCode === 'mainboard')
        const psuForm = __productForms.find(item => item.attributeSetCode === 'psu')
        // เขียนเพิ่มได้
        if (mainboardForm.productDiyIds.length === 0 && psuForm.productDiyIds.length === 0) {
          pf.maxSlotQuantity = 4
          pf.productDiyIds = []
        }


        console.log("🚀 ~ useEffect ~ mainboardForm:", mainboardForm)
      }
    }
    return __productForms
  }


  useEffect(() => {
    if (pageView === 'create' && ddComputerDiyCate.length) {
      createProductForm(ddComputerDiyCate)
    }
  },[ddComputerDiyCate])

  useEffect(() => {
    const { filter, filterAll } = fn_comset.convertToPartFilter(productForms, productObjects)
    setPartFilterAll(filterAll)
  },[productForms])

  const onDelProductForm = (attributeSetCode = 'xx', indexDelete = -1) => {
    const _productForms = _.cloneDeep(productForms)
    let tmpUpdate = _productForms.map(item => {
      if (item.attributeSetCode === attributeSetCode) {
        item.productDiyIds = item.productDiyIds.filter((pd, index) => index !== indexDelete)
      }
      return item
    })

    tmpUpdate = fnDefaultForm(tmpUpdate) // Default ค่าถ้าถูกลบอะไรบางอย่าง
    setProductForms(tmpUpdate)
  }

  console.log('productForms >>', productForms)

  const fnMergeFilter = (_filterAll = {}, attributeSetCode = 'xx') => {
    let filterAll = _.cloneDeep(_filterAll)
    let ans = {}
    if (filterAll[attributeSetCode]) {
      delete filterAll[attributeSetCode]
    }

    for (let key in filterAll) {
      const tmp = filterAll[key]
      ans = { ...ans, ...tmp }
    }
    return ans
  } 

  const fnPickItem = (productItem = {}, productForms = [], attributeSetCode = 'xx') => {
    // console.log("🚀 ~ fnPickItem ~ productForms:", productForms)
    // console.log("🚀 ~ fnPickItem ~ productItem:", productItem)
    // console.log("🚀 ~ fnPickItem ~ attributeSetCode:", attributeSetCode)
    const productFormsByCode = productForms.find(item => item.attributeSetCode === attributeSetCode)
    // console.log("🚀 ~ fnPickItem ~ productFormsByCode:", productFormsByCode)
    if (_.isEmpty(productFormsByCode) === false) {
      const productIds = productFormsByCode.productDiyIds.map(item =>item.productDiyId)
      // console.log("🚀 ~ fnPickItem ~ productFormsByCode: xxx", productFormsByCode)
      // console.log("🚀 ~ fnPickItem ~ productIds:", productIds)
      if (productIds.includes(productItem?.id)) {
        return true
      }
      
    }
    return false
  }

  const productListByCode = FN.getProductByCode(productObjects, selectPart)
  const partFilter = fnMergeFilter(partFilterAll, selectPart)
  const computerDiyCateByCode = FN.getComputerDiyCateByCode(ddComputerDiyCate, selectPart)
  let filterUse = fn_comset.fnFindFilterUse({ main: computerDiyCateByCode?.partFilterMain ?? {}, optional: {} }, partFilter )
  const sumFilter = fn_comset.sumFilterByConfig(selectPart, partFilterAll)
  filterUse = { ...filterUse, ...sumFilter }
  const productShow = fn_comset.searchPartWithFilter(selectPart, productListByCode, filterUse )
  // const productShow = productListByCode
  const debug = true
  return (
    <>
      <Row className="mb-3">
        <Col className="col-12">
          <h5 className="mb-3" 
          title={
            JSON.stringify({
              partFilterAll: partFilterAll,
              partFilter: partFilter,
              filterUse: filterUse,
            }, null, 2)
          }
          >Product In Set</h5>
          <hr/>
        </Col>
        <Col className="col-12">
        {/* {JSON.stringify(ddComputerDiyCate)} */}
        {
          debug && (
            <>
              {/* <h5 className="mb-3">productForms : {JSON.stringify(productForms,null,2)}</h5> */}
              {/* <br/> */}
              <br/>
              <p className="mb-3">partFilterAll : {JSON.stringify(partFilterAll, null, 2)}</p>
              <p className="mb-3">partFilter : {JSON.stringify(partFilter, null, 2)}</p>
              {/* <p className="mb-3">computerDiyCateByCode : {JSON.stringify(computerDiyCateByCode, null, 2)}</p> */}
              <br/>
              <p className="mb-3">filterUse : {JSON.stringify(filterUse, null, 2)}</p>
            </>
          )
        }
        </Col>
        <Col className="col-12">
          <DiyBoxMain>
            <DiySelectMain>
              {productForms.map((item0, i0) => {

                const productSelectQty = item0.productDiyIds.reduce((sum, item) => sum + (item.quantity * item.slotQuantity), 0)
                console.log(`productSelectQty >> ${item0.attributeSetCode}`, productSelectQty)
                const isFull = productSelectQty === item0.maxSlotQuantity
                const isError = item0.isCustomizable == "1" && item0.productDiyIds?.length === 0
                const isPass = item0.productDiyIds?.length > 0
                return (
                  <DiySelect
                    className={classnames({ active: item0.attributeSetCode === selectPart, error: isError , pass: isPass })}
                    key={i0}
                    onClick={() => {
                      setSelectPart(item0.attributeSetCode)
                    }}
                  >
                    {/* <div >{JSON.stringify(item0, null, 2)}</div> */}
                    <div className="diy-row">
                      <div className="diy-col">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          {item0.label} ( {productSelectQty} / {item0.maxSlotQuantity} )
                        </label>
                      </div>
                      <div className="diy-col no-margin">
                        <InputSwitch
                          labelTrue="Yes"
                          labelFalse="No"
                          value={item0.isCustomizable == "1"}
                          onChange={(value)=> {
                            const updateValue = value ? '1' : '0'
                            const _productForms = _.cloneDeep(productForms)
                            const tmpUpdate = _productForms.map((item, ii0) => {
                              if (i0 === ii0) {
                                item.isCustomizable = updateValue
                              }
                              return item
                            })
                            setProductForms(tmpUpdate)
                          }}
                          // disabled={["view"].includes(pageView)}
                        />
                        </div>
                      </div>
                      {
                        item0.productDiyIds.map((item1, i1) => {

                          const productByCode = FN.getProductByCode(productObjects, item0.attributeSetCode)
                          const productSelect = productByCode.find(pd => pd.id === item1.productDiyId)

                          return(
                            <div className="product-select-main"  key={i1} >
                              <div 
                                className="product-select"
                              >
                                {/* {JSON.stringify(item1)} */}
                                <div className="bImg">
                                  <img className="pImg" src={productSelect?.image}/>
                                </div>
                                <p className="pName">{productSelect?.name}</p>
                              
                              </div>
                              <div className="product-control">
                                <div className="btn-up-down">
                                  <div onClick={() => {
                                    const _productForms = _.cloneDeep(productForms)
                                    const tmpUpdate = _productForms.map((item,ii0) => {
                                      if (i0 === ii0) {
                                        item.productDiyIds = item.productDiyIds.map((pd, ii1) => {
                                          if (i1 === ii1) {
                                            pd.quantity = pd.quantity > 1 ? pd.quantity - 1 : 1
                                          }
                                          return pd
                                        })
                                      }
                                      return item
                                    })
                                    setProductForms(tmpUpdate)
                                  }}>-</div>
                                  <Input
                                    readOnly={true}
                                    className="form-control"
                                    type="number"
                                    value={item1?.quantity}
                                    onChange={(e) => {
                                      const newValue = e.target.value
                                      const _productForms = _.cloneDeep(productForms)
                                      const tmpUpdate = _productForms.map((item,ii0) => {
                                        if (i0 === ii0) {
                                          item.productDiyIds = item.productDiyIds.map((pd, ii1) => {
                                            if (i1 === ii1) {
                                              pd.quantity = newValue
                                            }
                                            return pd
                                          })
                                        }
                                        return item
                                      })
                                      setProductForms(tmpUpdate)
                                    }}
                                    // onBlur={validation.handleBlur}
                                    min={0}
                                    max={item0.maxSlotQuantity}
                                    disabled={item0.maxSlotQuantity == 1}
                                  />
                                  <div onClick={() => {
                                    const _productForms = _.cloneDeep(productForms)
                                    const tmpUpdate = _productForms.map((item, ii0) => {
                                      let productSelectQty = item.productDiyIds.reduce((sum, itemx) => sum + (itemx.quantity * itemx.slotQuantity), 0)
                                      if (i0 === ii0) {
                                        item.productDiyIds = item.productDiyIds.map((pd, ii1) => {
                                          if (i1 === ii1) {
                                            // alert('productSelectQty xx' + productSelectQty)
                                            productSelectQty = productSelectQty + (pd.quantity * pd.slotQuantity)
                                            // pd.quantity = productSelectQty < item.maxSlotQuantity ? pd.quantity + 1 : item.maxSlotQuantity
                                            if (productSelectQty <= item.maxSlotQuantity) {
                                              pd.quantity =  pd.quantity + 1
                                            } else {
                                              alertNoAdd(item.attributeSetCode)
                                            }
                                          }
                                          return pd
                                        })
                                      }
                                      return item
                                    })
                                    setProductForms(tmpUpdate)
                                  }}>+</div>
                                </div>
                                <Button 
                                  type="button" 
                                  color="primary" 
                                  onClick={() => {
                                    onDelProductForm(item0.attributeSetCode, i1)
                                  }}
                                >
                                  DELETE
                                </Button>
                            </div>
                            </div>
                          )
                        })
                      }
                      { (item0.attributeSetCode === selectPart && productSelectQty < item0.maxSlotQuantity) && (
                        <div className="cur-select">
                          <p>+</p>
                          <p className="">Select {`${selectPart}`.toUpperCase()}</p>
                        </div>
                      )}
                  </DiySelect>
                )
              })}
            </DiySelectMain>
            <DiyProductMain>
              {productShow.map((item0, i0) => {
                return (
                  <DiyProduct 
                    key={i0}
                    className={classnames({ active: fnPickItem(item0, productForms, selectPart) })}
                    onClick={() => {
                      onSelectProduct(item0, selectPart,)
                    }}
                  >
                    <div className="bImage">
                      <img src={item0.image}/>
                    </div>
                    <div className="bDesc" >
                      <p style={{ minHeight: '34px'}}title={debug || true ? JSON.stringify(item0.attributeKey,null,2) : ''}>{item0.name}</p>
                      { debug && (<h6 style={{ zIndex: 999 }}>{JSON.stringify(item0.attributeKey,null,2)}</h6>)}
                      {/* <p style={{ minHeight: '34px' }}><span>{item0.description}</span></p> */}
                    </div>
                    {/* <p>{item0.name}</p> */}
                    <div className="bPrice">
                      <p>฿ {fn_helper.FN.toNumberWithCommas(item0.price)}</p>
                      <BtnAddPart/>
                    </div>
                    
                    
                  </DiyProduct>
                )
              })}
            </DiyProductMain>
          </DiyBoxMain>
        </Col>
      </Row>
    </>
  )
}
export default withTranslation()(ProductInSetInfo)


const BtnAddPart = () => {
  const BtnAdd = styled.div`
  padding: 12px;
    /* background-image: none; */
    /* background: url("./assets"); */
    background-color: #2C3D92;
    /* padding: 10px; */
    min-width: 44px;
    width: 44px;
    min-height: 44px;
    border-radius: 8px;
  `
  return (
    <BtnAdd>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_10999_11863)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99998 2.49999C5.85784 2.49999 2.49998 5.85786 2.49998 9.99999C2.49998 14.1421 5.85784 17.5 9.99998 17.5C14.1421 17.5 17.5 14.1421 17.5 9.99999C17.5 5.85786 14.1421 2.49999 9.99998 2.49999ZM0.833313 9.99999C0.833313 4.93738 4.93737 0.833328 9.99998 0.833328C15.0626 0.833328 19.1666 4.93738 19.1666 9.99999C19.1666 15.0626 15.0626 19.1667 9.99998 19.1667C4.93737 19.1667 0.833313 15.0626 0.833313 9.99999ZM9.99998 5.83333C10.4602 5.83333 10.8333 6.20642 10.8333 6.66666V9.16666H13.3333C13.7935 9.16666 14.1666 9.53976 14.1666 9.99999C14.1666 10.4602 13.7935 10.8333 13.3333 10.8333H10.8333V13.3333C10.8333 13.7936 10.4602 14.1667 9.99998 14.1667C9.53974 14.1667 9.16665 13.7936 9.16665 13.3333V10.8333H6.66665C6.20641 10.8333 5.83331 10.4602 5.83331 9.99999C5.83331 9.53976 6.20641 9.16666 6.66665 9.16666H9.16665V6.66666C9.16665 6.20642 9.53974 5.83333 9.99998 5.83333Z"
          fill="#FCFCFD"
        />
      </g>
      <defs>
        <clipPath id="clip0_10999_11863">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
    </BtnAdd>
  );
};