import React from "react";

const EmptyTableBody = () => {
    return (
        <tr className="sc-ghWlax eQCDMA">
            <td colSpan="7" className="sc-kLhKbu lbpXmo">
                <p>No data available</p>
            </td>
        </tr>
    );
};

export default EmptyTableBody;
