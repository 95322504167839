import PropTypes from "prop-types"
import React, {
    useEffect,
    useState,
    useMemo,
    useCallback,
    useContext,
} from "react"
import {
    Input,
    FormFeedback,
} from "reactstrap"
import Select from "react-select"

import { Link, useNavigate, useParams } from "react-router-dom"

//import components
import TableContainer from "../../../components/Common/TableContainer"
import { withTranslation } from "react-i18next"

import { get } from "helpers/api_helper"
import { checkScope } from "helpers/utility_helper"
import "../../../components/Common/datatables.scss"
import Spinners from "components/Common/Spinner"

import ModalContext from "../../../contexts/ModalContext"
import moment from "moment"
import InputSwitch from "components/Input/InputSwitch"
import {
    DeleteProducts,
    GetProductByProductCateId,
    PatchProducts,
    SearchProducts,
} from "services/api/module/Products"
import fn_helper from "helpers/fn_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import InputText from "components/Input/InputText"
import { HeaderColumn } from "components/Common/HeaderColumn"
import EmptyTableBody from "components/Common/EmptyTableBody"
import InputSelect from "components/Input/InputSelect"

const DISCOUNT_TYPE_OPTION = [
    { id: "FIXED", name: "Fixed Amount Off" },
    { id: "PERCENT", name: "Percentage Off" },
]

const tableColumn = ["", "SKU", "Name", "Thumbnail", "Price Type", "Price Amount", "Discount Type", "Discount Value", "Price Calculate", "Stock Type", "Stock Amount", "is Customer Limit", "Customer Limit", "isFastShipping", "Status"]

const PromotionMainContainer = ({
    cateId = "",
    pageView = "view",
}) => {
    // ========== Form Validation ========== //
    const validationSchema = Yup.object({

        promotionMainProductList: Yup.array().of(
            Yup.object().shape({
                priceAmount: Yup.number().when("priceType", {
                    is: value => value === false,
                    then: schema =>
                        schema
                            .required("Please Enter price Amount")
                }),
                discountValue: Yup.number().when("discountType", {
                    is: value => value === false,
                    then: schema =>
                        schema
                            .required("Please Enter discount Value")
                }),
                stockAmount: Yup.number().when("stockType", {
                    is: value => value === false,
                    then: schema =>
                        schema
                            .required("Please Enter Stock Amount")
                }),
                customerLimit: Yup.number().when("isCustomerLimit", {
                    is: value => value === false,
                    then: schema =>
                        schema
                            .required("Please Enter Customer Limit")
                }),

            })
        ),
    })

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            promotionMainProductList: [],
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            // log
        },
    })

    // POPUP
    const { mAlert } = useContext(ModalContext);

    // LOCAL STATE
    const [contentList, setContentList] = useState([])
    const [contentListLoading, setContentListLoading] = useState(false)
    const [selectedContentId, setSelectedContentId] = useState([])

    const onSelectionChange = (selectedValue = {}, oldValues = [], option = [], mode = "") => {
        const selectedId = selectedValue?.id
        const tmpOldValues = _.cloneDeep(oldValues)
        const findOldValue = tmpOldValues.filter(id => id === selectedId)

        let updateState = []
        if (findOldValue && findOldValue.length) {
            updateState = tmpOldValues.filter(id => id != selectedId)
        } else {
            updateState = tmpOldValues
            updateState.push(selectedId)
        }

        if (mode === "all") {
            if (tmpOldValues.length >= option.length) {
                updateState = []
            } else {
                updateState = option.map(item => item.id)
            }
        }

        let contenSeleted = contentList.filter((item) => updateState.includes(item.id));
        contenSeleted = contenSeleted.map((item) => {
            return {
                sku: item.sku,
                name: item.nameTh,
                gallery: item.gallery,
                priceType: true,
                priceAmount: null,
                discountType: false,
                discountValue: 0,
                priceCalculate: 0,
                stockType: false,
                stockAmount: 0,
                isCustomerLimit: false,
                customerLimit: 0,
                isFastShipping: true,
                status: true,
            }
        });

        validation.setFieldValue("promotionMainProductList", contenSeleted)

        return updateState
    };


    const columns = useMemo(
        () => [
            {
                id: "colcheckbox",
                header: cellProps => {
                    const row = cellProps?.row?.original
                    return (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Input
                                type={`checkbox`}
                                checked={contentList.length === selectedContentId.length}
                                onClick={() => {
                                    let updateData = onSelectionChange(
                                        row,
                                        selectedContentId,
                                        contentList,
                                        "all"
                                    )
                                    setSelectedContentId(updateData)
                                }}
                                readOnly
                            />
                        </div>
                    )
                },
                enableColumnFilter: false,
                enableSorting: false,
                cell: cellProps => {
                    const row = cellProps?.row?.original
                    return (
                        <>
                            <div
                                id={cellProps.row.id}
                                style={{ display: "flex", justifyContent: "center" }}
                            >
                                <Input
                                    type={`checkbox`}
                                    value={cellProps.row.original.id}
                                    checked={selectedContentId.includes(row.id)}
                                    onClick={() => {
                                        let updateData = onSelectionChange(
                                            row,
                                            selectedContentId,
                                            contentList
                                        )
                                        setSelectedContentId(updateData)
                                    }}
                                    readOnly
                                />
                            </div>
                        </>
                    )
                },
            },
            {
                header: "SKU",
                accessorKey: "sku",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const row = cellProps?.row?.original
                    return row.sku ? fn_helper.FN.splitText(row.sku, 30) : ''
                }
            },
            {
                header: "Name TH",
                accessorKey: "nameTh",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const row = cellProps?.row?.original
                    return row.nameTh ? fn_helper.FN.splitText(row.nameTh, 30) : ''
                }
            },
            {
                header: "NameEN",
                accessorKey: "nameEn",
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const row = cellProps?.row?.original
                    return row.nameEn ? fn_helper.FN.splitText(row.nameEn, 30) : ''
                }
            },
            {
                header: "Thumbnail",
                accessorKey: "thumbnail",
                enableColumnFilter: false,
                enableSorting: true,
                cell: cellProps => {
                    const [thumbnail = {}] = cellProps?.row?.original?.gallery
                    return <img className="bImageBox" src={thumbnail?.url} />
                },
            },
            {
                header: "Created At",
                accessorKey: "createdAt",
                enableColumnFilter: false,
                enableSorting: true,
                cell: cellProps => {
                    const row = cellProps?.row?.original
                    return row.createdAt ? moment(row.createdAt).format("DD/MM/YYYY") : ""
                },
            },
            {
                header: "Updated At",
                accessorKey: "updatedAt",
                enableColumnFilter: false,
                enableSorting: true,
                cell: cellProps => {
                    const row = cellProps?.row?.original
                    return row.updatedAt ? moment(row.updatedAt).format("DD/MM/YYYY") : ""
                },
            },
        ],
        [selectedContentId, contentList]
    )

    const API = {
        fetchContentList: async () => {
            try {
                const response = await GetProductByProductCateId({ id: cateId })
                const resData = response?.data ?? []
                setContentList(resData.data)
            } catch (e) {
                console.log(e)
                mAlert.info({
                    title: "Alert",
                    subTitle: "Something went wrong",
                    content: "",
                    onClose: () => {
                        //  alert("onClose")
                    },
                })
            } finally {
                setContentListLoading(false)
            }
        },
    }

    const fetchAPI = () => {
        const payload = { id: cateId }
        API.fetchContentList(payload)
    }

    useEffect(() => {
        setContentListLoading(true)
        fetchAPI()
    }, [cateId])

    useEffect(() => {
        if (contentList.length) {
            setContentListLoading(false)
        }
    }, [contentList])
    //meta title
    document.title = "PromotionMain | " + process.env.REACT_APP_CMS_TITLE;

    return (
        <div className="">
            <div className="container-fluid">

                <pre>{JSON.stringify(validation.values, null, 2)}</pre>

                <div className="table-responsive">
                    <table className="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline table table-hover">
                        <HeaderColumn column={tableColumn} />
                        <tbody>
                            {validation.values.promotionMainProductList.length ?
                                validation.values.promotionMainProductList.map((item, index) => (
                                    <tr key={index} className="align-middle">
                                        <td>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    value={item.id}

                                                />
                                            </div>
                                        </td>
                                        <td>{item.sku}</td>
                                        <td>{item.name}</td>
                                        <td>
                                            {item.gallery.length && item.gallery.map((item2, index2) => {
                                                return (
                                                    <img className="bImageBox" key={index2} src={item2.url} alt={item2} />
                                                )
                                            })
                                            }

                                        </td>
                                        <td>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    value={item.priceType}
                                                    onChange={(e) => {
                                                        let tmpArray = _.cloneDeep(validation.values.promotionMainProductList)
                                                        tmpArray[index].priceType = e.target.checked
                                                        validation.setFieldValue(`promotionMainProductList[${index}].priceType`, e.target.checked)
                                                    }}
                                                />
                                            </div>

                                        </td>
                                        <td>

                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <InputText
                                                    className="form-control"
                                                    type="number"
                                                    placeholder=""
                                                    required={true}
                                                    value={item.priceAmount}
                                                    onChange={(newValue) => {
                                                        let tmpArray = _.cloneDeep(validation.values.promotionMainProductList)
                                                        tmpArray[index].priceAmount = newValue
                                                        validation.setFieldValue(`promotionMainProductList[${index}].priceAmount`, newValue)
                                                    }}

                                                    isError={validation.touched.promotionMainProductList?.[index]?.priceAmount && validation.errors.promotionMainProductList?.[index]?.priceAmount ? true : false}
                                                    labelError={validation.errors.promotionMainProductList?.[index]?.priceAmount}
                                                    disabled={validation.values.promotionMainProductList?.[index]?.priceType ? true : false}
                                                />
                                            </div>


                                        </td>
                                        <td>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <InputSelect
                                                    placeholder={`Select`}
                                                    value={item.discountType}
                                                    onChange={(newValue) => {
                                                        let tmpArray = _.cloneDeep(validation.values.promotionMainProductList)
                                                        tmpArray[index].discountType = newValue
                                                        validation.setFieldValue(`promotionMainProductList[${index}].discountType`, newValue)
                                                    }}
                                                    isClearable={true}
                                                    options={DISCOUNT_TYPE_OPTION}
                                                    disabled={false}

                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <input type="text" className="form-control" value={item.discountValue} />
                                            </div>
                                        </td>
                                        <td>{item.priceCalculate}</td>
                                        <td>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <input type="checkbox" className="form-check-input" checked={item.stockType} />
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <input type="text" className="form-control" value={item.stockAmount} />
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={item.isCustomerLimit}
                                                    readOnly
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <input type="text" className="form-control" value={item.customerLimit} />
                                            </div>
                                        </td>
                                        <td>
                                            <Switch isChecked={item.isFastShipping} />
                                        </td>
                                        <td>
                                            <Switch isChecked={item.status} />
                                        </td>
                                    </tr>
                                )) :
                                <EmptyTableBody />}
                        </tbody>
                    </table>
                </div>


            </div>
            <div className="container-fluid">
                <TableContainer
                    columns={columns}
                    data={contentList || []}
                    paginationWrapper="dataTables_paginate paging_simple_numbers"
                    tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                    rootMenu="systemsettings"
                    subMenu="usersetting"
                    isSelected={false}
                    loading={contentListLoading}

                />
            </div>
        </div>
    );
}



PromotionMainContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation()(PromotionMainContainer)

const Switch = ({ isChecked }) => (
    <div className="react-switch">
        <div
            className="react-switch-bg"
            style={{
                background: isChecked ? "rgb(52, 195, 143)" : "rgb(216, 216, 216)",
            }}
        >
            <div>{isChecked ? "Active" : "Inactive"}</div>
        </div>
        <input type="checkbox" checked={isChecked} readOnly />
    </div>
);
