import React, { useEffect, useState, useContext } from "react"
import { Card, Col, Row, TabPane } from "reactstrap"
import Select from "react-select"
import { withTranslation } from "react-i18next"
import _ from "lodash"
import fn_helper from "helpers/fn_helper"
import InputText from "components/Input/InputText"
import UploadFileAPI from "components/UploadFileAPI"
import InputSelect from "components/Input/InputSelect"
import { ReactSortable } from "react-sortablejs"
import { FieldArray } from "formik"
import FileUploadGallery from "components/FileUploadGallery"
import FormUpload from "./FormUpload"
import ImageViewer360 from "./ImageViewer360"


const Image360Info = ({
  validation = null,
  pageView = "create",
  dropdownOption = {
    category: [],
    brand: [],
    attributeSet: [],
  },
}) => {
  const schema = validation?.validationSchema

  let img360Show = []
  if (validation?.values?.img360?.length) {
    img360Show = validation?.values?.img360.map(item => item.url)
  }


  return (
    <>
      <Row className="mb-3">
        <Col className="col-12">
          <h5 className="mb-3">Image 360 Infomation</h5>
        </Col>
        <Col className="col-12">
        {/* {JSON.stringify(validation.values.img360)} */}
          <Row className="">
            <Col className="col-12 mb-3">
              <ImageViewer360 images={img360Show}/>
              <br/>
              <br/>
              <FileUploadGallery 
                setting={{
                  bucketName: "image-360",
                  upload: "Image",
                  typeUpload: "ImageMap",
                  prefixName: "image360",
                  label: "Upload Image 360",
                  required: fn_helper.FNPRODUCT.isFieldRequired(schema, 'img360'),
                  widthSize: 1024,
                  heightSize: 1024,
                  description: "description",
                }}
                value={validation.values.img360} 
                onChange={(newValue) => {
                  validation.setFieldValue("img360", newValue)
                }}
                invalid={validation.touched.img360 && validation.errors.img360 ? true : false}
                errorText={validation.errors.img360}
                pageView={pageView}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
export default withTranslation()(Image360Info)
