import React, { useRef, useEffect, useState } from "react";

import { ReactImageTurntable } from "react-image-turntable";
import empty360 from 'assets/images/empty-360-view.png'

// const images = Array.from({ length: 200 }, (_, i) => `./img/File${i + 1}.png`);

import styled, { keyframes } from 'styled-components';

const Box360 = styled.div`
  .bBtnHandle {
    display: flex;
    justify-content: center;
    .bGroup360 {
      display: flex;
      gap: 8px;
      .btnControl{
        cursor: pointer;
        width: 32px;
        height: 32px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        display: block;
        
        &.stop{
          background-image: url(https://jib-web-dev.wedv.io/assets/img/ci/product/ico-stop.svg);
        }
        &.rotate{
          background-image: url(https://jib-web-dev.wedv.io/assets/img/ci/product/ico-rotate.svg);
          
        }
        &.zoomIn{
          background-image: url(https://jib-web-dev.wedv.io/assets/img/ci/product/ico-zoomIn.svg);
        }
        &.zoomOut{
          background-image: url(https://jib-web-dev.wedv.io/assets/img/ci/product/ico-zoomOut.svg);
        }
        &.fullscreen{
          background-image: url(https://jib-web-dev.wedv.io/assets/img/ci/product/ico-fullscreen.svg);
        }
        &.move{
          background-image: url(https://jib-web-dev.wedv.io/assets/img/ci/product/ico-move.svg);
        }
      }
    }
  }
`
const ImageViewer360 = ({
  rotateSpeed = 100,
  movementSensitivity = 100,
  images = [],
}) => {

  const srcImage = images.length ? images : [empty360]
  const totalImages  = srcImage.length

  const [currentIndex, setCurrentIndex] = useState(0); // ดัชนีภาพปัจจุบัน
  const [getRotate, setGetRotate] = useState(true);
  const [zoomLevel, setZoomLevel] = useState(1); // ระดับการ Zoom
  const [openFullscreen, setOpenFullScreen] = useState(false);
  const [verticalOffset, setVerticalOffset] = useState(0); // Offset สำหรับเลื่อนแนวตั้ง
  const [imgMove, setImgMove] = useState(false);
  const [rotateStatus , setRotateStatus] = useState(false)

  const isDragging = useRef(false);
  const lastMouseX = useRef(0);
  const lastMouseY = useRef(0);
  const velocityX = useRef(0);
  const velocityY = useRef(0);
  const animationFrameId = useRef(null);

  // function Mouse drag sensitive
  const handleMouseDown = (e) => {
    isDragging.current = true;
    lastMouseX.current = e.clientX;
    lastMouseY.current = e.clientY;
    velocityX.current = 0;
    velocityY.current = 0;
    cancelAnimationFrame(animationFrameId.current);
  };

  const handleMouseMove = (e) => {
    if (!isDragging.current) return;
    const diffX = e.clientX - lastMouseX.current;
    const diffY = e.clientY - lastMouseY.current;
    velocityX.current = diffX * 0.2;
    velocityY.current = diffY * 0.2;
    lastMouseX.current = e.clientX;
    lastMouseY.current = e.clientY;

    setCurrentIndex((prevIndex) => {
      let newIndex = (prevIndex + Math.round(velocityX.current)) % totalImages;
      if (newIndex < 0) newIndex += totalImages;
      return newIndex;
    });
    {
      imgMove &&
        setVerticalOffset((prevOffset) => {
          const newOffset = prevOffset + diffY;
          const maxOffset = 200; // ค่าเลื่อนสูงสุด
          const minOffset = -200; // ค่าเลื่อนต่ำสุด

          return Math.max(minOffset, Math.min(newOffset, maxOffset));
        });
    }
  };

  const handleMouseUp = () => {
    isDragging.current = false;

    const applyInertia = () => {
      if (
        Math.abs(velocityX.current) > 0.1 ||
        Math.abs(velocityY.current) > 0.1
      ) {
        setCurrentIndex((prevIndex) => {
          let newIndex =
            (prevIndex + Math.round(velocityX.current)) % totalImages;
          if (newIndex < 0) newIndex += totalImages;
          return newIndex;
        });

        {
          imgMove &&
            setVerticalOffset((prevOffset) => {
              const newOffset = prevOffset + velocityY.current;
              const maxOffset = 200;
              const minOffset = -200;

              return Math.max(minOffset, Math.min(newOffset, maxOffset));
            });
        }
        velocityX.current *= 0.95;
        velocityY.current *= 0.95;

        animationFrameId.current = requestAnimationFrame(applyInertia);
      }
    };

    applyInertia();
  };

  // ฟังก์ชันสำหรับ Zoom In
  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => {
      const newZoom = Math.min(prevZoom + 0.5, 30); // ซูมเข้า (สูงสุด 3x)
      return newZoom;
    });
  };

  // ฟังก์ชันสำหรับ Zoom Out
  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => {
      const newZoom = Math.max(prevZoom - 0.5, 1); // ซูมออก (ต่ำสุด 1x)
      return newZoom;
    });
  };

  // ฟังก์ชั่น fullscreen
  const handleFullscreen = () => {
    if (openFullscreen) {
      setOpenFullScreen(false);
      document?.exitFullscreen();
      setVerticalOffset(0);
    } else {
      document.documentElement.requestFullscreen();
      setOpenFullScreen(true);
    }
  };

  useEffect(() => {
    // ล้าง animation frame เมื่อ component ถูก unmount
    return () => cancelAnimationFrame(animationFrameId.current);
  }, []);

  // handle rotate
  const handleRotate = () => {
    if(rotateStatus){
      setRotateStatus(false)
    }else{
      setRotateStatus(true)
    }
    setGetRotate(rotateStatus);
  };

  return (
      <div
        className="bModal bCard"
        id="ImageViewer360"
        style={{
          display: "block",
          borderRadius: openFullscreen ? "0" : "16px",
        }}
      >
        {/* <img images="../../../public/assets/img/imgProduct/File1.png" alt="" /> */}
        <div className="bInner" style={{ borderRadius: "0" }}>
          {!openFullscreen && (
            <div
              className="closeBtn btnCloseModal"
              onClick={() => {
                onClose();
                openFullscreen && document?.exitFullscreen();
              }}
            ></div>
          )}

          <div
            className="tContent"
            style={{ height: openFullscreen ? "100vh" : "530px" }}
          >
            <div
              className="bGroupContent "
              style={{
                overflow: "hidden",
                position: "relative",
                background: "#eeeeee",
                width: "100%",
                height: "100%",
              }}
            >
              <Box360
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    cursor: 'move',
                    width: "500px",
                    overflow: "hidden",
                    transform: `translateY(${verticalOffset}px) scale(${zoomLevel})`, // ซูมตามระดับ zoomLevel
                    transformOrigin: "center", // ซูมจากจุดกึ่งกลาง
                    transition: "transform 0.2s ease", // การเปลี่ยนแปลงแบบ smooth
                  }}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={handleMouseUp}
                >
                  <ReactImageTurntable
                    images={srcImage.length ? srcImage : []}
                    // images={images}
                    initialImageIndex={currentIndex} // ใช้ index ที่คำนวณไว้
                    autoRotate={{ disabled: getRotate, interval: rotateSpeed }} // disabled = true ปิดการหมุนอัตโนมัติ
                    loop={true}
                    movementSensitivity={movementSensitivity} // ปรับค่าตามต้องการ
                  />
                </div>
                <div
                  className="bBtnHandle"
                  style={{
                    marginTop: "10px",
                    position: "absolute",
                    bottom: "40px",
                    
                  }}
                >
                  <div className="bGroup360" style={{ position: "absolute" }}>
                  <div className="btnControl stop" onClick={() => handleRotate()}></div>
                    {/* <div className="btnControl rotate" onClick={() => handleRotate()}></div> */}
                    <div className="btnControl zoomIn" onClick={() => handleZoomIn()}></div>
                    <div className="btnControl zoomOut" onClick={() => handleZoomOut()}></div>
                    {/* <div
                      className="btnControl fullscreen"
                        onClick={() => {
                          handleFullscreen();
                          setZoomLevel(1);
                        }}
                    >
                    </div> */}
                    {/* <div
                      className="btnControl move"
                        onClick={() => {
                          if (imgMove) {
                            setImgMove(false);
                            setVerticalOffset(0);
                          } else {
                            setImgMove(true);
                          }
                        }}
                    >
                    </div> */}
                  </div>
                </div>
              </Box360>
            </div>
          </div>
        </div>
      </div>
  );
};

export default ImageViewer360;
