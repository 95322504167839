import React, { Fragment, useEffect, useState, useContext } from "react"
import { Row, Table, Button, Col, Card } from "reactstrap"
import { Spinner } from "reactstrap";
import { Link } from "react-router-dom"

import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    flexRender,
    onSortingChange,
} from "@tanstack/react-table"

import { rankItem } from "@tanstack/match-sorter-utils"
import { checkScope } from "helpers/utility_helper";
import { ExportJsonToExcel } from "./ExportJsonToExcel"
import fn_helper from "helpers/fn_helper"
import Select from "react-select"
import Sortable from "sortablejs"
import { SortTableFeature } from "services/api/module/TableFeature"
import ModalContext from "contexts/ModalContext"
import { TableEmpty, TableLoading } from "components/TableComponents";

// Column Filter
const Filter = ({ column }) => {
    const columnFilterValue = column.getFilterValue()

    return (
        <>
            <DebouncedInput
                type="text"
                value={columnFilterValue ?? ""}
                onChange={value => column.setFilterValue(value)}
                placeholder="Search..."
                className="w-36 border shadow rounded"
                list={column.id + "list"}
            />
            <div className="h-1" />
        </>
    )
}

// Global Filter
const DebouncedInput = ({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
}) => {
    const [value, setValue] = useState(initialValue)

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
        const timeout = setTimeout(() => {
        onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [debounce, onChange, value])

    return (
        <React.Fragment>
        <Col sm={4}>
            <input
            {...props}
            value={value}
            onChange={e => setValue(e.target.value)}
            />
        </Col>
        </React.Fragment>
    )
}

const TableContainer = ({
    columns,
    data,
    tableClass,
    theadClass,
    divClassName,
    isBordered,
    isPagination,
    isGlobalFilter,
    paginationWrapper,
    SearchPlaceholder,
    pagination,
    buttonClass,
    buttonName,
    isAddButton,
    addRowLink,
    exportLink,
    isCustomPageSize,
    handleUserClick,
    rootMenu,
    subMenu,
    isSelected = false,
    loading = false,
    noTop = false,
    onClearSelected = () => console.log('onClearSelected'),
    onDeleleSelected = () => console.log('onDeleleSelected'),
    isCustomFilter= false,
    customFilter = <></>,
    
    isExportButton,
    exportOption = { name: "excel-file", dateColumns: ['']},

    dropdownFilter = [],
    sortTable={
        tableName: 'table_name',
        db: 'sql',
        fetchCallBack: () => alert('fetchCallBack')
    }
}) => {
    // POPUP
    const { mAlert } = useContext(ModalContext);

    const [sorting, setSorting] = useState()
    const [isLoadingSort, setIsLoadingSort] = useState(false)

    const [columnFilters, setColumnFilters] = useState([])
    const [globalFilter, setGlobalFilter] = useState("")

    // OPTION FILTER
    const [optionFilter, setOptionFilter] = useState({})

    const fuzzyFilter = (row, columnId, value, addMeta) => {
        console.log("🚀 ~ fuzzyFilter ~ row:", row)
        console.log("🚀 ~ fuzzyFilter ~ columnId:", columnId)
        console.log("🚀 ~ fuzzyFilter ~ value:", value)
        console.log("🚀 ~ fuzzyFilter ~ addMeta:", addMeta)
        const itemRank = rankItem(row.getValue(columnId), value)
        console.log('rankItem',itemRank)
        addMeta({
            itemRank,
        })
        return itemRank.passed
    }
    const fuzzyFilter2 = (row, columnId, value, addMeta) => {
        console.log("🚀 ~ fuzzyFilter2 ~ row:", row)
        console.log("🚀 ~ fuzzyFilter2 ~ columnId:", columnId)
        console.log("🚀 ~ fuzzyFilter2 ~ value:", value)
        console.log("🚀 ~ fuzzyFilter2 ~ addMeta:", addMeta)
        const itemRank = rankItem(row.getValue(columnId), value)
        addMeta({
            itemRank,
        })
        return itemRank.passed
    }

    // const changeSortingCss = () => {
    //     // table.getHeaderCheader.column.getToggleSortingHandler()
    //     console.log(table.getState().sorting)
    // }

    const fnFilterDropDown = (_rowData = [], _filters = {}) => {
        console.log('_filters!!!', _filters)
        // for (let ft in _filters) {
        //     _rowData[ft.key]
        // }
        _rowData = _rowData.filter(item => {
            let isPass = true
            if (Object.keys(_filters)) {
                isPass = false
                for (let ft in _filters) {
                    _rowData[ft.key]
                }
            }
            return isPass
        })
        return _rowData
    }

    const table = useReactTable({
        columns,
        // data: fnFilterDropDown(data, optionFilter),
        data: data,
        filterFns: {
            fuzzy: fuzzyFilter,
            optionFN: fuzzyFilter,
        },
        state: {
            columnFilters,
            globalFilter,
            sorting,
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        // debugAll: true
        // onStateChange: changeSortingCss
    })

    const {
        getHeaderGroups,
        getRowModel,
        getCanPreviousPage,
        getCanNextPage,
        getPageOptions,
        setPageIndex,
        nextPage,
        previousPage,
        // setPageSize,
        getState,
    } = table

    const API = {
        fetchSortTable: async (payload) => {
            try {
                setIsLoadingSort(true)
                const response = await SortTableFeature({ data: payload })
                const resData = response.data
                if (resData.status === 202) {
                    sortTable.fetchCallBack()
                }
            } catch (err) {
                console.log('err ', err)
                mAlert.info({
                    title: "Alert",
                    subTitle: "Something went wrong",
                    content: "",
                    onClose: () => {
                    //  alert("onClose")
                    },
                })
            } finally {
                setTimeout(() => {
                    setIsLoadingSort(false)
                },400)
            }
        }
    }

    

  // useEffect(() => {
  //   Number(customPageSize) && setPageSize(Number(customPageSize));
  // }, [customPageSize, setPageSize]);

  useEffect(() => {
    if (sortTable?.tableName !== 'table_name') {
        const sortable = Sortable.create(document.getElementById("table-main-sort"), {
            animation: 150,
            onEnd: (evt) => {
                console.log('evt.oldIndex', evt.oldIndex)
                console.log('evt.newIndex', evt.newIndex)
                // console.log('evt.data-main-id', evt.item.attributes('data-main-id').value)
                // console.log("row main_id", evt.item.attributes[`data-main-id`].value)
                const tableAll = document.getElementById("table-main-sort").querySelectorAll('tr')
                // console.log("🚀 ~ sortable ~ tableAll:", tableAll)
                // const array = tableAll.map(item => item.sortOrder)
                // const minSeq = Math.min(...array)
                const tableAllData = []
                const sortData = []
                for (let ta of tableAll) {
                    const sortOld = ta.attributes[`data-sort-order`]?.value
                    const tmp = {
                        id: ta.attributes[`data-main-id`]?.value,
                        sortOld: sortOld
                    }
                    tableAllData.push(tmp)
                    sortData.push(sortOld)
                }
                console.log("🚀 ~ sortable ~ sortData:", sortData)
                const sortDataNotNull = _.compact(sortData)
                const useSortData = sortDataNotNull.length > 0 ? sortDataNotNull : [1] // เผื่อเป็น null หมดจะเริ่ม 1 ใหม่ให้เอง
                const minSeq = Math.min(...useSortData)
                console.log("🚀 ~ sortable ~ minSeq:", minSeq)

                
                let sortList = []
                let startSeq = minSeq
                let loop = 0
    
                for (let ta of tableAllData) {
                    const id = ta.id
                    let tmp = {}
                    if(sortTable.db == 'sql'){
                        tmp = { id: Number(`${id}`), seqNo: startSeq + loop}
                    }else{
                        tmp = { id: `${id}`, seqNo: startSeq + loop}
                    }
                    sortList.push(tmp)
                    loop++
                }
                console.log("🚀 ~ sortable ~ sortList:", JSON.stringify(sortList))
    
                // ยิง API Sort Table
                const payload = { tableName: sortTable.tableName, sortList: sortList , dbType: sortTable.db  }
                API.fetchSortTable(payload)
    
            },
        })
    
        return () => {
            sortable.destroy()
        }
    }   
    
  }, [])

  useEffect(() => {
    // Number(customPageSize) && setPageSize(Number(customPageSize));
    if (Object.keys(optionFilter)) {
        let tmpFilter = []
        for(let keyOpt in optionFilter) {
            const tmpRow = { id: keyOpt, value: `${optionFilter[keyOpt]}` }
            tmpFilter.push(tmpRow)
        }
        setColumnFilters(tmpFilter)
    }
  }, [optionFilter]);
    
    return (
        <Fragment>
            <Row className="mb-2">
                {isCustomPageSize && (
                    <Col sm={2}>
                        <select
                            className="form-select pageSize mb-2"
                            value={table.getState().pagination.pageSize}
                            onChange={e => {
                                table.setPageSize(Number(e.target.value))
                            }}
                        >
                        {[3, 10, 20, 30, 40, 50].map((pageSize, i) => (
                            <option key={i} value={pageSize}>
                            Show {pageSize}
                            </option>
                        ))}
                        </select>
                    </Col>
                )}


                {isGlobalFilter && (
                        <DebouncedInput
                            value={globalFilter ?? ""}
                            onChange={value => setGlobalFilter(String(value))}
                            className="form-control search-box me-2 mb-2 d-inline-block"
                            placeholder={SearchPlaceholder}
                        />
                )}

                {isCustomFilter && <>{customFilter}</>}
                
                {isGlobalFilter && (
                    <>
                        {
                            dropdownFilter.map(item => {
                                return (
                                    <Col sm={3} key={item.id}>
                                        <Select
                                            placeholder={`Select ${_.startCase(item.label)}`}
                                            onChange={(e) => {
                                                let tmp = _.cloneDeep(optionFilter)
                                                console.log("🚀 ~ item.key:", item.key)
                                                if (item.key) {
                                                    tmp[item.key] = e.id
                                                    setOptionFilter(tmp)
                                                }
                                            }}
                                            getOptionValue={(option) => option.id}
                                            getOptionLabel={(option) => option.name}
                                            options={item.option}
                                        />
                                    </Col>
                                )
                            })
                        }
                    </>
                )}
                

                {/* {isJobListGlobalFilter && <JobListGlobalFilter setGlobalFilter={setGlobalFilter} />} */}
                
            </Row>
            {
                noTop ? <></>
                : (
                    <div className="d-flex mb-2" style={{ minHeight: 36.5 }}>
                        <div className="d-flex align-items-center flex-grow-1"></div>
                        <div className="flex-shrink-0 d-flex gap-2">
                            {
                                (isAddButton && checkScope(rootMenu + ':' + subMenu + ':create')) && (
                                    <Link
                                        to={`${location.href}${addRowLink}`}
                                        type="button"
                                        className={`btn btn-primary`}
                                        onClick={handleUserClick}
                                        >
                                        <i className="bx bx-plus me-1"></i> Create
                                    </Link>
                                )
                            }
                            {/* {
                                (isExportButton && checkScope(rootMenu + ':' + subMenu + ':create')) && (
                                    <ExportJsonToExcel 
                                        arrData={getRowModel().rows.map(item => item.original)} 
                                        fileName={exportOption.filename}
                                        dateColumns={exportOption.dateColumns}
                                    />
                                )
                            } */}
                        </div>
                    </div>
                )
            }
            
            {
                isSelected && (
                    <div className="d-flex mb-2" style={{ minHeight: 36.5 }}>
                        <div className="d-flex align-items-center flex-grow-1"></div>
                        <div className="flex-shrink-0 d-flex gap-2">
                            <a className="btn btn-info" onClick={() => onClearSelected()}> Clear Selected</a>
                            <a className="btn btn-danger" onClick={() => onDeleleSelected()}> Delele Selected</a>
                        </div>
                    </div>
                )
            }
            {/* <div className="" id="table-main-sort">
                <div>1</div>
                <div>2</div>
                <div>3</div>
                <div>4</div>
            </div> */}
            <div className={divClassName ? divClassName : "table-responsive"}>
                <Table hover className={tableClass} bordered={isBordered}>
                    <thead className={theadClass} >
                        {getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id} >
                                {headerGroup.headers.map(header => {
                                    return (
                                        <th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            className="align-middle"
                                            // className={`${
                                            //     header.column.columnDef.enableSorting
                                            //     ? "sorting sorting_none"
                                            //     : ""
                                            // }`}
                                        >
                                        {header.isPlaceholder ? null : (
                                            <React.Fragment>
                                                <div
                                                    {...{
                                                        className: header.column.getCanSort()
                                                            ? "cursor-pointer select-none"
                                                            : "",
                                                        onClick: header.column.getToggleSortingHandler(),
                                                    }}
                                                    // onClick={() => {
                                                    //     changeSortingCss();
                                                    // }}
                                                >
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                    {{
                                                        asc: ' ↑',
                                                        desc: ' ↓',
                                                    }[header.column.getIsSorted()] ?? null}
                                                </div>
                                                {header.column.getCanFilter() ? (
                                                    <div>
                                                        <Filter column={header.column} table={table} />
                                                    </div>
                                                ) : null}
                                            </React.Fragment>
                                        )}
                                        </th>
                                    )
                                })}
                            </tr>
                        ))}
                    </thead>
                    {
                        <tbody id="table-main-sort">
                            {
                                isLoadingSort || loading
                                ? <TableLoading colSpan={table.getAllColumns().length}/>
                                // ? <TableEmpty colSpan={table.getAllColumns().length}/>
                                : data.length === 0 
                                    ? <TableEmpty colSpan={table.getAllColumns().length}/>
                                    :(
                                        <>
                                        {
                                            getRowModel().rows.map((row,index) => {
                                                return (
                                                    <tr 
                                                        key={index}
                                                        data-main-id={row.original.id}
                                                        data-sort-order={row?.original?.sortOrder ? row.original.sortOrder :null}
                                                        className="align-middle">
                                                        {row.getVisibleCells().map(cell => {
                                                            let _style = {}
                                                            const _cellName = cell.id
                                                            if(_cellName.indexOf('purpose') !== -1) {
                                                                _style = {
                                                                    width: "300px",
                                                                    overflow: "hidden",
                                                                    whiteSpace: "nowrap",
                                                                    textOverflow: "ellipsis",
                                                                }
                                                            }
                                                            return (
                                                                <td key={cell.id}>
                                                                    <div style={_style}>
                                                                        {flexRender(
                                                                            cell.column.columnDef.cell,
                                                                            cell.getContext()
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            )
                                                        })}
                                                    </tr>
                                                )
                                            })
                                        }
                                        </>
                                    )
                            }
                        </tbody>
                    }
                </Table>
            </div>

            {isPagination && (
                <Row>
                    <Col sm={12} md={5}>
                        <div className="dataTables_info">
                            Showing {getState().pagination.pageSize} of {data.length} Results
                        </div>
                    </Col>
                    <Col sm={12} md={7}>
                        <div className={paginationWrapper}>
                            <ul className={pagination}>
                                {/* ปุ่ม First */}
                                {
                                    data.length >= 100 && (
                                        <li className={`paginate_button page-item ${!getCanPreviousPage() ? "disabled" : ""}`}>
                                            <button 
                                                className="page-link"
                                                disabled={!getCanPreviousPage()} 
                                                onClick={() => setPageIndex(0)}
                                            >
                                                « First
                                            </button>
                                        </li>
                                    )
                                }
                                
                                
                                {/* ปุ่ม Previous */}
                                <li
                                    className={`paginate_button page-item previous ${
                                        !getCanPreviousPage() ? "disabled" : ""
                                    }`}
                                >
                                    <Link to="#" className="page-link" onClick={previousPage}>
                                        <i className="mdi mdi-chevron-left"></i>
                                    </Link>
                                </li>
                                {
                                    (() => {
                                        const visiblePageCount = 10;
                                        const currentPage = getState().pagination.pageIndex;
                                        const totalPages = getPageOptions().length;
                
                                        let startPage = Math.max(currentPage - 4, 0);  // ถ้าอยู่ที่ปุ่มที่ 5+ ให้เลื่อนช่วง
                                        let endPage = Math.min(startPage + visiblePageCount, totalPages); // แสดง 10 ปุ่ม
                
                                        // ปรับช่วงถ้าใกล้หน้าสุดท้าย (เช่น ถ้ามี 20 หน้า และเลือกหน้า 16 -> แสดง 11-20)
                                        if (endPage === totalPages) {
                                            startPage = Math.max(endPage - visiblePageCount, 0);
                                        }

                                        return getPageOptions()
                                            .slice(startPage, endPage)
                                            .map((item, key) => (
                                            <li
                                                key={key}
                                                    className={`paginate_button page-item ${
                                                    getState().pagination.pageIndex === item ? "active" : ""
                                                }`}
                                            >
                                                <Link
                                                    to="#"
                                                    className="page-link"
                                                    onClick={() => setPageIndex(item)}
                                                    >
                                                    {item + 1}
                                                </Link>
                                            </li>
                                        ))
                                    })()
                                }
                                {/* ปุ่ม Next */}
                                <li
                                    className={`paginate_button page-item next ${
                                        !getCanNextPage() ? "disabled" : ""
                                    }`}
                                >
                                    <Link to="#" className="page-link" onClick={nextPage}>
                                        <i className="mdi mdi-chevron-right"></i>
                                    </Link>
                                </li>

                                {/* ปุ่ม Last */}
                                {
                                    data.length >= 100 && (
                                    <li className={`paginate_button page-item ${!getCanNextPage() ? "disabled" : ""}`}>
                                        <button 
                                            className="page-link"
                                            disabled={!getCanNextPage()} 
                                            onClick={() => setPageIndex(getPageOptions().length - 1)}
                                        >
                                            Last »
                                        </button>
                                    </li>
                                    )
                                }
                                
                            </ul>
                        </div>
                    </Col>
                </Row>
            )}
        </Fragment>
    )
}

export default TableContainer
