import React, { useEffect, useState, useContext } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import {
  CreateShippingCondition,
  GetShippingConditionById,
  UpdateShippingCondition,
} from "services/api/module/ShippingCondition"
import ModalContext from "../../contexts/ModalContext"
import UploadFileAPI from "components/UploadFileAPI"
import MyEditor from "components/MyEditor"

const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const SHIPPING_OPTION = [
  { id: "1", name: "ส่งด่วน 3 ชม." },
  { id: "2", name: "ส่งด่วน 2 ชม." },
]

const ShippingConditionTemplate = ({ pageView = "create" }) => {
  // POPUP
  const { mAlert } = useContext(ModalContext)

  // OTHER STATE
  const qParams = useParams()
  const qShippingConditionId = qParams.id
  const navigate = useNavigate()

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    conditionTh: Yup.string().required("Please Enter Content TH"),
    conditionEn: Yup.string().required("Please Enter Content EN"),
    status: Yup.string().required("Please Enter Status"),
    shippingType: Yup.string().required("Please Enter shippingType"),
  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] =
    useState(validationSchema)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      conditionTh: "",
      conditionEn: "",
      shippingType: "1",
      status: "1",
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ ShippingConditionCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        conditionTh: values.conditionTh,
        conditionEn: values.conditionEn,
        status: Number(values.status),
        shippingType: Number(values.shippingType),
      }
      console.log("🚀 ~ onSave ~ payload:", payload)

      if (pageView === "update") {
        API.fetchUpdateShippingCondition(payload)
      } else {
        API.fetchCreateShippingCondition(payload)
      }
    },
  })

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("conditionTh", resData.conditionTh)
    validation.setFieldValue("conditionEn", resData.conditionEn)
    validation.setFieldValue("shippingType", resData.shippingType)
    validation.setFieldValue("status", resData.status)
  }

  const API = {
    fetchGetShippingConditionById: async payload => {
      try {
        const response = await GetShippingConditionById({ id: payload.id })
        const resData = response.data
        if (resData.status == 202) {
          preFilInput(resData.data)
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchCreateShippingCondition: async payload => {
      try {
        const response = await CreateShippingCondition({ data: payload })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Created Data Success",
            content: "",
            onClose: () => {
              navigate("/help-center/shipping-condition")
            },
          })
        }
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchUpdateShippingCondition: async payload => {
      try {
        const response = await UpdateShippingCondition({
          data: payload,
          id: payload.id,
        })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              navigate("/help-center/shipping-condition")
            },
          })
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
  }

  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id: qShippingConditionId }
      API.fetchGetShippingConditionById(payload)
    }
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title =
    `Shipping Condition | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} Shipping Condition`}
            breadcrumbItems={[
              { title: "Shipping Condition", link: "banner/banner-platform" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <Col className="col-12">
                        <Row className="">
                          <Col className="col-12">
                            <Row className="">
                              <Col className="col-12">
                                <h5 className="mb-3">
                                  Shipping Condition Infomation
                                </h5>
                              </Col>
                              <Col className="col-6 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Shipping Type
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Select
                                    placeholder="Select Status"
                                    value={fn_helper.FNFORM.getObjectValue(
                                      SHIPPING_OPTION,
                                      `${validation.values.shippingType}`
                                    )}
                                    onChange={value =>
                                      validation.setFieldValue(
                                        "shippingType",
                                        value.id
                                      )
                                    }
                                    getOptionValue={option => option.id}
                                    getOptionLabel={option => option.name}
                                    options={SHIPPING_OPTION}
                                    className={`select2-selection ${
                                      validation.touched.shippingType &&
                                      validation.errors.shippingType
                                        ? "input-err"
                                        : ""
                                    }`}
                                    isDisabled={pageView === "view"}
                                  />
                                  {validation.touched.shippingType &&
                                    validation.errors.shippingType && (
                                      <div
                                        type="invalid"
                                        className="invalid-feedback d-block"
                                      >
                                        {validation.errors.shippingType}
                                      </div>
                                    )}
                                  {validation.touched.shippingType &&
                                    validation.errors.shippingType && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.shippingType}
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col className="col-6 mb-3"></Col>
                              <Col className="col-6 mb-3">
                                <label
                                  htmlFor="category_name"
                                  className="col-md-12 col-form-label"
                                >
                                  Status (Active/Inactive)
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <Select
                                    placeholder="Select Status"
                                    value={fn_helper.FNFORM.getObjectValue(
                                      STATUS_OPTION,
                                      `${validation.values.status}`
                                    )}
                                    onChange={value =>
                                      validation.setFieldValue(
                                        "status",
                                        value.id
                                      )
                                    }
                                    getOptionValue={option => option.id}
                                    getOptionLabel={option => option.name}
                                    options={STATUS_OPTION}
                                    className={`select2-selection ${
                                      validation.touched.status &&
                                      validation.errors.status
                                        ? "input-err"
                                        : ""
                                    }`}
                                    isDisabled={pageView === "view"}
                                  />
                                  {validation.touched.status &&
                                    validation.errors.status && (
                                      <div
                                        type="invalid"
                                        className="invalid-feedback d-block"
                                      >
                                        {validation.errors.status}
                                      </div>
                                    )}
                                  {validation.touched.status &&
                                    validation.errors.status && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.status}
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="conditionTh"
                                  className="col-md-12 col-form-label"
                                >
                                  Content Thai
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <div
                                    className={
                                      validation.touched.conditionTh &&
                                      validation.errors.conditionTh
                                        ? "invalid-editor"
                                        : ""
                                    }
                                  >
                                    <MyEditor
                                      value={validation.values.conditionTh}
                                      onChange={(event, editor) => {
                                        const data = editor.getData()
                                        validation.setFieldValue(
                                          "conditionTh",
                                          data
                                        )
                                        console.log({ event, editor, data })
                                      }}
                                      disabled={pageView === "view"}
                                    />
                                  </div>

                                  {validation.touched.conditionTh &&
                                    validation.errors.conditionTh && (
                                      <div
                                        type="invalid"
                                        className="invalid-feedback d-block"
                                      >
                                        {validation.errors.conditionTh}
                                      </div>
                                    )}
                                </div>
                              </Col>
                              <Col className="col-12 mb-3">
                                <label
                                  htmlFor="conditionEn"
                                  className="col-md-12 col-form-label"
                                >
                                  Content En
                                  <span className="t-require">*</span>
                                </label>
                                <div className="col-md-12">
                                  <div
                                    className={
                                      validation.touched.conditionEn &&
                                      validation.errors.conditionEn
                                        ? "invalid-editor"
                                        : ""
                                    }
                                  >
                                    <MyEditor
                                      value={validation.values.conditionEn}
                                      onChange={(event, editor) => {
                                        const data = editor.getData()
                                        validation.setFieldValue(
                                          "conditionEn",
                                          data
                                        )
                                        console.log({ event, editor, data })
                                      }}
                                      disabled={pageView === "view"}
                                    />
                                  </div>

                                  {validation.touched.conditionEn &&
                                    validation.errors.conditionEn && (
                                      <div
                                        type="invalid"
                                        className="invalid-feedback d-block"
                                      >
                                        {validation.errors.conditionEn}
                                      </div>
                                    )}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "8px",
                      }}
                    >
                      {pageView == "view" ? (
                        <>
                          <button
                            className="btn btn-primary w-md"
                            onClick={() =>
                              navigate("/help-center/shipping-condition")
                            }
                          >
                            BACK
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            SAVE
                          </button>
                          <button
                            className="btn btn-secondary w-md"
                            onClick={() =>
                              navigate("/help-center/shipping-condition")
                            }
                          >
                            CANCEL
                          </button>
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(ShippingConditionTemplate)
