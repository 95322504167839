import { get, post, put, patch } from "../Config"

export function SearchShippingCondition({ params = {} }) {
  return get({ url: `/v1/admin/shippingCondition`, params })
}
export function GetShippingConditionById({ id }) {
  return get({ url: `/v1/admin/shippingCondition/${id}` })
}
export function CreateShippingCondition({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/shippingCondition`, params, data })
}
export function UpdateShippingCondition({ params = {}, data = {}, id }) {
  return put({ url: `/v1/admin/shippingCondition/${id}`, params, data })
}
export function PatchShippingCondition({ params = {}, data = {}, id }) {
  return patch({ url: `/v1/admin/shippingCondition/${id}`, params, data })
}
export function DeleteShippingCondition({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/shippingCondition/mutiDelete`, params, data })
}
