import PropTypes from "prop-types"
import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useContext,
} from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Badge,
} from "reactstrap"

import { Link, useNavigate, useParams } from "react-router-dom"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import TableContainer from "../../../components/Common/TableContainer"
import { withTranslation } from "react-i18next"

import { get } from "helpers/api_helper"
import { checkScope } from "helpers/utility_helper"
import "../../../components/Common/datatables.scss"
import Spinners from "components/Common/Spinner"
import {
  DeleteJobApplication,
  GetJobApplicationById,
  PatchJobApplication,
  SearchJobApplication,
} from "services/api/module/JobApplication"

import ModalContext from "../../../contexts/ModalContext"
import {
  DropdownJobPosition,
  DropdownJobType,
} from "services/api/module/Dropdown"
import InputSwitch from "components/Input/InputSwitch"
import moment from "moment"
import fn_helper from "helpers/fn_helper"
import PrintComponent from "./PrintComponent"
import jibIcon from "../../../assets/images/jib/ico-logo-head-color.svg"

const WORK_PATTERN_OPTION = fn_helper.OPTIONS.WORK_PATTERN_OPTION
const STEP_JOB_OPTION = [
  { id: 1, name: "รอสัมภาษณ์" },
  { id: 2, name: "ผ่าน" },
  { id: 3, name: "ไม่ผ่าน" },
]

const GENDER_OPTION = [
  { id: "m", name: "ผู้ชาย" },
  { id: "f", name: "ผู้หญิง" },
  { id: "x", name: "ไม่ระบุเพศ" },
]

const RowStatus = ({ status }) => {
  switch (String(status)) {
    case "0":
      return <Badge className="bg-danger">InActive</Badge>
    case "1":
      return <Badge className="bg-success">Active</Badge>
    default:
      return <Badge className="bg-success">{status}</Badge>
  }
}

const JobApplicationList = props => {
  const [docData, setDocData] = useState({})
  const [docDataLoading, setDocDataLoading] = useState(false)
  const [docDataIdLoading, setDocDataIdLoading] = useState(0)
  const { genPdf, PrintDoc } = PrintComponent({ prefixName: "apply" }) // FN print

  // POPUP
  const { mAlert } = useContext(ModalContext)

  // OTHER STATE
  const { categoryName } = useParams()
  const navigate = useNavigate()

  // LOCAL STATE
  const [idCard, setIdCard] = useState("")
  const [contentList, setContentList] = useState([])
  const [contentListLoading, setContentListLoading] = useState(false)
  const [selectedContentId, setSelectedContentId] = useState([])

  // DROPDOWN
  const [dropdownFilter, setDropdownFilter] = useState([])

  const onDeleteRow = (ids = [], name = "") => {
    // alert('onDeleteRow'+ JSON.stringify(ids))
    mAlert.info({
      type: "confrim",
      title: "Alert Confrim",
      subTitle: `Are you sure Delete ${name} ?`,
      mode: "red",
      content: "",
      onClose: () => {},
      onYes: () => {
        const payload = { jobApplicationIds: ids }
        API.fetchDeleteContentList(payload)
      },
      onNo: () => {
        console.log("Cancel")
      },
    })
  }
  const onPrintRow = async (id = 0) => {
    const payload = { id: id }
    setDocDataLoading(true)
    setDocDataIdLoading(id)
    const resData = await API.fetchGetJobApplicationById(payload)
    console.log("resData >>", resData)
    setDocData(resData)
  }
  const onPatchRow = (id = 0, fieldName = "", value = null) => {
    const payload = { id: id, fieldName: fieldName, value: value }
    API.fetchPatchContent(payload)
  }

  const onSelectionChange = (
    selectedValue = {},
    oldValues = [],
    option = [],
    mode = ""
  ) => {
    const selectedId = selectedValue?.id
    const tmpOldValues = _.cloneDeep(oldValues)
    const findOldValue = tmpOldValues.filter(id => id === selectedId)

    let updateState = []
    if (findOldValue && findOldValue.length) {
      updateState = tmpOldValues.filter(id => id != selectedId)
    } else {
      updateState = tmpOldValues
      updateState.push(selectedId)
    }

    if (mode === "all") {
      if (tmpOldValues.length >= option.length) {
        updateState = []
      } else {
        updateState = option.map(item => item.id)
      }
    }
    console.log("🚀 ~ onChangeEnum ~ updateState:", updateState)
    return updateState
  }

  const columns = useMemo(
    () => [
      {
        id: "colcheckbox",
        header: cellProps => {
          const row = cellProps?.row?.original
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Input
                type={`checkbox`}
                checked={contentList.length === selectedContentId.length}
                onClick={() => {
                  let updateData = onSelectionChange(
                    row,
                    selectedContentId,
                    contentList,
                    "all"
                  )
                  setSelectedContentId(updateData)
                }}
                readOnly
              />
            </div>
          )
        },
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          const row = cellProps?.row?.original
          return (
            <>
              <div
                id={cellProps.row.id}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Input
                  type={`checkbox`}
                  value={cellProps.row.original.id}
                  checked={selectedContentId.includes(row.id)}
                  onClick={() => {
                    let updateData = onSelectionChange(
                      row,
                      selectedContentId,
                      contentList
                    )
                    setSelectedContentId(updateData)
                  }}
                  readOnly
                />
              </div>
            </>
          )
        },
      },
      {
        header: "#",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {parseInt(cellProps.row.id) + 1}
              </div>
            </>
          )
        },
      },
      // {
      //   header: "IDCARD",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      //   cell: cellProps => {
      //     const data = cellProps?.row?.original
      //     return <div>{data.jobApplicationProfile.idCard}</div>
      //   },
      // },
      // {
      //     header: 'Status',
      //     accessorKey: 'status',
      //     enableColumnFilter: false,
      //     enableSorting: true,
      //     width: '10%',
      //     cell: (cellProps) => {
      //         const { id, status } = cellProps.row.original
      //         return (
      //             <InputSwitch
      //                 labelON=""
      //                 labelOFF=""
      //                 value={status == 1}
      //                 onChange={(value)=> {
      //                     const updateValue = value ? 1 : 0
      //                     onPatchRow(id, 'status', updateValue)
      //                 }}
      //             />
      //         )
      //     }
      // },
      {
        header: "Job Type",
        accessorKey: "jobTypeId",
        enableColumnFilter: false,
        enableSorting: true,
        filterFn: "optionFN",
        cell: cellProps => {
          const { jobTypeName = "" } = cellProps?.row?.original
          return <div>{jobTypeName}</div>
        },
      },
      {
        header: "Job Position",
        accessorKey: "jobPositionId",
        enableColumnFilter: false,
        enableSorting: true,
        filterFn: "optionFN",
        cell: cellProps => {
          const { jobPositionName = "" } = cellProps?.row?.original
          return <div>{jobPositionName}</div>
        },
      },
      {
        header: "Pattern",
        accessorKey: "jobPositionWorkPattern",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          const { jobPositionWorkPattern = "" } = cellProps?.row?.original
          const workPatternName =
            WORK_PATTERN_OPTION.find(item => item.id === jobPositionWorkPattern)
              ?.name ?? ""
          return <div>{workPatternName}</div>
        },
      },
      {
        header: "Firstname",
        accessorKey: "profileFirstname",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Lastname",
        accessorKey: "profileLastname",
        enableColumnFilter: false,
        enableSorting: true,
      },

      {
        header: "Expected Salary",
        accessorKey: "expectSalary",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          const row = cellProps?.row?.original
          return row.expectSalary
            ? fn_helper.FN.toNumberWithCommas(row.expectSalary)
            : "0"
        },
      },
      {
        header: "Annouce Date",
        accessorKey: "jobPositionAnnoucementDate",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          const row = cellProps?.row?.original
          return row.jobPositionAnnoucementDate
            ? moment(row.jobPositionAnnoucementDate).format("DD/MM/YYYY")
            : ""
        },
      },
      {
        header: "Apply Date",
        accessorKey: "createdAt",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          const row = cellProps?.row?.original
          return row.createdAt ? moment(row.createdAt).format("DD/MM/YYYY") : ""
        },
      },
      {
        header: "Tools",
        accessorKey: "user_id",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          let { id, jobApplicationName } = cellProps?.row?.original ?? {}
          return (
            <>
              {/* {checkScope('systemsettings:usersetting:view') && <Link className={`btn btn-info`} to={`/jobs-managment/job-application/view/${id}`}><i className={`bx bx-show-alt`}></i></Link>} */}
              {/* &nbsp; */}
              {checkScope("systemsettings:usersetting:update") && (
                <Link
                  className={`btn btn-info`}
                  to={`/jobs-managment/job-application/edit/${id}`}
                >
                  <i className={`bx bx-show-alt`}></i>
                </Link>
              )}
              &nbsp;
              {checkScope("systemsettings:usersetting:delete") && (
                <a
                  className={`btn btn-danger`}
                  onClick={() => onDeleteRow([id], jobApplicationName)}
                >
                  <i className={`bx bx-trash`}></i>
                </a>
              )}
              &nbsp;
              {checkScope("systemsettings:usersetting:delete") ? (
                docDataLoading ? (
                  docDataIdLoading == id ? (
                    <a className={`btn btn-success`}>
                      <i className={`bx bx-loader`}></i>
                    </a>
                  ) : (
                    <a className={`btn btn-success`}>
                      <i className={`bx bx-lock-alt`}></i>
                    </a>
                  )
                ) : (
                  <a
                    className={`btn btn-success`}
                    onClick={() => onPrintRow(id)}
                  >
                    <i className={`bx bx-printer`}></i>
                  </a>
                )
              ) : (
                <></>
              )}
            </>
          )
        },
      },
    ],
    [selectedContentId, contentList, docDataLoading]
  )

  const API = {
    fetchContentList: async (payload) => {
      try {
        const response = await SearchJobApplication({ params: payload })
        const resData = response?.data ?? []
        setContentList(resData.data)
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      } finally {
        setContentListLoading(false)
      }
    },
    fetchPatchContent: async payload => {
      try {
        const response = await PatchJobApplication({
          data: payload,
          id: payload.id,
        })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              fetchAPI()
            },
          })
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchGetJobApplicationById: async payload => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await GetJobApplicationById({ id: payload.id })
          const resData = response.data
          if (resData.status == 202) {
            return resolve(resData.data)
          }
        } catch (err) {
          console.log("err ", err)
          mAlert.info({
            title: "Alert",
            subTitle: "Something went wrong",
            content: "",
            onClose: () => {
              //  alert("onClose")
            },
          })
          return resolve({})
        }
      })
    },
    fetchDeleteContentList: async payload => {
      try {
        const response = await DeleteJobApplication({ data: payload })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Delete Data Success",
            content: "",
            onClose: () => {
              fetchAPI()
            },
          })
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
  }

  const fetchAPI = () => {
    const payload = { idCard: idCard}
    setContentListLoading(true)
    API.fetchContentList(payload)
  }
  const fetchAPIDropDown = () => {
    let _dropdownFilter = [
      { label: "jobType", key: "jobTypeId", option: [] },
      { label: "jobPosition", key: "jobPositionId", option: [] },
    ]
    const request1 = DropdownJobType({})
    const request2 = DropdownJobPosition({})

    Promise.all([request1, request2])
      .then(results => {
        let DEF_DROPDOWN = [{ id: "", name: "NO SELECT" }]
        let updateDropDown = _dropdownFilter.map((item, i) => {
          const resData = results[i]?.data ?? {}
          const tmpOption = resData?.data ?? []
          item.option = [...DEF_DROPDOWN, ...tmpOption]
          return item
        })
        // updateDropDown = [ ...DEF_DROPDOWN, ...updateDropDown]
        setDropdownFilter(updateDropDown)
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    if (Object.keys(docData).length) {
      genPdf(() => {
        console.log("done File", docData)
        setDocDataLoading(false)
        setDocDataIdLoading(0)
        setDocData({})
      })
    }
  }, [docData])

  useEffect(() => {
    fetchAPIDropDown()
    fetchAPI()
  }, [])

  useEffect(() => {
    if (contentList.length) {
      setContentListLoading(false)
    }
  }, [contentList])
  useEffect(() => {
    fetchAPI()
  }, [idCard])

  //meta title
  document.title = "Job Application List | " + process.env.REACT_APP_CMS_TITLE
  const numComma = value => fn_helper.FN.toNumberWithCommas(`${value}`)
  const toDateStr = value => (value ? moment(value).format("DD/MM/YYYY") : "-")
  const toText = value => (value || value == 0 ? value : "-")
  const toTel = value => (value ? fn_helper.FORMAT.TEL(value) : "-")
  const workPatternName =
    WORK_PATTERN_OPTION.find(
      item => item.id === docData?.application?.jobPositionWorkPattern
    )?.name ?? ""

  
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Job Application"
            breadcrumbItem="Job Application List"
          />
            <TableContainer
              isAddButton={false}
              addRowLink={`/create`}
              columns={columns}
              data={contentList || []}
              isGlobalFilter={true}
              isPagination={true}
              SearchPlaceholder="Search"
              pagination="pagination"
              paginationWrapper="dataTables_paginate paging_simple_numbers"
              tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
              rootMenu="systemsettings"
              subMenu="usersetting"
              isCustomPageSize={true}
              isSelected={selectedContentId.length > 0}
              onClearSelected={() => setSelectedContentId([])}
              onDeleleSelected={() =>
                onDeleteRow(_.cloneDeep(selectedContentId))
              }
              isExportButton={true}
              exportOption={{
                filename: "jobApplication-platform",
                dateColumns: [],
              }}
              loading={contentListLoading}
              dropdownFilter={dropdownFilter}
              isCustomFilter={true}
              customFilter={
                <Col sm={3}>
                    <input
                      placeholder="Search ID card number"
                      className="form-control search-box me-2 mb-2 d-inline-block"
                    // {...props}
                    // value={value}
                    onChange={e => setIdCard(e.target.value)}
                    />
                </Col>
              }
            />
        </div>
      </div>
      <PrintDoc isShow={false}>
        <div id="render-doc-page" className="outer-container doc-jib">
          {/* <!-- pdf-content-page-1 เปลี่ยนเลขไปเรื่อยๆ --> */}
          <div id="pdf-content-page-1" className="pdf-preview">
            <div className="bHead">
              <div className="bLogo">
                <img src={jibIcon} alt="" />
              </div>
              <div className="bProfile">
                <img src={docData?.document?.personalImage} alt="" />
              </div>
              <div className="bJobNumber">
                <p>JOB-{docData?.jobApplicationId}</p>
              </div>
            </div>
            <h2>ลักษณะงานที่ต้องการ</h2>
            <table>
              <tr>
                <th className="text-center">แผนกงาน</th>
                <th className="text-center">ตำแหน่ง</th>
                <th className="text-center">เงินเดือน</th>
                <th className="text-center">ประเภทงาน</th>
                <th className="text-center">วันที่เริ่มงานได้</th>
              </tr>
              <tr>
                <td className="text-center">
                  {toText(docData?.application?.jobTypeName)}
                </td>
                <td className="text-center">
                  {toText(docData?.application?.jobPositionName)}
                </td>
                {/* <td className="text-center">{docData?.application?.expectSalary ? numComma(docData?.application?.expectSalary) : '-'}</td> */}
                <td className="text-center">{"-"}</td>
                <td className="text-center">
                  {workPatternName ? workPatternName : "-"}
                </td>
                <td className="text-center">
                  {toDateStr(docData?.application?.startDate)}
                </td>
              </tr>
            </table>

            <h2>ประวัติส่วนตัว</h2>
            <table>
              <tr>
                <th>ชื่อ - นามสกุล</th>
                <td colSpan="3">
                  {`${toText(docData?.profile?.prefixName)} ${toText(
                    docData?.profile?.firstname
                  )} ${toText(docData?.profile?.lastname)}`}
                </td>
              </tr>
              <tr>
                <th>เลขที่บัตรประชาชน</th>
                <td>{toText(docData?.profile?.idCard)}</td>
                <th>เพศ</th>
                <td>
                  {GENDER_OPTION.find(
                    item => item.id === docData?.profile?.gender
                  )?.name ?? "ไม่ระบุเพศ"}
                </td>
              </tr>
              <tr>
                <th>วัน เดือน ปี เกิด</th>
                <td>{toDateStr(docData?.profile?.birthDate)}</td>
                <th>อายุ</th>
                <td>{toText(docData?.profile?.age ?? "0")} ปี</td>
              </tr>
              <tr>
                <th>ส่วนสูง</th>
                <td>{toText(docData?.profile?.height ?? "0")} ซม.</td>
                <th>น้ำหนัก</th>
                <td>{toText(docData?.profile?.weight ?? "0")} กก.</td>
              </tr>
              <tr>
                <th>สัญชาติ</th>
                <td>{toText(docData?.profile?.ethnicity)}</td>
                <th>เชื้อชาติ</th>
                <td>{toText(docData?.profile?.nationality)}</td>
              </tr>
              <tr>
                <th>ภูมิลำเนาเกิด</th>
                <td>{toText(docData?.profile?.provinceOfBirth)}</td>
                <th>ศาสนา</th>
                <td>{toText(docData?.profile?.religion)}</td>
              </tr>
              <tr>
                <th>ที่อยู่ปัจจุบัน</th>
                <td colSpan="3">{toText(docData?.contactAddress?.address)}</td>
              </tr>
              <tr>
                <th>จังหวัด</th>
                <td>{toText(docData?.contactAddress?.province)}</td>
                <th>อำเภอ/เขต</th>
                <td>{toText(docData?.contactAddress?.amphure)}</td>
              </tr>
              <tr>
                <th>ตำบล/แขวง</th>
                <td>{toText(docData?.contactAddress?.tombon)}</td>
                <th>รหัสไปรษณีย์</th>
                <td>{toText(docData?.contactAddress?.postcode)}</td>
              </tr>
              <tr>
                <th>เบอร์โทรศัพท์</th>
                <td>{toTel(docData?.contactAddress?.tel)}</td>
                <th>อีเมล</th>
                <td>{toText(docData?.contactAddress?.contactEmail)}</td>
              </tr>
              <tr>
                <th>LINE ID</th>
                <td colSpan="3">
                  {toText(docData?.contactAddress?.contactLineId)}
                </td>
              </tr>
              <tr>
                <th>ปัจจุบันอาศัยอยู่กับ</th>
                <td colSpan="">
                  {toText(docData?.contactAddress?.currentLiveWith)}
                </td>
                <th>สถานภาพที่อยู่อาศัย</th>
                <td colSpan="">
                  {toText(docData?.contactAddress?.residentStatus)}
                </td>
              </tr>
              <tr>
                <th>สถานภาพทางครอบครัว</th>
                <td colSpan="3">{toText(docData?.profile?.maritalStatus)}</td>
              </tr>
              <tr>
                <th>ชื่อคู่สมรส</th>
                <td colSpan="3">{toText(docData?.profile?.maritalName)}</td>
              </tr>
              <tr>
                <th>อาชีพ</th>
                <td colSpan="3">{toText(docData?.profile?.maritalJob)}</td>
              </tr>
              <tr>
                <th>สถานที่ทำงาน</th>
                <td colSpan="3">{toText(docData?.profile?.maritalCompany)}</td>
              </tr>
              <tr>
                <th>จำนวนบุตร</th>
                <td colSpan="">{toText(docData?.profile?.numberOfChild)}</td>
                <th>จำนวนบุตรที่กำลังศึกษา</th>
                <td colSpan="">
                  {toText(docData?.profile?.numberOfChildStudy)}
                </td>
              </tr>
              <tr>
                <th>สถานะทางการทหาร</th>
                <td colSpan="3">{toText(docData?.profile?.militaryStatus)}</td>
              </tr>
              <tr>
                <th>เหตุผลได้รับการยกเว้น</th>
                <td colSpan="3">{toText(docData?.profile?.militaryRemark)}</td>
              </tr>
              <tr>
                <th>ผู้ติดต่อกรณีฉุกเฉิน</th>
                <td colSpan="3">
                  {toText(docData?.contactAddress?.eContactName)}
                </td>
              </tr>
              <tr>
                <th>ความสัมพันธ์</th>
                <td colSpan="">
                  {toText(docData?.contactAddress?.eContactRelation)}
                </td>
                <th>เบอร์โทรศัพท์</th>
                <td colSpan="">
                  {toTel(docData?.contactAddress?.eContactMobileNo)}
                </td>
              </tr>
              <tr>
                <th>ที่อยู่</th>
                <td colSpan="3">
                  {toText(docData?.contactAddress?.eContactAddress)}
                </td>
              </tr>
              <tr>
                <th>จังหวัด</th>
                <td colSpan="">
                  {toText(docData?.contactAddress?.eContactProvince)}
                </td>
                <th>อำเภอ/เขต</th>
                <td colSpan="">
                  {toText(docData?.contactAddress?.eContactAmphure)}
                </td>
              </tr>
              <tr>
                <th>ตำบล/แขวง</th>
                <td colSpan="">
                  {toText(docData?.contactAddress?.eContactTombon)}
                </td>
                <th>รหัสไปรษณีย์</th>
                <td colSpan="">
                  {toText(docData?.contactAddress?.eContactPostcode)}
                </td>
              </tr>
            </table>
          </div>

          {/* <!-- pdf-content-page-2 เปลี่ยนเลขไปเรื่อยๆ --> */}
          <div id="pdf-content-page-2" className="pdf-preview">
            <div className="bHead">
              <div className="bLogo">
                <img src={jibIcon} alt="" />
              </div>
            </div>
            <h2 className="section-title">ประวัติการศึกษา</h2>
            <table>
              <thead>
                <tr>
                  <th>ระดับการศึกษา</th>
                  <th>สถาบันการศึกษา</th>
                  <th>สาขาวิชาเอก</th>
                  <th>ปีจบการศึกษา</th>
                  <th>คะแนนเฉลี่ย</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{toText(docData?.educationHistory?.degreeHighest)}</td>
                  <td>{toText(docData?.educationHistory?.university)}</td>
                  <td>{toText(docData?.educationHistory?.department)}</td>
                  <td>{toText(docData?.educationHistory?.graduateYear)}</td>
                  <td>
                    {docData?.educationHistory?.gpax
                      ? Number(`${docData?.educationHistory?.gpax}`).toFixed(2)
                      : 0}
                  </td>
                </tr>
              </tbody>
            </table>

            <h2 className="section-title">ประวัติการฝึกอบรม</h2>
            <table>
              <thead>
                <tr>
                  <th>ชื่อหน่วยงาน/สถานที่ฝึกงาน</th>
                  <th>หลักสูตร/กิจกรรม ฝึกอบรมงาน</th>
                  <th>ระยะเวลา</th>
                  <th>ปีที่อบรม/ฝึกงาน</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(docData?.trainingHistory) &&
                  docData?.trainingHistory.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{item?.placeName ?? "-"}</td>
                        <td>{item?.curriculum ?? "-"}</td>
                        <td>{item?.period ?? "-"}</td>
                        <td>{item?.year ?? "-"}</td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>

            <h2 className="section-title">ประวัติการทำงาน</h2>
            <table>
              <thead>
                <tr>
                  <th>ลำดับ</th>
                  <th>ชื่อบริษัท</th>
                  <th>ตำแหน่ง</th>
                  <th>หน้าที่รับผิดชอบ</th>
                  <th>เงินเดือน</th>
                  <th>จากวันที่</th>
                  <th>ถึงวันที่</th>
                  <th>สาเหตุที่ออก</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(docData?.workHistory) &&
                  docData?.workHistory.map((item, i) => {
                    let lineColor =
                      i % 2 === 0 && false
                        ? { "--bs-table-bg-type": "#F8F9FA" }
                        : {}
                    return (
                      <>
                        <tr className="" style={lineColor} key={i}>
                          <td className="text-center row-car" rowSpan={4}>
                            {i + 1}
                          </td>
                          <td className="text-left">{item.company}</td>
                          <td className="text-left">{item.rolePosition}</td>
                          <td className="text-left">{item.responsibility}</td>
                          <td className="text-left">{numComma(item.salary)}</td>
                          <td className="text-left">
                            {toDateStr(item.startDate)}
                          </td>
                          <td className="text-left">
                            {toDateStr(item.endDate)}
                          </td>
                          <td className="text-left">{item.reason}</td>
                        </tr>
                        <tr style={lineColor}>
                          <td className="text-left row-car">บุคคลอ้างอิง</td>
                          <td className="text-left" colSpan={6}>
                            {item.referPersonName}
                          </td>
                        </tr>
                        <tr style={lineColor}>
                          <td className="text-left row-car">ตำแหน่ง</td>
                          <td className="text-left" colSpan={6}>
                            {item.referPersonPosition}
                          </td>
                        </tr>
                        <tr style={lineColor}>
                          <td className="text-left row-car">เบอร์โทรศัพท์</td>
                          <td className="text-left" colSpan={6}>
                            {fn_helper.FORMAT.TEL(
                              `${item.referPersonMobileNo}`
                            )}
                          </td>
                        </tr>
                      </>
                    )
                  })}
              </tbody>
            </table>
          </div>
          <div id="pdf-content-page-3" className="pdf-preview">
            <div className="bHead">
              <div className="bLogo">
                <img src={jibIcon} alt="" />
              </div>
            </div>
            <div className="bTable">
              <h2>ทักษะด้านภาษา</h2>
              <table>
                <tr>
                  <th>ภาษา</th>
                  <th>การพูด</th>
                  <th>การอ่าน</th>
                  <th>การเขียน</th>
                  <th>การฟัง</th>
                </tr>
                <tr>
                  <td>ภาษาไทย</td>
                  <td>{toText(docData?.skill?.thSpeakingSkill)}</td>
                  <td>{toText(docData?.skill?.thReadingSkill)}</td>
                  <td>{toText(docData?.skill?.thWritingSkill)}</td>
                  <td>{toText(docData?.skill?.thListeningSkill)}</td>
                </tr>
                <tr>
                  <td>ภาษาอังกฤษ</td>
                  <td>{toText(docData?.skill?.enSpeakingSkill)}</td>
                  <td>{toText(docData?.skill?.enReadingSkill)}</td>
                  <td>{toText(docData?.skill?.enWritingSkill)}</td>
                  <td>{toText(docData?.skill?.enListeningSkill)}</td>
                </tr>
              </table>
            </div>

            <div className="bTable">
              <h2>ทักษะด้านคอมพิวเตอร์</h2>
              <table>
                <tr>
                  <td className="tHead" style={{ width: "270px" }}>
                    MS Excel
                  </td>
                  <td>{toText(docData?.skill?.computerSkillExcel)}</td>
                </tr>
                <tr>
                  <td className="tHead" style={{ width: "270px" }}>
                    MS Word
                  </td>
                  <td>{toText(docData?.skill?.computerSkillWord)}</td>
                </tr>
                <tr>
                  <td className="tHead" style={{ width: "270px" }}>
                    MS Windows
                  </td>
                  <td>{toText(docData?.skill?.computerSkillWindows)}</td>
                </tr>
                <tr>
                  <td className="tHead" style={{ width: "270px" }}>
                    MS PowerPoint
                  </td>
                  <td>{toText(docData?.skill?.computerSkillPowerPoint)}</td>
                </tr>
                <tr>
                  <td className="tHead" style={{ width: "270px" }}>
                    อื่น ๆ
                  </td>
                  <td>{toText(docData?.skill?.computerSkillOther)}</td>
                </tr>
              </table>
            </div>

            <div className="bTable">
              <h2>ทักษะด้านการพิมพ์</h2>
              <table>
                <tr>
                  <th>ภาษา</th>
                  <th>จำนวนคำ/นาที</th>
                </tr>
                <tr>
                  <td className="tHead">ภาษาไทย</td>
                  <td className="text-center">
                    {toText(docData?.skill?.typingThai)}
                  </td>
                </tr>
                <tr>
                  <td className="tHead">ภาษาอังกฤษ</td>
                  <td className="text-center">
                    {toText(docData?.skill?.typingEng)}
                  </td>
                </tr>
              </table>
            </div>
            <div className="bTable">
              <h2>ความสามารถในการขับรถยนต์</h2>
              <table>
                <tr>
                  <td className="tHead" style={{ width: "270px" }}>
                    รถยนต์ (ขับได้)
                  </td>
                  <td className="text-center">
                    {docData?.skill?.canDriveCar ? "ขับได้" : "ขับไม่ได้"}
                  </td>
                </tr>
                <tr>
                  <td className="tHead" style={{ width: "270px" }}>
                    รถยนต์ (มีใบขับขี่)
                  </td>
                  <td className="text-center">
                    {docData?.skill?.carLicense ? "มี" : "ไม่มี"}
                  </td>
                </tr>
                <tr>
                  <td className="tHead" style={{ width: "270px" }}>
                    รถจักรยานยนต์ (ขับได้)
                  </td>
                  <td className="text-center">
                    {docData?.skill?.canDriveMotercycle
                      ? "ขับได้"
                      : "ขับไม่ได้"}
                  </td>
                </tr>
                <tr>
                  <td className="tHead" style={{ width: "270px" }}>
                    รถจักรยานยนต์ (มีใบขับขี่)
                  </td>
                  <td className="text-center">
                    {docData?.skill?.motercycleLicense ? "มี" : "ไม่มี"}
                  </td>
                </tr>
              </table>
            </div>
            <div className="bTable">
              <h2>ทักษะหรือความสามารถอื่น</h2>
              <table>
                <tr>
                  <td className="tHead">
                    บันทึกทักษะอื่น ๆ
                    ที่สำคัญที่เป็นประโยชน์ในการปฏิบัติงานของตำแหน่ง
                  </td>
                </tr>
                <tr>
                  <td>
                    {
                      [
                        toText(docData?.skill?.otherLanguage),
                        toText(docData?.skill?.otherSkill)
                      ].map(skillText => <>{skillText && skillText !== '-'?<>{skillText}<br/></>:<></>}</>)
                    } 
                  </td>
                </tr>
              </table>
            </div>
            <div className="bTable">
              <h2>เกี่ยวกับ JIB</h2>
              <table>
                <tr>
                  <td className="tHead">รู้จัก JIB จาก</td>
                  <td colSpan="3">{toText(docData?.profile?.jibChannel)}</td>
                </tr>
                <tr>
                  <td className="tHead">บุคคลในบริษัท JIB ที่รู้จัก</td>
                  <td colSpan="3">{toText(docData?.profile?.jibPersonName)}</td>
                </tr>
                <tr>
                  <td className="tHead">ตำแหน่ง</td>
                  <td className="wide-column">
                    {toText(docData?.profile?.jibPersonPosition)}
                  </td>
                  <td className="tHead">ความสัมพันธ์</td>
                  <td>{toText(docData?.profile?.jibPersonRelation)}</td>
                </tr>
              </table>
            </div>
          </div>

          <div id="pdf-content-page-4" className="pdf-preview">
            <div className="bHead">
              <div className="bLogo">
                <img src={jibIcon} alt="" />
              </div>
            </div>
            <div className="bTable">
              <h2>การประเมินหลังสัมภาษณ์</h2>
              <table>
                <tr>
                  <th colSpan="2">สถานะ</th>
                </tr>
                <tr>
                  <td style={{ width: "270px" }}>ตรวจสอบข้อมูลแล้ว</td>
                  <td>
                    {docData?.application?.firstInterview ? "ใช่" : "ไม่"}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "270px" }}>รอบที่ 1 ฝ่ายบุคคล</td>
                  <td>
                    {STEP_JOB_OPTION.find(
                      item => item.id === docData?.application?.firstInterview
                    )?.name ?? "-"}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "270px" }}>รอบที่ 2 หัวหน้างาน</td>
                  <td>
                    {STEP_JOB_OPTION.find(
                      item => item.id === docData?.application?.secondInterview
                    )?.name ?? "-"}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "270px" }}>รอบที่ 3 ผู้บริหาร</td>
                  <td>
                    {STEP_JOB_OPTION.find(
                      item => item.id === docData?.application?.thirdInterview
                    )?.name ?? "-"}
                  </td>
                </tr>
              </table>
              <br />
              <div>
                <p style={{ lineHeight: '160%', textIndent: '40px' }}>ข้าพเจ้าขอรับรองว่า ข้อความดังกล่าวทั้งหมดในใบสมัครนี้เป็นความจริงทุกประการ หลังจากบริษัทจ้างเข้ามา
                <nobr>ทำงานแล้วปรากฏว่า</nobr> ข้อความในใบสมัครงานเอกสารที่นำมาแสดง หรือรายละเอียดที่ให้ไว้ไม่เป็นความจริง บริษัทฯ มีสิทธิ์<nobr>ที่จะเลิกจ้าง</nobr>ข้าพเจ้าได้โดยไม่ต้องจ่ายเงินชดเชยหรือค่าเสียหายใดๆ ทั้งสิ้น</p>
                <p style={{ lineHeight: '160%', textIndent: '40px' }}>
                I certify all statement given in this application form is true if any is found to be untrue after engagement.
                The Company has right to terminate my employment without any compensation or severance pay what soever.
                </p>
              </div>
              <div id="bSign">
                <div className="bGroup">
                  <p>...........................................</p>
                  <p>
                    ลายมือชื่อผู้สมัคร <br /> Applicants signature
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PrintDoc>
    </>
  )
}

JobApplicationList.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(JobApplicationList)
