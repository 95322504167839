import React from "react";
import AsyncSelect from "react-select/async";

const fetchOptions = async (attributeSetCode, inputSearch = "") => {
  if (!attributeSetCode) {
    return [];
  }

  // ใช้ fetch ทำ POST request
  const response = await fetch(`https://localhost:3000/web/api/jib/v1/diy-computer-set/part`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      attributeSetCode: attributeSetCode, // ส่ง attributeSetCode เป็น payload
      productName: inputSearch, // ส่ง name เป็น payload
    }),
  });

  const data = await response.json();
  console.log("🚀 ~ fetchOptions ~ data:", data)

  // แปลงข้อมูลให้เป็นรูปแบบที่ react-select เข้าใจ
  return data.resultData.map((item) => ({
    label: item.name,
    value: item.id,
  }));
};

const InputSelectApi = ({
  attributeSetCode = "cpu-one",
}) => {
  return (
    <AsyncSelect
      cacheOptions
      loadOptions={(newValue) => fetchOptions(attributeSetCode, newValue)} // ใช้ fetchOptions ในการดึงข้อมูล
      defaultOptions // โหลดตัวเลือกเริ่มต้น (option cache)
      placeholder="Select CPU DIY"
      isClearable={true}
    />
  );
};

export default InputSelectApi;
