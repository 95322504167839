import { get, post, put, patch } from "../Config"

export function SearchOrderHistory({ params = {} }) {
  return get({ url: `/v1/admin/orderHistory`, params })
}
export function GetOrderHistoryById({ uuid }) {
  return get({ url: `/v1/admin/orderHistory/${uuid}` })
}
export function CreateOrderHistory({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/orderHistory`, params, data })
}
export function UpdateOrderHistory({ params = {}, data = {}, id }) {
  return put({ url: `/v1/admin/orderHistory/${id}`, params, data })
}
export function PatchOrderHistory({ params = {}, data = {}, id }) {
  return patch({ url: `/v1/admin/orderHistory/${id}`, params, data })
}
export function DeleteOrderHistory({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/orderHistory/mutiDelete`, params, data })
}
export function GetOrderProductByOrderHistoryId({ uuid }) {
  return get({ url: `/v1/admin/orderHistory/orderProduct/${uuid}` })
}
