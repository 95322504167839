import { get, post, put, patch } from "../Config"

export function Search({ params = {} }) {
  return get({ url: `/v1/admin/installmentPlans`, params })
}
export function GetById({ id }) {
  return get({ url: `/v1/admin/installmentPlans/${id}` })
}
export function Create({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/installmentPlans`, params, data })
}
export function Update({ params = {}, data = {}, id }) {
  return put({ url: `/v1/admin/installmentPlans/${id}`, params, data })
}
export function Patch({ params = {}, data = {}, id }) {
  return patch({ url: `/v1/admin/installmentPlans/${id}`, params, data })
}
export function Delete({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/installmentPlans/mutiDelete`, params, data })
}
