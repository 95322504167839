import React, { useEffect, useState, useContext, useMemo } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import moment from "moment"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import TableContainer from "../../components/Common/TableContainer"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import {
  CreateAttributeSet,
  GetAttributeSetById,
  UpdateAttributeSet,
} from "services/api/module/AttributeSet"
import { SearchAttributes,PatchAttributes } from "services/api/module/Attributes"
import ModalContext from "../../contexts/ModalContext"
import UploadFileAPI from "components/UploadFileAPI"
import MyEditor from "components/MyEditor"
import InputSwitch from "components/Input/InputSwitch"

const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const AttributeSetTemplate = ({ pageView = "create" }) => {
  // POPUP
  const { mAlert } = useContext(ModalContext)

  // OTHER STATE
  const qParams = useParams()
  const qAttributeSetId = qParams.id
  const navigate = useNavigate()

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    code: Yup.string().required("Please Enter Code"),
    nameTh: Yup.string().required("Please Enter Detail TH"),
    nameEn: Yup.string().required("Please Enter Detail EN"),
    descriptionTh: Yup.string().required("Please Enter Detail EN"),
    descriptionEn: Yup.string().required("Please Enter Detail EN"),
    status: Yup.string().max(100).required("Please Enter Status"),
  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] =
    useState(validationSchema)
  const [contentList, setContentList] = useState([])
  const [contentListLoading, setContentListLoading] = useState(false)
  const [selectedContentId, setSelectedContentId] = useState([])
  

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: "",
      nameTh: "",
      nameEn: "",
      descriptionTh: "",
      descriptionEn: "",
      status: "",
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      console.log("🚀 ~ AttributeSetCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        code: values.code,
        nameTh: values.nameTh,
        nameEn: values.nameEn,
        descriptionTh: values.descriptionTh,
        descriptionEn: values.descriptionEn,
        status: Number(values.status),
        selectedAttributes: selectedContentId,
      }
      console.log("🚀 ~ onSave ~ payload:", payload)

      if (pageView === "update") {
        API.fetchUpdateAttributeSet(payload)
      } else {
        API.fetchCreateAttributeSet(payload)
      }
    },
  })

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("code", resData.code)
    validation.setFieldValue("nameTh", resData.nameTh)
    validation.setFieldValue("nameEn", resData.nameEn)
    validation.setFieldValue("descriptionTh", resData.descriptionTh)
    validation.setFieldValue("descriptionEn", resData.descriptionEn)
    validation.setFieldValue("status", resData.status)

    if (resData.attributesSetList && Array.isArray(resData.attributesSetList)) {
      const initialSelectedIds = resData.attributesSetList.map(
        attr => attr.attributeId
      )
      setSelectedContentId(initialSelectedIds)
    }
  }

  const API = {
    fetchGetAttributeSetById: async payload => {
      try {
        const response = await GetAttributeSetById({ id: payload.id })
        const resData = response.data
        if (resData.status == 202) {
          preFilInput(resData.data)
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchCreateAttributeSet: async payload => {
      try {
        const response = await CreateAttributeSet({ data: payload })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Created Data Success",
            content: "",
            onClose: () => {
              navigate("/product-management/attribute-set")
            },
          })
        }
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchUpdateAttributeSet: async payload => {
      try {
        const response = await UpdateAttributeSet({
          data: payload,
          id: payload.id,
        })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              navigate("/product-management/attribute-set")
            },
          })
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchContentList: async () => {
      try {
        const response = await SearchAttributes({})
        const resData = response?.data ?? []
        setContentList(resData.data)
      } catch (e) {
        console.log(e)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
    fetchPatchContent: async payload => {
      try {
        const response = await PatchAttributes({
          data: payload,
          id: payload.id,
        })
        const resData = response.data
        if (resData.status === 201) {
          mAlert.info({
            title: "Alert",
            subTitle: "Update Data Success",
            content: "",
            onClose: () => {
              fetchAPI()
            },
          })
        }
      } catch (err) {
        console.log("err ", err)
        mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
            //  alert("onClose")
          },
        })
      }
    },
  }

  const onSelectionChange = (
    selectedValue = {},
    oldValues = [],
    option = [],
    mode = ""
  ) => {
    const selectedId = selectedValue?.id
    const tmpOldValues = _.cloneDeep(oldValues)
    const findOldValue = tmpOldValues.filter(id => id === selectedId)

    let updateState = []
    if (findOldValue && findOldValue.length) {
      updateState = tmpOldValues.filter(id => id != selectedId)
    } else {
      updateState = tmpOldValues
      updateState.push(selectedId)
    }

    if (mode === "all") {
      if (tmpOldValues.length >= option.length) {
        updateState = []
      } else {
        updateState = option.map(item => item.id)
      }
    }
    console.log("🚀 ~ onChangeEnum ~ updateState:", updateState)
    return updateState
  }

  const onPatchRow = (id = 0, fieldName = "", value = null, valueReset = null) => {
    const payload = { id: id, fieldName: fieldName, value: value, valueReset: valueReset }
    API.fetchPatchContent(payload)
  }

  const columns = useMemo(
    () => [
      {
        id: "colcheckbox",
        header: cellProps => {
          const row = cellProps?.row?.original
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Input
                type={`checkbox`}
                checked={contentList.length === selectedContentId.length}
                onClick={() => {
                  let updateData = onSelectionChange(
                    row,
                    selectedContentId,
                    contentList,
                    "all"
                  )
                  setSelectedContentId(updateData)
                }}
                readOnly
                disabled={["view"].includes(pageView)}
              />
            </div>
          )
        },
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          const row = cellProps?.row?.original
          return (
            <>
              <div
                id={cellProps.row.id}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Input
                  type={`checkbox`}
                  value={cellProps.row.original.id}
                  checked={selectedContentId.includes(row.id)}
                  onClick={() => {
                    let updateData = onSelectionChange(
                      row,
                      selectedContentId,
                      contentList
                    )
                    setSelectedContentId(updateData)
                  }}
                  readOnly
                  disabled={["view"].includes(pageView)}
                />
              </div>
            </>
          )
        },
      },
      {
        header: "#",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {parseInt(cellProps.row.id) + 1}
              </div>
            </>
          )
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        // cell: cellProps => <RowStatus status={cellProps.row.original.status} />,
        cell: cellProps => {
          const { id, status } = cellProps.row.original
          return (
            <InputSwitch
              labelON="Active"
              labelOFF="Inactive"
              value={status == 1}
              onChange={value => {
                const updateValue = value ? 1 : 0
                onPatchRow(id, "status", updateValue, 0)
              }}
              onColor="#34c38f"
              offColor="#f1b44c"
              width={72}
              disabled
            />
          )
        },
      },
      {
        header: "Attribute Code",
        accessorKey: "code",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Label TH",
        accessorKey: "labelTh",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Label EN",
        accessorKey: "labelEn",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Input Type",
        accessorKey: "inputType",
        enableColumnFilter: false,
        enableSorting: true,
      },
    ],
    [selectedContentId, contentList]
  )

  // กรณี Edit
  useEffect(() => {
    if (["update", "view"].includes(pageView)) {
      const payload = { id: qAttributeSetId }
      API.fetchGetAttributeSetById(payload)
    }
  }, [])

  const fetchAPI = () => {
    const payload = {}
    API.fetchContentList(payload)
  }

  useEffect(() => {
    setContentListLoading(true)
    fetchAPI()
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title =
    `AttributeSet | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} AttributeSet`}
            breadcrumbItems={[
              { title: "AttributeSet", link: "banner/banner-platform" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <Col className="col-12">
                        <Row className="mb-3">
                          <Col className="col-12">
                            <h5 className="mb-3">Attribute Set</h5>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="code"
                              className="col-md-12 col-form-label"
                            >
                              Code
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="code"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="code"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.code}
                                invalid={
                                  validation.touched.code &&
                                  validation.errors.code
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.code &&
                                validation.errors.code && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.code}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Name TH
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="nameTh"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="nameTh"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.nameTh}
                                invalid={
                                  validation.touched.nameTh &&
                                  validation.errors.nameTh
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.nameTh &&
                                validation.errors.nameTh && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.nameTh}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Name En
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="nameEn"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="nameEn"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.nameEn}
                                invalid={
                                  validation.touched.nameEn &&
                                  validation.errors.nameEn
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.nameEn &&
                                validation.errors.nameEn && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.nameEn}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Description Th
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="descriptionTh"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="descriptionTh"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.descriptionTh}
                                invalid={
                                  validation.touched.descriptionTh &&
                                  validation.errors.descriptionTh
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.descriptionTh &&
                                validation.errors.descriptionTh && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.descriptionTh}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="category_name"
                              className="col-md-12 col-form-label"
                            >
                              Description En
                            </label>
                            <div className="col-md-12">
                              <Input
                                id="descriptionEn"
                                className="form-control"
                                type="text"
                                placeholder=""
                                name="descriptionEn"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.descriptionEn}
                                invalid={
                                  validation.touched.descriptionEn &&
                                  validation.errors.descriptionEn
                                    ? true
                                    : false
                                }
                                disabled={pageView === "view"}
                              />
                              {validation.touched.descriptionEn &&
                                validation.errors.descriptionEn && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.descriptionEn}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                          <Col className="col-6 mb-3">
                            <label
                              htmlFor="status"
                              className="col-md-12 col-form-label"
                            >
                              Status (Active/Inactive)
                              <span className="t-require">*</span>
                            </label>
                            <div className="col-md-12">
                              <Select
                                placeholder="Select Status"
                                value={fn_helper.FNFORM.getObjectValue(
                                  STATUS_OPTION,
                                  `${validation.values.status}`
                                )}
                                onChange={value =>
                                  validation.setFieldValue("status", value.id)
                                }
                                getOptionValue={option => option.id}
                                getOptionLabel={option => option.name}
                                options={STATUS_OPTION}
                                className={`select2-selection ${
                                  validation.touched.status &&
                                  validation.errors.status
                                    ? "input-err"
                                    : ""
                                }`}
                                isDisabled={pageView === "view"}
                              />
                              {validation.touched.status &&
                                validation.errors.status && (
                                  <div
                                    type="invalid"
                                    className="invalid-feedback d-block"
                                  >
                                    {validation.errors.status}
                                  </div>
                                )}
                              {validation.touched.status &&
                                validation.errors.status && (
                                  <FormFeedback type="invalid">
                                    {validation.errors.status}
                                  </FormFeedback>
                                )}
                            </div>
                          </Col>
                        </Row>
                        <Card>
                          <CardBody>
                            <CardTitle className="h4">Detail</CardTitle>
                            <div className="d-flex justify-content-between align-items-center">
                              <p className="card-title-desc">
                                (Selected attribute)
                              </p>
                              {pageView != "view" && (
                              <div className="flex-shrink-0 d-flex gap-2">
                                <a className="btn btn-info" onClick={() => setSelectedContentId([])}> Clear Selected All</a>
                              </div>
                              )}
                            </div>
                            <Col className="col-12 mb-3">
                              <TableContainer
                                noTop={true}
                                tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                                columns={columns}
                                data={contentList.filter(item => selectedContentId.includes(item.id))}
                              />
                            </Col>
                          </CardBody>
                        </Card>
                        <Row>
                          <Col className="col-12">
                            <h5 className="mb-3">Attribute List</h5>
                          </Col>
                          <TableContainer
                            isAddButton={false} // Set to true if you want an add button
                            columns={columns} // Ensure `columns` is defined with your required structure
                            data={contentList || []} // Adjust to match the data for your attribute list
                            isGlobalFilter={true} // Set true if you need a global filter for the table
                            isPagination={true}
                            SearchPlaceholder="Search"
                            pagination="pagination"
                            paginationWrapper="dataTables_paginate paging_simple_numbers"
                            tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                            rootMenu="systemsettings"
                            subMenu="usersetting"
                            isCustomPageSize={true}
                          />
                        </Row>
                      </Col>
                    </Row>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "8px",
                      }}
                    >
                      {pageView == "view" ? (
                        <>
                          <button
                            className="btn btn-primary w-md"
                            onClick={() =>
                              navigate("/product-management/attribute-set")
                            }
                          >
                            BACK
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            SAVE
                          </button>
                          <button
                            className="btn btn-secondary w-md"
                            onClick={() =>
                              navigate("/product-management/attribute-set")
                            }
                          >
                            CANCEL
                          </button>
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(AttributeSetTemplate)
