import { get, post, put, patch } from "../Config"

export function SearchPromotionArticleCategory({ params = {} }) {
  return get({ url: `/v1/admin/promotionArticleCategory`, params })
}
export function GetPromotionArticleCategoryById({ id }) {
  return get({ url: `/v1/admin/promotionArticleCategory/${id}` })
}
export function CreatePromotionArticleCategory({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/promotionArticleCategory`, params, data })
}
export function UpdatePromotionArticleCategory({ params = {}, data = {}, id }) {
  return put({ url: `/v1/admin/promotionArticleCategory/${id}`, params, data })
}
export function PatchPromotionArticleCategory({ params = {}, data = {}, id }) {
  return patch({ url: `/v1/admin/promotionArticleCategory/${id}`, params, data })
}
export function DeletePromotionArticleCategory({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/promotionArticleCategory/mutiDelete`, params, data })
}
export function CheckDuplicatePromotionArticleCategory({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/promotionArticleCategory/duplicate`, params, data })
}
