import { get, post, put, patch } from "../Config"

export function SearchCommercialProduct({ params = {} }) {
  return get({ url: `/v1/admin/commercialProduct`, params })
}
export function GetCommercialProductById({ id }) {
  return get({ url: `/v1/admin/commercialProduct/${id}` })
}
export function CreateCommercialProduct({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/commercialProduct`, params, data })
}
export function UpdateCommercialProduct({ params = {}, data = {} , id }) {
  return put({ url: `/v1/admin/commercialProduct/${id}`, params, data })
}
export function PatchCommercialProduct({ params = {}, data = {} , id }) {
  return patch({ url: `/v1/admin/commercialProduct/${id}`, params, data })
}
export function DeleteCommercialProduct({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/commercialProduct/mutiDelete`, params, data })
}
