import { get, post, put, patch } from "../Config"

export function SearchPromotionTheme({ params = {} }) {
  return get({ url: `/v1/admin/promotionTheme`, params })
}
export function GetPromotionThemeById({ id }) {
  return get({ url: `/v1/admin/promotionTheme/${id}` })
}
export function CreatePromotionTheme({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/promotionTheme`, params, data })
}
export function UpdatePromotionTheme({ params = {}, data = {}, id }) {
  return put({ url: `/v1/admin/promotionTheme/${id}`, params, data })
}
export function PatchPromotionTheme({ params = {}, data = {}, id }) {
  return patch({ url: `/v1/admin/promotionTheme/${id}`, params, data })
}
export function DeletePromotionTheme({ params = {}, data = {} }) {
  return post({ url: `/v1/admin/promotionTheme/mutiDelete`, params, data })
}
